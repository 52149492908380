import * as types from './types'
import {
  getAllDeployments,
  addDeployment,
  deleteDeployment,
  getDeploymentConfig,
  updateDeploymentConfigs,
  getAllDeploymentsBelongingToAServer,
} from '@/services/deployment'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetAllDeployments({ commit }, projectUId) {
    commit(types.DEPLOYMENTS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllDeployments(projectUId)
        .then(response => {
          commit(types.DEPLOYMENTS, response.data.data)
          commit(types.DEPLOYMENTS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DEPLOYMENTS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetAllServerDeployments({ commit }, serverSlug) {
    commit(types.SERVER_DEPLOYMENTS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllDeploymentsBelongingToAServer(serverSlug)
        .then(response => {
          commit(types.SERVER_DEPLOYMENTS, response.data.data)
          commit(types.SERVER_DEPLOYMENTS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.SERVER_DEPLOYMENTS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAddDeployment({ commit }, params) {
    commit(types.DEPLOYMENT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addDeployment(params)
        .then(response => {
          commit(types.DEPLOYMENT, response.data.data)
          commit(types.DEPLOYMENT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DEPLOYMENT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAddDeploymentConfigs({ commit }, params) {
    commit(types.ADD_DEPLOYMENT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addDeployment(params)
        .then(response => {
          commit(types.ADD_DEPLOYMENT, response.data.data)
          commit(types.ADD_DEPLOYMENT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ADD_DEPLOYMENT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteDeployment({ commit }, params) {
    commit(types.DELETE_DEPLOYMENT, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteDeployment(params)
        .then(response => {
          commit(types.DELETE_DEPLOYMENT, response.data.data)
          commit(types.DELETE_DEPLOYMENT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_DEPLOYMENT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetDeploymentConfigs({ commit }, deploymentSlug) {
    commit(types.DEPLOYMENT_CONFIGS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getDeploymentConfig(deploymentSlug)
        .then(response => {
          commit(types.DEPLOYMENT_CONFIGS, response.data.data)
          commit(types.DEPLOYMENT_CONFIGS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DEPLOYMENT_CONFIGS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleUpdateDeploymentConfigs({ commit }, params) {
    commit(types.UPDATE_DEPLOYMENT_CONFIGS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      updateDeploymentConfigs(params)
        .then(response => {
          commit(types.UPDATE_DEPLOYMENT_CONFIGS, response.data.data)
          commit(types.UPDATE_DEPLOYMENT_CONFIGS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.UPDATE_DEPLOYMENT_CONFIGS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
