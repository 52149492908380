<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <div class="alert alert-success" role="alert" v-if="success">
        {{ message }}
      </div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <h6>Edit Project Information</h6>
      <p class="description">You can change this later</p>
      <div class="form-group">
        <label for="project_name">Project Name</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          placeholder="Name of this project"
          maxlength="20"
          v-model="project.name"
        />
      </div>
      <div class="form-group">
        <label for="project_category">Project Category</label>
        <select
          name="project_category"
          id="project_category"
          class="form-control"
          v-model="project.category_id"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option
            v-for="category in categories"
            v-bind:key="category.id"
            v-bind:value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="project_description">Project Description</label>
        <textarea
          name="project_description"
          id="project_description"
          class="form-control"
          cols="30"
          rows="10"
          maxlength="300"
          placeholder="Briefly what this project is about"
          v-model="project.description"
        ></textarea>
      </div>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click.prevent="updateProject"
        >
          <span class="">Update Project</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'UpdateProjectModal',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
    },
    businessSlug: {
      type: String,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: false,
      message: '',
      error: '',
      project: {
        name: '',
        category_id: '',
        description: '',
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleEditModal', value)
      },
    },
  },
  methods: {
    ...mapActions(['handleAddProject']),

    async updateProject() {
      this.loading = true
      this.error = ''
      this.success = false
      this.message = ''
      //destruct the required properties
      const { id, category_id, name, description, business_id } = this.project

      const projectData = {
        project_id: id,
        category_id,
        name,
        description,
        business_id,
      }

      const params = {
        slug: this.businessSlug,
        bodyData: projectData,
      }

      this.$store
        .dispatch('handleUpdateProject', params)
        .then(response => {
          this.success = true
          this.message = response.data.message
          this.loading = false
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },

    removeError() {
      this.error = ''
    },
    allFilled() {
      const { name, description, category_id } = this.project
      return name !== '' && description !== '' && category_id !== ''
    },
  },
  updated() {
    this.project = this.projectDetails
    this.error = ''
    this.success = false
    this.message = ''
  },
}
</script>
