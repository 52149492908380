<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Add a collaborator</h6>
      <label> Add someone from your team to this project. </label>
      <div class="form-group">
        <label for="member">Select someone from your team</label>
        <br />
        <select
          name="member"
          id="member"
          class="form-control"
          v-model="user.id"
        >
          <option value="" disabled>Select an Option</option>
          <option
            :value="member.profile.id"
            v-for="(member, index) in teamMembers"
            :key="index"
          >
            {{ member.profile.first }} {{ member.profile.last }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!userIsSelected()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="addMember"
        >
          <!-- :inactive="!validate" -->
          <span class="">Add member</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddMemberModal',
  components: { Button },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    projectUid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      error: '',
      success: '',
      loading: false,
      user: {
        id: '',
      },
    }
  },
  methods: {
    ...mapActions(['handleGetOneProject']),
    addMember() {
      this.loading = true
      this.error = ''
      this.success = ''

      const data = {
        user_id: this.user.id,
        project_id: this.projectId,
      }

      this.$store
        .dispatch('handleSubmitTeamMember', data)
        .then(async response => {
          this.success = response.data.message
          await this.handleGetOneProject(this.projectUid)

          this.redirect()
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
    redirect() {
      this.show = false
      this.loading = false
      this.success = ''
      this.user = {}
    },
    userIsSelected() {
      return this.user.id !== ''
    },
  },
  computed: {
    ...mapGetters({
      getProject: 'getProject',
    }),
  },
  watch: {
    getProject: {
      handler(value) {
        this.project = value
      },
    },
    showModal: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
}
</script>
