export const PROJECT_REPOS_STATUS = 'PROJECT_REPOS_STATUS'
export const PROJECT_REPOS = 'PROJECT_REPOS'
export const DELETE_REPO = 'DELETE_REPO'
export const DELETE_REPO_STATUS = 'DELETE_REPO_STATUS'
export const ALL_COMMITS = 'ALL_COMMITS'
export const ALL_COMMITS_STATUS = 'ALL_COMMITS_STATUS'
export const USER_INTEGRATIONS = 'ALL_USER_INTEGRATIONS'
export const USER_INTEGRATIONS_STATUS = 'USER_INTEGRATIONS_STATUS'
export const ADD_REPOSITORY_STATUS = 'ADD_REPOSITORY_STATUS'
export const ADD_REPOSITORY = 'ADD_REPOSITORY'
export const FETCH_REPO_INTEGRATIONS = 'FETCH_REPO_INTEGRATIONS'
export const FETCH_REPO_INTEGRATIONS_STATUS = 'FETCH_REPO_INTEGRATIONS_STATUS'
export const IMPORT_REPO = 'IMPORT_REPO'
export const IMPORT_REPO_STATUS = 'IMPORT_REPO_STATUS'
