import http from '../../config/axios'

export const projectUrls = {
  GET_ALL_URL: 'projects/all',
  GET_ONE_URL: 'projects',
  REMOVE_MEMBER_URL: 'projects/remove-member',
  PROJECT_CATEGORIES_URL: 'api/categories',
  ADD_PROJECT: 'projects/create',
  INVITE_URL: 'invitations/invite',
  INVITATIONS_URL: 'invitations',
  TRANSFER_URL: 'projects/transfer-ownership',
  UPDATE_URL: 'projects/update',
}

const getAllUsersProjects = async slug => {
  return await http.get(`${projectUrls.GET_ALL_URL}/${slug}`)
}

const getOneProject = async slug => {
  return await http.get(`${projectUrls.GET_ONE_URL}/${slug}`)
}

const getProjectCategories = async () => {
  return await http.get(projectUrls.PROJECT_CATEGORIES_URL)
}

const getProjectMembers = async projectID => {
  return await http.get(`${projectUrls.GET_ONE_URL}/${projectID}/members`)
}

const getInvitations = async userSlug => {
  return await http.get(`${projectUrls.INVITATIONS_URL}/${userSlug}`)
}

const deleteProjectMember = async memberID => {
  return await http.delete(`${projectUrls.REMOVE_MEMBER_URL}/${memberID}`)
}

const addProject = async params => {
  return await http.post(projectUrls.ADD_PROJECT, params)
}

const inviteCollaborator = async params => {
  return await http.post(projectUrls.INVITE_URL, params)
}

const transferOwnership = async params => {
  return await http.post(projectUrls.TRANSFER_URL, params)
}

const updateProject = async params => {
  return await http.post(
    `${projectUrls.UPDATE_URL}/${params.slug}`,
    params.bodyData
  )
}

const deleteProjectById = async params => {
  return await http.delete(
    `${projectUrls.GET_ONE_URL}/${params.projectUid}/delete/${params.businessSlug}`
  )
}

export {
  getAllUsersProjects,
  getOneProject,
  getProjectCategories,
  addProject,
  deleteProjectById,
  getProjectMembers,
  deleteProjectMember,
  inviteCollaborator,
  transferOwnership,
  getInvitations,
  updateProject,
}
