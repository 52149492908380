import * as types from '@/store/modules/project/types'

export default {
  [types.PROJECTS_STATUS](state, value) {
    state.projectsStatus = value
  },
  [types.PROJECTS](state, payload) {
    state.projects = payload
  },
  [types.PROJECT_STATUS](state, value) {
    state.projectStatus = value
  },
  [types.PROJECT](state, payload) {
    state.project = payload
  },
  [types.PROJECT_CATEGORY](state, payload) {
    state.allProjectCategories = payload
  },
  [types.ADD_PROJECT](state, payload) {
    state.projectCreated = payload
  },
  [types.PROJECTS_SUMMARY](state, payload) {
    state.projectsSummary = payload
  },
  [types.PROJECTS_ARRAY](state, payload) {
    state.projectsArray = payload
  },
  [types.DELETE_STATUS](state, payload) {
    state.deleteStatus = payload
  },
  [types.DELETE](state, payload) {
    state.delete = payload
  },
  [types.PROJECT_MEMBERS_STATUS](state, payload) {
    state.projectMembersStatus = payload
  },
  [types.PROJECT_MEMBERS](state, payload) {
    state.projectMembers = payload
  },
  [types.DELETE_PROJECT_MEMBER](state, payload) {
    state.deleteProjectMember = payload
  },
  [types.DELETE_PROJECT_MEMBER_STATUS](state, payload) {
    state.deleteProjectMemberStatus = payload
  },
  [types.INVITE_COLLABORATOR_STATUS](state, payload) {
    state.inviteCollaboratorStatus = payload
  },
  [types.INVITE_COLLABORATOR](state, payload) {
    state.inviteCollaborator = payload
  },
  [types.TRANSFER_OWNERSHIP_STATUS](state, payload) {
    state.transferOwnershipStatus = payload
  },
  [types.TRANSFER_OWNERSHIP](state, payload) {
    state.transferOwnership = payload
  },
  [types.INVITATIONS_STATUS](state, payload) {
    state.invitationsStatus = payload
  },
  [types.INVITATIONS](state, payload) {
    state.invitations = payload
  },
  [types.UPDATE_PROJECT_STATUS](state, payload) {
    state.updateProjectStatus = payload
  },
  [types.UPDATE_PROJECT](state, payload) {
    state.updateProject = payload
  },
}
