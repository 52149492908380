import * as types from './types'
import {
  getAllUsersFalcons,
  getFalconsLogs,
  pilotTemplates,
  pilotDetails,
  deletePilot,
  provisionPilot,
  rebootPilot,
  turnOnPilot,
  turnOffPilot,
  addAccess,
  deleteAccess,
  getAccessEntries,
  addFalcon,
  fetchProjectWithSSH,
} from '@/services/falcon'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetUsersFalcons({ commit }, projectSlug) {
    commit(types.FALCONS_STATUS, 'LOADING')
    getAllUsersFalcons(projectSlug)
      .then(response => {
        commit(types.FALCONS, response.data.data)
        commit(types.FALCONS_STATUS, 'COMPLETED')
      })
      .catch(error => {
        formatError(error)
        commit(types.FALCONS_STATUS, 'PENDING')
      })
  },

  handleGetFalconLogs({ commit }, projectSlug) {
    commit(types.FALCONS_LOGS_STATUS, 'LOADING')
    getFalconsLogs(projectSlug)
      .then(response => {
        commit(types.FALCONS_LOGS, response.data.data)
        commit(types.FALCONS_LOGS_STATUS, 'COMPLETED')
      })
      .catch(error => {
        formatError(error)
        commit(types.FALCONS_LOGS_STATUS, 'PENDING')
      })
  },

  handleGetPilotTemplates({ commit }) {
    commit(types.PILOT_TEMPLATES_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      pilotTemplates()
        .then(response => {
          commit(types.PILOT_TEMPLATES, response.data.data)
          commit(types.PILOT_TEMPLATES_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PILOT_TEMPLATES_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetPilotDetails({ commit }) {
    commit(types.PILOT_DETAILS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      pilotDetails()
        .then(response => {
          commit(types.PILOT_DETAILS, response.data.data)
          commit(types.PILOT_DETAILS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PILOT_DETAILS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteFalcon({ commit }, slug) {
    commit(types.DELETE_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deletePilot(slug)
        .then(response => {
          commit(types.DELETE_FALCON, response.data.data)
          commit(types.DELETE_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleProvisionFalcon({ commit }, id) {
    commit(types.PROVISION_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      provisionPilot(id)
        .then(response => {
          commit(types.PROVISION_FALCON, response.data.data)
          commit(types.PROVISION_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROVISION_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleRebootFalcon({ commit }, id) {
    commit(types.REBOOT_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      rebootPilot(id)
        .then(response => {
          commit(types.REBOOT_FALCON, response.data.data)
          commit(types.REBOOT_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.REBOOT_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handlePowerOnFalcon({ commit }, id) {
    commit(types.POWER_ON_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      turnOnPilot(id)
        .then(response => {
          commit(types.POWER_ON_FALCON, response.data.data)
          commit(types.POWER_ON_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.POWER_ON_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handlePowerOffFalcon({ commit }, id) {
    commit(types.POWER_OFF_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      turnOffPilot(id)
        .then(response => {
          commit(types.POWER_OFF_FALCON, response.data.data)
          commit(types.POWER_OFF_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.POWER_OFF_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAddAccess({ commit }, params) {
    commit(types.ADD_ACCESS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addAccess(params)
        .then(response => {
          commit(types.ADD_ACCESS, response.data.data)
          commit(types.ADD_ACCESS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ADD_ACCESS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteAccess({ commit }, slug) {
    commit(types.DELETE_ACCESS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteAccess(slug)
        .then(response => {
          commit(types.DELETE_ACCESS, response.data.data)
          commit(types.DELETE_ACCESS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_ACCESS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetAccessEntries({ commit }, slug) {
    commit(types.GET_ACCESS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAccessEntries(slug)
        .then(response => {
          commit(types.GET_ACCESS, response.data.data)
          commit(types.GET_ACCESS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.GET_ACCESS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAddFalcon({ commit }, params) {
    commit(types.ADD_FALCON_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addFalcon(params)
        .then(response => {
          commit(types.ADD_FALCON, response.data.data)
          commit(types.ADD_FALCON_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ADD_FALCON_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleFetchProjectWithSSH({ commit }, projectUID) {
    commit(types.PROJECT_SSH_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      fetchProjectWithSSH(projectUID)
        .then(response => {
          commit(types.PROJECT_SSH, response.data.data)
          commit(types.PROJECT_SSH_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROJECT_SSH_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
