import transactionMutations from './mutations'
import transactionActions from './actions'

export default {
  state: {
    billings: [],
    billingsStatus: 'PENDING',
    billingsByType: [],
    billingsByTypeStatus: 'PENDING',
    initPaymentStatus: 'PENDING',
    initPayment: {},
    renewPilotStatus: 'PENDING',
    renewPilot: {},
    verifyPaymentStatus: 'PENDING',
    verifyPayment: {},
    activateSubscription: {},
    activateSubscriptionStatus: 'PENDING',
    expertsPricing: [],
    expertsPricingStatus: 'PENDING',
    hireExpertStatus: 'PENDING',
    hireExpert: {},
  },
  actions: { ...transactionActions },
  mutations: { ...transactionMutations },
  getters: {
    getBillings(state) {
      return state.billings
    },
    getBillingsStatus(state) {
      return state.billingsStatus
    },
    getBillingsByTypeStatus(state) {
      return state.billingsByTypeStatus
    },
    getInitPaymentStatus(state) {
      return state.initPaymentStatus
    },
    getInitPayment(state) {
      return state.initPayment
    },
    getRenewPilotStatus(state) {
      return state.renewPilotStatus
    },
    getVerifyPaymentStatus(state) {
      return state.verifyPaymentStatus
    },
    getActivateSubscriptionStatus(state) {
      return state.activateSubscriptionStatus
    },
    getExpertsPricing(state) {
      return state.expertsPricing
    },
    getExpertsPricingStatus(state) {
      return state.expertsPricingStatus
    },
    getHireExpertStatus(state) {
      return state.hireExpertStatus
    },
  },
}
