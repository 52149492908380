export const USER_INFO = 'USER_INFO'
export const BUSINESS_INFO = 'BUSINESS_INFO'
export const USER_STATUS = 'USER_STATUS'
export const USER_SLUG = 'USER_SLUG'
export const USER_SLUG_STATUS = 'USER_SLUG_STATUS'
export const BUSINESS_ID = 'BUSINESS_ID'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_STATUS = 'UPDATE_PROFILE_STATUS'
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR'
export const UPLOAD_AVATAR_STATUS = 'UPLOAD_AVATAR_STATUS'
