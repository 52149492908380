<template>
  <section class="page-hire__success">
    <a class="page__head page__back" @click="$emit('goBack')">
      <div class="mr-2">
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <use xlink:href="/sprite.svg#icon-angle-left"></use>
        </svg>
      </div>
      <div class="mt-1">
        <p>Go Back</p>
      </div>
    </a>
    <div class="row application-steps">
      <div class="col-md-4 step" v-for="(step, index) in steps" :key="index">
        <div class="number">
          <h5>{{ index + 1 }}</h5>
        </div>
        <h6 class="title">{{ step.title }}</h6>
        <p>{{ step.description }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ApplicationSuccess',
  data() {
    return {
      steps: [
        {
          title: 'Sign In',
          description:
            'Sign up and start your free 7 day trial to get introduced to your Account Manager and run through your development requirements',
        },
        {
          title: 'Meet Your Developer',
          description:
            'Your account manager will hand select the best developer for you based on your product needs. You will then have an intro call to meet your developer and go through the process.',
        },
        {
          title: 'Affordable',
          description:
            'Fybrr allows you to build out an affordable development team, without compromising on quality and expertise.',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.page__back {
  cursor: pointer;
}
.application-steps {
  margin-top: 50px;
  .step {
    .number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: #447ffc;
    }
    .title {
      margin: 20px 0;
    }
  }
}
</style>
