<template>
  <div class="overlay">
    <AtomSpinner :animation-duration="1000" :size="60" :color="'#FFFFFF'" />
  </div>
</template>
<script>
import { AtomSpinner } from 'epic-spinners'
export default {
  name: 'OverlayLoading',
  components: { AtomSpinner },
}
</script>
<style>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
