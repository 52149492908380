import HomePage from '@/views/pages/index'
import ContactPage from '@/views/pages/contact'
import FeaturesPage from '@/views/pages/features'
import PricingPage from '@/views/pages/pricing'
import HireExpertPage from '@/views/pages/hire-expert'
import AboutPage from '@/views/pages/about'
import Login from '@/views/authentication/login'
import Logout from '@/views/authentication/logout'
import Register from '@/views/authentication/register'
import ForgotPassword from '@/views/authentication/forgot-password'
import ResetPassword from '@/views/authentication/reset-password'
import DashboardOverview from '@/views/dashboard/overview'
import DashboardProjects from '@/views/dashboard/project/index'
import CreateProjects from '@/views/dashboard/project/createProject'
import SingleProject from '@/views/dashboard/project/singleProject'
import DashboardWebsiteBuilder from '@/views/dashboard/project/builder/index'
import CreateWebsite from '@/views/dashboard/project/builder/createWebsite'
import Store from '@/views/dashboard/project/ecommerce/index'
import CreateStore from '@/views/dashboard/project/ecommerce/createStore'
import Repository from '@/views/dashboard/project/repository/index'
import Hosting from '@/views/dashboard/project/hosting/index'
import FetchRepo from '@/views/dashboard/project/repository/fetch-repo'
import ManageSSH from '@/views/dashboard/project/hosting/manage-ssh'
import Deployments_For_Hosting from '@/views/dashboard/project/hosting/deployments'
import AddPilot from '@/views/dashboard/project/hosting/add-pilot'
import Collaborators from '@/views/dashboard/project/collaborators'
import Workflow from '@/views/dashboard/project/workflow'
import Deployment from '@/views/dashboard/project/deployment'
import ViewCommand from '@/views/dashboard/project/deployment/command-form'
import ViewEnvironmentVariables from '@/views/dashboard/project/deployment/variable-form'
import ProjectIntegrations from '@/views/dashboard/project/integrations'
import Integrations from '@/views/dashboard/Integration'
import Transfer from '@/views/dashboard/project/transfer'
import Billing from '@/views/dashboard/billing'
import HireExpert from '@/views/dashboard/hireExpert'
import DashboardTeams from '@/views/dashboard/team'
import Verify from '@/views/authentication/verify'
import VisitorGuard from './visitor-guard'
import AuthGuard from './auth-guard'
import Profile from '@/views/dashboard/profile'
import WebsiteBuilder from '@/views/website/index'
import TransactionDownload from '@/views/dashboard/billing-download'

export default [
  {
    path: '/',
    component: HomePage,
    name: 'HomePage',
    meta: {
      layout: 'landing',
    },
  },
  {
    path: '/contact',
    component: ContactPage,
    name: 'ContactPage',
    meta: {
      layout: 'landing',
      class: 'contact',
    },
  },
  {
    path: '/pricing',
    component: PricingPage,
    name: 'PricingPage',
    meta: {
      layout: 'landing',
    },
  },
  {
    path: '/hire-expert',
    component: HireExpertPage,
    name: 'HireExpertPage',
    meta: {
      layout: 'landing',
    },
  },
  {
    path: '/features',
    component: FeaturesPage,
    name: 'FeaturesPage',
    meta: {
      layout: 'landing',
    },
  },
  {
    path: '/about',
    component: AboutPage,
    name: 'AboutPage',
    meta: {
      layout: 'landing',
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    beforeEnter: VisitorGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'Register',
    beforeEnter: VisitorGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword',
    beforeEnter: VisitorGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/password/:token',
    component: ResetPassword,
    name: 'ResetPassword',
    beforeEnter: VisitorGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/verify/:token',
    component: Verify,
    name: 'Verify',
    beforeEnter: VisitorGuard,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/404/index.vue'),
    meta: {
      layout: 'empty',
    },
  },
  //Dashboard
  {
    path: '/dashboard',
    component: DashboardOverview,
    name: 'DashboardOverview',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/projects',
    component: DashboardProjects,
    name: 'DashboardProjects',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/projects/create',
    component: CreateProjects,
    name: 'CreateProjects',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/project/:uid',
    component: SingleProject,
    name: 'SingleProject',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/builder',
    component: DashboardWebsiteBuilder,
    name: 'DashboardWebsiteBuilder',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/builder/create',
    component: CreateWebsite,
    name: 'CreateWebsite',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/store',
    component: Store,
    name: 'Store',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/store/create',
    component: CreateStore,
    name: 'CreateStore',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/repository',
    component: Repository,
    name: 'Repository',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/hosting',
    component: Hosting,
    name: 'Hosting',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  //This is to fetch the repos for a particular platform
  {
    path: '/dashboard/project/repository/:uid/:platform',
    component: FetchRepo,
    name: 'FetchRepo',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/hosting/:uid/add-pilot',
    component: AddPilot,
    name: 'AddPilot',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },

  //This is to manage the ssh users
  {
    path: '/dashboard/project/:uid/:falconid/hosting/:slug',
    component: ManageSSH,
    name: 'ManageSSH',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },

  {
    path: '/dashboard/project/hosting/:uid/:slug/:serverid/:servername',
    component: Deployments_For_Hosting,
    name: 'Deployments_For_Hosting',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },

  {
    path: '/dashboard/project/:uid/collaborators',
    component: Collaborators,
    name: 'Collaborators',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/workflow',
    component: Workflow,
    name: 'Workflow',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/deployments',
    component: Deployment,
    name: 'Deployment',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    //I purposely passed the UID because I needed it to be able to navigate the
    //Project Nav easily from anywhere on the website
    path: '/dashboard/project/:uid/:deployment_id/commands/:project_id',
    component: ViewCommand,
    name: 'ViewCommand',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    //I purposely passed the UID because I needed it to be able to navigate the
    //Project Nav easily from anywhere on the website
    path: '/dashboard/project/:uid/variables/:deployment_slug/:deployment_id',
    component: ViewEnvironmentVariables,
    name: 'ViewEnvironmentVariables',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/integrations',
    component: ProjectIntegrations,
    name: 'ProjectIntegrations',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/project/:uid/transfer',
    component: Transfer,
    name: 'Transfer',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'project',
    },
  },
  {
    path: '/dashboard/teams',
    component: DashboardTeams,
    name: 'DashboardTeams',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/integrations',
    component: Integrations,
    name: 'DashboardIntegration',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/profile',
    component: Profile,
    name: 'DashboardProfile',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/billing',
    component: Billing,
    name: 'DashboardBilling',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  {
    path: '/dashboard/billing/download/:ref',
    component: TransactionDownload,
    name: 'TransactionDownload',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/dashboard/hire-expert',
    component: HireExpert,
    name: 'DashboardHireExpert',
    beforeEnter: AuthGuard,
    meta: {
      layout: 'dashboard',
    },
  },
  // Website Builder
  {
    path: '/website-builder',
    name: 'WebsiteBuilder',
    component: WebsiteBuilder,
    beforeEnter: AuthGuard,
  },
]
