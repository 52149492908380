<template>
  <aside :class="`outer-right ${sectionsVisibility && 'show'}`">
    <svg class="icon" viewBox="0 0 18 18" @click="$emit('toggleVisibility')">
      <use xlink:href="/sprite.svg#icon-close" />
    </svg>
    <nav class="d-flex align-items-start flex-column py-4 px-3">
      <div class="side_header">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'gray2'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-3 mr-2"
          @click="''"
        >
          <!-- :inactive="!validate" -->
          <span class="d-flex align-items-center">
            <svg class="icon" width="20px" height="20px" viewBox="0 0 27 27">
              <use xlink:href="/sprite.svg#icon-d-export" />
            </svg>
            Export</span
          >
        </Button>
        <div class="dropdown show">
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="submit"
            btnClass="px-1 dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="''"
          >
            <!-- :inactive="!validate" -->
            <span class="">Save Changes</span></Button
          >
          <PublishDropdown />
        </div>
      </div>
      <div class="side_body">
        <h6>Sections arrangement</h6>
        <p class="description">Drag sections to how yo want arranged</p>
        <div v-for="(item, index) in pages" :key="index">
          <div class="side_item" @click="item.show = !item.show">
            <div class="d-flex">
              <svg class="icon" width="17px" height="17px" viewBox="0 0 16 16">
                <use xlink:href="/sprite.svg#icon-d-arrow-all" />
              </svg>
              <p class="ml-2">{{ item.name }}</p>
            </div>
            <svg class="icon" width="14px" height="14px" viewBox="0 0 12 7">
              <use xlink:href="/sprite.svg#icon-d-angle-down" />
            </svg>
          </div>
          <div class="tree">
            <transition name="slide">
              <ul v-if="item.show">
                <li v-for="(single_child, i) in item.children" :key="i">
                  <svg
                    class="icon"
                    width="17px"
                    height="17px"
                    viewBox="0 0 16 16"
                  >
                    <use xlink:href="/sprite.svg#icon-d-arrow-all" />
                  </svg>
                  <span class="ml-2"> {{ single_child.name }} </span>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
    </nav>
  </aside>
</template>
<script>
import { defineComponent } from 'vue'
import Button from '@/components/ui/Button'
import PublishDropdown from '@/components/website-builder/PublishDropdown'
export default defineComponent({
  name: 'ActionsBar',
  components: { Button, PublishDropdown },
  emits: ['toggleVisibility'],
  props: {
    sectionsVisibility: {
      type: Boolean,
    },
    pages: {
      type: Array,
    },
  },
})
</script>

<style>
.outer-right > .icon {
  width: 24px;
  height: 24px;
}
</style>
