<template>
  <aside
    class="outer-left"
    :style="{ width: `${width}px` }"
    @focusout="$emit('toggleVisibility', [false, ''])"
  >
    <nav class="d-flex align-items-start flex-column py-4 px-3">
      <div class="side_header">
        <h5>Pages</h5>
        <p>Select and add Pages</p>
      </div>
      <div class="side_body">
        <div
          :class="`side_item ${activePage === item ? 'active' : ''}`"
          v-for="(item, index) in pages"
          :key="index"
          @click="switchPage(pages_code[index])"
        >
          <p>{{ item }}</p>
          <svg
            class="icon cursor-pointer"
            width="17px"
            height="17px"
            viewBox="0 0 24 24"
            @click="togglePageModal(item)"
          >
            <use xlink:href="/sprite.svg#icon-settings3" />
          </svg>
        </div>
        <a @click="addNewPage('page')">
          <svg class="icon" width="12px" height="12px" viewBox="0 0 24 24">
            <use xlink:href="/sprite.svg#icon-plus" />
          </svg>
          <span class="ml-3">Add a new page</span>
        </a>
      </div>
    </nav>
  </aside>
  <PageModal
    :show="showPageModal"
    :pageName="selectedPage"
    @closeModal="showPageModal = false"
    @editPageAction="editPageAction"
  />
</template>
<script>
import PageModal from '@/components/website-builder/pageModal'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'PageBar',
  emits: ['toggleVisibility'],
  components: { PageModal },
  props: {
    width: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const showPageModal = ref(false)
    const pages = ref(['index'])
    const pages_code = ref(['index'])
    const activePage = ref('index')
    const selectedPage = ref('index')

    const togglePageModal = page => {
      selectedPage.value = page
      showPageModal.value = true
    }

    const addNewPage = pageName => {
      while (pages_code.value.includes(pageName)) {
        let args = pageName.split('--')
        if (args[1]) {
          pageName = args[0] + '--' + (args[1] * 1 + 1)
        } else {
          pageName = args[0] + '--0'
        }
      }
      createPage(pageName)
    }

    const editPageAction = ({ pageData, pageName }) => {
      pages.value[pages.value.indexOf(pageName)] = pageData.title
      let iframe = document.getElementById('website-builder_iframe')
      iframe.contentWindow.postMessage({
        signature: 'pageDataChange',
        data: JSON.stringify({
          pageName: pageName,
          pageData: pageData,
        }),
      })
    }

    const createPage = pageName => {
      pages.value.push(pageName)
      pages_code.value.push(pageName)
      let iframe = document.getElementById('website-builder_iframe')
      iframe.contentWindow.document.getElementById('addPage').innerText =
        pageName
    }

    const switchPage = pageName => {
      activePage.value = pageName
      let iframe = document.getElementById('website-builder_iframe')
      iframe.contentWindow.document.getElementById('switchPage').innerText =
        pageName
    }
    return {
      pages,
      selectedPage,
      addNewPage,
      pages_code,
      switchPage,
      activePage,
      showPageModal,
      togglePageModal,
      editPageAction,
    }
  },
})
</script>
