import integrationMutations from './mutations'
import integrationActions from './actions'
export default {
  state: {
    config: '',
    configStatus: 'PENDING',
    platformStatus: 'PENDING',
    platform: {},
    revokePlatform: {},
    revokePlatformStatus: 'PENDING',
  },
  actions: { ...integrationActions },
  mutations: { ...integrationMutations },
  getters: {
    getConfig(state) {
      return state.config
    },
    getConfigStatus(state) {
      return state.configStatus
    },
    getPlatformStatus(state) {
      return state.platformStatus
    },
    getPlatform(state) {
      return state.platform
    },
    getRevokePlatformStatus(state) {
      return state.revokePlatformStatus
    },
    getRevokePlatform(state) {
      return state.revokePlatform
    },
  },
}
