<template>
  <div :class="`card card-${color}`">
    <div class="card-body">
      <slot></slot>
      <h4 class="my-3">
        {{ total }}
        <a class="active">MEMBERS</a>
      </h4>
      <slot name="button"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TeamCard',
  props: {
    color: {
      type: String,
      default: 'blue',
      validator: value =>
        ['blue', 'dark-blue', 'cyan', 'pink', 'white'].includes(value),
    },
    total: {
      type: Number,
    },
  },
}
</script>
