<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <div class="alert alert-danger" v-if="error">
        {{ error }}
      </div>
      <div class="alert alert-success" v-if="success">
        {{ success }}
      </div>
      <div>
        <h6>Remove Member</h6>
        <label class="mt-2">
          Are you sure you want to remove
          <span style="font-weight: bold"
            >{{ memberDetails?.profile.first }}
            {{ memberDetails?.profile.last }}</span
          >
          from your team list? This action cannot be undone.
        </label>
      </div>

      <div class="d-flex align-items-center mt-4">
        <div class="font-weight-normal cancel-btn mr-3" @click="show = false">
          Cancel
        </div>
        <Button
          :loading="loading"
          :inactive="false"
          :variant="'danger'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="removeMember(memberDetails?.id)"
        >
          <!-- :inactive="!validate" -->
          <span class="">Remove from all projects and team</span></Button
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'
export default {
  name: 'RemoveMemberModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    memberDetails: {
      type: Object,
      required: true,
    },
    businessSlug: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      success: false,
      error: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions(['handleGetAllTeamMembers']),
    async removeMember(id) {
      this.loading = true
      this.error = ''

      this.$store
        .dispatch('handleDeleteTeamMember', id)
        .then(() => {
          this.success = 'Team member removed successfully.'

          setTimeout(this.redirect, 2500)
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
    redirect() {
      this.success = false
      this.error = false
      this.show = false
      this.loading = false

      //Calling the getTeam dispatch function directly
      //in this function will cause it to malfunction
      setTimeout(() => this.handleGetAllTeamMembers(this.businessSlug), 1000)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.success = false
        this.error = false
        this.loading = false
      },
    },
  },
}
</script>
