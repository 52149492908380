<template>
  <div :class="`card card-${color}`">
    <div class="card-body">
      <slot></slot>
      <h4 class="my-2">
        {{ total }}
        <a class="active">{{ active }} ACTIVE</a>
      </h4>
      <!-- I made the change because of this button - check the @click method -->
      <Button
        :loading="false"
        :inactive="false"
        :variant="buttonColor"
        :size="'md'"
        type="submit"
        name="createProject"
        btnClass="mt-3"
        @click="
          $emit(
            'toggleModal',
            true,
            this.modalTitle,
            this.modalDestination,
            this.modalDestinationName
          )
        "
      >
        <span class="">SEE ALL</span></Button
      >
    </div>
  </div>
</template>
<script>
//   -----  Please don't DELETE -------
/*  I created this Custom Analytics Card to suit
my need for the dashboard, without it, passing in the
project ID to the store and Create Website Modal will be impossible*/

import Button from '@/components/ui/Button'
export default {
  name: 'AnalyticsCard',
  components: { Button },
  props: {
    color: {
      type: String,
      default: 'blue',
      validator: value => ['blue', 'dark-blue', 'cyan', 'pink'].includes(value),
    },
    total: {
      type: Number,
    },
    active: {
      type: Number,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    modalDestination: {
      type: String,
      default: '',
    },
    modalDestinationName: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: 'light-transparent',
    },
  },
}
</script>
