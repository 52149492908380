import * as types from '@/store/modules/integration/types'

export default {
  [types.CONFIG_STATUS](state, value) {
    state.configStatus = value
  },
  [types.CONFIG](state, value) {
    state.config = value
  },
  [types.PLATFORM](state, value) {
    state.platform = value
  },
  [types.PLATFORM_STATUS](state, value) {
    state.platformStatus = value
  },
  [types.REVOKE_PLATFORM_STATUS](state, value) {
    state.revokePlatformStatus = value
  },
  [types.REVOKE_PLATFORM](state, value) {
    state.revokePlatform = value
  },
}
