<template>
  <div class="website-card card">
    <div class="card-body">
      <div class="cb-img" v-if="store.image">
        <figure :style="{ backgroundImage: `url(${store.image})` }"></figure>
      </div>
      <h4
        v-else
        class="d-flex align-items-center h-100 justify-content-center text-uppercase text-muted"
      >
        {{ store.name.split(' ')[0] }}
      </h4>
      <div class="overlay">
        <div class="d-block">
          <Button
            v-if="store.image"
            :loading="false"
            :inactive="false"
            :variant="'gray'"
            :size="'md'"
            type="submit"
            name="loginButton"
            btnClass="px-5"
            @click="''"
          >
            <!-- :inactive="!validate" -->
            <span class="">Preview</span></Button
          >
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="loginButton"
            btnClass="px-5 mt-4"
            @click="toggleModal(true)"
          >
            <!-- :inactive="!validate" -->
            <span class="">Select</span></Button
          >
        </div>
      </div>
    </div>
    <div class="card-footer">
      <p class="name">{{ store.name }}</p>
      <p class="price">{{ store.price }}</p>
    </div>
  </div>
  <Modal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :projectUID="projectUID"
  ></Modal>
</template>
<script>
import Button from '@/components/ui/Button'
import Modal from '@/components/dashboard/project/CreateStoreModal'
export default {
  name: 'Store Card',
  components: {
    Button,
    Modal,
  },
  props: {
    store: {
      type: Object,
    },
  },
  data() {
    return {
      modalIsVisible: false,
      projectUID: this.$route.params.uid,
    }
  },
  methods: {
    toggleModal(value) {
      this.modalIsVisible = value
    },
  },
}
</script>
