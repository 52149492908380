import storeMutations from './mutations'
import storeActions from './actions'
export default {
  state: {
    stores: [],
    storesStatus: 'PENDING',
    deleteStore: '',
    deleteStoreStatus: '',
    createStore: {},
    createStoreStatus: 'PENDING',
  },
  actions: { ...storeActions },
  mutations: { ...storeMutations },
  getters: {
    getStores(state) {
      return state.stores
    },
    getStoresStatus(state) {
      return state.storesStatus
    },
    getDeleteStoreStatus(state) {
      return state.deleteStoreStatus
    },
    getDeleteStore(state) {
      return state.deleteStore
    },
    getCreateStoreStatus(state) {
      return state.createStoreStatus
    },
    getCreateStore(state) {
      return state.createStore
    },
  },
}
