import websiteMutations from './mutations'
import websiteActions from './actions'

export default {
  state: {
    websiteStatus: 'PENDING',
  },
  actions: { ...websiteActions },
  mutations: { ...websiteMutations },
  getters: {},
}
