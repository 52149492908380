<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >

      <h6>Create New Workspace</h6>
      <!-- <p class="description">You can change this later</p> -->
      <div class="form-group">
        <label for="workspace_name">Workspace Name</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          placeholder="Name for this workspace"
          v-model="workspace.name"
        />
      </div>

      <div class="form-group">
        <label for="workspace_email">Workspace Email</label>
        <input
          type="email"
          class="form-control"
          id="project_name"
          placeholder="Email for this workspace"
          v-model="workspace.email"
        />
      </div>

      <div class="form-group">
        <label for="workspace_phone_number">Workspace Phone Number</label>
        <input
          type="text"
          class="form-control"
          id="workspace_number"
          placeholder="Phone number for this workspace"
          v-model="workspace.phone"
        />
      </div>

      <p v-if="allFilled" class="text-danger mt-4">Enter all input fields</p>
      <div class="d-flex align-items-center mt-2">
        <Button
          :loading="loading"
          :inactive="allFilled"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="createWorkspace()"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create Workspace</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'

export default {
  name: 'CreateWorkspaceModal',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: '',
      workspace: {
        name: '',
        email: '',
        phone: '',
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.workspace = {}
      },
    },
  },
  computed: {
    allFilled() {
      return !(
        this.workspace.name !== '' &&
        this.workspace.email !== '' &&
        this.workspace.phone !== ''
      )
    },
  },
  methods: {
    removeError() {
      this.error = ''
    },

    createWorkspace() {
      this.loading = true
      this.error = ''
      const params = {
        ...this.workspace,
        type: 'Professional',
      }

      this.$store
        .dispatch('handleAddWorkSpace', params)
        .then(() => {
          this.loading = false
          this.show = false
          this.$router.push({ name: 'DashboardOverview' })
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
  },
}
</script>
