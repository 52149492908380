<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="deployment" />
      <div class="d-flex justify-content-end align-items-center my-3 mx-3">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="addDeployment"
          @click="toggleModal(true)"
        >
          <span class="">Add Deployment</span></Button
        >
      </div>
      <div class="hosting-table">
        <table
          class="table"
          v-if="
            deploymentLogs !== null &&
            deploymentLogs.length > 0 &&
            paginateData !== undefined
          "
        >
          <thead>
            <tr>
              <th scope="col">SERVER NAME</th>
              <th scope="col">BRANCH</th>
              <th scope="col">DEPLOY PATH</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in paginateData" :key="index">
              <SingleDeploymentRow
                :singleDeployment="log"
                :projectId="projectId"
                :userID="userID"
                :businessID="businessID"
              />
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex align-items-center justify-content-center mt-5"
          v-if="deploymentLogs !== null && deploymentLogs.length < 1"
        >
          <div class="text-center">
            <h3>You have no deployments</h3>
            <p>
              No current list of your deployments. Set build commands to run
              after successful deployments.
            </p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="submit"
              btnClass="px-5 mt-4"
              @click="toggleModal(true)"
            >
              <span class="font-weight-normal">Add deployment</span>
            </Button>
          </div>
        </div>
        <nav class="pagination-container">
          <div></div>
          <ul class="pagination">
            <li
              class="page-item"
              v-for="index in this.totalPages"
              :key="index"
              @click="changePaginatedData(index)"
              :class="`${activePage(index)}`"
            >
              <a class="page-link" href="#">{{ index }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </main>
    <!-- create deployment modal -->
    <CreateDeploymentModal
      :isVisible="modalIsVisible"
      @toggleModal="toggleModal"
      :getProjectRepos="projectRepos"
      :getFalcons="falconsLogs"
      :projectId="projectId"
    ></CreateDeploymentModal>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import Button from '@/components/ui/Button'
import SingleDeploymentRow from '@/components/dashboard/project/SingleDeploymentRow'
import CreateDeploymentModal from '@/components/dashboard/project/CreateDeploymentModal'
import { Paginator } from 'array-paginator'

import { mapGetters } from 'vuex'
export default {
  name: 'Deployment',
  components: {
    ProjectNav,
    Button,
    SingleDeploymentRow,
    CreateDeploymentModal,
  },
  data() {
    return {
      deploymentLogs: null,
      projectRepos: null,
      falconsLogs: [],
      projectId: null,
      userID: null,
      businessID: null,
      modalIsVisible: false,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getDeployments',
      'getDeploymentsStatus',
      'getProjectRepos',
      'getProjectReposStatus',
      'getFalcons',
      'getFalconsStatus',
      'getProject',
      'getProjectStatus',
      'getBusinessInfo',
      'getUserInfoStatus',
    ]),
  },
  methods: {
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    getButtonClass(status) {
      switch (status) {
        case 'live':
          return 'active'
        case 'inactive':
          return 'inactive'
        default:
          return 'active'
      }
    },

    toggleModal(value) {
      this.modalIsVisible = value
    },
  },
  watch: {
    getDeploymentsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.deploymentLogs = this.getDeployments

          //Paginate the data manually
          this.paginator = new Paginator(this.deploymentLogs, 12, 1)

          //Set the first page as the data
          this.paginateData = this.paginator.first()
          if (this.paginateData === undefined || !this.paginateData) {
            this.paginateData = []
          }
          this.totalPages = this.paginator.totalPages
        }
      },
    },
    getProjectReposStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectRepos = this.getProjectRepos.data
        }
      },
    },
    getFalconsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.falconsLogs = this.getFalcons.data
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectId = this.getProject.id
          this.businessID = this.getProject.business_id
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
}
</script>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
