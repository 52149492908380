<template>
  <section class="pages--contact">
    <div class="container pages--contact-container">
      <div class="row">
        <div class="col-lg-7">
          <h2 class="title fs_lh-64_64">Lets get started</h2>
          <p class="description fs_lh-24_31">
            Build, host and ship websites and applications quickly with Fybrr.
            Our fully-managed platform gives you the simplest path to delivering
            apps quickly without stress.
          </p>

          <div class="input-wrap mt-5 col-md-7">
            <input
              type="text"
              placeholder="Enter your work email"
              class="form-control"
            />
            <Button
              :loading="false"
              :inactive="false"
              :variant="'transparent'"
              :size="'md'"
              type="submit"
              name="editProject"
              btnClass="mr-3 mt-1"
              @click="''"
            >
              <span class="text-primary">Get started</span></Button
            >
          </div>
        </div>
      </div>
      <div class="bg-img">
        <img src="@/assets/img/home/contact-bg.svg" alt="" />
      </div>
    </div>
  </section>
</template>
<script>
import Button from '@/components/ui/Button'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ContactComponent',
  components: { Button },
})
</script>
