<template>
  <aside
    class="outer-left"
    :style="{ width: `${width}px` }"
    @focusout="$emit('toggleVisibility', [false, ''])"
  >
    <nav class="d-flex align-items-start flex-column py-4 px-3">
      <div class="side_header">
        <h5>Edit Element</h5>
        <p>Select an element on the page to edit</p>
      </div>
    </nav>
    <ul class="ml-2">
      <li class="mt-4" v-if="allowsBackgroundImage">
        <span>Background Image</span>
        <div>
          <input
            type="file"
            ref="uploadedFile"
            accept="image/png, image/svg, image/jpg, image/jpeg"
            @change="previewImage('bg')"
          />
          <img
            :src="bgImageThumb.src ?? ''"
            ref="bgImageThumb"
            class="imageThumb"
            alt="Image Preview ..."
          />
        </div>
      </li>
      <li class="mt-4" v-if="allowsHeaderTypographyTags">
        <span>Typography</span>
        <div class="row">
          <div
            v-for="(tag, index) in givenTypography"
            :key="index"
            @click="selectTypography(tag)"
            class="p-3 mr-2 my-1"
            :class="typography === tag ? 'bg-primary' : 'bg-dark'"
          >
            {{ tag }}
          </div>
        </div>
      </li>
      <li class="mt-4" v-if="allowsFontSize">
        <span>Font Size</span>
        <div>
          <select name="" id="" class="form-control" v-model="fontSize">
            <option value="">none</option>
            <option :value="`${index}px`" v-for="index in 32" :key="index">
              {{ index }}px
            </option>
          </select>
        </div>
      </li>
      <li
        v-if="allowsImagesUpload || allowsGalleryImagesUpload"
        class="w-100 mt-4"
      >
        <span>Image Upload</span>
        <div>
          <input
            type="file"
            ref="uploadedFile"
            accept="image/png, image/svg, image/jpg, image/jpeg"
            @change="previewImage"
          />
          <img
            :src="imageThumb.src ?? ''"
            ref="imageThumb"
            class="imageThumb"
            alt="Image Preview ..."
          />
        </div>
      </li>
      <li class="mt-4" v-if="allowsFontColor">
        <span>Font Color</span>
        <div>
          <input type="color" v-model="fontColor" />
        </div>
      </li>
      <li
        class="mt-4"
        v-if="
          allowsFontColor ||
          allowsFontSize ||
          allowsHeaderTypographyTags ||
          allowsAnchorTag
        "
      >
        <span>Link</span>
        <div>
          <input type="text" class="form-control" v-model="typographyLink" />
        </div>
      </li>
    </ul>
    <Button
      :loading="false"
      :inactive="false"
      :variant="'primary'"
      :size="'md'"
      type="submit"
      name="applyChanges"
      btnClass="mt-3"
      @click="applyChanges"
    >
      <span class="">Apply</span></Button
    >
  </aside>
  <p class="hidden" id="editElement-trigger"></p>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import Button from '@/components/ui/Button'

export default defineComponent({
  props: {
    width: {
      type: Number,
      required: true,
    },
  },
  emits: ['toggleVisibility', 'triggerEditForTags'],
  name: 'EditElementBar',
  components: { Button },
  setup() {
    let id
    let iframe
    let element
    const fontSize = ref('none')
    const imageThumb = ref({
      src: '',
    })
    const bgImageThumb = ref({
      src: '',
    })
    const typographyLink = ref('')
    const uploadedFile = ref('')
    const fontColor = ref('none')
    const typography = ref('H1')
    const givenTypography = ref(['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'])
    let controls = ref('')
    let observer = null
    let options = {
      subtree: true,
      childList: true,
      attributes: true,
    }

    const allowsFontSize = computed(() => {
      return controls.value.includes('FontSizeES')
    })

    const allowsAnchorTag = computed(() => {
      return controls.value.includes('AnchorES')
    })

    const allowsHeaderTypographyTags = computed(() => {
      return controls.value.includes('HeadingTypographyES')
    })

    const allowsImagesUpload = computed(() => {
      return controls.value.includes('ImagingES')
    })

    const allowsGalleryImagesUpload = computed(() => {
      return controls.value.includes('ElementCBS(galleryItemOptions)')
    })

    const allowsBackgroundImage = computed(() => {
      return controls.value.includes('BackgroundImageES')
    })

    const allowsBackgroundColor = computed(() => {
      return controls.value.includes('BackgroundColorES')
    })

    const allowsBackgroundRepeat = computed(() => {
      return controls.value.includes('BackgroundRepeatES')
    })

    const allowsBackgroundSize = computed(() => {
      return controls.value.includes('BackgroundSizeES')
    })

    const allowsBackgroundPosition = computed(() => {
      return controls.value.includes('BackgroundSizeES')
    })

    const allowsFontColor = computed(() => {
      return controls.value.includes('FontColorES')
    })

    const selectTypography = typo => {
      typography.value = typo
      changeTagName()
    }

    const changeTagName = () => {
      let newTag = document.createElement(typography.value)
      let index

      // console.log(getE)
      // Copy the children
      while (element.firstChild) {
        newTag.appendChild(element.firstChild) // *Moves* the child
      }

      // Copy the attributes
      for (index = element.attributes.length - 1; index >= 0; --index) {
        newTag.attributes.setNamedItem(element.attributes[index].cloneNode())
      }

      // Replace it
      element.parentNode.replaceChild(newTag, element)
      iframe.contentWindow.postMessage({
        signature: 'tagChanged',
        data: JSON.stringify({
          id: id,
          controls: localStorage.getItem('editElement-attributes'),
        }),
      })
    }

    const applyChanges = () => {
      if (allowsFontSize.value) {
        if (fontSize.value !== 'none') {
          element.style.fontSize = fontSize.value
        }
        addLinkToElement('typography')
      }
      if (allowsFontColor.value) {
        element.style.color = fontColor.value
      }
      if (allowsImagesUpload.value) {
        element.src = imageThumb.value.src
      }
      if (allowsGalleryImagesUpload.value) {
        element.getElementsByTagName('img')[0].src = imageThumb.value.src
      }
      if (allowsBackgroundImage.value) {
        element.style.backgroundImage = `url(${bgImageThumb.value.src})`
      }
      if (allowsAnchorTag.value) {
        addLinkToElement()
      }
      //  apply changes to present element
    }

    const addLinkToElement = (type = 'anchor') => {
      if (type === 'anchor') {
        element.href = typographyLink.value
      } else if (type === 'typography') {
        let anchor = element.getElementsByTagName('A')[0]
        if (anchor) {
          anchor.href = typographyLink.value
        } else {
          anchor = document.createElement('A')
          anchor.href = typographyLink.value
          let text = element.innerText
          element.innerText = ''
          anchor.innerText = text
          element.appendChild(anchor)
        }
      }
    }
    // eslint-disable-next-line no-unused-vars
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          id = document.getElementById('editElement-trigger').innerHTML
          element = iframe.contentWindow.document.getElementById(id)
          controls.value = localStorage.getItem('editElement-attributes')
          if (allowsImagesUpload.value) {
            imageThumb.value = {
              src: element.src,
            }
          }
          if (allowsGalleryImagesUpload.value) {
            console.log('inhere', element.getElementsByTagName('img')[0].src)
            imageThumb.value = {
              src: element.getElementsByTagName('img')[0].src,
            }
          }
          if (allowsBackgroundImage.value) {
            let eleStyle =
              element.currentStyle || window.getComputedStyle(element, false)
            bgImageThumb.value = {
              src: eleStyle.backgroundImage.slice(4, -1).replace(/"/g, ''),
            }
          }
        } else if (mutation.type === 'attributes') {
          console.log(`The ${mutation.attributeName} attribute was modified.`)
        }
      }
    }

    onMounted(() => {
      let target = document.querySelector('#editElement-trigger')
      iframe = document.getElementById('website-builder_iframe')
      observer = new MutationObserver(callback)

      observer.observe(target, options)
    })

    const previewImage = (type = 'bg') => {
      let preview
      if (type === 'bg') {
        preview = bgImageThumb.value
      } else {
        preview = imageThumb.value
      }
      let file = uploadedFile.value.files[0]
      let reader = new FileReader()

      reader.onloadend = function () {
        preview.src = reader.result
      }

      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)
        // The size of the file.
        if (size >= 2048) {
          alert('File too Big, please select a file less than 2mb')
        } else {
          reader.readAsDataURL(file)
        }
      } else {
        preview.src = ''
      }
    }
    return {
      fontSize,
      previewImage,
      imageThumb,
      bgImageThumb,
      uploadedFile,
      allowsFontSize,
      applyChanges,
      selectTypography,
      typography,
      allowsFontColor,
      allowsImagesUpload,
      allowsGalleryImagesUpload,
      allowsBackgroundColor,
      allowsBackgroundImage,
      allowsBackgroundSize,
      allowsBackgroundPosition,
      allowsBackgroundRepeat,
      allowsAnchorTag,
      givenTypography,
      addLinkToElement,
      typographyLink,
      allowsHeaderTypographyTags,
      fontColor,
    }
  },
})
</script>
<style scoped>
.imageThumb {
  margin-top: 20px;
  width: 200px;
}
</style>
