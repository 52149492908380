import http from '../../config/axios'

export const workspaceUrls = {
  GET_ALL_URL: 'users/workspaces',
  CREATE_WORKSPACE: 'businesses/create',
}

/** getAllUsersWorkspace */
const getAllUsersWorkspaces = async () => {
  return await http.get(workspaceUrls.GET_ALL_URL)
}

const addWorkspace = async params => {
  return await http.post(workspaceUrls.CREATE_WORKSPACE, params)
}

export { getAllUsersWorkspaces, addWorkspace }
