<template>
  <div class="page__body">
    <div class="page__head page__back">
      <div>
        <h6>Hello Boss</h6>
        <p>Manage your team members</p>
      </div>
    </div>
    <main class="page-dashboard mt-5 px-4 border rounded-default">
      <div class="page-dashboard-cards page-dashboard-analytics">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-9">
            <TeamCard color="white" :total="teamMembers?.length">
              <div class="d-flex justify-content-between">
                <p class="card-subtitle font-weight-normal text-muted">
                  Team Members
                </p>
              </div>
              <template #button>
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'primary'"
                  :size="'md'"
                  type="submit"
                  name="submit"
                  btnClass="px-5"
                  @click="toggleModal(true)"
                >
                  <span class="font-weight-normal">ADD NEW TEAM MEMBERS</span>
                </Button>
              </template>
            </TeamCard>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-9">
            <TeamCard color="white" :total="pendingInvitations?.length">
              <div class="d-flex justify-content-between">
                <p class="card-subtitle font-weight-normal text-muted">
                  Pending requests
                </p>
              </div>
              <template #button>
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'primary'"
                  :size="'md'"
                  type="submit"
                  name="submit"
                  btnClass="px-5"
                  @click="tabShowing = 'invitations'"
                >
                  <span class="font-weight-normal"
                    >SEE ALL PENDING REQUEST</span
                  >
                </Button>
              </template>
            </TeamCard>
          </div>
        </div>
      </div>

      <!-- The alert messages -->
      <div>
        <div class="alert alert-success" role="alert" v-if="success">
          {{ message }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ message }}
        </div>
      </div>

      <ul class="nav nav-pills mb-3">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="isActiveClass('members')"
            aria-current="page"
            href="#"
            @click="tabShowing = 'members'"
            >Team Members</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="isActiveClass('invitations')"
            href="#"
            @click="tabShowing = 'invitations'"
            >Pending Requests</a
          >
        </li>
      </ul>

      <div class="hosting-table">
        <!-- Table for the Team Members -->
        <table
          class="table"
          v-if="
            getTeamMembersStatus === 'COMPLETED' && tabShowing === 'members'
          "
        >
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">STATUS</th>
              <th scope="col">DATE ADDED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in paginateData" :key="index">
              <MemberData :member="member" :userSlug="userSlug"></MemberData>
            </tr>
          </tbody>
        </table>

        <!-- Table for the invitations -->
        <table class="table" v-if="tabShowing === 'invitations'">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">STATUS</th>
              <th scope="col">ROLE</th>
              <th scope="col">DATE ADDED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invitation, index) in paginateData" :key="index">
              <InvitationsData
                :member="invitation"
                :userSlug="userSlug"
                @toggleResult="toggleShowResult"
              ></InvitationsData>
            </tr>
          </tbody>
        </table>

        <!-- This is shown if the list is empty -->
        <div
          class="d-flex align-items-center justify-content-center mt-5"
          v-if="paginateData < 1 && contentLoaded"
        >
          <div class="text-center">
            <h3>You do not have any pending request on this workspace</h3>
            <p>
              <!-- No current list of your deployments. Set build commands to run
              after successful deployments. -->
            </p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="submit"
              btnClass="px-5 mt-4"
              @click="toggleModal(true)"
            >
              <span class="font-weight-normal">Add Team Member</span>
            </Button>
          </div>
        </div>
        <nav class="page-dashboard__pagination mr-3">
          <ul class="pagination">
            <li
              class="page-item"
              v-for="index in this.totalPages"
              :key="index"
              @click="changePaginatedData(index)"
              :class="`${activePage(index)}`"
            >
              <a class="page-link" href="#">{{ index }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </main>
  </div>
  <Modal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :userSlug="userSlug"
    :businessID="businessID"
  ></Modal>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import Button from '@/components/ui/Button'
import TeamCard from '@/components/dashboard/team/TeamCard'
import Modal from '@/components/dashboard/team/AddMemberModal'
import MemberData from '@/components/dashboard/team/tableData'
import InvitationsData from '@/components/dashboard/team/InvitationsTable'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'
import { Paginator } from 'array-paginator'

export default {
  name: 'Team',
  components: {
    Button,
    TeamCard,
    Modal,
    MemberData,
    OverlayLoading,
    InvitationsData,
  },
  data() {
    return {
      success: false,
      error: false,
      message: '',
      businessID: 0,
      modalIsVisible: false,
      loaderIsVisible: false,
      teamMembers: [],
      pendingInvitations: [],
      contentLoaded: false,
      userSlug: '',
      tabShowing: '',
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getTeamMembers',
      'getTeamMembersStatus',
      'getInvitationsStatus',
      'getInvitations',
      'getUserInfoStatus',
      'getSelectedWorkspace',
    ]),
  },
  watch: {
    getTeamMembersStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.teamMembers = this.getTeamMembers
          this.loaderIsVisible = false

          this.tabShowing = 'members'
        }
      },
    },

    tabShowing: {
      handler(value) {
        if (value === 'members') {
          this.paginateNewData(this.teamMembers)
          this.contentLoaded = true
        } else if (value === 'invitations') {
          //Paginate the data manually
          this.paginateNewData(this.pendingInvitations)
        }
      },
    },

    getInvitationsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.setPendingInvitations(this.getInvitations)
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'handleGetInvitations',
      'handleGetLoggedInUser',
      'handleGetAllTeamMembers',
      'handleGetUsersWorkspaces',
    ]),
    paginateNewData(data) {
      this.paginator = new Paginator(data, 15, 1)

      //Set the first page as the data
      this.paginateData = this.paginator.first()
      this.totalPages = this.paginator.totalPages

      if (this.paginateData === undefined) {
        this.paginateData = []
      }
    },
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },
    setPendingInvitations(invitationsList) {
      const userPendingInvitations = []

      for (let i = 0; i < invitationsList.length; i++) {
        if (invitationsList[i].status === 'pending') {
          userPendingInvitations.push(invitationsList[i])
        }
      }

      this.pendingInvitations = userPendingInvitations
    },
    isActiveClass(value) {
      if (this.tabShowing === value) {
        return 'active'
      } else {
        return ''
      }
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message

        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message

        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
  },
  async mounted() {
    //Set the user slug - check with localStorage or Vuex Getter

    const result = await this.handleGetUsersWorkspaces()

    if (result.status === 200) {
      this.userSlug = await this.getSelectedWorkspace?.slug
      this.businessID = await this.getSelectedWorkspace?.subscription
        ?.business_id

      if (!this.userSlug) {
        this.userSlug = await localStorage.getItem('selected_workspace_slug')
      }
    }

    //Insert the data if it is already available on Vuex
    if (
      this.getTeamMembersStatus === 'COMPLETED' &&
      this.getInvitationsStatus === 'COMPLETED'
    ) {
      //Data has been loaded already - don't reload
      this.teamMembers = this.getTeamMembers

      this.setPendingInvitations(this.getInvitations)
      this.tabShowing = 'members'
    } else {
      this.loaderIsVisible = true
      this.contentLoaded = false

      await this.handleGetInvitations(this.userSlug)
      await this.handleGetAllTeamMembers(this.userSlug)
    }
  },
}
</script>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
