import * as types from './types'
import {
  addTask,
  getTasks,
  updateTask,
  assignMember,
  deleteMember,
  deleteTask,
} from '@/services/workflow'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleAddTask({ commit }, params) {
    commit(types.TASK_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addTask(params)
        .then(response => {
          commit(types.TASK, response.data.data)
          commit(types.TASK_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.TASK_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetTasks({ commit }, projectId) {
    commit(types.TASKS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getTasks(projectId)
        .then(response => {
          commit(types.TASKS, response.data.data)
          commit(types.TASKS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.TASKS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
  handleUpdateTask({ commit }, params) {
    commit(types.UPDATE_TASK_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      updateTask(params)
        .then(response => {
          commit(types.UPDATE_TASK, response.data.data)
          commit(types.UPDATE_TASK_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.UPDATE_TASK_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAssignMember({ commit }, params) {
    commit(types.ASSIGN_MEMBER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      assignMember(params)
        .then(response => {
          commit(types.ASSIGN_MEMBER, response.data.data)
          commit(types.ASSIGN_MEMBER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ASSIGN_MEMBER_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
  handleDeleteMember({ commit }, params) {
    commit(types.DELETE_MEMBER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteMember(params)
        .then(response => {
          commit(types.DELETE_MEMBER, response.data.data)
          commit(types.DELETE_MEMBER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_MEMBER_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteTask({ commit }, params) {
    commit(types.DELETE_TASK_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteTask(params)
        .then(response => {
          commit(types.DELETE_TASK, response.data.data)
          commit(types.DELETE_TASK_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_TASK_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
