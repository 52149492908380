export const DEPLOYMENTS = 'DEPLOYMENTS'
export const DEPLOYMENTS_STATUS = 'DEPLOYMENTS_STATUS'
export const SERVER_DEPLOYMENTS = 'SERVER_DEPLOYMENTS'
export const SERVER_DEPLOYMENTS_STATUS = 'SERVER_DEPLOYMENTS_STATUS'
export const DEPLOYMENT = 'DEPLOYMENT'
export const DEPLOYMENT_STATUS = 'DEPLOYMENT_STATUS'
export const ADD_DEPLOYMENT = 'ADD_DEPLOYMENT'
export const ADD_DEPLOYMENT_STATUS = 'ADD_DEPLOYMENT_STATUS'
export const DELETE_DEPLOYMENT = 'DELETE_DEPLOYMENT'
export const DELETE_DEPLOYMENT_STATUS = 'DELETE_DEPLOYMENT_STATUS'
export const DEPLOYMENT_CONFIGS = 'DEPLOYMENT_CONFIGS'
export const DEPLOYMENT_CONFIGS_STATUS = 'DEPLOYMENT_CONFIGS_STATUS'
export const UPDATE_DEPLOYMENT_CONFIGS = 'UPDATE_DEPLOYMENT_CONFIGS'
export const UPDATE_DEPLOYMENT_CONFIGS_STATUS =
  'UPDATE_DEPLOYMENT_CONFIGS_STATUS'
