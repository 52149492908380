<template>
  <div class="website-card card">
    <div class="card-body">
      <div class="cb-img" v-if="template.image">
        <figure :style="{ backgroundImage: `url(${template.image})` }"></figure>
      </div>
      <h4
        v-else
        class="d-flex align-items-center h-100 justify-content-center text-uppercase text-muted"
      >
        {{ template.name.split(' ')[0] }}
      </h4>
      <div class="overlay">
        <div class="d-block">
          <Button
            v-if="template.image"
            :loading="false"
            :inactive="false"
            :variant="'gray'"
            :size="'md'"
            type="submit"
            name="loginButton"
            btnClass="px-5"
            @click="''"
          >
            <!-- :inactive="!validate" -->
            <span class="">Preview</span></Button
          >
          <!--This button was meant to trigger the create website form-->
          <Button
            :loading="false"
            :inactive="btnLoading"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="loginButton"
            btnClass="px-5 mt-4"
            @click="goToCreateWebsite"
          >
            <!-- :inactive="!validate" -->
            <span class="">Select</span></Button
          >
        </div>
      </div>
    </div>
    <div class="card-footer">
      <p class="name">{{ template.name }}</p>
      <p class="price">{{ template.price }}</p>
    </div>
  </div>
  <Modal :isVisible="modalIsVisible" @toggleModal="toggleModal"></Modal>
</template>
<script>
import Button from '@/components/ui/Button'
import Modal from '@/components/dashboard/project/CreateWebsiteModal'
import { mapActions } from 'vuex'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default {
  name: 'Template Card',
  components: {
    Button,
    Modal,
  },
  props: {
    template: {
      type: Object,
    },
  },
  data() {
    return {
      modalIsVisible: false,
      projectUID: this.$route.params.uid,
      btnLoading: false,
    }
  },

  methods: {
    ...mapActions(['handleGetLoggedInUser', 'handleAuthenticateUser']),

    toggleModal(value) {
      this.modalIsVisible = value
    },

    async goToCreateWebsite() {
      this.btnLoading = true
      //Get the needed data to login
      const res = await this.handleGetLoggedInUser()

      const userObject = res.data.data

      const { email, first, last, phone, galaxy_token } = userObject

      //Authenticate the user
      this.handleAuthenticateUser({
        email,
        firstName: first,
        lastName: last,
        phoneNumber: phone,
        password: galaxy_token,
      })
        .then(() => {
          this.$router.push({ name: 'WebsiteBuilder' })
          this.btnLoading = false
        })
        .catch(err => {
          toast(`${err.response?.data?.meta?.message}`, {
            autoClose: 4000,
            type: 'error',
          })
          this.btnLoading = false
        })
    },
  },
}
</script>
