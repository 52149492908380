<template>
  <aside class="left">
    <nav class="d-flex align-items-start flex-column">
      <ul class="mb-auto">
        <li
          @click="changeWidth()"
          class="left-item d-flex justify-content-center align-items-center"
          :class="mode === 'edit-sections' ? 'active' : ''"
          data-toggle="tooltip"
          data-placement="right"
          title="Add section"
        >
          <svg class="icon" width="30px" height="30px" viewBox="0 0 24 24">
            <use xlink:href="/sprite.svg#icon-d-plus-circle" />
          </svg>
        </li>
        <li
          @click="triggerEditElementMode()"
          class="left-item d-flex justify-content-center align-items-center"
          :class="mode === 'edit-elements' ? 'active' : ''"
          data-toggle="tooltip"
          data-placement="right"
          title="Global styling"
        >
          <svg class="icon" width="30px" height="30px" viewBox="0 0 24 24">
            <use xlink:href="/sprite.svg#icon-d-water-drop" />
          </svg>
        </li>
        <li
          @click="changePageWidth()"
          class="left-item d-flex justify-content-center align-items-center"
          :class="mode === 'edit-pages' ? 'active' : ''"
          data-toggle="tooltip"
          data-placement="right"
          title="List of Pages"
        >
          <svg class="icon" width="30px" height="30px" viewBox="0 0 24 24">
            <use xlink:href="/sprite.svg#icon-d-cards" />
          </svg>
        </li>
      </ul>
      <ul>
        <li
          id="preview"
          class="logo-bottom d-flex justify-content-center align-items-center"
          data-toggle="tooltip"
          data-placement="right"
          title="Back to Fybrr dashboard"
        >
          <svg class="icon" width="30px" height="30px" viewBox="0 0 41 45">
            <use xlink:href="/sprite.svg#icon-d-logo" />
          </svg>
        </li>
      </ul>
    </nav>
  </aside>
  <SectionBar
    :width="width"
    :sidebar_items="sidebar_items"
    @toggleVisibility="changeVisibility"
  />
  <EditElementBar
    :width="editBarWidth"
    @toggleVisibility="changeEditBarVisibility"
  />
  <PagesBar
    :width="pageBar.width"
    @toggleVisibility="changePageBarVisibility"
  />
  <SubSectionBar :sub_sections="sidebar_items" :visibility="sub_visibility" />
  <ActionsBar
    :sectionsVisibility="sectionsVisibility"
    @toggleVisibility="$emit('toggleSection')"
    :pages="pages"
  />
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import ActionsBar from '@/components/website-builder/Sidebar/ActionsBar'
import SectionBar from '@/components/website-builder/Sidebar/SectionBar'
import PagesBar from '@/components/website-builder/Sidebar/PagesBar'
import SubSectionBar from '@/components/website-builder/Sidebar/SubSectionBar'
import { defaultSections } from '@/components/website-builder/Section.json'
import EditElementBar from '@/components/website-builder/Sidebar/EditElementBar'
export default defineComponent({
  name: 'WebsiteBuilderSidebar',
  components: {
    EditElementBar,
    SubSectionBar,
    ActionsBar,
    SectionBar,
    PagesBar,
  },
  emits: ['toggleSection'],
  props: {
    sectionsVisibility: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const sidebar_items = ref([])
    const isShow = ref(false)
    const pages = ref([
      {
        name: 'Home',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
      {
        name: 'About us',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
      {
        name: 'Company',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
      {
        name: 'Contact us',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
      {
        name: 'Blog',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
      {
        name: 'Gallery',
        show: false,
        children: [
          { name: 'Navbar' },
          { name: 'Header' },
          { name: 'Description' },
          { name: 'Gallery' },
          { name: 'Footer' },
        ],
      },
    ])
    const width = ref(0)
    const mode = ref('edit-sections')
    const editBarWidth = ref(0)
    const pageBar = ref({ width: 0 })
    const sub_visibility = ref({ is_visible: false, name: '' })
    const sub_pageBar_visibility = ref({ is_visible: false, name: '' })
    const edit_bar_visibility = ref({ is_visible: false, name: '' })

    onMounted(() => {
      let i = 0
      for (let key in defaultSections) {
        sidebar_items.value.push({
          name: key,
          isActive: i === 0,
          sub_sections: defaultSections[key],
        })
        i += 1
      }
    })

    const triggerEditElementMode = () => {
      changeEditWidth()
      if (mode.value !== 'edit-elements') {
        let iframe = document.getElementById('website-builder_iframe')
        let overlays = iframe.contentWindow.document.querySelectorAll(
          '.overlay.btn-group.nowrap'
        )
        iframe.contentWindow.document.getElementById(
          'triggerOverlay'
        ).innerText = 'jdjdjd'
        overlays.forEach(function (overlay) {
          overlay.classList.add('d-none')
        })
        mode.value = 'edit-elements'
      }
    }

    const changeVisibility = vn => {
      sub_visibility.value = { is_visible: vn[0], name: vn[1] }
    }

    const changePageBarVisibility = vn => {
      sub_pageBar_visibility.value = { is_visible: vn[0], name: vn[1] }
    }

    const changeEditBarVisibility = vn => {
      edit_bar_visibility.value = { is_visible: vn[0], name: vn[1] }
    }

    const changeWidth = () => {
      changeVisibility([false, ''])
      editBarWidth.value = 0
      pageBar.value.width = 0
      width.value = width.value === 0 ? 220 : 0
      if (mode.value !== 'edit-sections') {
        let iframe = document.getElementById('website-builder_iframe')
        let overlays = iframe.contentWindow.document.querySelectorAll(
          '.overlay.btn-group.nowrap'
        )
        iframe.contentWindow.document.getElementById(
          'triggerOverlay'
        ).innerText = 'jdjdjd'
        overlays.forEach(function (overlay) {
          overlay.classList.remove('d-none')
        })
        mode.value = 'edit-sections'
      }
    }

    const changeEditWidth = () => {
      changeEditBarVisibility([false, ''])
      changeVisibility([false, ''])
      width.value = 0
      pageBar.value.width = 0
      editBarWidth.value = editBarWidth.value === 0 ? 220 : 0
    }
    const changePageWidth = () => {
      mode.value = 'edit-pages'
      changePageBarVisibility([false, ''])
      width.value = 0
      editBarWidth.value = 0
      pageBar.value.width = pageBar.value.width === 0 ? 220 : 0
    }

    return {
      sub_visibility,
      sidebar_items,
      pages,
      isShow,
      changeWidth,
      width,
      changeVisibility,
      changePageWidth,
      changePageBarVisibility,
      changeEditBarVisibility,
      editBarWidth,
      changeEditWidth,
      pageBar,
      mode,
      triggerEditElementMode,
    }
  },
})
</script>
