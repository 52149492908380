<template>
  <th class="font-weight-light">{{ SSHKey.name }}</th>
  <td>{{ moment(SSHKey.created_at).format('LLL') }}</td>
  <td>
    <Button
      v-if="businessID === userID"
      :loading="loading"
      :inactive="false"
      :variant="'outline-grey'"
      :size="'md'"
      type="submit"
      name="submit"
      btnClass="px-5"
      @click.prevent="deleteKey(SSHKey.slug)"
    >
      <span class="font-weight-normal">Delete</span>
    </Button>
  </td>
  <!-- pilot actions modal -->
  <PilotActionsModal
    :isVisible="pilotActionIsVisible"
    @togglePilotActions="togglePilotActions"
    :modalObj="modalObj"
  ></PilotActionsModal>
</template>
<script>
import moment from 'moment'
import Button from '@/components/ui/Button'
import PilotActionsModal from '@/components/dashboard/project/PilotActionsModal'
import { mapActions } from 'vuex'

export default {
  name: 'SingleSSHRow',
  components: { Button, PilotActionsModal },
  props: {
    SSHKey: {
      type: Object,
    },
    businessID: {
      type: Number,
    },
    userID: {
      type: Number,
    },
  },
  data() {
    return {
      moment: moment,
      loading: false,
      pilotActionIsVisible: false,
      modalObj: {},
    }
  },
  methods: {
    ...mapActions(['handleDeleteAccess']),
    togglePilotActions(value) {
      this.pilotActionIsVisible = value
    },
    deleteKey(slug) {
      this.modalObj = {
        title: `Delete ${this.SSHKey.name}`,
        label: `Are you sure you want to delete this SSH Key Access?`,
        buttonText: 'Delete',
        type: 'deleteKey',
        action: this.handleDeleteAccess,
        params: slug,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },
  },
  mounted() {},
}
</script>

<style scoped>
.menu__items {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.menu__items:hover {
  color: #447ffc;
}
</style>
