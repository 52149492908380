<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Delete {{ deploymentName }} {{ slug }}</h6>
      <label>
        Are you sure you want to delete this deployment? This action cannot be
        undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteDeployment"
      >
        <!-- :inactive="!validate" -->
        <span class="">Delete deployment</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'DeleteDeploymentModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    deploymentName: {
      type: String,
    },
    slug: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: '',
      error: '',
    }
  },
  methods: {
    ...mapActions(['handleGetAllDeployments', 'handleDeleteDeployment']),

    async deleteDeployment() {
      this.loading = true
      this.error = ''
      this.success = ''

      await this.handleDeleteDeployment(this.slug)
        .then(async response => {
          this.success = response.data.message
          await this.handleGetAllDeployments(this.$route.params.uid)
          setTimeout(this.redirect(), 3000)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },
    redirect() {
      this.success = ''
      this.show = false
      this.loading = false
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleDeleteModal', value)

        this.error = ''
      },
    },
  },
}
</script>
