<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="hosting" />
      <div class="pb-3">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5 mt-4"
          @click="goBack"
        >
          <span class="font-weight-normal">Go Back</span>
        </Button>
      </div>

      <div class="d-flex flex-direction-row justify-content-end mb-3 mr-4">
        <Button
          v-if="paginateData !== null && paginateData.length > 0 && loaded"
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5 mt-2"
          @click="toggleAddUser(true)"
        >
          <span class="font-weight-normal">Add SSH User</span>
        </Button>
      </div>

      <!-- The alert messages -->
      <div class="px-2">
        <div class="alert alert-success" role="alert" v-if="success">
          {{ success }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ error }}
        </div>
      </div>

      <div class="hosting-table">
        <table class="table" v-if="paginateData.length > 0">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in paginateData" :key="index">
              <SingleSSHRow
                :SSHKey="log"
                :businessID="businessID"
                :userID="userID"
              />
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex align-items-center justify-content-center mt-5"
          v-if="paginateData !== null && paginateData.length < 1 && loaded"
        >
          <div class="text-center">
            <h3>List is empty</h3>
            <p>You have not added any SSH User added on this server</p>
            <div class="text-center">
              <Button
                :loading="false"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5 mt-4"
                @click="toggleAddUser(true)"
              >
                <span class="font-weight-normal">Add SSH User</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <nav class="page-dashboard__pagination p-3 mt-2">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
    <OverlayLoading v-if="loaderIsVisible" />
    <CreateSSHKey
      :isVisible="modalIsVisible"
      @toggleAddUser="toggleAddUser"
      :projectID="projectID"
      :falconSlug="falconSlug"
    ></CreateSSHKey>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import SingleSSHRow from '@/components/dashboard/project/SingleSSHRow'
import CreateSSHKey from '@/components/dashboard/project/CreateSSHKey'
import { Paginator } from 'array-paginator'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Button from '@/components/ui/Button'

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ManageSSH',
  components: {
    ProjectNav,
    SingleSSHRow,
    Button,
    OverlayLoading,
    CreateSSHKey,
  },
  data() {
    return {
      businessID: 0,
      userID: 0,
      falconSlug: '',
      falconID: this.$route.params.falconid,
      projectID: 0,
      modalIsVisible: false,
      loaderIsVisible: true,
      sshKeys: [],
      success: '',
      error: '',
      loaded: false,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getProjectStatus',
      'getProject',
      'getSelectedWorkspace',
      'getEntriesAccess',
      'getEntriesAccessStatus',
      'getBusinessInfo',
      'getDeleteAccessStatus',
    ]),
  },
  methods: {
    ...mapActions([
      'handleGetAccessEntries',
      'handleGetLoggedInUser',
      'handleGetOneProject',
    ]),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message

        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message

        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
    goBack() {
      history.back()
    },
    paginateNewData(data) {
      //Paginate the data manually
      this.paginator = new Paginator(data, 10, 1)

      //Set the first page as the data
      this.paginateData = this.paginator.first()

      if (this.paginateData === undefined) {
        this.paginateData = []
      }

      this.totalPages = this.paginator.totalPages
      this.loaderIsVisible = false
      this.loaded = true
    },
    toggleAddUser(value) {
      this.modalIsVisible = value
    },
  },
  watch: {
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.businessID = this.getProject.business_id
        }
      },
    },
    getEntriesAccessStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.sshKeys = []
          this.sshKeys = this.getEntriesAccess
          this.paginateNewData(this.sshKeys)
        }
      },
    },
    //Check if a key is deleted
    getDeleteAccessStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.handleGetAccessEntries(this.falconSlug)
        }
      },
    },
  },
  async mounted() {
    this.falconSlug = this.$route.params.slug

    await this.handleGetOneProject(this.$route.params.uid)

    this.projectID = this.getProject.id

    await this.handleGetLoggedInUser()
      .then(() => {
        this.userID = this.getBusinessInfo.user_id
      })
      .catch(() => {
        this.error = 'Could not get your User ID, please reload page'
      })

    await this.handleGetAccessEntries(this.falconSlug)
      .then(response => {
        this.sshKeys = response.data.data
      })
      .catch(error => {
        this.error = error.response.data.message
      })
  },
}
</script>

<style lang="scss">
.hosting-table {
  &__options {
    .badge {
      border-radius: 12px;
      padding: 8px 14px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
    }
  }
  thead {
    th {
      border-top: none;
      font-weight: 300;
    }
  }
}
</style>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
