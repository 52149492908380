<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent py-4">
    <router-link to="/">
      <img
        src="@/assets/img/logo-white.svg"
        alt="Fybrr logo"
        class="navbar-logo"
      />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mx-auto">
        <li v-for="(item, index) in navItems" :key="index" class="nav-item">
          <div v-if="item.hasDropdown" class="dropdown show">
            <a
              class="nav-link dropdown-toggle fs_lh-16_20"
              href="#"
              :id="item?.dropDownId"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ item.name }}</a
            >
            <component
              :is="getComponentFromName(item.componentName)"
            ></component>
          </div>
          <div v-else>
            <a
              class="nav-link fs_lh-16_20"
              :href="item.link"
              v-if="item.isExternal"
              >{{ item.name }}</a
            >
            <router-link class="nav-link fs_lh-16_20" :to="item.link" v-else>{{
              item.name
            }}</router-link>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav">
        <Button
          :variant="'light-transparent'"
          :size="'md'"
          type="submit"
          name="loginButton"
          @click="$router.push('/register')"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create account</span></Button
        >
        <Button
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="ml-3"
          @click="$router.push('/login')"
        >
          <!-- :inactive="!validate" -->
          <span class="">Login</span></Button
        >
      </ul>
    </div>
  </nav>
</template>
<script>
import FeatureDropdown from '@/components/pages/FeatureDropdown'
import Button from '@/components/ui/Button'
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'HomePageNavbar',
  components: { Button },
  setup() {
    const navItems = reactive([
      // {
      //   name: 'About',
      //   link: '/about',
      //   isExternal: false,
      //   hasDropdown: false,
      //   componentName: '',
      // },
      // {
      //   name: 'Features',
      //   link: '/features',
      //   hasDropdown: true,
      //   isExternal: false,
      //   dropDownId: 'navbarFeatures',
      //   componentName: 'FeatureDropdown',
      // },
      // {
      //   name: 'Pricing',
      //   link: '/pricing',
      //   hasDropdown: false,
      //   isExternal: false,
      //   componentName: '',
      // },
      // {
      //   name: 'Community',
      //   link: 'https://community.fybrr.com/',
      //   isExternal: true,
      //   hasDropdown: false,
      //   componentName: '',
      // },
      // {
      //   name: 'Contact',
      //   link: '/contact',
      //   isExternal: false,
      //   hasDropdown: false,
      //   componentName: '',
      // },
    ])

    const getComponentFromName = name => {
      let currentDropDown
      switch (name) {
        case 'FeatureDropdown':
          currentDropDown = FeatureDropdown
          break
        default:
          currentDropDown = ''
      }
      return currentDropDown
    }

    return { navItems, getComponentFromName }
  },
})
</script>
<style lang="scss" scoped>
.navbar {
  &-logo {
    width: 130px;
  }
  &-nav .nav-item {
    margin: 0 20px;
    .nav-link {
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
