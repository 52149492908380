<template>
  <b-modal
    class="app-modal"
    centered
    v-model="showTaskModal"
    hide-footer
    hide-header
  >
    <div>
      <h6>Task</h6>
      <!-- <p class="description">You can change this later</p> -->
      <div class="form-group">
        <label for="title">Title of task</label>
        <input
          type="text"
          class="form-control"
          id="title"
          maxlength="30"
          placeholder="Enter title of task"
          v-model="task.title"
          disabled
        />
      </div>
      <div class="form-group">
        <label for="priority">Priority</label>
        <select
          name="priority"
          id="priority"
          class="form-control"
          v-model="task.priority"
          disabled
        >
          <option :value="undefined" selected hidden disabled>
            Select priority level
          </option>
          <option value="low">Low</option>
          <option value="normal">Normal</option>
          <option value="critical">Critical</option>
          <option value="important">Important</option>
        </select>
      </div>
      <div class="form-group">
        <label for="status">Status</label>
        <select
          name="status"
          id="status"
          class="form-control"
          v-model="task.status"
          disabled
        >
          <option :value="undefined" selected hidden disabled>
            Select workflow status
          </option>
          <option value="todo">Todo</option>
          <option value="in_progress">In Progress</option>
          <option value="review">In Review</option>
          <option value="done">Done</option>
        </select>
      </div>
      <div class="form-group">
        <label>Start Date:</label>
        <input
          type="date"
          class="form-control"
          placeholder="Start Date"
          v-model="task.start_date"
          disabled
        />
      </div>
      <div class="form-group">
        <label>End Date:</label>
        <input
          type="date"
          class="form-control"
          placeholder="End Date"
          v-model="task.end_date"
          disabled
        />
      </div>

      <div class="form-group">
        <label for="description">Assignees</label>
        <div v-for="member in this.task?.assignees" :key="member">
          <p>
            {{ member?.first }} {{ member?.last }}
            <span style="font-weight: bold; display: inline-block">
              [ {{ member?.email }} ]</span
            >
          </p>
        </div>
        <p v-if="task?.assignees < 1">
          No member has being assigned for this task
        </p>
      </div>

      <!-- <div class="form-group">
        <label for="description">Task description</label>
        <textarea
          name="description"
          id="description"
          class="form-control"
          cols="30"
          rows="10"
          maxlength="300"
          v-model="task.description"
          disabled
        ></textarea>
      </div> -->
      <div class="form-group">
        <label for="description">Task description</label>
        <Editor
          disabled
          api-key="uypuxdvqkqw9e4ua1bo1olwfig921cslj0z12oc12n1esk32"
          v-model="task.description"
          :init="{
            plugins: 'lists link image table code help wordcount',
          }"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { mapActions } from 'vuex'

export default {
  name: 'ViewTaskModal',
  components: { Editor },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    teamMembers: {
      type: Array,
    },
    projectID: {
      type: Number,
      required: true,
    },
    currentTask: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTaskModal: false,
      loading: false,
      error: false,
      assigneesList: this.currentTask.assignees,
      task: {
        title: '',
        priority: '',
        description: '',
      },
    }
  },
  methods: {
    ...mapActions(['handleGetTasks']),
  },
  watch: {
    isVisible: {
      handler(value) {
        this.showTaskModal = value

        this.task = {}
      },
    },
    showTaskModal: {
      handler(value) {
        this.$emit('toggleViewModal', {}, value)

        //assign the values
        this.task = this.currentTask
      },
    },
  },
}
</script>
