<template>
  <b-modal
    class="code-modal"
    size="lg"
    centered
    v-model="show"
    hide-footer
    hide-header
  >
    <div>
      <h6 class="mb-3">Section code editor</h6>
      <div class="textSection">
        <codemirror
          v-model="code"
          placeholder="Code goes here..."
          :style="{ height: '400px' }"
          :autofocus="true"
          :indent-with-tab="true"
          :tab-size="2"
          :extensions="extensions"
          @ready="handleReady"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <div
          class="ml-3 font-weight-normal cancel-btn cursor-pointer"
          @click="show = false"
        >
          Cancel
        </div>
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="applyEditedCode"
        >
          <!-- :inactive="!validate" -->
          <span class="">Apply</span></Button
        >
      </div>
    </div>
  </b-modal>
  <p id="codeModalToggle"></p>
</template>
<script>
import Button from '@/components/ui/Button'
import { defineComponent, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { Codemirror } from 'vue-codemirror'
import { html } from '@codemirror/lang-html'
import { oneDark } from '@codemirror/theme-one-dark'

export default defineComponent({
  name: 'CodeModal',
  components: { Button, Codemirror },
  props: {
    id: String,
  },
  setup() {
    const extensions = [html(), oneDark]

    const show = ref(false)
    // Codemirror EditorView instance ref
    const view = shallowRef()
    const handleReady = payload => {
      view.value = payload.view
    }

    let code = ref('')
    let observer = null
    let options = {
      subtree: true,
      childList: true,
      attributes: true,
    }
    // eslint-disable-next-line no-unused-vars
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          code.value = localStorage.getItem('currentCode')
          show.value = true
        } else if (mutation.type === 'attributes') {
          console.log(`The ${mutation.attributeName} attribute was modified.`)
        }
      }
    }
    const applyEditedCode = () => {
      localStorage.setItem('currentCode', code.value)
      let iframe = document.getElementById('website-builder_iframe')
      let target = document.querySelector('#codeModalToggle')
      show.value = false
      iframe.contentWindow.document.getElementById('applyTrigger').innerText =
        target.innerText
    }
    onMounted(() => {
      let target = document.querySelector('#codeModalToggle')
      observer = new MutationObserver(callback)

      observer.observe(target, options)
    })

    onUnmounted(() => observer && observer.disconnect())

    return {
      code,
      extensions,
      handleReady,
      show,
      applyEditedCode,
    }
  },
})
</script>
<style>
.textSection {
  max-height: 500px;
  overflow-y: scroll;
}

/*.code-modal .modal-content {*/
/*  background-color: #000000;*/
/*}*/
</style>
