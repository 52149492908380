<template>
  <div class="card rounded-default">
    <div class="card-body">
      <span class="badge mb-2 badge--active">Active</span>
      <h6 class="w-100 title">{{ websiteDetails.name }}</h6>
      <div class="d-flex mt-3">
        <a
          :href="`https://${websiteDetails.custom_domain}.fybrr.app`"
          target="_blank"
        >
          <span
            class="badge badge--blue"
            v-if="websiteDetails.custom_domain !== ''"
            >https://{{ websiteDetails.custom_domain }}.fybrr.app</span
          >
        </a>
        <a href="#">
          <span
            class="badge badge--blue"
            v-if="websiteDetails.custom_domain == ''"
            >NO WEBSITE URL</span
          >
        </a>
      </div>
      <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Created at</p>
          <h6>{{ moment(websiteDetails.created_at).format('LLL') }}</h6>
        </div>
        <div class="project-card-info__details">
          <p>Last edit at</p>
          <h6>
            {{ moment(websiteDetails.updated_at).format('LLL') }}
          </h6>
        </div>
      </div>
      <div class="project-card-button mt-3 d-flex align-items-center">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          @click="goToEditURL(websiteDetails.uuid)"
        >
          <span class="">EDIT</span></Button
        >
        <Button
          :loading="false"
          :inactive="false"
          :variant="'outline'"
          :size="'md'"
          type="submit"
          name="editWebsite"
          btnClass="mr-3 mt-1"
          @click="toggleSettingsModal(true)"
        >
          <span class="">SETTINGS</span></Button
        >
        <Button
          v-if="showAdminButton"
          :loading="false"
          :inactive="false"
          :variant="'outline-danger'"
          :size="'md'"
          type="submit"
          name="editDelete"
          btnClass="mt-1"
          @click="toggleModal(true)"
        >
          <svg
            class="icon cursor-pointer"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <use xlink:href="/sprite.svg#icon-d-trash" />
          </svg>
          <span class="">DELETE</span></Button
        >
      </div>
    </div>
  </div>
  <DeleteWebsiteModal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :websiteName="websiteDetails.name"
    :websiteId="websiteDetails.id"
  ></DeleteWebsiteModal>

  <!-- website settings modal -->
  <WebsiteSettingsModal
    :isVisible="settingsModalIsVisible"
    @toggleSettingsModal="toggleSettingsModal"
    :websiteName="websiteDetails.name"
    :websiteDomain="websiteDetails.custom_domain"
    :websiteUUID="websiteDetails.uuid"
  ></WebsiteSettingsModal>
</template>
<script>
import Button from '@/components/ui/Button'
import DeleteWebsiteModal from '@/components/dashboard/project/DeleteWebsiteModal'
import WebsiteSettingsModal from '@/components/dashboard/project/WebsiteSettingsModal'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'SingleProjectCard',
  components: { Button, DeleteWebsiteModal, WebsiteSettingsModal },
  props: {
    websiteDetails: {
      type: Object,
    },
    showAdminButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      moment: moment,
      modalIsVisible: false,
      settingsModalIsVisible: false,
    }
  },
  methods: {
    ...mapActions(['handleGetProjectWebsites']),
    toggleModal(value) {
      this.modalIsVisible = value
    },
    toggleSettingsModal(value) {
      this.settingsModalIsVisible = value
    },
    goToEditURL(uuid) {
      const editURL = `https://fybrr.app/projects/${uuid}/edit`
      window.open(editURL, '_blank')
    },
  },
}
</script>
<style lang="scss">
.min-h-60 {
  min-height: 60px;
}
.cursor-pointer {
  cursor: pointer;
}
.title {
  text-transform: capitalize;
}
</style>
