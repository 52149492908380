<template>
  <b-modal
    class="app-modal integration-modal"
    centered
    v-model="show"
    hide-footer
    hide-header
  >
    <div v-if="!isProcessed">
      <h6>Select Option</h6>
      <div class="mt-4">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click="isProcessed = true"
        >
          <!-- :inactive="!validate" -->
          <span class="">Import Repository</span></Button
        >
        <Button
          :loading="false"
          :inactive="false"
          :variant="'outline-primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5 mt-3"
          @click="showCreateRepo(true)"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create new Repository</span></Button
        >
      </div>
    </div>
    <div v-else>
      <div v-if="servicesLength > 0">
        <h6>Select repo source</h6>
        <p class="description">
          These are the available services you can integrate into your projects.
        </p>
        <div class="row">
          <div
            class="col-md-4"
            v-for="(service, index) in services"
            :key="index"
            @click="gotoURL(service.platform)"
          >
            <div class="integration-modal__card">
              <div>
                <div class="img">
                  <img
                    :src="`/assets/img/integration/${service.platform}.png`"
                    :alt="`${service.platform}`"
                  />
                </div>
                <p class="text-xs">
                  {{ service.platform }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h6>Select repo source</h6>
        <p class="description mt-3">
          You do not have any available service to integrate in your projects
        </p>
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="addRepository"
          btnClass="mt-3"
          @click="goToIntegrationsPage"
        >
          <span class="">INTEGRATE NOW</span></Button
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapGetters } from 'vuex'
export default {
  name: 'SelectIntegrationModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    Integrations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isProcessed: false,
      services: [],
      servicesLength: 0,
      createModalIsVibible: false,
      createRepo: false,
    }
  },
  methods: {
    showCreateRepo(value) {
      this.$emit('showCreateModal', value)
      this.show = false
    },
    gotoURL(platform) {
      this.show = false
      this.$router.push({
        name: 'FetchRepo',
        params: { uid: this.$route.params.uid, platform: platform },
      })
    },
    goToIntegrationsPage() {
      this.show = false
      this.$router.push('/dashboard/integrations')
    },
  },
  computed: {
    ...mapGetters(['getProject']),
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
        this.services = this.Integrations
        this.servicesLength = this.services.length
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.isProcessed = false
      },
    },
    createRepo: {
      handler(value) {
        this.isProcessed = false
        this.$emit('createRepo', value)
        this.$emit('toggleModal', !value)
      },
    },
  },
}
</script>
<style lang="scss">
.integration-modal {
  &__card {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(99, 122, 135, 0.24);
    border-radius: 8px;
    cursor: pointer;
    .img {
      min-height: 60px;
      min-width: 60px;
    }
    img {
      width: 60px;
    }
    p {
      margin-top: 10px;
      font-weight: 300;
    }
  }
  .button {
    display: block;
    width: 100%;
  }
}
</style>
