<template>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <div class="dropdown-body row">
      <div class="col-md-4" v-for="(item, index) in dropDownItems" :key="index">
        <router-link :to="item.to" class="dropdown-item">
          <div class="icon mr-4">
            <svg width="30px" height="30px" viewBox="0 0 24 24">
              <use :xlink:href="`/sprite.svg#icon-${item.icon}`"></use>
            </svg>
          </div>
          <div>
            <h6 class="fs_lh-14_18">{{ item.title }}</h6>
            <p class="fs_lh-14_18 mt-2">{{ item.description }}</p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="dropdown-footer">
      <div class="w-50">
        <a href="#" class="link">Join the Fybrr Community to learn more </a>
        <p class="description text-xs">
          For growing teams - Integrate your version control platforms to build
          scalable apps
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'FeatureDropDown',
  setup() {
    const dropDownItems = reactive([
      {
        icon: 'website-builder',
        title: 'Website Builder',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'store',
        title: 'Ecommerce store',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'deployment',
        title: 'Deployment',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'integration',
        title: 'Integration',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'hosting',
        title: 'Hosting',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'workflow',
        title: 'Workflow & Collaboration',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'transfer',
        title: 'Transfers',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'expert',
        title: 'Fybrr Expert',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
      {
        icon: 'team-work',
        title: 'Team Work',
        to: '/features',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
      },
    ])

    return { dropDownItems }
  },
})
</script>
<style lang="scss" scoped>
.dropdown-menu {
  width: 1200px;
  left: -300px;
  border-radius: 20px;
  box-shadow: 0 0 64px 2px rgba(99, 122, 135, 0.08);
  & > * {
    padding: 40px;
  }
  .dropdown-body {
    .dropdown-item {
      display: flex;
      white-space: break-spaces;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 8px;
      transition: 0.2s;
      &:hover .dropdown-item {
        background-color: rgba(99, 122, 135, 1);
        box-shadow: 0 0 64px 2px rgba(99, 122, 135, 0.08);
      }
      .icon {
        background-color: rgba(99, 122, 135, 0.12);
        border-radius: 50%;
        color: #000000;
        width: 50px;
        height: 50px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        line-height: 1.2;
        font-weight: 300;
      }
    }
  }
  .dropdown-footer {
    background-color: rgba(99, 122, 135, 0.08);
    & div > * {
      font-weight: 400;
    }
  }
}
</style>
