<template>
  <aside
    class="outer-left"
    :style="{ width: `${width}px` }"
    @focusout="$emit('toggleVisibility', [false, ''])"
  >
    <nav class="d-flex align-items-start flex-column py-4 px-3">
      <div class="side_header">
        <h5>Add sections</h5>
        <p>Select and add layout</p>
      </div>
      <div class="side_body">
        <div
          :class="`side_item ${item.isActive ? 'active' : ''}`"
          v-for="(item, index) in sidebar_items"
          @click="$emit('toggleVisibility', [true, item.name])"
          :key="index"
        >
          <p>{{ item.name }}</p>
          <svg class="icon" width="17px" height="17px" viewBox="0 0 7 13">
            <use xlink:href="/sprite.svg#icon-d-angle-right" />
          </svg>
        </div>
      </div>
    </nav>
  </aside>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SectionBar',
  emits: ['toggleVisibility'],
  props: {
    width: {
      type: Number,
      required: true,
    },
    sidebar_items: {
      type: Object,
      required: true,
    },
  },
})
</script>
