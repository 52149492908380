import repoMutations from './mutations'
import repoActions from './actions'

export default {
  state: {
    projectReposStatus: 'PENDING',
    projectRepos: {},
    deleteRepo: {},
    deleteRepoStatus: 'PENDING',
    allCommits: {},
    allCommitsStatus: 'PENDING',
    userIntegrationsStatus: 'PENDING',
    userIntegrations: {},
    addRepositoryStatus: 'PENDING',
    addRepository: {},
    fetchRepoIntegrations: {},
    fetchRepoIntegrationsStatus: 'PENDING',
    importRepoStatus: 'PENDING',
    importRepo: {},
  },
  actions: { ...repoActions },
  mutations: { ...repoMutations },
  getters: {
    getProjectReposStatus(state) {
      return state.projectReposStatus
    },
    getProjectRepos(state) {
      return state.projectRepos
    },
    getDeleteRepo(state) {
      return state.deleteRepo
    },
    getDeleteRepoStatus(state) {
      return state.deleteRepoStatus
    },
    getAllCommitsStatus(state) {
      return state.allCommitsStatus
    },
    getAllCommits(state) {
      return state.allCommits
    },
    getUserIntegrations(state) {
      return state.userIntegrations
    },
    getUserIntegrationsStatus(state) {
      return state.userIntegrationsStatus
    },
    getAddRepositoryStatus(state) {
      return state.addRepositoryStatus
    },
    getAddRepository(state) {
      return state.addRepository
    },
    getFetchRepoIntegrations(state) {
      return state.fetchRepoIntegrations
    },
    getFetchRepoIntegrationsStatus(state) {
      return state.fetchRepoIntegrationsStatus
    },
    getImportRepoStatus(state) {
      return state.importRepoStatus
    },
    getImportRepo(state) {
      return state.importRepo
    },
  },
}
