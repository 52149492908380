<template>
  <!--  header  -->
  <div class="pages home">
    <Navbar></Navbar>
    <main class="container jumbotron-container">
      <div class="row pages-jumbotron">
        <div class="col-lg-10">
          <p class="pre fs_lh-14_18">Introducing our cutting-edge no code platform</p>
          <h1 class="title my-4 fs_lh-80_94">
            Create without limits,<br> build without code
          </h1>
          <p class="post fs_lh-24_31">
            With our user-friendly drag-and-drop interface and intuitive visual website builder, you can bring your ideas to life in minutes, without the need for technical expertise.
          </p>
          <div class="row mx-0 my-5 align-items-center">
            <div class="col-lg-7 input-wrap">
              <input
                type="text"
                placeholder="Enter your work email"
                class="form-control"
                style="color:#fff"
                id="email"
              />
              <Button
                :loading="false"
                :inactive="false"
                :variant="'transparent'"
                :size="'md'"
                type="submit"
                name="editProject"
                btnClass="mr-3 mt-1"
                @click="sendToContact()"
              >
                <span class="text-primary">Get Started for Free</span></Button
              >
            </div>
            <!-- <div class="col-lg-3">
              <Button
                :loading="false"
                :inactive="false"
                :variant="'transparent'"
                :size="'md'"
                type="submit"
                name="editProject"
                btnClass="mr-3 mt-1"
                @click="''"
              >
                <span class="text-cyan d-flex align-items-center">
                  <svg class="icon" width="24px" height="24px">
                    <use xlink:href="/sprite.svg#icon-play"></use>
                  </svg>
                  <span class="">Watch our video</span>
                </span>
              </Button>
            </div> -->
          </div>
          <!-- <div class="nav-services mt-3">
            <p class="text-dark-grey fs_lh-12_15">
              PROVISIONED CLOUD SERVERS AVAILABLE FOR
            </p>
            <div
              class="row mx-0 justify-content-between align-items-center mt-5"
            >
              <div v-for="(image, index) in images" :key="index">
                <img :src="`assets/img/home/${image}.svg`" :alt="image" />
              </div>
            </div>
          </div> -->
        </div>
        <div class="col">
          <div class="expert-container h-75">
            <div class="expert-container__img">
              <img src="@/assets/img/about/expert/expert-4.svg" alt="" />
            </div>
            <div class="expert-container__img align-self-end">
              <img src="@/assets/img/about/expert/expert-5.svg" alt="" />
            </div>
            <div class="expert-container__img">
              <img src="@/assets/img/about/expert/expert-6.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <!--  website builder  -->
  <section class="container pages--website-builder">
    <div class="row">
      <div class="col-lg-10">
        <h2 class="title fs_lh-64_64">
          Start building the future you envision today with our powerful no code platform.
        </h2>
        <p class="mt-3 fs_lh-24_31">Our platform is packed with a range of features and integrations, making it easy to customize your projects to your exact specifications. From creating websites to building e-commerce stores and hosting them all on a global infrastructure, our platform is designed to streamline your workflows and boost your productivity.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 img-container">
        <div class="video-player">
          <img src="@/assets/img/animated-bg.gif" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!--  prototype  -->
  <section class="pages--website-builder">
    <div class="row">
      <div class="col-lg-10">
        <h2 class="title fs_lh-64_64">Our pre-built website templates are the perfect solution for you. </h2>
        <p class="mt-3 fs_lh-24_31">
          With our drag-and-drop interface, you can easily add your own content, images, and branding elements to create a fully personalized website in just a few clicks. You don't need any technical skills or design experience to get started - simply choose a template that best suits your business or personal brand and let our platform do the rest.
        </p>
      </div>
    </div>
    <div class="py-4">
      <p class="mb-3 font-weight-normal fs_lh-16_20">
        Select template & Get started
      </p>
      <div class="row">
        <div
          class="col-lg-3"
          v-for="(singleTemplate, index) in templates"
          :key="index"
        >
          <TemplateCard :template="singleTemplate"></TemplateCard>
        </div>
      </div>
    </div>
  </section>

  <!--  who is Fybrr -->
  <section class="pages--about">
    <div class="pages--about-container">
      <div class="row">
        <div class="col-lg-10">
          <h2 class="fs_lh-64_64">Who is Fybrr for</h2>
          <p class="mt-4 fs_lh-24_31">
            If you're looking for a way to create powerful digital solutions without coding, our platform is the perfect solution for you. Whether you're an entrepreneur, small business owner, marketer, or creative professional, our platform empowers you to build the digital solutions you need to grow and succeed in today's digital landscape.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div
          class="col-lg-4 col-md-6"
          v-for="(audience, index) in audiences"
          :key="index"
        >
          <ServiceCard :size="'full'" :image="audience.image">
            <div class="fancy-box-footer text-left">
              <h4 class="fs_lh-32_40">{{ audience.name }}</h4>
              <p class="mt-2 fs_lh-18_19">
                {{ audience.description }}
              </p>
            </div>
          </ServiceCard>
        </div>
      </div>
      <div class="pages--about-functionalities">
        <h3 class="fs_lh-32_32">What can you do with Fybrr</h3>
        <div class="row mt-5">
          <div
            class="col-lg-3"
            v-for="(item, index) in functionalities"
            :key="index"
          >
            <div class="functionalities-card">
              <div class="functionality-icon mb-4">
                <svg width="50px" height="50px" viewBox="0 0 24 24">
                  <use :xlink:href="`/sprite.svg#icon-${item.icon}`"></use>
                </svg>
              </div>
              <h6 class="my-3 fs_lh-18_23">{{ item.title }}</h6>
              <p class="text-xs fs_lh-14_18">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  create store  -->
  <section class="pages--website-builder">
    <div class="row">
      <h2 class="title col-lg-10 fs_lh-64_64">
        Join the thousands of businesses already using our e-commerce platform to drive growth and success
      </h2>
      <p class="mt-4 col-lg-10 fs_lh-24_31">
        With our user-friendly interface and customizable templates, you can create a stunning online store that reflects your brand and showcases your products in the best possible way. Our platform is fully mobile-responsive, ensuring that your customers can shop on any device, anytime, anywhere.
      </p>
      <div class="col-md-12 img-container">
        <div class="video-player">
          <img src="@/assets/img/home/store.png" alt="" />
          <div class="play-button-overlay">
            <div class="play-button">
              <svg
                class="icon cursor-pointer"
                width="50px"
                height="50px"
                viewBox="0 0 58 70"
              >
                <use xlink:href="/sprite.svg#icon-play2" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  select template  -->
  <section class="pages--website-builder">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="title fs_lh-64_64">Select a Template and Get started</h2>
        <p class="mt-3 fs_lh-24_31">
          Whether you're looking to create a portfolio website, an e-commerce store, or a blog, our pre-built website templates are the perfect solution for you.
        </p>
      </div>
    </div>
    <div class="py-4">
      <div class="row">
        <div
          class="col-lg-3"
          v-for="(singleTemplate, index) in templates"
          :key="index"
        >
          <TemplateCard :template="singleTemplate"></TemplateCard>
        </div>
      </div>
    </div>
  </section>
  <ExpertComponent></ExpertComponent>
  <section class="pages--pricing">
    <div class="container pages--pricing-center px-0">
      <div class="row">
        <div class="col-lg-5">
          <h2 class="title fs_lh-64_64">Pay Only When You Go Live</h2>
          <p class="description fs_lh-24_31">
            With our affordable pricing and product support, you can focus on your business while we take care of your website and hosting needs.
          </p>
        </div>
      </div>
      <div class="container">
        <div class="row feature mt-5">
          <div class="col-lg-6">
            <div
              v-for="(feature, index) in features"
              :class="feature.active ? 'text-white' : ''"
              class="feature-key"
              @click="selectFeature(index)"
              :key="index"
            >
              <h4 class="fs_lh-20_25">
                {{ feature.title }}
                <span v-if="feature.active" class="price ml-2 fs_lh-20_23"
                  >{{ feature.price }}/mo</span
                >
              </h4>
              <p class="about mt-3 fs_lh-16_20">{{ feature.description }}</p>
            </div>
          </div>
          <div class="offset-2 col-lg-4">
            <h2 class="fs_lh-32_32">Features</h2>
            <ul class="feature-items">
              <li v-for="(feature, index) in activeFeatures" :key="index">
                <p class="fs_lh-18_23">{{ feature }}</p>
              </li>
            </ul>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="getStarted"
              btnClass="mr-3 mt-3 feature-items-button"
              @click="goToRegister()"
            >
              <span class="px-5">Get started</span></Button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <ContactComponent></ContactComponent>
</template>
<script>
import Button from '@/components/ui/Button'
import Navbar from '@/components/pages/navbar'
import TemplateCard from '@/components/pages/home/TemplateCard'
import ServiceCard from '@/components/dashboard/overview/ServiceCard'
import ExpertComponent from '@/components/pages/expert'
import ContactComponent from '@/components/pages/home/contact'
import { defineComponent, reactive, ref } from 'vue'
export default defineComponent({
  name: 'HomePage',
  components: {
    Navbar,
    Button,
    TemplateCard,
    ServiceCard,
    ExpertComponent,
    ContactComponent,
  },
  setup() {
    const templates = reactive([
      { name: 'Blank site', link: '/register', image: '' },
      {
        name: 'Personal starter',
        link: '/register',
        image: '/assets/img/template/template1.jpg',
      },
      {
        name: 'Agency starter',
        link: '/register',
        image: '/assets/img/template/template2.png',
      },
      {
        name: 'Corporate starter',
        link: '/register',
        image: '/assets/img/template/template3.png',
      },
    ])
    const audiences = reactive([
      {
        name: 'Individuals',
        description:
          'Building and scaling websites made easy. Launch websites with just a click of a button.',
        image: '/assets/img/home/audience/audience1.jpg',
      },
      {
        name: 'Startups & Agencies',
        description:
          'Build nth websites. Manage all your website projects from one dashboard. Get started for free.',
        image: '/assets/img/home/audience/audience2.jpg',
      },
      {
        name: 'Developers',
        description:
          'In today’s world, speed matters. Design, develop and launch websites faster with Fybrr.',
        image: '/assets/img/home/audience/audience3.jpg',
      },
    ])
    const features = reactive([
      {
        title: 'Website Builder',
        active: true,
        price: 'Starting from $10',
        description:
          'With our user-friendly drag-and-drop interface and intuitive visual workflow builder, you can bring your ideas to life in minutes, without the need for technical expertise.',
        items: [
          'Free templates',
          'Website Builder',
          '5GB Hosting Space'
        ],
      },
      {
        title: 'Ecommerce Store',
        active: false,
        price: 'Starting from $10',
        description:
          'Whether you are just starting out or looking to scale your online store, our e-commerce platform offers everything you need to succeed in today\'s competitive marketplace.',
        items: [
          'Free templates',
          'Website Builder',
          '5GB Hosting Space',
          'Product Inventory',
          'Accept Payments through Paystack',
          '1.5% Transaction Processing Fee'
        ],
      },
      {
        title: 'Hosting',
        active: false,
        price: 'Starting from $10',
        description:
          'Our hosting platform is built on a robust infrastructure, with multiple data centers and advanced security measures to ensure the highest level of uptime and protection for your website or application.',
        items: [
          '99.9% Uptime',
          'Over 15 Data Centers across the World',
          'Advanced Security',
          'Performance Optimizations',
          'Provisioned Servers with Pre-built OS Templates',
          'SSH Key Management'
        ],
      },
      // {
      //   title: 'Deployments',
      //   active: false,
      //   price: '$10',
      //   description:
      //     'For growing teams - Integrate your version control platforms to build scalable apps',
      //   items: [
      //     'Up to 3 Projects',
      //     'Website Builder',
      //     '3 Git Deployments',
      //     'Email Support',
      //     'Add Ons / Integrations',
      //     'Connect to 3rd party servers via SSH',
      //   ],
      // },
      {
        title: 'Fybrr Experts',
        active: false,
        price: 'Custom',
        description:
          'Get started today and work with our team of experts to unlock the full potential of no-code technology for your business.',
        items: [
          'Experienced No-Code Professionals',
          'Available On-Demand',
          'Flexible and Affordable Pricing',
          'Project Management'
        ],
      },
    ])
    let activeFeatures = ref(features[0].items)
    const functionalities = reactive([
      {
        icon: 'website-builder',
        title: 'Create websites',
        description:
          'Create a stunning website that will attract and engage your audience.',
      },
      {
        icon: 'store',
        title: 'Create online stores',
        description: 'Manage your products, inventory, orders, payments, and shipping, all in one place.',
      },
      {
        icon: 'hosting',
        title: 'Cloud hosting',
        description:
          'We provide fast, reliable, and secure hosting for your websites or applications.',
      },
      {
        icon: 'integration',
        title: 'Project collaboration',
        description:
          'Fybrr helps teams to collaborate more effectively on projects and achieve their goals faster.',
      },
    ])
    const images = reactive([
      'linux',
      'mysql',
      'python',
      'gitlab',
      'bitbucket',
      'github',
      'slack',
      'docker',
    ])

    const selectFeature = index => {
      features.forEach(element => {
        element.active === true ? (element.active = false) : ''
      })
      features[index].active = true
      activeFeatures.value = features[index].items
    }

    const sendToContact = () => {
      const email = document.getElementById('email').value;
      localStorage.setItem('email', email);

      location.href = '/register';
    }

    const goToRegister = () => {
      location.href = '/register';
    }

    return {
      images,
      templates,
      audiences,
      functionalities,
      features,
      activeFeatures,
      selectFeature,
      sendToContact,
      goToRegister
    }
  },
})
</script>
<style lang="scss">
.functionalities-card {
  width: 80%;
}
.functionality-icon {
  background-color: rgba(99, 122, 135, 0.12);
  border-radius: 50%;
  color: #ffffff;
  width: 90px;
  height: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-items-button {
  height: fit-content;
}
</style>
<style lang="scss" scoped>
.pages-jumbotron {
  .expert-container {
    &__img {
      align-self: flex-start;
    }
  }
}
</style>
