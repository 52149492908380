<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <h6>{{ modalTitle }}</h6>

      <div class="form-group">
        <label for="project_category">Projects</label>
        <select
          name="project_u_id"
          id="project_u_id"
          class="form-control"
          v-model="projectUniqueID"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option
            v-for="project in projects"
            v-bind:key="project.u_id"
            v-bind:value="project.u_id"
          >
            {{ project.name }}
          </option>
        </select>
      </div>
      <p class="description mt-2 text-center font-weight-bold">OR</p>
      <div class="col text-center mt-2">
        <router-link class="mt-3" to=""
          ><p @click="goToURL()">Create New Project</p></router-link
        >
        <!-- <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="createProject"
          btnClass="px-4"
          @click="goToURL()"
        >
          
          <span class="">Create New Project</span></Button
        > -->
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'lg'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click="goToProject()"
        >
          <span class="">Select</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapGetters } from 'vuex'
export default {
  name: 'SelectWebsiteModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalDestination: {
      type: String,
      required: true,
    },
    modalDestinationName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projects: [],
      show: false,
      projectUniqueID: '',
    }
  },
  computed: {
    ...mapGetters(['getProjectsArray']),
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
        this.projects = this.getProjectsArray
      },
    },
    show: {
      handler(value) {
        this.$emit(
          'toggleModal',
          value,
          this.modalTitle,
          this.modalDestination,
          this.modalDestinationName
        )
      },
    },
    getProjectsArray: function () {
      this.projects = this.getProjectsArray
    },
  },
  methods: {
    /*This function was declared to make the modal disapper then
    go to the new page*/
    goToURL() {
      this.show = false
      this.$router.push({ name: 'CreateProjects' })
    },
    goToProject() {
      this.show = false
      this.$router.push({
        name: this.modalDestinationName,
        params: { uid: this.projectUniqueID },
      })
    },
  },
}
</script>
