import * as types from '@/store/modules/deployment/types'

export default {
  [types.DEPLOYMENTS_STATUS](state, value) {
    state.deploymentsStatus = value
  },
  [types.DEPLOYMENTS](state, payload) {
    state.deployments = payload
  },
  [types.SERVER_DEPLOYMENTS_STATUS](state, value) {
    state.serverDeploymentsStatus = value
  },
  [types.SERVER_DEPLOYMENTS](state, payload) {
    state.serverDeployments = payload
  },

  [types.DEPLOYMENT_STATUS](state, value) {
    state.deploymentStatus = value
  },
  [types.DEPLOYMENT](state, payload) {
    state.deployment = payload
  },
  [types.DELETE_DEPLOYMENT](state, payload) {
    state.deleteDeployment = payload
  },
  [types.DELETE_DEPLOYMENT_STATUS](state, payload) {
    state.deleteDeploymentStatus = payload
  },
  [types.DEPLOYMENT_CONFIGS](state, payload) {
    state.deploymentConfigs = payload
  },
  [types.DEPLOYMENT_CONFIGS_STATUS](state, payload) {
    state.deploymentConfigsStatus = payload
  },
  [types.UPDATE_DEPLOYMENT_CONFIGS](state, payload) {
    state.updateDeploymentConfigs = payload
  },
  [types.UPDATE_DEPLOYMENT_CONFIGS_STATUS](state, payload) {
    state.updateDeploymentConfigsStatus = payload
  },
  [types.ADD_DEPLOYMENT](state, payload) {
    state.addDeployment = payload
  },
  [types.ADD_DEPLOYMENT_STATUS](state, payload) {
    state.addDeploymentStatus = payload
  },
}
