<template>
  <div class="dropdown-menu show" v-if="show">
    <div @click.prevent="this.show = false">
      <svg class="icon" viewBox="0 0 18 18">
        <use xlink:href="/sprite.svg#icon-close" />
      </svg>
      <span class="close__panel__text">CLOSE PANEL</span>
    </div>
    <div class="dropdown-body">
      <p class="title mb-3">HOW DO YOU WANT TO PUBLISH</p>
      <label
        for="fybrr_domain"
        class="dropdown-item mb-3"
        @click="domainType = 1"
      >
        <input
          class="form-check-input"
          type="radio"
          name="domain"
          :checked="domainType === 1"
          id="fybrr_domain"
        />
        <div class="ml-4">
          <p class="title">Continue with Fybrr Domain</p>
          <p class="description">{{ domainProperties.domain }}.fybrr.site</p>
        </div>
      </label>

      <label
        for="custom_domain"
        class="dropdown-item mb-3"
        @click="domainType = 2"
      >
        <input
          class="form-check-input"
          type="radio"
          name="domain"
          id="custom_domain"
          :checked="domainType === 2"
        />
        <div class="ml-4">
          <p class="title">Continue with Custom Domain</p>
          <a class="description link">Add custom domain</a>
        </div>
      </label>
      <div class="advanced-option" v-if="domainType === 2">
        <div class="advanced-option_header">
          <p class="title">ADVANCED OPTIONS</p>
          <svg width="20px" height="20px" viewBox="0 0 24 24">
            <use xlink:href="/sprite.svg#icon-angle-down"></use>
          </svg>
        </div>
        <div class="dropdown-item my-3">
          <div class="advanced-option_body">
            <p class="title">
              Fybrr Air - $5/m
              <span class="badge badge--white">current plan</span>
            </p>
            <div class="description">
              For small websites, non-commercial apps, such as proof of
              concepts, and MVPs
              <ul>
                <li>8GB site storage</li>
                <li>64GB xyz</li>
                <li>256SSD wby</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="dropdown-item my-3">
          <div class="advanced-option_body">
            <p class="title">Fybrr Earth - $10/m</p>
            <div class="description">
              For growing teams - Integrate your version control platforms to
              build scalable apps
              <ul>
                <li>8GB site storage</li>
                <li>64GB xyz</li>
                <li>256SSD wby</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="dropdown-item my-3">
          <div class="advanced-option_body">
            <p class="title">Fybrr Earth - $50/m</p>
            <div class="description">
              For growth stage companies - Build unlimited projects, mission
              critical sites and applications
              <ul>
                <li>8GB site storage</li>
                <li>64GB xyz</li>
                <li>256SSD wby</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="fybrr__domain__name" v-if="domainType == 1">
        <div class="form-group">
          <label for="fybrr_domain_name" style="color: #a3a3a3"
            >Enter a domain name</label
          >
          <input
            type="text"
            class="form-control"
            id="fybrr_domain_name"
            placeholder="Choose a Fybrr Domain"
            maxlength="20"
            v-model="domainProperties.domain"
          />
        </div>
      </div>
      <Button
        :loading="false"
        :inactive="false"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="submit"
        btnClass="px-4 my-3"
        @click="''"
      >
        <span class="d-flex align-items-center">Publish site</span></Button
      >
      <!-- <Button
        :loading="false"
        :inactive="false"
        :variant="'inline'"
        :size="'md'"
        type="submit"
        name="submit"
        btnClass="px-4 my-3"
        @click="''"
      >
        <span>Compare all plans</span></Button
      > -->
    </div>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'

export default {
  name: 'PublishDropdown',
  components: { Button },
  props: {
    isBoxVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
      show: false,
      domainType: 1,
      domainProperties: {
        htmlContent: '',
        userId: '',
        projectId: '',
        domain: '',
        list_of_pages: [],
        custom: '',
        plan: '',
      },
    }
  },
  watch: {
    isBoxVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
}
</script>
<style scoped lang="scss">
.form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #9a9a9a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  position: relative;
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%230d6efd'/%3e%3c/svg%3e");
    border-color: #0d6efd;
  }
}
.dropdown-menu {
  width: 400px;
  background: #444444;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 0 64px 2px rgba(99, 122, 135, 0.08);

  & > * {
    padding: 16px;
  }

  .close__panel__text {
    color: #fff;
    padding-left: 5px;
  }

  .advanced-option {
    &_header {
      color: #a3a3a3;
      display: flex;
      justify-content: space-between;
    }
    &_body {
      .title {
        .badge {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .description {
        ul {
          list-style-type: disc;
          margin-left: 20px;
        }
        white-space: break-spaces;
      }
    }
  }
  .dropdown-body {
    .title {
      font-size: 14px;
      color: #a3a3a3;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      background: #333333;
      padding: 20px;
      border-radius: 8px;
      input {
        width: 20px;
        height: 20px;
      }
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
      }
      .description {
        font-size: 16px;
        margin-top: 5px;
        color: #9a9a9a;
        &.link {
          text-decoration: underline;
        }
      }
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: #fff;
}
</style>
