<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Delete Collaborator</h6>
      <label class="mt-2">
        Are you sure you want to delete this collaborator? This action cannot be
        undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteCollaborator(collaboratorID)"
      >
        <span class="">Delete collaborator</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeleteCollaboratorModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    collaboratorID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: false,
      error: '',
      projectID: 0,
    }
  },
  computed: {
    ...mapGetters(['getProject', 'getProjectStatus']),
  },
  methods: {
    ...mapActions(['handleDeleteProjectMember', 'handleGetProjectMembers']),
    deleteCollaborator(id) {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleDeleteProjectMember', id)
        .then(async response => {
          this.success = response.data.message
          await this.handleGetProjectMembers(this.projectID)

          this.redirect()
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
    redirect() {
      this.success = ''
      this.error = ''
      this.show = false
      this.loading = false
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleDeleteModal', value, this.collaboratorID)
        this.error = ''
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectID = this.getProject.id
        }
      },
    },
  },
}
</script>
