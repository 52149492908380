import * as types from '@/store/modules/command/types'

export default {
  [types.COMMANDS_STATUS](state, value) {
    state.commandsStatus = value
  },
  [types.COMMANDS](state, payload) {
    state.commands = payload
  },
  [types.UPDATE_COMMANDS_STATUS](state, value) {
    state.updateCommandsStatus = value
  },
  [types.UPDATE_COMMANDS](state, payload) {
    state.updateCommands = payload
  },
  [types.ADD_COMMANDS_STATUS](state, value) {
    state.addCommandsStatus = value
  },
  [types.ADD_COMMANDS](state, payload) {
    state.addCommands = payload
  },
}
