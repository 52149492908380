import * as types from './types'
import {
  getIntegrationConfig,
  integratePlatform,
  revokePlatform,
} from '@/services/integration'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetConfig({ commit }, platform) {
    commit(types.CONFIG_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getIntegrationConfig(platform)
        .then(response => {
          commit(types.CONFIG, response.data.data)
          commit(types.CONFIG_STATUS, 'COMPLETED')
          resolve(response.data.data)
        })
        .catch(error => {
          formatError(error.response)
          commit(types.CONFIG_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleIntegratePlatform({ commit }, params) {
    commit(types.PLATFORM_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      integratePlatform(params)
        .then(response => {
          commit(types.PLATFORM, response.data.data)
          commit(types.PLATFORM_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PLATFORM_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleRevokePlatform({ commit }, params) {
    commit(types.REVOKE_PLATFORM_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      revokePlatform(params)
        .then(response => {
          commit(types.REVOKE_PLATFORM, response.data.data)
          commit(types.REVOKE_PLATFORM_STATUS, 'COMPLETED')
          resolve(response.data)
        })
        .catch(error => {
          formatError(error)
          commit(types.REVOKE_PLATFORM_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
