import deploymentMutations from './mutations'
import deploymentActions from './actions'
export default {
  state: {
    deployments: [],
    deploymentsStatus: 'PENDING',
    deployment: {},
    deploymentStatus: 'PENDING',
    deleteDeploymentStatus: 'PENDING',
    deleteDeployment: {},
    deploymentConfigs: {},
    deploymentConfigsStatus: 'PENDING',
    updateDeploymentConfigs: {},
    updateDeploymentConfigsStatus: 'PENDING',
    addDeploymentStatus: 'PENDING',
    addDeployment: {},
    serverDeployments: [],
    serverDeploymentsStatus: 'PENDING',
  },
  actions: { ...deploymentActions },
  mutations: { ...deploymentMutations },
  getters: {
    getDeployments(state) {
      return state.deployments
    },
    getDeploymentsStatus(state) {
      return state.deploymentsStatus
    },
    getServerDeployments(state) {
      return state.serverDeployments
    },
    getServerDeploymentsStatus(state) {
      return state.serverDeploymentsStatus
    },
    getDeployment(state) {
      return state.deployments
    },
    getDeploymentStatus(state) {
      return state.deploymentsStatus
    },
    getAddDeployment(state) {
      return state.addDeployment
    },
    getAddDeploymentStatus(state) {
      return state.addDeploymentStatus
    },
    getDeleteDeployment(state) {
      return state.deleteDeployment
    },
    getDeleteDeploymentStatus(state) {
      return state.deleteDeploymentStatus
    },
    getDeploymentConfigs(state) {
      return state.deploymentConfigs
    },
    getDeploymentConfigsStatus(state) {
      return state.deploymentConfigsStatus
    },
    getUpdateDeploymentConfigs(state) {
      return state.updateDeploymentConfigs
    },
    getUpdateDeploymentConfigsStatus(state) {
      return state.updateDeploymentConfigsStatus
    },
  },
}
