import * as types from './types'
import {
  login,
  forgot_password,
  reset_password,
  register,
  verify,
  get_logged_in_user,
  update_user_profile,
  upload_user_avatar,
} from '@/services/authentication'
import { Authorization } from '@/utils/authorization'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleLogin({ commit }, { loginData }) {
    commit(types.USER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      login(loginData)
        .then(response => {
          const { access_token, business } = response.data.data
          Authorization.set.accessToken('token', access_token)
          commit(types.USER_INFO, response.data.data)
          commit(types.BUSINESS_INFO, business)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  handleRegister({ commit }, { registerData }) {
    return new Promise((resolve, reject) => {
      register(registerData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  handleVerify({ commit }, { token }) {
    return new Promise((resolve, reject) => {
      verify(token)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  handleForgotPassword({ commit }, { email }) {
    return new Promise((resolve, reject) => {
      forgot_password({ email: email })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  handleResetPassword({ commit }, resetPasswordData) {
    return new Promise((resolve, reject) => {
      reset_password(resetPasswordData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async handleGetLoggedInUser({ commit }) {
    commit(types.USER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      get_logged_in_user()
        .then(async response => {
          const { business } = response.data.data
          const { slug } = business
          commit(types.BUSINESS_INFO, business)
          commit(types.BUSINESS_ID, business.subscription.business_id)
          commit(types.USER_INFO, response.data.data)
          commit(types.USER_SLUG, slug)

          //Reassign the workspace slug if it is empty
          let currentSlug = localStorage.getItem('selected_workspace_slug')

          //Whenever a page is loaded, this function runs and the slug is reassign,
          //so I made this check to avoid that.
          if (currentSlug === undefined) {
            await localStorage.removeItem('selected_workspace_slug')
            //Set the current slug in the localStorage - this will set the default slug on login
            await localStorage.setItem('selected_workspace_slug', slug)
          }

          commit(types.USER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          reject(error)
          commit(types.USER_STATUS, 'PENDING')
        })
    })
  },

  async handleUpdateUserProfile({ commit }, params) {
    commit(types.UPDATE_PROFILE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      update_user_profile(params)
        .then(response => {
          commit(types.UPDATE_PROFILE, response.data.data)
          commit(types.UPDATE_PROFILE_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          reject(error.response)
          commit(types.UPDATE_PROFILE_STATUS, 'PENDING')
        })
    })
  },

  async handleUploadAvatar({ commit }, avatar) {
    commit(types.UPLOAD_AVATAR_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      upload_user_avatar(avatar)
        .then(response => {
          commit(types.UPLOAD_AVATAR, response.data)
          commit(types.UPLOAD_AVATAR_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          reject(error.response)
          commit(types.UPLOAD_AVATAR_STATUS, 'PENDING')
        })
    })
  },
}
