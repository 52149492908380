<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="alert alert-success" v-if="success">
        {{ success }}
      </div>

      <h6 class="mb-3">Create Deployment</h6>

      <div class="form-group">
        <label for="project_category">Select a server</label>
        <select
          name="select_server"
          id="select_server"
          class="form-control"
          v-model="deployment.server_id"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option
            v-for="falcon in falcons"
            v-bind:key="falcon.id"
            v-bind:value="falcon.id"
          >
            {{ falcon.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="project_category">Select a repository</label>
        <select
          name="select_repository"
          id="select_repository"
          class="form-control"
          v-model="deployment.repo_id"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option
            v-for="repos in getProjectRepos"
            v-bind:key="repos.id"
            v-bind:value="repos.id"
          >
            {{ repos.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="project_name">Branch</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          placeholder="The preferred repo branch to pull update from"
          v-model="deployment.branch"
        />
      </div>

      <div class="form-group">
        <p>
          <label for="project_name" style="display: block; margin-bottom: 10px"
            >Would you prefer auto-deployment?</label
          >
        </p>
        <label for="project_name">Immediate deployment</label>
        <input
          type="radio"
          class="form-check-input ml-3"
          id="auto_deploy_radio"
          placeholder=""
          :value="0"
          ref="checkAutoDeploy"
          v-model="deployment.auto_deploy"
          checked
        />
      </div>

      <div class="form-group">
        <label for="project_name">Deploy path</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          placeholder="Deploy path e.g: /var/www/html/app-path"
          v-model="deployment.deploy_path"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click.prevent="createDeployment"
        >
          <span class="">Create Deployment</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'CreateDeploymentModal',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    getProjectRepos: {
      type: Array,
    },
    getFalcons: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: '',
      success: '',
      deployment: {},
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.deployment = {}
        this.handleGetAllDeployments(this.$route.params.uid)
        this.success = false
      },
    },
  },
  computed: {
    falcons: falcs => {
      falcs = falcs.getFalcons
      if (falcs !== null) {
        return falcs.filter(falcon => {
          return falcon.active === 1
        })
      }
    },
  },
  methods: {
    ...mapActions(['handleGetAllDeployments', 'handleAddDeployment']),

    async createDeployment() {
      this.loading = true
      this.error = ''
      this.success = ''

      //create the schedule time
      let now = new Date(Date.now())

      let schedule = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

      // if (!this.deployment.deploy_path) {
      //   this.deployment.deploy_path = 'undefined'
      // }

      //Include the schedule variable
      const deploymentData = { ...this.deployment, schedule }

      await this.handleAddDeployment(deploymentData)
        .then(async response => {
          this.success = response.data.message

          await this.handleGetAllDeployments(this.$route.params.uid)

          //Reload the Deployments
          setTimeout(this.removeBackground, 1000)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },

    removeError() {
      this.error = ''
    },

    removeBackground() {
      //remove the background fade
      this.show = false
      this.loading = false
    },

    allFilled() {
      const { branch, repo_id, server_id, deploy_path } = this.deployment
      return (
        branch !== undefined &&
        branch !== '' &&
        repo_id !== undefined &&
        server_id !== undefined &&
        deploy_path !== undefined &&
        deploy_path !== ''
      )
    },
  },
  updated() {
    this.$refs['checkAutoDeploy'].checked = true
    this.deployment.auto_deploy = 0
    this.deployment.project_id = this.projectId
  },
}
</script>
