<template>
  <div></div>
</template>
<script>
import { logoutOnAll } from '@/utils/logout'
export default {
  name: 'Logout',
  mounted() {
    logoutOnAll()
  },
}
</script>
