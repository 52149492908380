import * as types from '@/store/modules/workspace/types'

export default {
  [types.WORKSPACES_STATUS](state, value) {
    state.workspacesStatus = value
  },
  [types.WORKSPACES](state, payload) {
    let workspaces = []

    for (let index of payload) {
      if (index !== null || index !== undefined) {
        workspaces.push(index)
      }
    }

    state.workspaces = workspaces
  },
  [types.WORKSPACE_STATUS](state, payload) {
    state.workspaceStatus = payload
  },
  [types.SELECTED_WORKSPACE](state, payload) {
    state.selectedWorkspace = payload
  },
}
