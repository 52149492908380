import websiteMutations from './mutations'
import websiteActions from './actions'

export default {
  state: {
    websites: [],
    websitesStatus: 'PENDING',
    deleteWebsite: [],
    deleteWebsitesStatus: 'PENDING',
    projectWebsites: [],
    projectWebsitesStatus: 'PENDING',
    websiteUpdateStatus: 'PENDING',
    websiteUpdate: {},
  },
  actions: { ...websiteActions },
  mutations: { ...websiteMutations },
  getters: {
    getWebsites(state) {
      return state.websites
    },
    getWebsitesStatus(state) {
      return state.websitesStatus
    },
    deleteWebsite(state) {
      return state.deleteWebsite
    },
    deleteWebsiteStatus(state) {
      return state.deleteWebsiteStatus
    },
    getProjectWebsites(state) {
      return state.projectWebsites
    },
    getProjectWebsitesStatus(state) {
      return state.projectWebsitesStatus
    },
    getWebsiteUpdate(state) {
      return state.websiteUpdate
    },
    getWebsiteUpdateStatus(state) {
      return state.websiteUpdateStatus
    },
  },
}
