import { Authorization } from '@/utils/authorization'
import store from '../store'

export default (to, from, next) => {
  if (Authorization.check.isAuthenticated()) {
    if (store.getters.getUserInfoStatus !== 'COMPLETED') {
      store.dispatch('handleGetLoggedInUser')
    }
    next()
  } else {
    //Clear the localStorage
    localStorage.clear()
    // Store existing location in local_storage
    localStorage.setItem('FROM', to.fullPath)
    next({ path: '/login' })
  }
}
