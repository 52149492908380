<template>
  <!-- Fybrr Expert -->
  <section class="pages--fybrr-expert">
    <div class="container pages--expert-center">
      <div class="row">
        <div class="col-md-7">
          <h2 class="title fs_lh-64_64">Fybrr Experts to Help</h2>
          <p class="description fs_lh-24_31">
            Build, host and ship websites and applications quickly with Fybrr.
            Our fully-managed platform gives you the simplest path to delivering
            apps quickly without stress.
          </p>
        </div>
      </div>
    </div>
    <div class="pages--expert-container mt-5">
      <div class="container pages--expert-center">
        <div class="row">
          <div class="col-md-10">
            <h2 class="fs_lh-64_61">
              Work with our developers and designers across to get your project
              started
            </h2>
            <p class="my-5 fs_lh-24_31">
              Add your repository from github, gitlab and bitbucket. A webhook
              is added automatically to your repository
            </p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="getStarted"
              btnClass="mr-3 mt-3"
              @click="''"
            >
              <span class="px-5">Get started</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from '@/components/ui/Button'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ExpertComponent',
  components: { Button },
})
</script>
