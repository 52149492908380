export const FALCONS = 'FALCONS'
export const FALCONS_STATUS = 'FALCONS_STATUS'
export const FALCONS_LOGS = 'FALCONS_LOGS'
export const FALCONS_LOGS_STATUS = 'FALCONS_LOGS_STATUS'
export const PILOT_TEMPLATES = 'PILOT_TEMPLATES'
export const PILOT_TEMPLATES_STATUS = 'PILOT_TEMPLATES_STATUS'
export const PILOT_DETAILS = 'PILOT_DETAILS'
export const PILOT_DETAILS_STATUS = 'PILOT_DETAILS_STATUS'
export const DELETE_FALCON = 'DELETE_FALCON'
export const DELETE_FALCON_STATUS = 'DELETE_FALCON_STATUS'
export const PROVISION_FALCON = 'PROVISION_FALCON'
export const PROVISION_FALCON_STATUS = 'PROVISION_FALCON_STATUS'
export const REBOOT_FALCON = 'REBOOT_FALCON'
export const REBOOT_FALCON_STATUS = 'REBOOT_FALCON_STATUS'
export const POWER_ON_FALCON = 'POWER_ON_FALCON'
export const POWER_ON_FALCON_STATUS = 'POWER_ON_FALCON_STATUS'
export const POWER_OFF_FALCON = 'POWER_OFF_FALCON'
export const POWER_OFF_FALCON_STATUS = 'POWER_OFF_FALCON_STATUS'
export const ADD_ACCESS = 'ADD_ACCESS'
export const ADD_ACCESS_STATUS = 'ADD_ACCESS_STATUS'
export const DELETE_ACCESS = 'DELETE_ACCESS'
export const DELETE_ACCESS_STATUS = 'DELETE_ACCESS_STATUS'
export const GET_ACCESS = 'GET_ACCESS'
export const GET_ACCESS_STATUS = 'GET_ACCESS_STATUS'
export const ADD_FALCON = 'ADD_FALCON'
export const ADD_FALCON_STATUS = 'ADD_FALCON_STATUS'
export const PROJECT_SSH = 'PROJECT_SSH'
export const PROJECT_SSH_STATUS = 'PROJECT_SSH_STATUS'
