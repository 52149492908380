import authMutations from './mutations'
import authActions from './actions'

export default {
  state: {
    presentBusinessInfo: {},
    userInfo: {},
    userSlug: '',
    userInfoStatus: 'PENDING',
    businessId: '',
    updateProfileStatus: 'PENDING',
    updateProfile: {},
    uploadAvatarStatus: 'PENDING',
    uploadAvatar: {},
  },
  actions: { ...authActions },
  mutations: { ...authMutations },
  getters: {
    getUserInfo(state) {
      return state.userInfo
    },
    getBusinessInfo(state) {
      return state.presentBusinessInfo
    },
    getBusinessSlug(state) {
      return state.userSlug
    },
    getUserInfoStatus(state) {
      return state.userInfoStatus
    },
    getBusinessId(state) {
      return state.businessId.toString()
    },
    getUpdateProfileStatus(state) {
      return state.updateProfileStatus
    },
    getUploadAvatarStatus(state) {
      return state.uploadAvatarStatus
    },
  },
}
