<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        class="mb-2"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="alert alert-success mb-2" role="alert" v-if="success">
        {{ success }}
      </div>

      <h6>Assign Member</h6>
      <p class="description">You can change this later</p>

      <div class="form-group">
        <label for="assignee">Assignee</label>
        <select
          name="assignee"
          id="assignee"
          class="form-control"
          v-model="unfilteredAssignees"
          multiple
        >
          <option :value="''" disabled selected>
            Hold down the control (ctrl) button to select multiple options
          </option>
          <option
            v-for="member in teamMembers"
            v-bind:key="member.profile.id"
            v-bind:value="member.profile.id"
          >
            {{ member.profile.first }} {{ member.profile.last }} ({{
              member.profile.email
            }})
          </option>
        </select>
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="createTask"
          btnClass="px-5"
          @click="assignMember"
        >
          <!-- :inactive="!validate" -->
          <span class="">Assign Members</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import Feedback from '@/components/ui/Feedback'
import { mapActions } from 'vuex'

export default {
  name: 'AssignMember',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    teamMembers: {
      type: Array,
    },
    currentTask: {
      type: Object,
    },
    projectID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: '',
      success: '',
      unfilteredAssignees: [],
      task: {},
      taskID: null,
    }
  },
  methods: {
    ...mapActions([
      'handleGetTasks',
      'handleAssignMember',
      'handleGetAllTeamMembers',
    ]),
    removeError() {
      this.error = ''
    },
    async assignMember() {
      this.loading = true
      this.error = ''
      this.success = ''

      //Replace the assignees with enum
      const params = {
        task_id: this.taskID,
        assignees: this.unfilteredAssignees.join(','),
      }

      //dispatch function to create task
      await this.handleAssignMember(params)
        .then(async response => {
          this.success = response.data.message
          //reload the tasks list
          await this.handleGetTasks(this.projectID)
          // await this.handleGetAllTeamMembers()
          this.redirect()
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
    redirect() {
      setTimeout(() => {
        //disable loading button
        this.loading = false
        this.error = ''
        this.success = ''
        //disable the modal
        this.show = false
        //clear the fields
        this.task = {}
        this.unfilteredAssignees = []
      }, 500)
    },
    updated() {
      this.handleGetTasks(this.projectID)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleAssignModal', {}, value)

        this.taskID = this.currentTask.id
      },
    },
  },
}
</script>
