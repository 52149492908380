import falconMutations from './mutations'
import falconActions from './actions'
export default {
  state: {
    falcons: [],
    falconsStatus: 'PENDING',
    falconsLogs: [],
    falconsLogsStatus: 'PENDING',
    pilotDetailsStatus: 'PENDING',
    pilotDetails: [],
    pilotTemplatesStatus: 'PENDING',
    pilotTemplates: [],
    deleteFalconStatus: 'PENDING',
    deleteFalcon: {},
    provisionFalconStatus: 'PENDING',
    provisionFalcon: {},
    rebootFalconStatus: 'PENDING',
    rebootFalcon: {},
    powerOnFalconStatus: 'PENDING',
    powerOnFalcon: {},
    powerOffFalconStatus: 'PENDING',
    powerOffFalcon: {},
    addAccessStatus: 'PENDING',
    addAccess: {},
    deleteAccessStatus: 'PENDING',
    deleteAccess: {},
    getAccessStatus: 'PENDING',
    getAccess: [],
    projectSSH: {},
    projectSSHStatus: 'PENDING',
  },
  actions: { ...falconActions },
  mutations: { ...falconMutations },
  getters: {
    getFalcons(state) {
      return state.falcons
    },
    getFalconsStatus(state) {
      return state.falconsStatus
    },
    getFalconsLogs(state) {
      return state.falconsLogs
    },
    getFalconsLogsStatus(state) {
      return state.falconsLogsStatus
    },
    getPilotDetailsStatus(state) {
      return state.pilotDetailsStatus
    },
    getPilotDetails(state) {
      return state.pilotDetails
    },
    getPilotTemplatesStatus(state) {
      return state.pilotTemplatesStatus
    },
    getPilotTemplates(state) {
      return state.pilotTemplates
    },
    getDeleteFalconStatus(state) {
      return state.deleteFalconStatus
    },
    getProvisionFalconStatus(state) {
      return state.provisionFalconStatus
    },
    getRebootFalconStatus(state) {
      return state.rebootFalconStatus
    },
    getPowerOnFalconStatus(state) {
      return state.powerOnFalconStatus
    },
    getPowerOffFalconStatus(state) {
      return state.powerOffFalconStatus
    },
    getEntriesAccess(state) {
      return state.getAccess
    },
    getEntriesAccessStatus(state) {
      return state.getAccessStatus
    },
    getDeleteAccessStatus(state) {
      return state.deleteAccessStatus
    },
    getAddAccessStatus(state) {
      return state.addAccessStatus
    },
    getProjectSSHStatus(state) {
      return state.projectSSHStatus
    },
    getProjectSSH(state) {
      return state.projectSSH
    },
  },
}
