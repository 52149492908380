<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <div class="alert alert-danger py-2" role="alert" v-if="error">
        {{ message }}
      </div>
      <h6>
        Remove <i>{{ memberName }}</i> from this project
      </h6>
      <label>
        Are you sure you want to remove this member from this project? This
        action cannot be undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteMember(memberId)"
      >
        <!-- :inactive="!validate" -->
        <span class="">Delete member</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeleteMemberModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    memberId: {
      type: Number,
    },
    memberName: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      memberStatus: 'ENTRY',
      message: '',
      error: false,
      loading: false,
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.memberStatus = 'ENTRY'
        this.$emit('toggleModal', value)
      },
    },
    getMemberStatus: {
      handler(value) {
        this.memberStatus = value
      },
    },
  },
  methods: {
    ...mapActions(['handleGetOneProject']),
    async deleteMember(id) {
      this.loading = true
      this.error = false
      this.message = ''

      this.$store
        .dispatch('handleDeleteProjectMember', id)
        .then(async () => {
          //This will reload the list of members
          let reloadProject = await this.handleGetOneProject(
            this.$route.params.uid
          )

          if (reloadProject.status === 200) {
            this.loading = false
            this.show = false
          }
        })
        .catch(error => {
          this.error = true
          this.message = error.response.data.message
          this.loading = false
        })
    },
  },
  computed: {
    ...mapGetters({
      getMemberStatus: 'getMemberStatus',
    }),
  },
}
</script>
