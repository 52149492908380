import * as types from './types'
import {
  getAllUsersWebsites,
  deleteWebsite,
  getAllProjectWebsites,
  updateWebsite,
} from '@/services/website'
import { formatError } from '@/store/helpers/error-helper'

export default {
  async handleGetUsersWebsites({ commit }, userSlug) {
    commit(types.WEBSITES_STATUS, 'LOADING')
    getAllUsersWebsites(userSlug)
      .then(response => {
        commit(types.WEBSITES, response.data.data)
        commit(types.WEBSITES_STATUS, 'COMPLETED')
      })
      .catch(error => {
        formatError(error)
        commit(types.WEBSITES, 'PENDING')
      })
  },

  async handleUpdateUserWebsite({ commit }, updateData) {
    commit(types.WEBSITE_UPDATE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      updateWebsite(updateData)
        .then(response => {
          commit(types.WEBSITE_UPDATE, response.data.data)
          commit(types.WEBSITE_UPDATE_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.WEBSITE_UPDATE_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  async handleGetProjectWebsites({ commit }, projectSlug) {
    commit(types.PROJECT_WEBSITES_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllProjectWebsites(projectSlug)
        .then(response => {
          commit(types.PROJECT_WEBSITES, response.data.data)
          commit(types.PROJECT_WEBSITES_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROJECT_WEBSITES, 'PENDING')
          reject(error)
        })
    })
  },

  async handleDeleteWebsite({ commit }, websiteId) {
    commit(types.DELETE_WEBSITE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteWebsite(websiteId)
        .then(response => {
          commit(types.DELETE_WEBSITE, response.data.data)
          commit(types.DELETE_WEBSITE_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_WEBSITE_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
