<template>
  <div class="page__body">
    <div class="page__head page__back">
      <div>
        <h6>Profile</h6>
        <p>Manage your profile settings</p>
      </div>
    </div>
    <!-- The alert messages -->
    <div class="mt-2">
      <div class="alert alert-success" role="alert" v-if="success">
        {{ message }}
      </div>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ message }}
      </div>
    </div>
    <div class="profile row">
      <div class="col-lg-4">
        <div class="profile-card">
          <div class="profile-img">
            <img
              src="/assets/img/profile/profile1.svg"
              v-if="userDetails.avatar === null"
              alt=""
            />
            <img
              :src="userDetails.avatar"
              v-if="userDetails.avatar"
              alt="avatar"
            />
            <input
              ref="uploadAvatarInput"
              style="display: none"
              type="file"
              accept="image/*"
              @change="onFileChange"
              required
            />
            <button class="icon" @click="this.$refs.uploadAvatarInput.click()">
              📷
            </button>
          </div>
          <!-- button to upload avatar -->
          <div class="my-2" v-if="readyToUpload">
            <Button
              :loading="loading"
              :inactive="false"
              :variant="'outline-primary'"
              :size="'md'"
              type="submit"
              name="loginButton"
              btnClass="px-5"
              @click="uploadAvatar"
            >
              <!-- :inactive="!validate" -->
              <span class="">Upload Avatar</span></Button
            >
          </div>
          <div class="profile-details">
            <div class="profile-details__card">
              <h6>{{ userDetails.first }} {{ userDetails.last }}</h6>
              <p>Full name</p>
            </div>
            <div class="profile-details__card">
              <h6>{{ userDetails.phone }}</h6>
              <p>Phone number</p>
            </div>
            <div class="profile-details__card">
              <h6>{{ userDetails.email }}</h6>
              <p>Email address</p>
            </div>
            <div>
              <Button
                :loading="false"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="loginButton"
                btnClass="px-5"
                @click="activeStage = 'account_details'"
              >
                <!-- :inactive="!validate" -->
                <span class="">Edit account details</span></Button
              >
            </div>
            <div class="my-3">
              <Button
                :loading="false"
                :inactive="false"
                :variant="'outline-primary'"
                :size="'md'"
                type="submit"
                name="loginButton"
                btnClass="px-5"
                @click="activeStage = 'update_password'"
              >
                <!-- :inactive="!validate" -->
                <span class="">Reset Password</span></Button
              >
            </div>
            <!-- <div>
              <Button
                :loading="false"
                :inactive="false"
                :variant="'outline-danger'"
                :size="'md'"
                type="submit"
                name="loginButton"
                btnClass="px-5"
                @click="activeStage = 'delete_account'"
              >
                
                <span class="">Delete Account</span></Button
              >
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="profile-card2">
          <UpdateAccountDetails
            :userDetailsProp="userDetails"
            v-if="activeStage === 'account_details'"
          ></UpdateAccountDetails>
          <UpdatePassword
            v-if="activeStage === 'update_password'"
          ></UpdatePassword>
          <DeleteAccount
            v-if="activeStage === 'delete_account'"
          ></DeleteAccount>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import UpdateAccountDetails from '@/components/dashboard/profile/UpdateAccountDetails'
import UpdatePassword from '@/components/dashboard/profile/UpdatePassword'
import DeleteAccount from '@/components/dashboard/profile/DeleteAccount'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DashboardProfile',
  components: { Button, UpdateAccountDetails, UpdatePassword, DeleteAccount },
  data() {
    return {
      activeStage: 'account_details',
      success: false,
      error: false,
      message: '',
      loading: false,
      readyToUpload: false,
      userDetails: {
        first: '',
        last: '',
        phone: '',
        country: '',
        state: '',
        city: '',
        avatar: null,
      },
    }
  },
  methods: {
    ...mapActions(['handleGetLoggedInUser', 'handleUploadAvatar']),
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      //Size check - if greater than 5mb
      if (files[0].size > 5120000) {
        this.toggleShowResult({
          errorType: 'error',
          message: 'You can only upload images with max of 5MB',
        })
        //Remove the uploaded file
        e.target.value = ''
      } else {
        this.userDetails.avatar = await this.toBase64(files[0])
        this.readyToUpload = true
      }

      // console.log(this.userDetails.avatar)
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
    async uploadAvatar() {
      this.loading = true
      this.$store
        .dispatch('handleUploadAvatar', this.userDetails.avatar)
        .then(response => {
          this.toggleShowResult({
            errorType: 'success',
            message: response.data.message,
          })

          this.readyToUpload = false
          console.log(response)
        })
        .catch(error => {
          this.toggleShowResult({
            errorType: 'error',
            message: error.data.message,
          })
        })
    },
  },
  computed: {
    ...mapGetters(['getUserInfoStatus', 'getUserInfo']),
  },
  async mounted() {
    if (this.getUserInfoStatus === 'COMPLETED') {
      this.userDetails = this.getUserInfo
    } else {
      const userInfo = await this.handleGetLoggedInUser()

      if (userInfo.status === 200) {
        this.userDetails = userInfo.data.data
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  margin-top: 20px;
  & > * {
    padding: 20px;
    height: -webkit-fill-available;
    .profile-card,
    .profile-card2 {
      border: 1px solid #e2e2e5;
      height: 100%;
      border-radius: 8px;
      padding: 30px;
    }
  }
}
.profile-img {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 11%;
  .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.profile-details {
  margin-top: 40px;
}
.profile-details__card {
  margin-bottom: 10px;
  h6 {
    color: #000000;
    line-height: 1;
  }
  p {
    color: #565f62;
    font-size: 15px;
  }
}
</style>
