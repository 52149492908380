<template>
  <div class="card rounded-default">
    <div class="card-body">
      <div class="image-size">
        <img
          :src="`/assets/img/integration/${projectRepoDetails.platform}.png`"
          :alt="projectRepoDetails?.name"
        />
      </div>
      <h6 class="w-75">{{ projectRepoDetails.name }}</h6>
      <div class="d-flex mt-3">
        <span
          v-if="projectRepoDetails?.repo_url?.length > 15"
          class="badge badge--blue"
          >{{ projectRepoDetails?.repo_url?.slice(0, 40) }}...</span
        >
        <span v-else class="badge badge--blue">{{
          projectRepoDetails?.repo_url
        }}</span>
        <span v-if="!projectRepoDetails?.repo_url" class="badge badge--blue"
          >NO URL</span
        >
      </div>
      <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Added at</p>
          <h6>{{ moment(projectRepoDetails?.created_at).format('LLL') }}</h6>
        </div>
      </div>
      <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Last edit at</p>
          <h6>{{ moment(projectRepoDetails?.updated_at).format('LLL') }}</h6>
        </div>
      </div>
      <div class="project-card-button mt-3 d-flex align-items-center">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          data-bs-toggle="modal"
          :data-bs-target="`#repo-details-modal-${projectRepoDetails?.id}`"
          @click="toggleViewRepoModal(true)"
        >
          <span class="">VIEW</span></Button
        >
        <Button
          v-if="showAdminButton"
          :loading="false"
          :inactive="false"
          :variant="'outline-danger'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mt-1"
          @click="toggleModal(true)"
        >
          <svg
            class="icon cursor-pointer"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <use xlink:href="/sprite.svg#icon-d-trash" />
          </svg>
          <span class="">DELETE</span></Button
        >
        <DeleteRepoModal
          :isVisible="isVisible"
          @toggleModal="toggleModal"
          :projectUId="this.$route.params.uid"
          :repoName="projectRepoDetails?.name"
          :repoId="projectRepoDetails?.project_id"
        ></DeleteRepoModal>
        <ViewRepoModal
          :isVisible="repoModalIsVisible"
          @toggleViewRepoModal="toggleViewRepoModal"
          :repoObject="projectRepoDetails"
        ></ViewRepoModal>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import DeleteRepoModal from '@/components/dashboard/project/DeleteRepoModal'
import ViewRepoModal from '@/components/dashboard/project/ViewRepoModal'
import moment from 'moment'

export default {
  name: 'SingleIntegrationCard',
  components: { Button, DeleteRepoModal, ViewRepoModal },
  props: {
    projectRepoDetails: {
      type: Object,
    },
    showAdminButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      moment: moment,
      isVisible: false,
      repoModalIsVisible: false,
    }
  },
  methods: {
    toggleModal(value) {
      this.isVisible = value
    },
    toggleViewRepoModal(value) {
      this.repoModalIsVisible = false

      this.repoModalIsVisible = value
    },
  },
}
</script>
<style>
.image-size {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
</style>
