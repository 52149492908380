<template>
  <div class="page">
    <OverlayLoader v-if="loading"></OverlayLoader>
    <Sidebar />
    <main class="main">
      <div class="page__content">
        <Header />
        <div class="page__container">
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Header from '@/components/dashboard/Navigation.vue'
import Sidebar from '@/components/dashboard/Sidebar.vue'
import OverlayLoader from '@/components/ui/Loaders/overlay'
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  components: {
    Header,
    Sidebar,
    OverlayLoader,
  },
  mounted() {
    if (this.getWorkspacesStatus !== 'COMPLETED') {
      this.$store.dispatch('handleGetUsersWorkspaces')
    }
  },
  computed: {
    loading() {
      return (
        this.getUserInfoStatus === 'LOADING' ||
        this.getWorkspacesStatus === 'LOADING'
      )
    },
    ...mapGetters(['getUserInfoStatus', 'getWorkspacesStatus']),
  },
}
</script>
