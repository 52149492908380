<template>
  <div class="page__body">
    <div class="page__head page__back">
      <div>
        <h6>Hello Boss</h6>
        <p>Manage your integrations</p>
      </div>
    </div>
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <!-- The alert messages -->
      <div>
        <div class="alert alert-success" role="alert" v-if="success">
          {{ message }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ message }}
        </div>
      </div>
      <div class="row mt-3">
        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(integration, index) in integrations"
          :key="index"
        >
          <SingleIntegrationCard
            :integrationDetails="integration"
            :businessSlug="businessSlug"
            @toggleShowResult="toggleShowResult"
          ></SingleIntegrationCard>
        </div>
      </div>
    </main>
  </div>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import SingleIntegrationCard from '@/components/dashboard/integrations/SingleIntegrationsCard'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Integrations',
  components: { SingleIntegrationCard, OverlayLoading },
  data() {
    return {
      integrations: [
        {
          created_at: '2021-07-27T23:22:10.000000Z',
          description: 'A talent/mentors directory.',
          id: 80,
          badges: ['https://zojiepuppies.com'],
          name: 'Github',
          image: '/assets/img/integration/github.png',
          u_id: 'BnclCP4X9FMZR82tF6uyu1SA2hVDZrobs8oecmCbuptRGExLerCZEa4tAPoj9E0n12Mi8iFlZi4TzNzwHPn3TtIRZfQMyLYo7wm7hWuajtYjYkYj7eOlSfQ5112210',
          updated_at: '2021-07-27T23:22:10.000000Z',
          isActive: false,
        },
        {
          created_at: '2021-06-11T19:58:06.000000Z',
          description:
            'Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh',
          id: 66,
          badges: ['https://zojiepuppies.com'],
          name: 'Gitlab',
          status: 'inactive',
          image: '/assets/img/integration/gitlab.png',
          u_id: 'SNKTfjF2sFMu1f5fyd7Jl9Z6dfgWrJhG3j4EECZuFWX5mOE12zzCHntREvM6Ude7ttZr2kCWsxMbGdoucmHpHAViaWxo85LymO0krzqonpPu7VaXZLq0Iu7w075806',
          updated_at: '2021-06-11T19:58:06.000000Z',
          isActive: false,
        },
        {
          created_at: '2021-06-11T19:58:06.000000Z',
          description:
            'Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh Id excepturi repreh',
          id: 66,
          badges: ['https://zojiepuppies.com'],
          name: 'Bitbucket',
          status: 'inactive',
          image: '/assets/img/integration/bitbucket.png',
          u_id: 'SNKTfjF2sFMu1f5fyd7Jl9Z6dfgWrJhG3j4EECZuFWX5mOE12zzCHntREvM6Ude7ttZr2kCWsxMbGdoucmHpHAViaWxo85LymO0krzqonpPu7VaXZLq0Iu7w075806',
          updated_at: '2021-06-11T19:58:06.000000Z',
          isActive: false,
        },
      ],
      bitbucketActive: false,
      gitlabActive: false,
      loaderIsVisible: true,
      githubActive: false,
      businessID: '',
      businessSlug: '',
      success: false,
      error: false,
      message: '',
    }
  },
  computed: {
    ...mapGetters([
      'getUserInfoStatus',
      'getBusinessInfo',
      'getSelectedWorkspace',
    ]),
  },
  watch: {},
  methods: {
    ...mapActions([
      'handleGetLoggedInUser',
      'handleGetUserIntegrations',
      'handleIntegratePlatform',
      'handleGetUsersWorkspaces',
    ]),
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message

        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message

        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
    setAvailableIntegrationBtn(integrations) {
      let availableIntegrations = integrations.data.data

      for (let i = 0; i < availableIntegrations.length; i++) {
        if (availableIntegrations[i].platform === 'github') {
          this.integrations[0].isActive = true
        } else if (availableIntegrations[i].platform === 'gitlab') {
          this.integrations[1].isActive = true
        } else if (availableIntegrations[i].platform === 'bitbucket') {
          this.integrations[2].isActive = true
        }
      }

      this.loaderIsVisible = false
    },
  },
  async mounted() {
    //Global user slug
    let userSlug = ''

    let result = await this.handleGetUsersWorkspaces()
    if (result.status === 200) {
      this.businessID = this.getSelectedWorkspace.id
      this.businessSlug = this.getSelectedWorkspace.slug
      userSlug = this.businessSlug

      //Get Available Integrations
      let integrationsResult = await this.handleGetUserIntegrations(
        this.businessSlug
      )

      //set available integrations buttons
      this.setAvailableIntegrationBtn(integrationsResult)
    }

    //Integrate the platform on page load with the CODE params
    //1. Get the params from the url
    let code = ''
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const platform = await localStorage.getItem('platform')
    code = urlParams.get('code')

    let url = location.href
    if (url.indexOf('code') !== -1) {
      if (code !== null && platform !== undefined) {
        //2. Integrate the platform
        const params = {
          code,
          business_id: this.businessID,
          platform,
        }

        const integrateResult = await this.handleIntegratePlatform(params)

        if (integrateResult.status === 200) {
          //Show the toast message
          this.toggleShowResult({
            errorType: 'success',
            message: integrateResult.data.message,
          })

          //Reload the Integrations Buttons
          let integrationsResult = await this.handleGetUserIntegrations(
            this.businessSlug
          )

          //set available integrations buttons
          this.setAvailableIntegrationBtn(integrationsResult)
        }

        //Remove the localStorage item - no longer needed
        await localStorage.removeItem('platform')
      }
    } else {
      //Get Available Integrations
      let integrationsResult = await this.handleGetUserIntegrations(userSlug)

      //set available integrations buttons
      this.setAvailableIntegrationBtn(integrationsResult)
    }
  },
}
</script>
