<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="repository" />
      <ul class="nav nav-pills mt-4">
        <li class="nav-item">
          <button
            class="nav-link btn-border-remove"
            :class="isShowingRepo ? 'active' : 'not-active'"
            @click="changeNavTab('repositories')"
          >
            Repositories
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link btn-border-remove"
            :class="isShowingRepo ? 'not-active' : 'active'"
            @click="changeNavTab('commits')"
          >
            Commits
          </button>
        </li>
      </ul>
      <div class="row mt-3" v-if="isShowingRepo">
        <div class="col-md-4">
          <div class="card project-create-card rounded-default card-dashed">
            <h6>Add new repository</h6>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="addRepository"
              btnClass="mt-3"
              @click="toggleModal(true)"
            >
              <span class="">add new repo</span></Button
            >
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(repository, index) in paginateData"
          :key="index"
        >
          <SingleIntegrationCard
            :projectRepoDetails="repository"
            :showAdminButton="showAdminButton"
          ></SingleIntegrationCard>
        </div>
      </div>

      <div class="row mt-3 commit-table" v-if="!isShowingRepo">
        <div class="" v-for="(commit, index) in commits" :key="index">
          <p class="commit-text">{{ commit }}</p>
        </div>
        <p class="commit-text" v-if="this.commits.length === 0">
          No repository activity for this project
        </p>
      </div>
      <nav
        class="page-dashboard__pagination p-3 mt-2"
        v-if="isShowingRepo === 'repositories'"
      >
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
  </div>
  <Modal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    @showCreateModal="showCreateModal"
    :Integrations="integrations"
  ></Modal>
  <CreateRepoModal
    v-if="createModalIsVibible == true"
    @showCreateModal="showCreateModal"
    :userIntegrations="integrations"
    :projectID="projectID"
    :currentUserSlug="userSlug"
  ></CreateRepoModal>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import Button from '@/components/ui/Button'
import SingleIntegrationCard from '@/components/dashboard/project/SingleIntegrationCard'
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import Modal from '@/components/dashboard/project/SelectIntegrationModal'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'
import CreateRepoModal from '@/components/dashboard/project/CreateRepoModal'
import { Paginator } from 'array-paginator'

export default {
  name: 'Repositories',
  components: {
    ProjectNav,
    SingleIntegrationCard,
    Button,
    Modal,
    CreateRepoModal,
    OverlayLoading,
  },
  data() {
    return {
      integrations: [],
      projectRepos: [],
      projectID: 0,
      userSlug: '',
      commits: [],
      modalIsVisible: false,
      loaderIsVisible: true,
      createModalIsVibible: false,
      isShowingRepo: true,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  methods: {
    ...mapActions(['handleGetProjectRepos']),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },

    showCreateModal(value) {
      this.createModalIsVibible = value
    },

    paginate(page) {
      this.setPageRepos(page)
    },
    changeNavTab(value) {
      if (value === 'repositories') {
        this.isShowingRepo = true
      } else if (value === 'commits') {
        this.isShowingRepo = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'getProjectRepos',
      'getProjectReposStatus',
      'getAllCommitsStatus',
      'getAllCommits',
      'getUserIntegrations',
      'getUserIntegrationsStatus',
      'getProject',
      'getProjectStatus',
      'getUserInfoStatus',
      'getProject',
      'getBusinessInfo',
      'getSelectedWorkspace',
      'getWorkspacesStatus',
    ]),
  },
  watch: {
    getProject: {
      //Send the project id to the create Repo Modal
      handler(value) {
        this.projectID = value.id
      },
    },
    getWorkspacesStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userSlug = this.getSelectedWorkspace.slug
        }
      },
    },
    getProjectRepos: {
      handler(value) {
        this.projectRepos = value.data
        console.log(this.projectRepos)
        //Paginate the data manually
        this.paginator = new Paginator(this.projectRepos, 11, 1)

        //Set the first page as the data
        this.paginateData = this.paginator.first()
        this.totalPages = this.paginator.totalPages
      },
    },
    getAllCommits: {
      handler(value) {
        this.commits = value.data
      },
    },
    getProjectReposStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
        }
      },
    },
    getAllCommitsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
        }
      },
    },
    getUserIntegrationsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
          this.integrations = this.getUserIntegrations.data
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
  async mounted() {
    //Set the user slug - check with localStorage or Vuex Getter
    this.userSlug = this.getSelectedWorkspace?.slug
  },
}
</script>

<style scoped>
.commit-table {
  background: #000;
  color: #fff;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  margin-left: 5px;
}

.commit-text {
  margin: 0 0 10px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 300;
}

.btn-border-remove {
  background-repeat: no-repeat !important;
  border: none !important;
  cursor: pointer !important;
  overflow: hidden !important;
  outline: none !important;
}

.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
