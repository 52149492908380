import http from '../../config/axios'

export const workflowUrls = {
  CREATE_TASK: 'tasks/create',
  BASE_URL: 'tasks',
  UPDATE_TASK: 'tasks/update',
  DELETE_TASK: 'tasks/delete',
  ASSIGN_MEMBER: 'tasks/assign-member',
}

/** addTask */
const addTask = async params => {
  return await http.post(`${workflowUrls.CREATE_TASK}`, params)
}

/** getTasks */
const getTasks = async projectId => {
  return await http.get(`${workflowUrls.BASE_URL}/${projectId}`)
}

/** updateTask */
const updateTask = async params => {
  return await http.post(`${workflowUrls.UPDATE_TASK}`, params)
}

/** assignMember */
const assignMember = async params => {
  return await http.post(`${workflowUrls.ASSIGN_MEMBER}`, params)
}

/** deleteMember */
const deleteMember = async params => {
  return await http.delete(
    `${workflowUrls.BASE_URL}/${params[0]}/remove-member/${params[1]}`
  )
}

/** deleteTask */
const deleteTask = async taskId => {
  return await http.delete(`${workflowUrls.DELETE_TASK}/${taskId}`)
}

export { addTask, getTasks, updateTask, assignMember, deleteMember, deleteTask }
