import * as types from '@/store/modules/member/types'

export default {
  [types.MEMBER_STATUS](state, value) {
    state.memberStatus = value
  },
  [types.MEMBER](state, payload) {
    state.member = payload
  },
  [types.SUBMIT_TEAM_MEMBER_STATUS](state, value) {
    state.submitMemberStatus = value
  },
  [types.SUBMIT_TEAM_MEMBER](state, payload) {
    state.submitMember = payload
  },
}
