<template>
  <div
    class="fancy-box fancy-box-case-study scheme-light"
    :class="`fancy-box-${size}`"
  >
    <div class="fancy-box-contents border-radius-5">
      <div class="cb-img-container border-radius-5">
        <figure
          id="new-bg-color"
          class="fancy-box-image border-radius-5"
          :style="{
            backgroundImage: `url(${image})`,
            backgroundColor: `${backgroundColor}`,
          }"
        >
          <img class="invisible" :src="image" alt="Content Box" />
        </figure>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ServiceCard',
  props: {
    image: {
      type: String,
    },
    size: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
  },
}
</script>
<style scoped></style>
