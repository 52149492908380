<template>
  <!--  header  -->
  <div class="pages features">
    <Navbar></Navbar>
    <main class="jumbotron-container">
      <div class="row pages-jumbotron">
        <div class="col-lg-10">
          <p class="pre fs_lh-14_18">POWERED BY FYBRR</p>
          <h1 class="title my-4 fs_lh-80_94">
            Built for individuals & teams of all sizes
          </h1>
          <p class="post fs_lh-24_31 w-75">
            Whether you’re building a small startup or a large enterprise, Fybrr
            helps teams of all sizes build robust tech infrastructure without
            the need to complicate or accumulate technical debt.
          </p>
          <div class="nav-services mt-5">
            <p class="text-dark-grey text-xxs fs_lh-12_15">
              PROVISIONED CLOUD SERVERS AVAILABLE FOR
            </p>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div v-for="(image, index) in images" :key="index">
                <img :src="`assets/img/home/${image}.svg`" :alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <!--  website builder  -->
  <section class="features--website-builder">
    <div class="center">
      <h2 class="title col-lg-7 fs_lh-56_56">
        Website builder redefined max 2 lines content
      </h2>
    </div>
    <div class="container-fluid builder-container">
      <div class="row align-items-start">
        <div class="col-lg-7 img-container">
          <div class="video-player">
            <img src="@/assets/img/animated-bg.gif" alt="" />
          </div>
        </div>
        <div class="col">
          <div class="w-88">
            <div class="features--website-builder__items">
              <h6>Highlight feature of the website builder</h6>
              <p class="about mt-3">
                For growing teams - Integrate your version control platforms to
                build scalable apps
              </p>
            </div>
            <div class="features--website-builder__items">
              <h6>Highlight feature of the website builder</h6>
              <p class="about mt-3">
                For growing teams - Integrate your version control platforms to
                build scalable apps
              </p>
            </div>
            <div class="features--website-builder__items">
              <h6>Highlight feature of the website builder</h6>
              <p class="about mt-3">
                For growing teams - Integrate your version control platforms to
                build scalable apps
              </p>
            </div>
            <div class="features--website-builder__items">
              <h6>Highlight feature of the website builder</h6>
              <p class="about mt-3">
                For growing teams - Integrate your version control platforms to
                build scalable apps
              </p>
            </div>
            <div class="features--website-builder__items">
              <h6>Highlight feature of the website builder</h6>
              <p class="about mt-3">
                For growing teams - Integrate your version control platforms to
                build scalable apps
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  store -->
  <section class="features--store">
    <div class="center">
      <div class="col-lg-9">
        <h2>Create your online store anywhere. Max content two lines</h2>
        <p class="mt-3">
          Design and develop beautifully crafted digital products better and
          faster. Whether you're building a simple website, online store or
          powerful applications, Fybrr helps you launch faster without any
          complications.
        </p>
      </div>
      <div class="video-player">
        <div class="play-button-overlay">
          <div class="play-button">
            <svg
              class="icon cursor-pointer"
              width="50px"
              height="50px"
              viewBox="0 0 58 70"
            >
              <use xlink:href="/sprite.svg#icon-play2" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features--website-builder bg-repository">
    <div class="container-fluid builder-container">
      <div class="row align-items-start">
        <div class="col">
          <div class="w-88">
            <div class="pre mb-3">REPOSITORIES</div>
            <h2>Three line max content for hosting feature xyz lorem ipsum</h2>
            <div class="features--builder-item">
              <div class="features--website-builder__items">
                <h6>Add collaborators & Team Members</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class="features--website-builder bg-hosting">
    <div class="container-fluid builder-container">
      <div class="row align-items-center">
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
        <div class="col-lg-6">
          <div class="w-80 mx-auto features--builder-text">
            <div class="pre mb-3">HOSTING</div>
            <h2>Three line max content for hosting feature xyz lorem ipsum</h2>
            <p class="description">
              Design and develop beautifully crafted digital products better and
              faster. Whether you're building a simple website, online store or
              powerful applications, Fybrr helps you launch faster without any
              complications.
            </p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="getStarted"
              btnClass="mr-3 mt-3"
              @click="''"
            >
              <span class="px-5">Get started</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features--website-builder bg-deployment">
    <div class="container-fluid builder-container">
      <div class="row align-items-start">
        <div class="col-lg-6">
          <div class="w-80 mx-auto">
            <div class="pre mb-3">DEPLOYMENT</div>
            <h2>Three line max content for hosting feature xyz lorem ipsum</h2>
            <div class="features--builder-item">
              <div class="features--website-builder__items">
                <h6>Add collaborators & Team Members</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!--  workflow  -->
  <section class="features--workflow">
    <div class="center">
      <h2 class="title col-lg-6">
        Add people, create workflows to your projects
      </h2>
    </div>
    <div class="container-fluid builder-container">
      <div class="row align-items-center">
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
        <div class="offset-1 col-lg-4">
          <div class="features--workflow__items">
            <h6>Add collaborators & Team Members</h6>
            <p class="about mt-3">
              For growing teams - Integrate your version control platforms to
              build scalable apps
            </p>
          </div>
          <div class="features--workflow__items">
            <h6>Highlight feature of the website builder</h6>
            <p class="about mt-3">
              For growing teams - Integrate your version control platforms to
              build scalable apps
            </p>
          </div>
          <div class="features--workflow__items">
            <h6>Highlight feature of the website builder</h6>
            <p class="about mt-3">
              For growing teams - Integrate your version control platforms to
              build scalable apps
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features--website-builder bg-integration">
    <div class="container-fluid builder-container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="w-80 mx-auto features--builder-text">
            <div class="pre mb-3">INTEGRATIONS</div>
            <h2>Three line max content for hosting feature xyz lorem ipsum</h2>
            <p class="description">
              Design and develop beautifully crafted digital products better and
              faster. Whether you're building a simple website, online store or
              powerful applications, Fybrr helps you launch faster without any
              complications.
            </p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="getStarted"
              btnClass="mr-3 mt-3"
              @click="''"
            >
              <span class="px-5">Get started</span>
            </Button>
          </div>
        </div>
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class="features--website-builder bg-transfers">
    <div class="container-fluid builder-container">
      <div class="row align-items-start">
        <div class="col-lg-6 img-container">
          <img src="@/assets/img/features/workflow.jpg" alt="" />
        </div>
        <div class="col-lg-6">
          <div class="w-80 mx-auto">
            <div class="pre mb-3">TRANSFERS</div>
            <h2>Three line max content for hosting feature xyz lorem ipsum</h2>
            <div class="features--builder-item">
              <div class="features--website-builder__items">
                <h6>Add collaborators & Team Members</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
              <div class="features--website-builder__items">
                <h6>Highlight feature of the website builder</h6>
                <p class="about mt-3">
                  For growing teams - Integrate your version control platforms
                  to build scalable apps
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ExpertComponent></ExpertComponent>
  <ContactComponent></ContactComponent>
</template>
<script>
import Button from '@/components/ui/Button'
import Navbar from '@/components/pages/navbar'
import ExpertComponent from '@/components/pages/expert'
import ContactComponent from '@/components/pages/home/contact'
import { defineComponent, reactive, ref } from 'vue'
export default defineComponent({
  name: 'FeaturesPage',
  components: {
    Navbar,
    Button,
    ExpertComponent,
    ContactComponent,
  },
  setup() {
    const templates = reactive([
      { name: 'Blank site', link: '/', image: '' },
      {
        name: 'Personal starter',
        link: '/',
        image: '/assets/img/template/template1.jpg',
      },
      {
        name: 'Agency starter',
        link: '/',
        image: '/assets/img/template/template2.png',
      },
      {
        name: 'Corporate starter',
        link: '/',
        image: '/assets/img/template/template3.png',
      },
    ])
    const audiences = reactive([
      {
        name: 'Individuals',
        description:
          'Deploying and scaling apps made easy. Imagine the possibilities with automatic deployments.',
        image: '/assets/img/home/audience/audience1.jpg',
      },
      {
        name: 'Startups & Agencies',
        description:
          'Build nth websites. Our provisioned servers allows you to easily deploy popular frameworks in seconds.',
        image: '/assets/img/home/audience/audience2.jpg',
      },
      {
        name: 'Developers',
        description:
          'In today’s world, speed matters. Design, develop and launch products faster with Fybrr.',
        image: '/assets/img/home/audience/audience3.jpg',
      },
    ])
    const features = reactive([
      {
        title: 'Website Builder',
        active: true,
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        items: [
          'Up to 3 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
      {
        title: 'Ecommerce Store',
        active: false,
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        items: [
          'Up to 8 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
      {
        title: 'Hosting',
        active: false,
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        items: [
          'Up to 9 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
      {
        title: 'Deployments',
        active: false,
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        items: [
          'Up to 3 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
      {
        title: 'Fybrr Experts',
        active: false,
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        items: [
          'Up to 3 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
    ])
    let activeFeatures = ref(features[0].items)
    const functionalities = reactive([
      {
        icon: 'website-builder',
        title: 'Create websites',
        description:
          'Add your repository from github, gitlab and bitbucket. A webhook is added automatically to your repository',
      },
      {
        icon: 'store',
        title: 'Create online stores',
        description: 'Connect your servers to Fybrr for automatic deployment.',
      },
      {
        icon: 'hosting',
        title: 'Cloud hosting',
        description:
          'Choose the repository and server to deploy files to. Set build commands to run immediately the deployment is successful.',
      },
      {
        icon: 'integration',
        title: 'Integrate your git repos',
        description:
          'Your deployment is now live and Fybrr will instantly deploy changes whenever you push updates.',
      },
    ])
    const images = reactive([
      'linux',
      'mysql',
      'python',
      'gitlab',
      'bitbucket',
      'github',
      'slack',
      'docker',
    ])

    const selectFeature = index => {
      features.forEach(element => {
        element.active === true ? (element.active = false) : ''
      })
      features[index].active = true
      activeFeatures.value = features[index].items
    }
    return {
      images,
      templates,
      audiences,
      functionalities,
      features,
      activeFeatures,
      selectFeature,
    }
  },
})
</script>
<style lang="scss">
.w-88 {
  width: 88%;
  margin: 0 auto;
}
.w-80 {
  width: 80%;
  margin: 0 auto;
}
.functionalities-card {
  width: 80%;
}
.functionality-icon {
  background-color: rgba(99, 122, 135, 0.12);
  border-radius: 50%;
  color: #ffffff;
  width: 90px;
  height: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-items-button {
  height: fit-content;
}
</style>
