import axios from 'axios'
import http from '../../config/axios'

export const websiteUrls = {
  BUILDER_FILE_LOCATION: '/var/www/html/public/sites/2',
  BUILDER_URL: '/builder/delete',
}

/** getAllUsersWebsites */
const getAllUsersWebsites = async slug => {
  return await axios({
    method: 'GET',
    url: `https://fybrr.app/getsite/${slug}`,
  })
}

/** getAllUserProjectWebsites */
const getAllProjectWebsites = async projectSlug => {
  return await axios({
    method: 'GET',
    url: `https://fybrr.app/getsite/${projectSlug}`,
  })
}

const deleteWebsite = async websiteId => {
  const builder_path = `${websiteUrls.BUILDER_FILE_LOCATION}/${websiteId}`

  const requestData = {
    builder_path,
  }

  let result = []

  const deleteBuilder = await http.post(
    `${websiteUrls.BUILDER_URL}/${websiteId}`,
    requestData
  )

  if (deleteBuilder.status == 200) {
    result = await axios({
      method: 'GET',
      url: `https://fybrr.app/deletesite/${websiteId}`,
    })
  }

  return result
}

const updateWebsite = async updateData => {
  const { name, domain, uuid } = updateData
  return await axios({
    method: 'GET',
    url: `https://fybrr.app/updatesite/${name}/${domain}/${uuid}`,
  })
}

export {
  getAllUsersWebsites,
  deleteWebsite,
  getAllProjectWebsites,
  updateWebsite,
}
