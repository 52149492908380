<template>
  <div class="auth page-verify">
    <half-circle-spinner
      :animation-duration="1000"
      :size="60"
      color="#000000"
      v-if="loading"
    />
    <div v-if="message !== ''" class="text-white">
      <h1 class="text-capitalize font-weight-bold">Account Verified</h1>
      <p>Login to your account to get started</p>
      <div>
        <Button
          :variant="'primary'"
          :size="'md'"
          name="redirectButton"
          btnClass="btn-block h-100 mt-5 mb-3 py-1"
          @click="$router.push({ name: 'Login' })"
        >
          <!-- :inactive="!validate" -->
          <span class="py-3">Login to your account</span></Button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { HalfCircleSpinner } from 'epic-spinners'
import Button from '@/components/ui/Button'

export default {
  data() {
    return {
      message: '',
      loading: false,
      error: '',
      token: this.$route.params.token,
    }
  },
  components: { HalfCircleSpinner, Button },
  methods: {},
  mounted() {
    this.loading = true
    this.$store
      .dispatch('handleVerify', {
        token: this.token,
      })
      .then(response => {
        this.loading = false
        this.message = Object.prototype.hasOwnProperty.call(
          response.data,
          'message'
        )
          ? response.data.message
          : response.data.success
      })
      .catch(() => {
        this.loading = false
        this.$router.push({ name: 'NotFound' })
      })
  },
}
</script>
<style scoped>
.page-verify {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
