<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <div>
        <div class="alert alert-primary" role="alert" v-if="success">
          {{ success }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ error }}
        </div>
      </div>
      <h6>Create a seller account</h6>
      <p class="description">You can change this later</p>
      <div class="form-group">
        <label for="store_name">Name</label>
        <input
          type="text"
          class="form-control"
          id="store_name"
          placeholder="Name of owner eg Tunde Bakare"
          v-model="store.name"
        />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="email@domain.com"
          v-model="store.email"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="***********"
          v-model="store.password"
        />
      </div>
      <div class="form-group">
        <label for="url">Store URL</label>
        <input
          type="text"
          class="form-control"
          id="url"
          placeholder="url"
          v-model="store.domain"
        />
      </div>
      <p class="text-muted text-xxs" v-if="store.domain !== ''">
        {{ store.domain }}.fybrr.shop
      </p>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click.prevent="createStore"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create online store</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CreateStoreModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    projectUID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      store: {
        name: '',
        email: '',
        password: '',
        domain: '',
      },
      loading: false,
      success: false,
      error: false,
    }
  },
  methods: {
    ...mapActions(['handleCreateUserStore']),
    async createStore() {
      //Make the button load
      this.loading = true
      this.success = false
      this.error = false

      //Setup the params
      const params = {
        name: this.store.name,
        email: this.store.email.toLowerCase(),
        password: this.store.password,
        domain: this.store.domain.toLowerCase(),
        uuid: this.projectUID,
      }

      this.$store
        .dispatch('handleCreateUserStore', params)
        .then(response => {
          if (response.data.status !== 200) {
            this.error = response.data.message
            this.loading = false
          } else {
            this.success = response.data.data.msg
            setTimeout(this.redirect, 2500)
          }
        })
        .catch(() => {
          this.loading = false
          this.error = 'Sorry, an error occured. Try again'
        })
    },

    redirect() {
      this.loading = false
      this.show = false

      //Redirect back to the store page
      this.$router.push({
        name: 'Store',
        params: { uid: this.$route.params.uid },
      })
    },
    allFilled() {
      return (
        this.store.name !== '' &&
        this.store.email !== '' &&
        this.store.password !== '' &&
        this.store.domain !== ''
      )
    },
  },
  computed: {
    ...mapGetters(['getCreateStoreStatus']),
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.store = {}
      },
    },
  },
}
</script>
