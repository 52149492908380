<template>
  <div
    class="modal fade"
    :id="`repo-details-modal-${repoObject.id}`"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="`myLargeModalLabel-${repoObject.id}`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="padding: 40px 30px">
        <div class="modal-header px-0">
          <h5 class="modal-title" :id="`myLargeModalLabel-${repoObject.id}`">
            {{ repoObject.name }}
          </h5>

          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" @click="show = false">&times;</span>
          </button>
        </div>
        <!-- <div class="modal-body p-0 m-0"> -->
        <p class="repo-id">Repository ID: {{ repoObject.id }}</p>

        <p class="repo-text" style="font-weight: bold">
          Command line instructions
        </p>
        <p class="repo-text">
          You can also upload existing files from your computer using the
          instructions below.
        </p>
        <p class="repo-text" style="font-weight: bold">Git global setup</p>

        <div class="command-line">
          <p>git config --global user.name "{Your Name}"</p>
          <p>user.email "{Your email}"</p>
        </div>

        <p class="repo-text" style="font-weight: bold">
          Create a new repository
        </p>

        <div class="command-line">
          <p>git clone {{ repoObject.http_url }}</p>
          <p>cd {{ repoObject.name }}</p>
          <p>touch README.md</p>
          <p>git add README.md</p>
          <p>git commit -m "add README"</p>
          <p>git push -u origin master</p>
        </div>

        <p class="repo-text" style="font-weight: bold">
          Push an existing folder
        </p>
        <div class="command-line">
          <p>cd existing_folder</p>
          <p>git init</p>
          <p>git remote add origin {{ repoObject.ssh_url }}</p>
          <p>git add .</p>
          <p>git commit -m "Initial commit"</p>
          <p>git push -u origin master</p>
        </div>

        <p class="repo-text" style="font-weight: bold">
          Push an existing Git repository
        </p>
        <div class="command-line">
          <p>cd existing_repo</p>
          <p>git remote rename origin old-origin</p>
          <p>git remote add origin {{ repoObject.ssh_url }}</p>
          <p>git push -u origin --all</p>
          <p>git push -u origin --tags</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Button from '@/components/ui/Button'

export default {
  name: 'ViewRepoModal',
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    repoObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {},
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleViewRepoModal', value)
      },
    },
  },
  mounted() {},
}
</script>

<style scoped>
.repo-id {
  margin: 20px 0px 20px 0px;
}
.repo-text {
  margin: 10px 0px 10px 0px;
}

.command-line {
  margin: 10px 0px 10px 0px;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.command-line p {
  margin: 10px 0px 10px 0;
  font-size: 1.1rem;
}
</style>
