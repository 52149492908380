<template>
  <!-- The alert messages -->
  <div>
    <div class="alert alert-success" role="alert" v-if="success">
      {{ message }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="error">
      {{ message }}
    </div>
  </div>
  <div class="account_details">
    <h6>Update Password</h6>
    <p>Update Security</p>
    <div class="py-4">
      <!-- <form action="" class="py-4"> -->
      <!-- <div class="row">
      <div class="form-group col-lg-6">
        <label for="current_password">Current Password</label>
        <input
          type="password"
          id="current_password"
          class="form-control"
          v-model="password.current_password"
        />
      </div>
    </div> -->
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="new_password">New Password</label>
          <input
            type="password"
            id="new_password"
            class="form-control"
            v-model="password.password"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="confirm_password">Confirm Password</label>
          <input
            type="password"
            id="confirm_password"
            class="form-control"
            v-model="password.confirm_password"
          />
        </div>
      </div>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click="updatePassword"
        >
          <!-- :inactive="!validate" -->
          <span class="">Update Password</span></Button
        >
        <!-- <div class="ml-3 font-weight-normal cancel-btn">Cancel</div> -->
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>
<script>
import Button from '@/components/ui/Button'

export default {
  name: 'UpdatePassword',
  components: { Button },
  data() {
    return {
      success: false,
      message: '',
      error: false,
      loading: false,
      password: {
        password: '',
        confirm_password: '',
      },
    }
  },
  methods: {
    async updatePassword() {
      this.loading = true
      //Fetch the Params

      const params = {
        password: this.password.password,
        confirm_password: this.password.confirm_password,
      }

      console.log(params)

      //Update the profile
      this.$store
        .dispatch('handleResetPassword', params)
        .then(response => {
          this.toggleShowResult({
            errorType: 'success',
            message: response.data.success,
          })
        })
        .catch(error => {
          console.log(error.response)
          this.toggleShowResult({
            errorType: 'error',
            message: error.response.data.error,
          })
        })
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
  },
}
</script>
<style lang="scss">
.account_details {
  label {
    font-weight: normal;
  }
}
</style>
