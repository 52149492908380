<template>
  <div class="card rounded-default">
    <div class="card-body">
      <div class="image-size">
        <img :src="integrationDetails.image" :alt="integrationDetails.name" />
      </div>
      <h5>{{ integrationDetails?.name }}</h5>
      <!-- <div class="d-flex mt-4">
        <span
          class="badge badge--blue"
          v-for="(badge, index) in integrationDetails.badges"
          :key="index"
          >{{ badge }}</span
        >
      </div> -->
      <!-- <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Added at</p>
          <h6>{{ integrationDetails.created_at }}</h6>
        </div>
      </div> -->
      <div
        class="project-card-button mt-5 d-flex align-items-center"
        v-if="integrationDetails?.isActive"
      >
        <!-- <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          @click="''"
        >
          <span class="">View</span></Button
        > -->
        <Button
          :loading="loadingRevokeBtn"
          :inactive="false"
          :variant="'outline-danger'"
          :size="'md'"
          type="submit"
          name="editIntegration"
          btnClass="mt-1"
          @click="revokeIntegration(integrationDetails.name?.toLowerCase())"
        >
          <span class="">Remove Integration</span></Button
        >
      </div>
      <div class="project-card-button mt-5 d-flex align-items-center" v-else>
        <Button
          :loading="loadingAddBtn"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          @click="addIntegration(integrationDetails.name?.toLowerCase())"
        >
          <span class="">Add Integration</span></Button
        >
      </div>
    </div>
    <OverlayLoading v-if="loaderIsVisible" />
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import OverlayLoading from '@/components/ui/Loaders/overlay'

export default {
  name: 'SingleIntegrationCard',
  components: { Button, OverlayLoading },
  props: {
    integrationDetails: {
      type: Object,
    },
    businessSlug: {
      type: String,
    },
  },
  data() {
    return {
      loadingAddBtn: false,
      loadingRevokeBtn: false,
      loaderIsVisible: false,
    }
  },
  methods: {
    // ...mapActions(['handleGetUserIntegrations']),
    addIntegration(platform) {
      this.loadingAddBtn = true

      this.$store
        .dispatch('handleGetConfig', platform)
        .then(response => {
          const result = response

          //Save the platform on the local storage
          localStorage.setItem('platform', platform)

          if (platform === 'github') {
            window.location.href = `https://github.com/login/oauth/authorize?client_id=${result.client_id}&scope=repo,user,write:public_key,pull_requests`
          } else if (platform === 'gitlab') {
            window.location.href = `https://gitlab.com/oauth/authorize?client_id=${result.client_id}&response_type=code&redirect_uri=${result.redirect_url}`
          } else if (platform === 'bitbucket') {
            location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${result.client_id}&response_type=code`
          }

          this.loadingAddBtn = false
        })
        .catch(error => {
          //Display the error
          this.$emit('toggleShowResult', {
            errorType: 'error',
            message: error.response.data.message,
          })

          this.loadingAddBtn = false
        })
    },

    async revokeIntegration(platform) {
      this.loaderIsVisible = true
      this.loadingRevokeBtn = true

      const params = { slug: this.businessSlug, platform }

      this.$store
        .dispatch('handleRevokePlatform', params)
        .then(response => {
          //Display the response message
          this.$emit('toggleShowResult', {
            errorType: 'success',
            message: response.message,
          })

          //Reload the page without any params
          window.location = window.location.href.split('?')[0]

          this.loaderIsVisible = false
          this.loadingRevokeBtn = false
        })
        .catch(error => {
          //Display the error
          this.$emit('toggleShowResult', {
            errorType: 'error',
            message: error.response.data.message,
          })
          this.loaderIsVisible = false
          this.loadingRevokeBtn = false
        })
    },
  },
}
</script>
<style lang="scss">
.min-h-60 {
  min-height: 60px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
