import http from '../../config/axios'

export const memberUrls = {
  REMOVE_URL: 'projects/remove-member',
  SUBMIT_URL: 'projects/add-member',
}

/** deleteMember */
const deleteMember = async memberId => {
  return await http.delete(`${memberUrls.REMOVE_URL}/${+memberId}`)
}

const submitMember = async params => {
  return await http.post(memberUrls.SUBMIT_URL, params)
}

export { deleteMember, submitMember }
