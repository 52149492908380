import * as types from '@/store/modules/transaction/types'

export default {
  [types.BILLINGS](state, value) {
    state.billings = value
  },
  [types.BILLINGS_STATUS](state, value) {
    state.billingsStatus = value
  },
  [types.BILLINGS_BY_TYPE](state, value) {
    state.billingsByType = value
  },
  [types.BILLINGS_BY_TYPE_STATUS](state, value) {
    state.billingsByTypeStatus = value
  },
  [types.INITIALIZE_PAYMENT_STATUS](state, value) {
    state.initPaymentStatus = value
  },
  [types.INITIALIZE_PAYMENT](state, value) {
    state.initPayment = value
  },
  [types.RENEW_PILOT_STATUS](state, value) {
    state.renewPilotStatus = value
  },
  [types.RENEW_PILOT](state, value) {
    state.renewPilot = value
  },
  [types.VERIFY_PAYMENT_STATUS](state, value) {
    state.verifyPaymentStatus = value
  },
  [types.VERIFY_PAYMENT](state, value) {
    state.verifyPayment = value
  },
  [types.ACTIVATE_SUBSCRIPTION_STATUS](state, value) {
    state.activateSubscriptionStatus = value
  },
  [types.ACTIVATE_SUBSCRIPTION](state, value) {
    state.activateSubscription = value
  },
  [types.EXPERTS_PRICING_STATUS](state, value) {
    state.expertsPricingStatus = value
  },
  [types.EXPERTS_PRICING](state, value) {
    state.expertsPricing = value
  },
  [types.HIRE_EXPERT_STATUS](state, value) {
    state.hireExpertStatus = value
  },
  [types.HIRE_EXPERT](state, value) {
    state.hireExpert = value
  },
}
