<template>
  <nav class="website-nav navbar navbar-expand-lg navbar-light">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link">
            <svg class="icon" viewBox="0 0 32 32">
              <use xlink:href="/sprite.svg#icon-d-undo" />
            </svg>
            <span> Undo </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <svg class="icon" viewBox="0 0 32 32">
              <use xlink:href="/sprite.svg#icon-d-redo" />
            </svg>
            <span> Redo </span>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <img src="@/assets/img/logo-white.svg" alt="logo" class="nav-logo" />
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li
          v-for="(device, index) in devices"
          :class="`nav-item ${device === activeDevice && 'active'}`"
          :key="index"
          @click="switchDevice(device)"
        >
          <a class="nav-link">
            <svg class="icon" viewBox="0 0 32 32">
              <use :xlink:href="`/sprite.svg#icon-d-${device}`" />
            </svg>
            <span class="uppercase">{{ device }}</span>
          </a>
        </li>
        <!-- <li class="nav-item" @click="$emit('toggleVisibility')">
          <a class="nav-link">
            <svg class="icon toggle" viewBox="0 0 18 18">
              <use xlink:href="/sprite.svg#icon-maximize" />
            </svg>
            <span>Sections</span>
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" @click="() => toggleModal(true)">
            <!-- <svg class="icon toggle" viewBox="0 0 18 18">
              <use xlink:href="/sprite.svg#icon-maximize" />
            </svg> -->
            <svg
              class="icon toggle bi bi-rocket-takeoff-fill"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.578 3.578 0 0 0-.108-.563 2.22 2.22 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2.35 2.35 0 0 0-.16-.045 3.797 3.797 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.552 2.552 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526l.24-2.408Zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361.599.599.437 1.732-.36 2.531Z"
              />
              <path
                d="M5.205 10.787a7.632 7.632 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925Z"
              />
            </svg>
            <span>Publish</span>
          </a>
          <div class="dropdown__container">
            <PublishDropdown
              :isBoxVisible="showPublish"
              @toggleModal="toggleModal"
            ></PublishDropdown>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { ref } from 'vue'
import PublishDropdown from './PublishDropdown.vue'

export default {
  name: 'WebsiteBuilderNavbar',
  emits: ['changeFrameDimension'],
  components: { PublishDropdown },
  data() {
    return {
      activeDevice: ref('desktop'),
      devices: ref(['desktop', 'tablet', 'phone']),
      showPublish: false,
    }
  },
  methods: {
    toggleModal(value) {
      console.log('value inserted: ', value)
      this.showPublish = value
    },
    switchDevice(device) {
      this.activeDevice = device
      this.$emit('changeFrameDimension', device)
    },
  },
  watch: {
    showPublish: {
      handler(value) {
        this.toggleModal(value)
      },
    },
  },
}
</script>

<style>
.dropdown__container {
  position: absolute;
  right: 400px;
  top: 0;
}
</style>
