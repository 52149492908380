<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      Operation cannot be performed. Please try again later.
    </div>
    <div class="alert alert-success" v-if="success">
      Assignee(s) has been removed successfully.
    </div>
    <div>
      <h6 class="titleText">Remove Assignee(s)</h6>

      <div
        class="member-container"
        v-for="assignee in assignees"
        v-bind:key="assignee?.id"
        v-bind:value="assignee?.id"
      >
        <p>{{ assignee?.email }}</p>
        <button @click="removeMember(taskID, assignee.id)">Remove</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
// import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'RemoveMemberModal',
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    currentTask: {
      type: Object,
      required: true,
    },
    projectID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      params: this.$route.params.uid,
      loading: false,
      success: false,
      error: '',
      assignees: [],
      taskID: 0,
    }
  },
  methods: {
    ...mapActions(['handleGetTasks']),
    removeMember(taskID, userID) {
      this.loading = true
      this.error = ''

      const task_id = taskID
      const user_id = userID

      const params = [task_id, user_id]

      this.$store
        .dispatch('handleDeleteMember', params)
        .then(() => {
          this.success = true

          setTimeout(this.redirect, 1000)
        })
        .catch(error => {
          this.loading = false
          this.error = error
        })
    },
    redirect() {
      this.success = ''
      this.error = ''
      // this.show = false
      this.loading = false
      //update list of websites
      this.handleGetTasks(this.projectID)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleRemoveModal', {}, value)
        this.error = ''

        this.assignees = this.currentTask.assignees
        this.taskID = this.currentTask.id
      },
    },
  },
  updated() {
    // this.handleGetTasks(this.projectID)
  },
}
</script>

<style scoped>
.member-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1rem;
  padding: 5px 0px 5px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.titleText {
  margin-bottom: 10px;
}
</style>
