import * as types from '@/store/modules/workspace/types'
import { getAllUsersWorkspaces, addWorkspace } from '@/services/workspace'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetUsersWorkspaces({ commit, dispatch }) {
    commit(types.WORKSPACES_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllUsersWorkspaces()
        .then(response => {
          commit(types.WORKSPACES, response.data.data)
          dispatch('handleSwitchWorkspace', response.data.data)
          commit(types.WORKSPACES_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.WORKSPACES_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  async handleSwitchWorkspace({ commit }, workspaces) {
    //Set the current user workspace
    let userSlug = await localStorage.getItem('selected_workspace_slug')

    //filter the workspace to get the current workspace
    let selectedWorkSpace = workspaces.filter(workspace => {
      if (workspace !== null && workspace !== undefined) {
        return workspace.slug === userSlug
      }
    })

    commit(types.SELECTED_WORKSPACE, selectedWorkSpace[0])
  },

  handleAddWorkSpace({ commit }, workspaceDetails) {
    commit(types.WORKSPACE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addWorkspace(workspaceDetails)
        .then(response => {
          resolve(response)
          commit(types.WORKSPACE_STATUS, 'COMPLETED')
          commit(types.SELECTED_WORKSPACE, response.data.data)

          //Set the current slug in the localStorage
          localStorage.setItem(
            'selected_workspace_slug',
            response.data.data.slug
          )
        })
        .catch(error => {
          formatError(error)
          commit(types.WORKSPACES_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
