export const TASK = 'TASK'
export const TASK_STATUS = 'TASKS_STATUS'
export const TASKS = 'TASKS'
export const TASKS_STATUS = 'TASKS_STATUS'
export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const ASSIGN_MEMBER = 'ASSIGN_MEMBER'
export const ASSIGN_MEMBER_STATUS = 'ASSIGN_MEMBER_STATUS'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const DELETE_MEMBER_STATUS = 'DELETE_MEMBER_STATUS'
export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_STATUS = 'DELETE_TASK_STATUS'
