import commandMutations from './mutations'
import commandActions from './actions'
export default {
  state: {
    commands: {},
    commandsStatus: 'PENDING',
    updateCommands: {},
    updateCommandsStatus: 'PENDING',
    addCommands: {},
    addCommandsStatus: 'PENDING',
  },
  actions: { ...commandActions },
  mutations: { ...commandMutations },
  getters: {
    getCommandsStatus(state) {
      return state.commandsStatus
    },
    getCommands(state) {
      return state.commands
    },
    getUpdateCommandsStatus(state) {
      return state.updateCommandsStatus
    },
    getUpdateCommands(state) {
      return state.updateCommands
    },
    getAddCommandsStatus(state) {
      return state.addCommandsStatus
    },
    getAddCommands(state) {
      return state.addCommands
    },
  },
}
