import workspaceMutations from './mutations'
import workspaceActions from './actions'
export default {
  state: {
    workspaces: [],
    workspacesStatus: 'PENDING',
    workspaceStatus: 'PENDING',
    selectedWorkspace: {},
  },
  actions: { ...workspaceActions },
  mutations: { ...workspaceMutations },
  getters: {
    getWorkspaces(state) {
      return state.workspaces
    },
    getWorkspacesStatus(state) {
      return state.workspacesStatus
    },
    getWorkspaceStatus(state) {
      return state.getWorkspaceStatus
    },
    getSelectedWorkspace(state) {
      return state.selectedWorkspace
    },
  },
}
