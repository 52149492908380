<template>
  <div :class="`card card-${color}`">
    <div class="card-body">
      <slot></slot>
      <h4 class="my-2">
        {{ total }}
        <a class="active">{{ active }} ACTIVE</a>
      </h4>
      <Button
        :loading="false"
        :inactive="false"
        :variant="buttonColor"
        :size="'md'"
        type="submit"
        name="createProject"
        btnClass="mt-3"
        @click="$router.push(link)"
      >
        <span class="">SEE ALL</span></Button
      >
    </div>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
export default {
  name: 'AnalyticsCard',
  components: { Button },
  props: {
    color: {
      type: String,
      default: 'blue',
      validator: value =>
        ['blue', 'dark-blue', 'cyan', 'pink', 'white'].includes(value),
    },
    total: {
      type: Number,
    },
    active: {
      type: Number,
    },
    link: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: 'light-transparent',
    },
  },
}
</script>
