<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav :page="tabShowing" />

      <div class="hosting-table">
        <div class="pb-3">
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="submit"
            btnClass="px-5 mt-4"
            @click="goBack"
          >
            <span class="font-weight-normal">Go Back</span>
          </Button>
        </div>
        <div class="py-2">
          <div class="alert alert-success" v-if="success" role="alert">
            {{ success }}
          </div>
          <div class="alert alert-danger" v-if="error" role="alert">
            {{ error }}
          </div>
        </div>
        <div class="row">
          <div v-if="configs !== null">
            <h3>
              <strong>Environment Variables</strong>
              <small style="font-size: 1.3rem; padding-left: 10px">
                - Generate a new .env file for your app
              </small>
            </h3>

            <p class="my-3">
              Last updated:
              {{ moment(configs?.updated_at).format('LLL') }}
            </p>

            <div class="table-responsive">
              <textarea
                class="commands"
                cols="120"
                rows="20"
                style="resize: none"
                v-model="configs.variables"
                placeholder="Write a new set of build commands to run on your server after successful deployment... e.g composer install"
              ></textarea>

              <div>
                <Button
                  :loading="loading"
                  :inactive="false"
                  :variant="'primary'"
                  :size="'md'"
                  type="submit"
                  class="p-3"
                  @click="updateConfigs"
                >
                  <span class=""> Save Configs</span></Button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Button from '@/components/ui/Button'

export default {
  name: 'ViewEnvironmentVariables',
  components: {
    ProjectNav,
    Button,
    OverlayLoading,
  },
  data() {
    return {
      configs: null,
      moment: moment,
      loading: false,
      success: false,
      error: false,
      tabShowing: '',
      variableIsReady: false,
      loaderIsVisible: true,
    }
  },
  computed: {
    ...mapGetters([
      'getDeploymentConfigs',
      'getDeploymentConfigsStatus',
      'getUpdateDeploymentConfigsStatus',
    ]),
  },
  methods: {
    ...mapActions([
      'handleGetDeploymentConfigs',
      'handleUpdateDeploymentConfigs',
      'handleAddDeploymentConfigs',
    ]),

    goBack() {
      history.back()
    },

    async createNewConfigs() {
      //Set the new command data
      let configsData = {
        deployment_id: parseInt(this.$route.params.deployment_id),
        variables: '',
      }

      await this.handleAddDeploymentConfigs(configsData)
    },

    async updateConfigs() {
      this.loading = true
      const configData = {
        deployment_id: parseInt(this.$route.params.deployment_id),
        variables: this.configs.variables,
      }

      //Array to pass to the service - variable slug
      const slug = this.configs.slug

      const updateData = { slug, configData }

      await this.handleUpdateDeploymentConfigs(updateData)
        .then(response => {
          this.success = response.data.message
          this.showUpdateResult('success')
        })
        .catch(error => {
          this.error = error.response.data.message
          this.showUpdateResult('error')
        })
    },

    showUpdateResult(value) {
      if (value === 'success') {
        setTimeout(() => {
          this.loading = false
          this.success = ''
        }, 2500)
      } else {
        setTimeout(() => {
          this.loading = false
          this.error = ''
        }, 2500)
      }
    },
  },
  watch: {
    getDeploymentConfigsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.configs = this.getDeploymentConfigs
          this.loaderIsVisible = false
        }
      },
    },
  },
  async mounted() {
    const deploymentSlug = this.$route.params.deployment_slug

    await this.handleGetDeploymentConfigs(deploymentSlug)
      .then(response => {
        this.configs = response.data.data
      })
      .catch(error => {
        this.error = error.response.data.message
      })

    //show certain elements if the query is hosting
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('tab')

    if (myParam === 'hosting') {
      this.tabShowing = 'hosting'
    } else {
      this.tabShowing = 'deployment'
    }
  },
}
</script>

<style scoped>
.commands {
  background: #000;
  color: #fff;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
