<template>
  <!--  header  -->
  <div class="pages hire-expert">
    <Navbar></Navbar>
    <main class="container jumbotron-container px-0">
      <div class="row pages-jumbotron">
        <div class="col-lg-8">
          <p class="pre fs_lh-14_18">Hire Expert</p>
          <h1 class="title my-4 fs_lh-80_94">Hire a Developer from Fybrr</h1>
          <p class="post fs_lh-24_31">
            Fybrr offers a subscription based model for you to have your
            projects built, developed, maintained and supported all on one
            platform.
          </p>
        </div>
      </div>
    </main>
  </div>
  <section class="container hire-expert--expect px-0">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="fs_lh-56_56">What to expect</h2>
        <p class="mt-3 fs_lh-16_20">
          A platform that knows how you like to work and we have something for
          everyone.
        </p>
      </div>
    </div>
    <div class="row qualities">
      <div class="col-md-4" v-for="(quality, index) in qualities" :key="index">
        <img :src="`/assets/img/hireExpert/${quality.image}`" alt="" />
        <h4 class="title fs_lh-26_33">{{ quality.title }}</h4>
        <p class="fs_lh-16_20">
          {{ quality.description }}
        </p>
      </div>
    </div>
  </section>
  <section class="container center hire-expert--pricing px-0">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="fs_lh-56_56">Pricing</h2>
        <p class="mt-3 fs_lh-16_20">
          All of our pricing is flexible and can be tailored around your
          requirements as you scale up or down. We also have support
          subscriptions which can help you maintain your project once its up and
          built.
        </p>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6">
        <h6 class="fs_lh-16_20">Let us know how we can help</h6>
        <p class="text-dark-grey fs_lh-14_18 mt-2">
          Select from our flexible pricing
        </p>
        <h2 class="text-primary my-5 fs_lh-36_41">$10/mo</h2>
        <p class="fs_lh-14_18">
          Select how many developers you want to work with and number of days
          per week will the developers work for
        </p>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group mt-4">
              <label class="mb-2 fs_lh-14_18" for="no_of_developers"
                >How many developers do you want?</label
              >
              <input
                type="number"
                min="1"
                class="form-control"
                id="no_of_developers"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-4">
              <label class="mb-2 fs_lh-14_18" for="no_of_week"
                >How many days per week?</label
              >
              <input
                type="number"
                min="1"
                class="form-control"
                id="no_of_week"
              />
            </div>
          </div>
        </div>
        <p class="font-weight-normal fs_lh-14_18">What you will get</p>
        <ul class="list-group list-group-flush mt-3">
          <li
            class="list-group-item d-flex align-items-center fs_lh-14_18"
            v-for="(detail, index) in details"
            :key="index"
          >
            <svg class="icon" width="16px" height="16px">
              <use xlink:href="/sprite.svg#icon-circle-check"></use></svg
            ><span class="ml-2">{{ detail }}</span>
          </li>
        </ul>
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="getStarted"
          btnClass="mr-3 mt-5"
          @click="''"
        >
          <span class="px-3">Hire an Expert</span>
        </Button>
      </div>
      <div class="col-lg-6 text-center">
        <img src="@/assets/img/hireExpert/pricing.svg" alt="" />
      </div>
    </div>
  </section>
  <section class="hire-expert--help">
    <div class="container center px-0">
      <div class="row">
        <div class="col-md-7">
          <h2 class="fs_lh-56_56">How Fybrr Experts can help</h2>
          <p class="my-3 fs_lh-16_20">
            Fybrr Experts can help with a wide range of design, development, and
            marketing projects, including:
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div
          class="col-md-4 mt-5"
          v-for="(ability, index) in abilities"
          :key="index"
        >
          <div class="expert-abilities">
            <span class="expert-abilities__icon">
              <svg class="icon" width="30px" height="30px" viewBox="0 0 42 42">
                <use :xlink:href="`/sprite.svg#icon-${ability.icon}`"></use>
              </svg>
            </span>
            <h4 class="expert-abilities__title font-weight-normal fs_lh-26_33">
              {{ ability.title }}
            </h4>
            <p class="expert-abilities__description fs_lh-16_20">
              {{ ability.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container center px-0 my-5">
    <div class="row">
      <div class="col-lg-8">
        <h2 class="fs_lh-64_64">Not sure on what you need?</h2>
        <p class="my-3 fs_lh-24_31 w-88 m-0">
          Schedule a call for us to talk through your needs and we can help
          guide you through picking the right model for you
        </p>
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="getStarted"
          btnClass="mr-3 mt-3"
          @click="''"
        >
          <span class="px-3">Call us</span>
        </Button>
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from '@/components/pages/navbar'
import Button from '@/components/ui/Button'
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'HireExpertPage',
  components: {
    Navbar,
    Button,
  },
  setup() {
    const qualities = reactive([
      {
        image: 'flexible.svg',
        title: 'Flexible',
        description:
          'Our flexible contract terms and pricing help you get started quickly and scale up and down as needed with no long-term lock-in.',
      },
      {
        image: 'trusted.svg',
        title: 'Trusted & Skilled',
        description:
          'All of our developers are highly skilled and go through training.',
      },
      {
        image: 'affordable.svg',
        title: 'Affordable',
        description:
          'Fybrr allows you to build out an affordable development team, without compromising on quality and expertise.',
      },
    ])

    const abilities = reactive([
      {
        icon: 'd-activity2',
        title: 'Platform migrations',
        description:
          'Our flexible contract terms and pricing help you get started quickly and scale up and down as needed with no long-term lock-in. migrations',
      },
      {
        icon: 'users',
        title: 'Trusted & Skilled',
        description:
          'All of our developers are highly skilled and go through training.',
      },
      {
        icon: 'affordable',
        title: 'Affordable',
        description:
          'Fybrr allows you to build out an affordable development team, without compromising on quality and expertise.',
      },
      {
        icon: 'ecommerce',
        title: 'Ecommerce websites',
        description:
          'A website that sells things? Yes - we can help with that too.',
      },
      {
        icon: 'interaction',
        title: 'Interactions & deployment',
        description:
          'Bring life into your site with custom built interactions and animations.',
      },
      {
        icon: 'design-system',
        title: 'Design system setup',
        description:
          'Work with an Expert to plan, set up, and maintain a scalable design system.',
      },
    ])
    const details = reactive([
      '1 developer',
      '1 day per week',
      'Git repositories',
      'Site Templates',
      'Dedicated Support',
      'Project Maintenance',
    ])

    return { details, qualities, abilities }
  },
})
</script>
<style lang="scss" scoped>
.list-group .list-group-item {
  border: none;
  padding: 4px 0;
  .icon {
    color: rgba(68, 127, 252, 1);
  }
}
</style>
