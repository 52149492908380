<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="repository" />
      <div class="pb-3">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5 mt-4"
          @click="goBack"
        >
          <span class="font-weight-normal">Go Back</span>
        </Button>
      </div>

      <!-- The alert messages -->
      <div class="px-2">
        <div class="alert alert-success" role="alert" v-if="success">
          {{ message }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ message }}
        </div>
      </div>

      <div class="hosting-table">
        <table class="table" v-if="paginateData.length > 0">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">PLATFORM</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in paginateData" :key="index">
              <SingleRepositoryRow
                :reposToIntegrate="log"
                :projectID="projectId"
                :userSlug="userSlug"
                @toggleResult="toggleShowResult"
              />
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex align-items-center justify-content-center mt-5"
          v-if="paginateData !== null && paginateData.length < 1"
        >
          <div class="text-center">
            <h3 v-if="!customMessage && loaded">Repository is empty</h3>
            <h3 v-if="customMessage">{{ customMessage }}</h3>
            <p v-if="!customMessage && loaded">
              You do not have any repository to import from this platform
            </p>
            <p v-if="customMessage">
              You might need to re-integrate the platform from the integrations
              page
            </p>
            <div class="text-center" v-if="customMessage">
              <Button
                :loading="false"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5 mt-4"
                @click="this.$router.push('/dashboard/integrations')"
              >
                <span class="font-weight-normal">Integrations Page</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <nav class="page-dashboard__pagination p-3 mt-2">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
    <OverlayLoading v-if="loaderIsVisible" />
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import SingleRepositoryRow from '@/components/dashboard/project/SingleRepositoryRow'
import { Paginator } from 'array-paginator'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Button from '@/components/ui/Button'

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FetchRepo',
  components: {
    ProjectNav,
    SingleRepositoryRow,
    Button,
    OverlayLoading,
  },
  data() {
    return {
      projectId: 0,
      userSlug: '',
      modalIsVisible: false,
      loaderIsVisible: true,
      customMessage: '',
      success: false,
      message: false,
      error: false,
      loaded: false,
      reposToIntegrate: [
        {
          id: 32158016,
          description:
            'This repository holds the source files to the Fybrr portal administrator dashboard.',
          name: 'findworka-projects / Fybrr Verse',
          name_with_namespace: 'findworka-projects / Fybrr Verse',
          path: 'fybrr-verse',
          path_with_namespace: 'findworka-projects/fybrr-verse',
          created_at: '2021-12-15T21:14:36.858Z',
          default_branch: 'master',
          tag_list: [],
          topics: [],
          ssh_url_to_repo: 'git@gitlab.com:findworka-projects/fybrr-verse.git',
          http_url_to_repo:
            'https://gitlab.com/findworka-projects/fybrr-verse.git',
          web_url: 'https://gitlab.com/findworka-projects/fybrr-verse',
          readme_url:
            'https://gitlab.com/findworka-projects/fybrr-verse/-/blob/master/README.md',
          avatar_url: null,
          forks_count: 0,
          star_count: 0,
          last_activity_at: '2021-12-22T10:46:31.492Z',
          namespace: {
            id: 10730354,
            name: 'findworka-projects',
            path: 'findworka-projects',
            kind: 'group',
            full_path: 'findworka-projects',
            parent_id: null,
            avatar_url: null,
            web_url: 'https://gitlab.com/groups/findworka-projects',
          },
          container_registry_image_prefix:
            'registry.gitlab.com/findworka-projects/fybrr-verse',
          _links: {
            self: 'https://gitlab.com/api/v4/projects/32158016',
            issues: 'https://gitlab.com/api/v4/projects/32158016/issues',
            merge_requests:
              'https://gitlab.com/api/v4/projects/32158016/merge_requests',
            repo_branches:
              'https://gitlab.com/api/v4/projects/32158016/repository/branches',
            labels: 'https://gitlab.com/api/v4/projects/32158016/labels',
            events: 'https://gitlab.com/api/v4/projects/32158016/events',
            members: 'https://gitlab.com/api/v4/projects/32158016/members',
            cluster_agents:
              'https://gitlab.com/api/v4/projects/32158016/cluster_agents',
          },
          packages_enabled: true,
          empty_repo: false,
          archived: false,
          visibility: 'private',
          resolve_outdated_diff_discussions: false,
          container_expiration_policy: {
            cadence: '1d',
            enabled: false,
            keep_n: 10,
            older_than: '90d',
            name_regex: '.*',
            name_regex_keep: null,
            next_run_at: '2021-12-16T21:14:36.896Z',
          },
          issues_enabled: true,
          merge_requests_enabled: true,
          wiki_enabled: true,
          jobs_enabled: true,
          snippets_enabled: true,
          container_registry_enabled: true,
          service_desk_enabled: true,
          service_desk_address:
            'contact-project+findworka-projects-fybrr-verse-32158016-issue-@incoming.gitlab.com',
          can_create_merge_request_in: true,
          issues_access_level: 'enabled',
          repository_access_level: 'enabled',
          merge_requests_access_level: 'enabled',
          forking_access_level: 'enabled',
          wiki_access_level: 'enabled',
          builds_access_level: 'enabled',
          snippets_access_level: 'enabled',
          pages_access_level: 'private',
          operations_access_level: 'enabled',
          analytics_access_level: 'enabled',
          container_registry_access_level: 'enabled',
          security_and_compliance_access_level: 'private',
          releases_access_level: 'enabled',
          emails_disabled: null,
          shared_runners_enabled: true,
          lfs_enabled: true,
          creator_id: 65228,
          import_url: null,
          import_type: null,
          import_status: 'none',
          open_issues_count: 0,
          ci_default_git_depth: 50,
          ci_forward_deployment_enabled: true,
          ci_job_token_scope_enabled: false,
          ci_separated_caches: true,
          ci_opt_in_jwt: false,
          ci_allow_fork_pipelines_to_run_in_parent_project: true,
          public_jobs: true,
          build_timeout: 3600,
          auto_cancel_pending_pipelines: 'enabled',
          ci_config_path: '',
          shared_with_groups: [],
          only_allow_merge_if_pipeline_succeeds: false,
          allow_merge_on_skipped_pipeline: null,
          restrict_user_defined_variables: false,
          request_access_enabled: true,
          only_allow_merge_if_all_discussions_are_resolved: false,
          remove_source_branch_after_merge: true,
          printing_merge_request_link_enabled: true,
          merge_method: 'merge',
          squash_option: 'default_off',
          enforce_auth_checks_on_uploads: true,
          suggestion_commit_message: null,
          merge_commit_template: null,
          squash_commit_template: null,
          issue_branch_template: null,
          auto_devops_enabled: false,
          auto_devops_deploy_strategy: 'continuous',
          autoclose_referenced_issues: true,
          keep_latest_artifact: true,
          runner_token_expiration_interval: null,
          external_authorization_classification_label: '',
          requirements_enabled: false,
          requirements_access_level: 'enabled',
          security_and_compliance_enabled: true,
          compliance_frameworks: [],
          permissions: {
            project_access: null,
            group_access: {
              access_level: 50,
              notification_level: 3,
            },
          },
        },
      ],
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getFetchRepoIntegrationsStatus',
      'getFetchRepoIntegrations',
      'getProject',
      'getProjectStatus',
      'getSelectedWorkspace',
    ]),
  },
  methods: {
    ...mapActions(['handleFetchReposToIntegrate', 'handleGetUsersWorkspaces']),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message

        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message

        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
    goBack() {
      history.back()
    },
  },
  watch: {
    // getFetchRepoIntegrationsStatus: {
    //   handler(value) {
    //     if (value === 'COMPLETED') {
    //       this.reposToIntegrate = this.getFetchRepoIntegrations

    //       //Sometimes the result is bad credentials, so show the error
    //       if (!Array.isArray(this.reposToIntegrate)) {
    //         //If returned object is not an array - i.e Bad Credentials
    //         this.customMessage = this.getFetchRepoIntegrations.message
    //         this.paginateData = []
    //       } else {
    //         this.paginator = new Paginator(this.reposToIntegrate, 15, 1)

    //         //Set the first page as the data
    //         this.paginateData = this.paginator.first()
    //         this.totalPages = this.paginator.totalPages
    //       }
    //     } else {
    //       //Dummy data vs empty array
    //       // this.paginateData = this.reposToIntegrate
    //       this.paginateData = []
    //     }
    //   },
    // },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectId = this.getProject.id
        }
      },
    },
  },
  async mounted() {
    //Load the necessary variables
    //Set the user slug - check with localStorage or Vuex Getter
    const result = await this.handleGetUsersWorkspaces()

    if (result.status === 200) {
      this.userSlug = await this.getSelectedWorkspace.slug

      if (!this.userSlug) {
        this.userSlug = await localStorage.getItem('selected_workspace_slug')
      }
    }

    const params = {
      slug: this.userSlug,
      platform: this.$route.params.platform,
    }

    this.$store
      .dispatch('handleFetchReposToIntegrate', params)
      .then(response => {
        //Sometimes the result is bad credentials, so show the error
        if (!Array.isArray(response.data.data)) {
          //If returned object is not an array - i.e Bad Credentials
          this.customMessage = this.getFetchRepoIntegrations.message
          this.paginateData = []
        } else {
          this.paginator = new Paginator(response.data.data, 15, 1)

          //Set the first page as the data
          this.paginateData = this.paginator.first()

          if (this.paginateData === undefined) {
            this.paginateData = []
          }

          this.totalPages = this.paginator.totalPages
        }
        this.loaded = true
        this.loaderIsVisible = false
      })
      .catch(error => {
        this.customMessage = error.response.data.message
        this.loaded = true
        this.loaderIsVisible = false
      })
  },
}
</script>

<style lang="scss">
.hosting-table {
  &__options {
    .badge {
      border-radius: 12px;
      padding: 8px 14px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
    }
  }
  thead {
    th {
      border-top: none;
      font-weight: 300;
    }
  }
}
</style>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
