<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >

      <div class="alert alert-success" role="alert" v-if="success">
        {{ success }}
      </div>

      <h6>Connect to a Server</h6>
      <!-- <p class="description">You can change this later</p> -->
      <div class="form-group">
        <label for="server_name">Server Name</label>
        <input
          type="text"
          class="form-control"
          id="server_name"
          placeholder="Enter a name for the server"
          v-model="server.name"
        />
      </div>

      <div class="form-group">
        <label for="hosting_provider">Hosting Provider</label>
        <input
          type="text"
          class="form-control"
          id="hosting_provider"
          placeholder="Hosting Provider e.g: Amazon, Azure, etc"
          v-model="server.hostname"
        />
      </div>

      <div class="form-group">
        <label for="ip_address">IP Address</label>
        <input
          type="text"
          class="form-control"
          id="ip_address"
          placeholder="Enter the IP Address"
          v-model="server.ip"
        />
      </div>

      <div class="form-group">
        <label for="port">Port</label>
        <input
          type="text"
          class="form-control"
          id="port"
          placeholder="Port e.g: 22"
          v-model="server.port"
        />
      </div>

      <div class="form-group">
        <label for="protocal">Protocol</label>
        <select
          name="priority"
          id="protocal"
          class="form-control"
          v-model="server.protocal"
        >
          <option :value="undefined" selected hidden disabled>
            Select a protocol
          </option>
          <option value="ftp">FTP</option>
          <option value="ftps">FTPS</option>
          <option value="http">HTTP</option>
          <option value="https">HTTPS</option>
          <option value="ssh">SSH</option>
        </select>
      </div>

      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          class="form-control"
          id="username"
          placeholder="User Name"
          v-model="server.username"
        />
      </div>

      <div class="form-group">
        <input
          type="checkbox"
          id="use_ssh_key"
          v-model="useSSH"
          style="margin-right: 10px"
        />
        <label for="use_ssh_key" style="display: inline"
          >Use SSH key rather than password to authenticate? Add the following
          to your server.</label
        >
      </div>

      <div class="form-group" v-if="useSSH">
        <label for="ssh_key">SSH Key</label>
        <textarea
          type="text"
          class="form-control"
          id="ssh_key"
          placeholder="SSH Key"
          rows="6"
          v-model="server.ssh"
        ></textarea>
      </div>

      <div class="alert alert-info" role="alert" v-if="useSSH">
        <p style="font-weight: 800; text-decoration: underline">
          How to add SSH key to your server
        </p>
        <br />
        <p>Step 1 - Copy the content in the SSH box above</p>
        <p>Step 2 - Login to your server using SSH</p>
        <p>Step 3 - Type "sudo nano ~/.ssh/authorized_keys"</p>
        <p>Step 4 - Move cursor to a new line</p>
        <p>Step 5 - Paste the SSH content on the cursor line</p>
        <p>Step 6 - Press Ctrl + X, it will prompt you to save. Press Y</p>
        <p>Step 7 - That's it. You're done</p>
      </div>

      <div class="form-group" v-if="!useSSH">
        <label for="password">Password (optional)</label>
        <input
          type="password"
          v-model="server.password"
          required
          class="form-control"
          id="password"
          placeholder="Enter a Password"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="createServer"
          btnClass="px-5"
          @click.prevent="createServer"
        >
          <span class="">Create Server</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'ConnectServerModal',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    projectID: {
      type: Number,
    },
    projectSSH: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      useSSH: true,
      error: '',
      success: '',
      server: {
        hardlink: 0,
        maintenance: 0,
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
  methods: {
    ...mapActions(['handleAddFalcon', 'handleGetUsersFalcons']),

    async createServer() {
      this.loading = true
      this.error = ''
      this.success = ''

      //Delete either password or ssh
      this.useSSH ? delete this.server.password : delete this.server.ssh

      let params = {
        userSlug: await localStorage.getItem('selected_workspace_slug'),
        bodyData: {
          ...this.server,
          port: parseInt(this.server.port),
          project_id: this.projectID,
        },
      }

      await this.handleAddFalcon(params)
        .then(async response => {
          this.success = response.data.message
          await this.handleGetUsersFalcons(this.$route.params.uid)
          this.loading = false

          setTimeout(() => {
            this.show = false
            this.server = {}
          }, 1000)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },

    removeError() {
      this.error = ''
    },
    allFilled() {
      const { name, hostname, ip, port, protocal, username } = this.server
      return (
        name !== '' &&
        hostname !== '' &&
        ip !== '' &&
        port !== '' &&
        protocal !== '' &&
        username !== ''
      )
    },
  },
  updated() {
    //Set the SSH Key
    this.server.ssh = this.projectSSH
  },
}
</script>
