<template>
  <div v-if="page === 'website'">
    <div class="pricing-duration">
      <div class="active"><span>Monthly</span></div>
      <div><span>Yearly</span></div>
    </div>
    <div class="row mt-4">
      <PricingCard
        v-for="(plan, index) in plans"
        :pricing-details="plan"
        :key="index"
      ></PricingCard>
    </div>
  </div>
  <div v-if="page === 'ecommerce'">
    <div class="pricing-duration">
      <div class="active"><span>Monthly</span></div>
      <div><span>Yearly</span></div>
    </div>
    <div class="row mt-4">
      <PricingCard
        v-for="(plan, index) in plans"
        :pricing-details="plan"
        :key="index"
      ></PricingCard>
    </div>
  </div>
  <div v-if="page === 'hosting'">
    <div class="pricing-duration">
      <div class="active"><span>Monthly</span></div>
      <div><span>Yearly</span></div>
    </div>
    <div class="row mt-4">
      <PricingCard
        v-for="(plan, index) in plans"
        :pricing-details="plan"
        :key="index"
      ></PricingCard>
    </div>
  </div>
  <div v-if="page === 'fybrr-expert'">
    <div class="col-md-7 mt-3">
      <h3 class="text-primary">$10/mo</h3>
      <p class="text-xxs text-muted mt-3">
        Select how many developers you want to work with and number of days per
        week will the developers work for
      </p>
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="form-group mt-4">
            <label class="mb-3" for="no_of_developers"
              >How many developers do you want?</label
            >
            <input
              type="number"
              min="1"
              class="form-control"
              id="no_of_developers"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mt-4">
            <label class="mb-3" for="no_of_week">How many days per week?</label>
            <input type="number" min="1" class="form-control" id="no_of_week" />
          </div>
        </div>
      </div>
      <p class="text-xxs font-weight-normal">What you will get</p>
      <ul class="list-group list-group-flush my-3">
        <li
          class="list-group-item d-flex align-items-center"
          v-for="(detail, index) in details"
          :key="index"
        >
          <svg class="icon" width="16px" height="16px">
            <use xlink:href="/sprite.svg#icon-circle-check"></use></svg
          ><span class="ml-2">{{ detail }}</span>
        </li>
      </ul>
      <Button
        :loading="false"
        :inactive="false"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="getStarted"
        btnClass="mr-3 mt-5"
        @click="''"
      >
        <span class="px-5">Hire an Expert</span>
      </Button>
    </div>
  </div>
</template>
<script>
import PricingCard from '@/components/pages/pricing/pricingCard'
import { defineComponent, reactive } from 'vue'
import Button from '@/components/ui/Button'
export default defineComponent({
  components: { PricingCard, Button },
  props: {
    page: String,
  },
  setup() {
    const plans = reactive([
      {
        name: 'Fybrr Air',
        price: 'Free',
        description:
          'For small websites, non-commercial apps, such as proof of concepts, and MVPs',
        details: [
          '1 Project',
          'Website Builder',
          'No Git Deployment',
          'Maximum of 3 Deployments',
          'Community Support',
          'Add-Ons / Integrations',
        ],
      },
      {
        name: 'Fybrr Earth',
        price: '$10',
        description:
          'For growing teams - Integrate your version control platforms to build scalable apps',
        details: [
          'Up to 3 Projects',
          'Website Builder',
          '3 Git Deployments',
          'Email Support',
          'Add-Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
      {
        name: 'Fybrr Moon',
        price: '$50',
        description:
          'For growth stage companies - Build unlimited projects, mission critical sites and applications',
        details: [
          'Unlimited Projects',
          'Website Builder',
          'Unlimited Git Deployments',
          'Email & Phone Support',
          'Add-Ons / Integrations',
          'Connect to 3rd party servers via SSH',
        ],
      },
    ])
    const details = reactive([
      '1 developer',
      '1 day per week',
      'Git repositories',
      'Site Templates',
      'Dedicated Support',
      'Project Maintenance',
    ])
    return { plans, details }
  },
})
</script>
<style lang="scss" scoped>
.list-group .list-group-item {
  border: none;
  padding: 4px 0;
  .icon {
    color: rgba(68, 127, 252, 1);
  }
}
</style>
