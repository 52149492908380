import * as types from '@/store/modules/falcon/types'

export default {
  [types.FALCONS_STATUS](state, value) {
    state.falconsStatus = value
  },
  [types.FALCONS](state, payload) {
    state.falcons = payload
  },
  [types.FALCONS_LOGS_STATUS](state, payload) {
    state.falconsLogsStatus = payload
  },
  [types.FALCONS_LOGS](state, payload) {
    state.falconsLogs = payload
  },
  [types.PILOT_TEMPLATES_STATUS](state, payload) {
    state.pilotTemplatesStatus = payload
  },
  [types.PILOT_TEMPLATES](state, payload) {
    state.pilotTemplates = payload
  },
  [types.PILOT_DETAILS_STATUS](state, payload) {
    state.pilotDetailsStatus = payload
  },
  [types.PILOT_DETAILS](state, payload) {
    state.pilotDetails = payload
  },
  [types.DELETE_FALCON_STATUS](state, payload) {
    state.deleteFalconStatus = payload
  },
  [types.DELETE_FALCON](state, payload) {
    state.deleteFalcon = payload
  },
  [types.PROVISION_FALCON_STATUS](state, payload) {
    state.provisionFalconStatus = payload
  },
  [types.PROVISION_FALCON](state, payload) {
    state.provisionFalcon = payload
  },
  [types.REBOOT_FALCON](state, payload) {
    state.rebootFalcon = payload
  },
  [types.REBOOT_FALCON_STATUS](state, payload) {
    state.rebootFalconStatus = payload
  },
  [types.POWER_ON_FALCON_STATUS](state, payload) {
    state.powerOnFalconStatus = payload
  },
  [types.POWER_ON_FALCON](state, payload) {
    state.powerOnFalcon = payload
  },
  [types.POWER_OFF_FALCON](state, payload) {
    state.powerOffFalcon = payload
  },
  [types.POWER_OFF_FALCON_STATUS](state, payload) {
    state.powerOffFalconStatus = payload
  },
  [types.ADD_ACCESS_STATUS](state, payload) {
    state.addAccessStatus = payload
  },
  [types.ADD_ACCESS](state, payload) {
    state.addAccess = payload
  },
  [types.DELETE_ACCESS_STATUS](state, payload) {
    state.deleteAccessStatus = payload
  },
  [types.DELETE_ACCESS](state, payload) {
    state.deleteAccess = payload
  },
  [types.GET_ACCESS_STATUS](state, payload) {
    state.getAccessStatus = payload
  },
  [types.GET_ACCESS](state, payload) {
    state.getAccess = payload
  },
  [types.ADD_FALCON_STATUS](state, payload) {
    state.addFalconStatus = payload
  },
  [types.ADD_FALCON](state, payload) {
    state.addFalcon = payload
  },
  [types.PROJECT_SSH_STATUS](state, payload) {
    state.projectSSHStatus = payload
  },
  [types.PROJECT_SSH](state, payload) {
    state.projectSSH = payload
  },
}
