import http from '../../config/axios'

export const teamUrls = {
  GET_MEMBERS_URL: 'teams',
  DELETE_URL: 'teams/remove',
  DELETE_INVITATIONS: 'invitations/delete',
  RE_INVITE_URL: 'invitations/reinvite',
}

/** getAllTeamMembers */
const getAllTeamMembers = async slug => {
  return await http.get(`${teamUrls.GET_MEMBERS_URL}/${slug}`)
}

/** deleteTeamMember */
const deleteTeamMember = async memberID => {
  return await http.delete(`${teamUrls.DELETE_URL}/${memberID}`)
}

/** deleteInvitations */
const deleteInvitation = async invitationID => {
  return await http.delete(`${teamUrls.DELETE_INVITATIONS}?id=${invitationID}`)
}

/** reInviteMember */
const reInviteMember = async params => {
  return await http.post(`${teamUrls.RE_INVITE_URL}`, params)
}

export { getAllTeamMembers, deleteTeamMember, deleteInvitation, reInviteMember }
