<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="website" />
      <div class="my-3" v-for="(template, index) in templates" :key="index">
        <h6 class="my-4 text-capitalize">{{ index }}</h6>
        <div class="row">
          <div
            class="col-md-3"
            v-for="(singleTemplate, id) in template"
            :key="id"
          >
            <TemplateCard :template="singleTemplate"></TemplateCard>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import TemplateCard from '@/components/dashboard/project/TemplateCard'
export default {
  name: 'Website Builder',
  components: { ProjectNav, TemplateCard },
  data() {
    return {
      templates: {
        'select template': [
          { name: 'Blank site', price: 'Free', image: '' },
          {
            name: 'Personal starter',
            price: 'Free',
            image: '/assets/img/template/template1.jpg',
          },
          {
            name: 'Agency starter',
            price: 'Free',
            image: '/assets/img/template/template2.png',
          },
          {
            name: 'Corporate starter',
            price: 'Free',
            image: '/assets/img/template/template3.png',
          },
        ],
        'agency templates': [
          {
            name: 'Blank site',
            price: 'Free',
            image: '/assets/img/template/template4.png',
          },
          {
            name: 'Personal starter',
            price: 'Free',
            image: '/assets/img/template/template5.png',
          },
          {
            name: 'Agency starter',
            price: 'Free',
            image: '/assets/img/template/template6.png',
          },
          {
            name: 'Corporate starter',
            price: 'Free',
            image: '/assets/img/template/template7.png',
          },
        ],
      },
    }
  },
}
</script>
