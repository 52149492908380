<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="hosting" />
      <div class="hosting-table">
        <div class="d-flex justify-content-between align-items-center my-3">
          <ul class="nav nav-pills mt-4">
            <li class="nav-item mr-2">
              <button
                class="nav-link btn-border-remove"
                :class="tabShowing === 'Pilots' ? 'active' : 'not-active'"
                @click="tabShowing = 'Pilots'"
              >
                Launch Pilots
              </button>
            </li>
            <li class="nav-item mr-2">
              <button
                class="nav-link btn-border-remove"
                :class="
                  tabShowing === 'Server Connections' ? 'active' : 'not-active'
                "
                @click="tabShowing = 'Server Connections'"
              >
                Server Connections
              </button>
            </li>
            <li class="nav-item mr-2">
              <button
                class="nav-link btn-border-remove"
                :class="tabShowing === 'Server Logs' ? 'active' : 'not-active'"
                @click="tabShowing = 'Server Logs'"
              >
                Server Logs
              </button>
            </li>
          </ul>
          <Button
            v-if="tabShowing !== 'Server Logs'"
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="addPilot"
            @click="goToAddPage(tabShowing)"
          >
            <span v-if="tabShowing === 'Pilots'" class="">Add Pilot</span>
            <span v-if="tabShowing === 'Server Connections'" class=""
              >Connect a Server</span
            >
          </Button>
        </div>

        <table
          class="table"
          v-if="paginateData.length > 0 && tabShowing === 'Pilots'"
        >
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">HOSTING PROVIDER</th>
              <th scope="col">IP ADDRESS</th>
              <th class="text-center">STATUS</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(log, index) in paginateData" :key="index">
              <LaunchPilotsRow
                :singlePilot="log"
                :userID="userID"
                :businessID="businessID"
              />
            </tr>
          </tbody>
        </table>

        <table
          class="table"
          v-if="paginateData.length > 0 && tabShowing === 'Server Connections'"
        >
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">HOSTING PROVIDER</th>
              <th scope="col">IP ADDRESS</th>
              <th class="text-center">STATUS</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(log, index) in paginateData" :key="index">
              <ServerConnectionsRow
                :singleServer="log"
                :userID="userID"
                :businessID="businessID"
              />
            </tr>
          </tbody>
        </table>

        <div
          class="d-flex align-items-center justify-content-center mt-5"
          v-if="paginateData.length === 0 && !showServerLogs && loaded"
        >
          <div class="text-center">
            <h3>
              You have not added any {{ tabShowing[0].toLowerCase()
              }}{{ tabShowing.slice(1).toLowerCase() }}
            </h3>
            <p>Add more fully provisioned servers for your projects.</p>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="submit"
              btnClass="px-5 mt-4"
              @click="goToAddPage(tabShowing)"
            >
              <span class="font-weight-normal">Add {{ tabShowing }}</span>
            </Button>
          </div>
        </div>

        <div class="row mt-3 commit-table" v-if="showServerLogs">
          <div class="" v-for="(commit, index) in serverLogs" :key="index">
            <p class="commit-text">{{ commit }}</p>
          </div>
          <p class="commit-text" v-if="this.serverLogs.length === 0">
            No repository activity for this project
          </p>
        </div>

        <nav class="pagination-container">
          <div></div>
          <ul class="pagination">
            <li
              class="page-item"
              v-for="index in this.totalPages"
              :key="index"
              @click="changePaginatedData(index)"
              :class="`${activePage(index)}`"
            >
              <a class="page-link" href="#">{{ index }}</a>
            </li>
          </ul>
        </nav>

        <OverlayLoading v-if="loaderIsVisible" />
      </div>
    </main>
    <ConnectServerModal
      :isVisible="modalIsVisible"
      @toggleModal="toggleModal"
      :projectID="projectID"
      :projectSSH="projectSSH"
    ></ConnectServerModal>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import LaunchPilotsRow from '@/components/dashboard/project/LaunchPilotsRow'
import ServerConnectionsRow from '@/components/dashboard/project/ServerConnectionsRow'
import ConnectServerModal from '@/components/dashboard/project/ConnectServerModal'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Button from '@/components/ui/Button'
import { Paginator } from 'array-paginator'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'Hosting',
  components: {
    ProjectNav,
    Button,
    LaunchPilotsRow,
    OverlayLoading,
    ServerConnectionsRow,
    ConnectServerModal,
  },
  data() {
    return {
      //I removed the logs with null ips
      loaderIsVisible: true,
      projectUID: this.$route.params,
      moment: moment,
      loaded: false,
      modalIsVisible: false,
      businessID: 0,
      userID: 0,
      tabShowing: 'Pilots',
      allFalconList: [],
      pilotsList: [],
      serverConnections: [],
      serverLogs: [],
      projectID: null,
      projectSSH: '',
      currentTabArray: [],
      showServerLogs: false,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getFalcons',
      'getFalconsStatus',
      'getFalconsLogs',
      'getProject',
      'getBusinessInfo',
      'getProjectSSHStatus',
      'getProjectSSH',
    ]),
  },
  methods: {
    ...mapActions([
      'handleGetLoggedInUser',
      'handleGetOneProject',
      'handleFetchProjectWithSSH',
    ]),
    goToAddPage(tab) {
      if (tab === 'Pilots') {
        this.$router.push({
          name: 'AddPilot',
          params: { uid: this.$route.params.uid },
        })
      } else {
        this.toggleModal(true)
      }
    },

    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },

    paginate(data) {
      //Paginate the data manually
      this.paginator = new Paginator(data, 12, 1)

      //Set the first page as the data
      this.paginateData = this.paginator.first()
      if (this.paginateData === undefined) {
        this.paginateData = []
      }
      this.totalPages = this.paginator.totalPages
    },
  },
  watch: {
    tabShowing: {
      handler(value) {
        //Change the current list
        if (value === 'Pilots') {
          this.currentTabArray = this.pilotsList
          //paginate the data
          this.paginate(this.currentTabArray)
          this.showServerLogs = false
        } else if (value === 'Server Connections') {
          this.currentTabArray = this.serverConnections
          //paginate the data
          this.paginate(this.currentTabArray)
          this.showServerLogs = false
        } else if (value === 'Server Logs') {
          this.showServerLogs = true
        }
      },
    },

    getFalconsLogs: {
      handler(value) {
        this.serverLogs = value
      },
    },

    getFalconsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          //clear the former list
          this.serverConnections = []
          this.pilotsList = []

          this.allFalconList = this.getFalcons.data

          for (let i = 0; i < this.allFalconList.length; i++) {
            //This is a falcon/server connection
            if (
              this.allFalconList[i].pilot_id === null &&
              this.allFalconList[i].slug !== null
            ) {
              this.serverConnections.push(this.allFalconList[i])
            }

            //This is Launch Pilots
            if (this.allFalconList[i].pilot_id !== null) {
              this.pilotsList.push(this.allFalconList[i])
            }
          }

          //List to display on first load
          this.currentTabArray = this.pilotsList

          //paginate the data
          this.paginate(this.currentTabArray)

          this.loaderIsVisible = false
          this.loaded = true
        }
      },
    },
    getProjectSSHStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectSSH = this.getProjectSSH.ssh_key
        }
      },
    },
  },
  async mounted() {
    await this.handleGetOneProject(this.$route.params.uid)
    await this.handleFetchProjectWithSSH(this.$route.params.uid)
    const result = await this.handleGetLoggedInUser()

    if (result.status === 200) {
      this.businessID = await this.getProject.business_id
      this.projectID = await this.getProject.id
      this.userID = await this.getBusinessInfo.user_id
    }
  },
}
</script>
<style lang="scss">
.hosting-table {
  &__options {
    .badge {
      border-radius: 12px;
      padding: 8px 14px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
    }
  }
  thead {
    th {
      border-top: none;
      font-weight: 300;
    }
  }
}
</style>
<style scoped>
.commit-table {
  background: #000;
  color: #fff;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  margin-left: 5px;
}

.commit-text {
  margin: 0 0 10px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 300;
}

.btn-border-remove {
  background-repeat: no-repeat !important;
  border: none !important;
  cursor: pointer !important;
  overflow: hidden !important;
  outline: none !important;
}

.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
