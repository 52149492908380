<template>
  <div class="page__body">
    <div class="page__head page__back mb-4">
      <div>
        <h6>Plan & Pricing</h6>
        <p>Select plan for you</p>
      </div>
    </div>
    <billingNav page="deployment"></billingNav>
    <div class="alert alert-danger mt-3" role="alert" v-if="error">
      {{ message }}
    </div>
    <div class="alert alert-success mt-3" role="alert" v-if="success">
      {{ success }}
    </div>
    <!-- <div class="row mt-4">
      <pricingCard
        v-for="(plan, index) in plans"
        :pricing-details="plan"
        :key="index"
      ></pricingCard>
    </div> -->
    <div class="hosting-table">
      <div class="page__head page__back mb-4">
        <div>
          <h6>Billing history</h6>
          <p>See all payment history</p>
        </div>
      </div>
      <table class="table" v-if="billings">
        <thead>
          <tr>
            <th scope="col">DATE</th>
            <th scope="col">PAYMENT TYPE</th>
            <th scope="col">REFERENCE</th>
            <th scope="col">CURRENCY</th>
            <th scope="col">AMOUNT</th>
            <th scope="col">STATUS</th>
            <th scope="col">ACTION</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(billing, index) in paginateData" :key="index">
            <td class="font-weight-light">
              {{ moment(billing.created_at).format('LLL') }}
            </td>
            <td>
              {{ billing.type[0].toUpperCase() }}{{ billing.type.slice(1) }}
            </td>
            <td>{{ billing.reference }}</td>
            <td>{{ billing.currency }}</td>
            <td>{{ billing.amount }}</td>
            <td>
              <span
                :class="`badge badge--${getButtonClass(billing.status)} px-3`"
              >
                <span v-if="billing.status === 'success'">{{
                  billing.status
                }}</span>
                <span v-else>PENDING</span>
              </span>
            </td>
            <td>
              <Button
                :loading="false"
                :inactive="false"
                :variant="'outline-grey'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5"
                @click="goToDownloadPage(billing)"
              >
                <span class="font-weight-normal"> View </span>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex align-items-center justify-content-center mt-5" v-else>
        <div class="text-center">
          <h3>You have no billing</h3>
          <!-- <p>
            No current list of your deployments. Set build commands to run after
            successful deployments.
          </p>
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="submit"
            btnClass="px-5 mt-4"
            @click="''"
          >
            <span class="font-weight-normal">Add Billing</span>
          </Button> -->
        </div>
      </div>
      <nav class="page-dashboard__pagination mr-3">
        <ul class="pagination mt-3">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <OverlayLoading v-if="loaderIsVisible" />
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
// import pricingCard from '@/components/dashboard/billing/pricingCard'
import billingNav from '@/components/dashboard/billing/billingNav'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'
import { Paginator } from 'array-paginator'
import moment from 'moment'

export default {
  name: 'Billings',
  components: { Button, billingNav, OverlayLoading },
  data() {
    return {
      plans: [
        {
          color: 'cyan',
          name: 'Fybrr Air',
          isCurrent: true,
          price: 'Free',
          description:
            'For small websites, non-commercial apps, such as proof of concepts, and MVPs',
          details: [
            '1 Project',
            'Website Builder',
            'No Git Deployment',
            'Maximum of 3 Deployments',
            'Community Support',
            'Add-Ons / Integrations',
          ],
        },
        {
          color: 'primary',
          name: 'Fybrr Earth',
          isCurrent: false,
          price: '$10/mo',
          description:
            'For growing teams - Integrate your version control platforms to build scalable apps',
          details: [
            'Up to 3 Projects',
            'Website Builder',
            '3 Git Deployments',
            'Email Support',
            'Add-Ons / Integrations',
            'Connect to 3rd party servers via SSH',
          ],
        },
        {
          color: 'danger',
          name: 'Fybrr Moon',
          isCurrent: false,
          price: '$50/mo',
          description:
            'For growth stage companies - Build unlimited projects, mission critical sites and applications',
          details: [
            'Unlimited Projects',
            'Website Builder',
            'Unlimited Git Deployments',
            'Email & Phone Support',
            'Add-Ons / Integrations',
            'Connect to 3rd party servers via SSH',
          ],
        },
      ],
      billings: [
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
        {
          name: 'Ehizojie Tunde Bakare',
          type: 'Website builder',
          amount: '$45',
          project_assigned: ['Zojie', 'Shoes'],
          created_at: 'January 22, 2022 15:32',
        },
      ],
      userDetails: {},
      tabShowing: '',
      fullName: '',
      success: '',
      loaderIsVisible: true,
      paginator: Paginator,
      paginateData: [],
      moment: moment,
      totalPages: 0,
      error: false,
      message: '',
      currentPage: 1,
    }
  },
  methods: {
    ...mapActions([
      'handleGetLoggedInUser',
      'handleGetUsersWorkspaces',
      'handleVerifyPayment',
      'handleProvisionFalcon',
      'handleGetAllBillingsByType',
      'handleGetAllBillings',
    ]),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    getButtonClass(status) {
      switch (status) {
        case 'success':
          return 'active'
        case 'null':
          return 'inactive'
        default:
          return 'inactive'
      }
    },
    async goToDownloadPage(billing) {
      //save the object in local storage
      await localStorage.setItem('billing', JSON.stringify(billing))
      await localStorage.setItem('billerName', this.fullName)

      //Go to the transaction page
      const page = this.$router.resolve({
        name: 'TransactionDownload',
        params: {
          ref: billing.reference,
        },
      })

      window.open(page.href, '_blank')
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message

        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message

        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },

    async sleep(ms) {
      return new Promise(resolve => {
        this.loaderIsVisible = true
        setTimeout(resolve, ms)
      })
    },
  },
  computed: {
    ...mapGetters(['getSelectedWorkspace']),
  },
  watch: {
    tabShowing: {
      handler(value) {
        if (value === 'subscription') {
          //Paginate the data manually
          this.paginator = new Paginator(this.billings, 10, 1)

          //Set the first page as the data
          this.paginateData = this.paginator.first()
          this.totalPages = this.paginator.totalPages
          this.loaderIsVisible = false
        }
      },
    },
  },
  async mounted() {
    //get the needed variables
    const resultOfUser = await this.handleGetLoggedInUser()
    const typeOfBilling = await localStorage.getItem('type')

    if (resultOfUser.status === 200) {
      this.userDetails = resultOfUser.data.data
      this.fullName = `${this.userDetails.first} ${this.userDetails.last}`

      //Get workspace info
      const workSpaceInfo = await this.handleGetUsersWorkspaces()

      if (workSpaceInfo.status === 200) {
        if (typeOfBilling !== null && typeOfBilling !== undefined) {
          const params = {
            slug: this.getSelectedWorkspace.slug,
            bodyData: {
              business_id: this.getSelectedWorkspace.id,
              plan_id: 1,
              type: typeOfBilling,
            },
          }
          //Call a specific billing type
          await this.handleGetAllBillingsByType(params)
            .then(response => {
              this.tabShowing = 'subscription'
              //Store the custom billing type list
              this.billings = response.data
            })
            .catch(error => {
              this.toggleShowResult({
                errorType: error,
                message: error.data.message,
              })
            })
        } else {
          //Call all billings endpoint
          const params = {
            slug: this.getSelectedWorkspace.slug,
            bodyData: {
              business_id: this.getSelectedWorkspace.id,
              plan_id: 1,
              type: 'subscription',
            },
          }
          await this.handleGetAllBillings(params)
            .then(response => {
              this.tabShowing = 'subscription'
              //Store the subscriptions list
              this.billings = response.data
            })
            .catch(error => {
              this.toggleShowResult({
                errorType: error,
                message: error.data.message,
              })
            })
        }
      }
    }

    //----Confirm user payments from checkout page----
    //1. Get the transaction ID
    let transaction_id = ''
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    transaction_id = urlParams.get('transaction_id')

    const reference = await localStorage.getItem('reference_no')
    const type = await localStorage.getItem('type')

    if (
      reference !== undefined &&
      reference !== null &&
      transaction_id !== '' &&
      transaction_id !== null
    ) {
      //Show the loader back
      this.loaderIsVisible = true
      if (type == 'pilot') {
        await this.handleVerifyPayment({
          reference,
          transaction_id,
        })
          .then(async response => {
            //If the payment is successful
            if (response.data.error !== true) {
              const pilotId = response.data.data.action.pilot_id
              //get the project uid
              const pilot_project_uid = await localStorage.getItem(
                'pilot_project_uid'
              )

              await localStorage.removeItem('type')
              await localStorage.removeItem('reference_no')

              //Provision the pilot
              const provisionPilot = await this.handleProvisionFalcon(pilotId)
              console.log(provisionPilot)

              //Show the message in the back
              this.success =
                'Payment successful! Please wait for some seconds to provision your pilot'

              //sleep until the server is provisioned
              await this.sleep(60000)
              this.loaderIsVisible = false

              //Go back to the project tab
              window.location.href = `project/${pilot_project_uid}/hosting`

              await localStorage.removeItem('pilot_project_uid')
            }
          })
          .catch(() => {
            this.error = 'Pilot Payment failed! '
          })
      }

      if (type == 'website') {
        await this.handleVerifyPayment({
          reference,
          transaction_id,
        })
          .then(async response => {
            //If the payment is successful
            if (response.data.error !== true) {
              const pilotId = response.data.data.action.pilot_id
              //get the project uid
              const pilot_project_uid = await localStorage.getItem(
                'pilot_project_uid'
              )

              await localStorage.removeItem('type')
              await localStorage.removeItem('reference_no')

              //Provision the pilot
              await this.handleProvisionFalcon(pilotId)

              //Show the message in the back
              this.success =
                'Payment successful! Please wait while we setup your website'

              //sleep until the server is provisioned
              await this.sleep(60000)
              this.loaderIsVisible = false

              //Go back to the project tab
              window.location.href = `project/${pilot_project_uid}/builder`

              await localStorage.removeItem('pilot_project_uid')
            }
          })
          .catch(() => {
            this.error = 'Pilot Payment failed! '
          })
      }

      if (type == 'subscription') {
        await this.handleActivateSubscription({
          reference,
          id: transaction_id,
        }).then(response => {
          this.success = response.data.message
        })

        const user = await JSON.parse(localStorage.getItem('deployn_user'))

        //console.log('User=>', user);

        // if(Array.isArray(user.business.subscription)) {
        //   user.business.subscription.push(true);
        //   user.business.subscription.status = 'active';
        // } else {
        //   user.business.subscription.transaction_id = true;
        //   user.business.subscription.status = 'active';
        // }

        await localStorage.setItem('deployn_user', JSON.stringify(user))
        // await this.setLoggedInUserCredentials(user);

        //console.log('New User=>', user);

        await localStorage.removeItem('deployn_subscription_id')
        await localStorage.removeItem('deployn_subscription_reference')
        await localStorage.removeItem('deployn_token')
        await localStorage.removeItem('type')
        await localStorage.removeItem('reference_no')

        window.location.href = `integrations`
      }
    }
  },
}
</script>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
