import * as types from './types'
import { login } from '@/services/website-builder'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleAuthenticateUser({ commit }, params) {
    commit(types.WEBSITE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      login(params)
        .then(response => {
          resolve(response)
          commit(types.WEBSITE_STATUS, 'COMPLETED')
        })
        .catch(error => {
          formatError(error)
          reject(error)
          commit(types.WEBSITE_STATUS, 'PENDING')
        })
    })
  },
}
