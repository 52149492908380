<template>
  <!--  header  -->
  <div class="pages pricing">
    <Navbar></Navbar>
    <main class="container jumbotron-container">
      <div class="row pages-jumbotron">
        <div class="col-md-8">
          <p class="pre fs_lh-14_18">PRICING</p>
          <h1 class="title my-4 fs_lh-80_94">
            Available for everyone Pay as you grow
          </h1>
          <p class="post mt-3 fs_lh-24_30">
            We know that you care about pricing and that is why we have
            different pricing plans that have been carefully curated to suit
            your project needs.
          </p>
        </div>
      </div>
    </main>
  </div>
  <section class="container pricing--features">
    <div class="row">
      <div class="col-lg-9">
        <h2 class="fs_lh-56_65">
          Instantly connect with your leads, extra content of 2 lines max
        </h2>
      </div>
      <div class="col-lg-12">
        <PricingPageNav :page="page" class="mt-5" @changePage="changePage" />
        <PricingFeatures :page="page" />
      </div>
    </div>
  </section>
  <ContactComponent />
</template>
<script>
import Navbar from '@/components/pages/navbar'
import PricingPageNav from '@/components/pages/pricing/nav'
import PricingFeatures from '@/components/pages/pricing/pricingFeatures'
import ContactComponent from '@/components/pages/home/contact'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'PricingPage',
  components: {
    Navbar,
    PricingPageNav,
    ContactComponent,
    PricingFeatures,
  },
  setup() {
    const page = ref('website')
    const changePage = pageToChange => {
      page.value = pageToChange
    }
    return { page, changePage }
  },
})
</script>
<style lang="scss">
.pricing-duration {
  margin-top: 30px;
  display: flex;
  width: 200px;
  height: 30px;
  & > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & > * {
    width: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
    border: 1px solid rgba(99, 122, 135, 0.24);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    transition: all 0.5s;
  }
  & > div.active,
  & > div:hover {
    background: rgba(68, 127, 252, 1);
    border-color: rgba(68, 127, 252, 1);
    color: #ffffff;
  }
}
</style>
