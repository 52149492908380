import * as types from '@/store/modules/store/types'

export default {
  [types.STORES_STATUS](state, value) {
    state.storesStatus = value
  },
  [types.STORES](state, payload) {
    state.stores = payload
  },
  [types.DELETE_STORE_STATUS](state, payload) {
    state.deleteStoreStatus = payload
  },
  [types.DELETE_STORE](state, payload) {
    state.deleteStore = payload
  },
  [types.CREATE_STORE](state, payload) {
    state.createStore = payload
  },
  [types.CREATE_STORE_STATUS](state, payload) {
    state.createStoreStatus = payload
  },
}
