import * as types from './types'
import { deleteMember, submitMember } from '@/services/member'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleDeleteMember({ commit }, memberId) {
    commit(types.MEMBER_STATUS, 'LOADING')
    return deleteMember(memberId)
      .then(response => {
        commit(types.MEMBER, response.data.data)
        commit(types.MEMBER_STATUS, 'COMPLETED')
      })
      .catch(error => {
        formatError(error)
        commit(types.MEMBER_STATUS, 'PENDING')
      })
  },

  handleSubmitTeamMember({ commit }, teamData) {
    commit(types.SUBMIT_TEAM_MEMBER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      submitMember(teamData)
        .then(response => {
          commit(types.SUBMIT_TEAM_MEMBER, response.data.data)
          commit(types.SUBMIT_TEAM_MEMBER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.SUBMIT_TEAM_MEMBER_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
