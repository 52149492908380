<template>
  <div class="page__body">
    <div class="page__head">
      <h6>Dashboard</h6>
      <p>Get a summary of your account</p>
    </div>
    <main class="page-dashboard">
      <div class="page-dashboard-cards page-dashboard-analytics">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-9">
            <AnalyticsCard
              color="blue"
              :total="getNumOfTotalProjects"
              :active="getNumOfActiveProjects"
              :link="goToURL('/dashboard/projects')"
            >
              <div class="d-flex justify-content-between">
                <p class="card-subtitle">Total projects</p>
                <svg
                  class="icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-d-chart"></use>
                </svg>
              </div>
            </AnalyticsCard>
          </div>
          <div class="col-lg-3 col-md-6 col-9">
            <AnalyticsCard
              color="cyan"
              :total="getTeamMembers.length"
              :active="getActiveMembers(getTeamMembers)"
              :link="goToURL('/dashboard/teams')"
            >
              <div class="d-flex justify-content-between">
                <p class="card-subtitle">All team members</p>
                <svg
                  class="icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-d-activity"></use>
                </svg>
              </div>
            </AnalyticsCard>
          </div>
          <div class="col-lg-3 col-md-6 col-9">
            <CustomAnalyticsCard
              color="dark-blue"
              :total="getStores.length"
              :active="getStores.length"
              @toggleModal="toggleModal"
              :modalTitle="'Online Stores'"
              :modalDestination="'/store'"
              :modalDestinationName="'Store'"
            >
              <div class="d-flex justify-content-between">
                <p class="card-subtitle">Online stores</p>
                <svg
                  class="icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-d-bag"></use>
                </svg>
              </div>
            </CustomAnalyticsCard>
          </div>
          <div class="col-lg-3 col-md-6 col-9">
            <CustomAnalyticsCard
              color="pink"
              :total="getWebsites.length"
              :active="getWebsites.length"
              @toggleModal="toggleModal"
              :modalTitle="'Total Websites'"
              :modalDestination="'/builder'"
              :modalDestinationName="'DashboardWebsiteBuilder'"
            >
              <div class="d-flex justify-content-between">
                <p class="card-subtitle">Total websites</p>
                <svg
                  class="icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-d-discovery"></use>
                </svg>
              </div>
            </CustomAnalyticsCard>
          </div>
        </div>
      </div>
      <div class="page-dashboard-cards">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <ServiceCard
              :size="'full'"
              :image="'/assets/img/dashboard/overview1.svg'"
              :backgroundColor="'#000'"
            >
              <div class="fancy-box-header">
                <div class="d-flex justify-content-between">
                  <p class="card-subtitle">Website Builder</p>
                  <svg
                    class="icon"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  >
                    <use xlink:href="/sprite.svg#icon-d-shield"></use>
                  </svg>
                </div>
              </div>
              <div class="fancy-box-footer">
                <h6 class="text-center">
                  Create your website with our drag and drop easy to use builder
                </h6>
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'primary'"
                  :size="'md'"
                  type="submit"
                  name="createProject"
                  btnClass="mt-3"
                  @click="
                    toggleModal(
                      true,
                      'Create Website',
                      '/builder/create',
                      'CreateWebsite'
                    )
                  "
                >
                  <span class="">Create website</span></Button
                >
              </div>
            </ServiceCard>
          </div>
          <div class="col-lg-4 col-md-6">
            <ServiceCard
              :size="'full'"
              :image="'/assets/img/dashboard/overview2.svg'"
              :backgroundColor="'#292929'"
            >
              <div class="fancy-box-header">
                <div class="d-flex justify-content-between">
                  <p class="card-subtitle">Create Ecommerce Store</p>
                  <svg
                    class="icon"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  >
                    <use xlink:href="/sprite.svg#icon-d-shield"></use>
                  </svg>
                </div>
              </div>
              <div class="fancy-box-footer">
                <h6 class="text-center">
                  Start selling with our ecommerce store builder
                </h6>
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'cyan'"
                  :size="'md'"
                  type="submit"
                  name="createProject"
                  btnClass="mt-3"
                  @click="
                    toggleModal(
                      true,
                      'Create Store',
                      '/store/create',
                      'CreateStore'
                    )
                  "
                >
                  <span class="">Create ecommerce store</span></Button
                >
              </div>
            </ServiceCard>
          </div>
          <div class="col-lg-4 col-md-6">
            <ServiceCard
              :size="'half'"
              :image="'/assets/img/dashboard/overview3.jpg'"
            >
              <div class="fancy-box-footer half text-left">
                <p class="font-weight-bold">Cloud Hosting</p>
                <p class="font-weight-bold my-2">
                  Select available services to host your project
                </p>
                <a
                  class="font-weight-bold"
                  href="#"
                  @click="
                    toggleModal(
                      true,
                      'Create Cloud Hosting',
                      '/hosting',
                      'Hosting'
                    )
                  "
                  >Get started</a
                >
              </div>
            </ServiceCard>
            <ServiceCard
              :size="'half'"
              :image="'/assets/img/dashboard/overview4.jpg'"
            >
              <div class="fancy-box-footer half text-left">
                <p class="font-weight-bold">Hire a Fybrr Expert</p>
                <p class="font-weight-bold my-2">
                  Select available services to host your project
                </p>
                <a
                  class="font-weight-bold"
                  href="#"
                  @click="$router.push('/dashboard/hire-expert')"
                  >Get started</a
                >
              </div>
            </ServiceCard>
          </div>
        </div>
      </div>
      <OverlayLoading v-if="loaderIsVisible" />
      <SelectWebsiteModal
        :isVisible="modalIsVisible"
        @toggleModal="toggleModal"
        :modalTitle="this.modalTitle"
        :modalDestination="this.modalDestination"
        :modalDestinationName="this.modalDestinationName"
      ></SelectWebsiteModal>
    </main>
  </div>
</template>
<script>
import AnalyticsCard from '@/components/dashboard/overview/AnalyticsCard'
import CustomAnalyticsCard from '@/components/dashboard/overview/CustomAnalyticsCard'
import ServiceCard from '@/components/dashboard/overview/ServiceCard'
import SelectWebsiteModal from '../../components/dashboard/project/SelectWebsiteModal'
import Button from '@/components/ui/Button'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Overview',
  components: {
    AnalyticsCard,
    ServiceCard,
    Button,
    SelectWebsiteModal,
    OverlayLoading,
    CustomAnalyticsCard,
  },
  data() {
    return {
      modalIsVisible: false,
      loaderIsVisible: true,
      modalTitle: '',
      modalDestination: '',
      modalDestinationName: '',
      userSlug: '',
      projects: [],
      /*booleans to hold if the dashboard data is loaded
      Good for controlling the loader logic*/
      projectsLoaded: false,
      teamMembersLoaded: false,
      storesLoaded: false,
      websitesLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'getBusinessSlug',
      'getNumOfActiveProjects',
      'getNumOfTotalProjects',
      'getTeamMembers',
      'getStores',
      'getWebsites',
      'getProjects',
      'getUserInfoStatus',
      'getProjectsArray',
      'getSelectedWorkspace',
    ]),

    contentIsLoaded() {
      return (
        this.projectsLoaded &&
        this.teamMembersLoaded &&
        // this.storesLoaded &&
        this.websitesLoaded
      )
    },
  },
  watch: {
    /*This will stop the load when all the data
    has been loaded*/
    contentIsLoaded: function () {
      this.projects = this.getProjectsArray
      this.loaderIsVisible = false
    },

    getWebsites: function () {
      this.websitesLoaded = true
    },

    getProjects: function () {
      this.projectsLoaded = true
    },
    getTeamMembers: function () {
      this.teamMembersLoaded = true
    },
    getStores: function () {
      this.storesLoaded = true
    },
  },
  methods: {
    ...mapActions(['fetchAllAuthStates', 'handleGetUsersWorkspaces']),

    getActiveMembers(getTeamMembers) {
      let activeMembers = 0
      getTeamMembers.forEach(member => {
        if (member.profile.is_active === 1) {
          activeMembers++
        }
      })
      return activeMembers
    },
    goToURL(url) {
      return url
    },
    toggleModal(value, title, destination, destinationName) {
      this.projects = this.getProjectsArray
      this.modalIsVisible = value
      this.modalTitle = title
      this.modalDestination = destination
      this.modalDestinationName = destinationName
    },
    reloadItems(slug) {
      // this.loaderIsVisible = false
      this.projects = this.getProjectsArray
      this.fetchAllAuthStates(slug)
    },
    unmounted() {
      this.loaderIsVisible = false
    },
  },
  async mounted() {
    //Set the user slug - check with localStorage or Vuex Getter
    const result = await this.handleGetUsersWorkspaces()

    if (result.status === 200) {
      this.userSlug = this.getSelectedWorkspace.slug

      if (this.userSlug) {
        this.reloadItems(this.userSlug)
      } else {
        this.userSlug = await localStorage.getItem('selected_workspace_slug')
        this.reloadItems(this.userSlug)
      }
    }
  },
}
</script>
