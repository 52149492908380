import teamMutations from './mutations'
import teamActions from './actions'

export default {
  state: {
    teamMembers: [],
    teamMembersStatus: 'PENDING',
    deleteMemberStatus: 'PENDING',
    deleteMember: {},
    deleteInvitationStatus: 'PENDING',
    deleteInvitation: {},
  },
  actions: { ...teamActions },
  mutations: { ...teamMutations },
  getters: {
    getTeamMembers(state) {
      return state.teamMembers
    },
    getTeamMembersStatus(state) {
      return state.teamMembersStatus
    },
    getDeleteTeamMemberStatus(state) {
      return state.deleteMemberStatus
    },
    getDeleteInvitationStatus(state) {
      return state.deleteInvitationStatus
    },
  },
}
