import projectMutations from './mutations'
import projectActions from './actions'

export default {
  state: {
    projects: [],
    project: [],
    projectsStatus: 'PENDING',
    projectStatus: 'PENDING',
    allProjectCategories: [],
    projectCreated: {},
    projectsArray: [],
    projectsSummary: {
      active: 0,
      total: 0,
    },
    deleteStatus: 'PENDING',
    delete: {},
    projectMembers: [],
    projectMembersStatus: 'PENDING',
    transferOwnershipStatus: 'PENDING',
    invitations: {},
    invitationsStatus: 'PENDING',
    updateProject: {},
    updateProjectStatus: 'PENDING',
  },
  actions: { ...projectActions },
  mutations: { ...projectMutations },
  getters: {
    getProjects(state) {
      return state.projects
    },
    getProjectsArray(state) {
      return state.projectsArray
    },
    getNumOfActiveProjects(state) {
      //This returns the total of active projects
      const { active } = state.projectsSummary
      return active
    },
    getNumOfTotalProjects(state) {
      //This returns the total of projects
      const { total } = state.projectsSummary
      return total
    },
    getProjectsStatus(state) {
      return state.projectsStatus
    },
    getProject(state) {
      return state.project
    },
    getProjectStatus(state) {
      return state.projectStatus
    },
    getProjectCategories(state) {
      return state.allProjectCategories
    },
    getDeleteStatus(state) {
      return state.deleteStatus
    },
    getDelete(state) {
      return state.delete
    },
    getProjectMembers(state) {
      return state.projectMembers
    },
    getProjectMembersStatus(state) {
      return state.projectMembersStatus
    },
    getDeleteProjectMemberStatus(state) {
      return state.deleteProjectMemberStatus
    },
    getDeleteProjectMember(state) {
      return state.deleteProjectMember
    },
    getTransferOwnershipStatus(state) {
      return state.transferOwnershipStatus
    },
    getInvitations(state) {
      return state.invitations
    },
    getInvitationsStatus(state) {
      return state.invitationsStatus
    },
    getUpdateProjectStatus(state) {
      return state.updateProjectStatus
    },
  },
}
