import * as types from '@/store/modules/team/types'

export default {
  [types.TEAM_MEMBERS_STATUS](state, value) {
    state.teamMembersStatus = value
  },
  [types.TEAM_MEMBERS](state, payload) {
    state.teamMembers = payload
  },
  [types.DELETE_MEMBER_STATUS](state, payload) {
    state.deleteMemberStatus = payload
  },
  [types.DELETE_MEMBER](state, payload) {
    state.deleteMember = payload
  },
  [types.DELETE_INVITATION_STATUS](state, payload) {
    state.deleteInvitationStatus = payload
  },
  [types.DELETE_INVITATION](state, payload) {
    state.deleteInvitation = payload
  },
  [types.RE_INVITE_STATUS](state, payload) {
    state.reInviteStatus = payload
  },
  [types.RE_INVITE](state, payload) {
    state.reInvite = payload
  },
}
