<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="integrations" />
      <div class="row mt-3">
        <div
          class="col-xl-2 col-lg-6 col-md-6"
          v-for="(integration, index) in integrations"
          :key="index"
        >
          <SingleIntegrationCard
            :integrationDetails="integration"
          ></SingleIntegrationCard>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import SingleIntegrationCard from '@/components/dashboard/project/Integrations/SingleIntegrationCard'
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import { mapGetters } from 'vuex'
export default {
  name: 'Integrations',
  components: { ProjectNav, SingleIntegrationCard },
  data() {
    return {
      integrations: [],
    }
  },
  methods: {},
  computed: {
    ...mapGetters(['getUserIntegrationsStatus', 'getUserIntegrations']),
  },
  watch: {
    getUserIntegrationsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.integrations = this.getUserIntegrations.data
         
        }
      },
    },
  },
}
</script>
