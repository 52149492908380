<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Edit Website Information</h6>
      <p class="description">You can change this later</p>
      <div class="form-group">
        <label for="website_name">Website Name</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          placeholder="Name of this website"
          v-model="website.name"
        />
      </div>

      <div class="form-group">
        <label for="website_subdomain">Website Subdomain</label>
        <input
          type="text"
          class="form-control"
          id="website_subdomain"
          placeholder="Enter subdomain name"
          @keydown.space.prevent
          v-model="website.domain"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click.prevent="updateWebsite"
        >
          <span class="">Update Website</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'WebsiteSettingsModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    websiteName: {
      type: String,
    },
    websiteDomain: {
      type: String,
    },
    websiteUUID: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: false,
      success: false,
      params: this.$route.params.uid,
      website: {
        name: '',
        domain: '',
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleSettingsModal', value)

        //Set the input fields
        this.website.domain = this.websiteDomain
        this.website.name = this.websiteName
        this.success = false
        this.error = false
      },
    },
  },
  methods: {
    ...mapActions(['handleGetProjectWebsites']),

    updateWebsite() {
      this.loading = true
      this.error = false
      this.success = false

      let trimSubdomain = this.website.domain.split(' ').join('')

      let updateData = {
        name: this.website.name,
        domain: trimSubdomain,
        uuid: this.websiteUUID,
      }

      this.$store
        .dispatch('handleUpdateUserWebsite', updateData)
        .then(async response => {
          this.success = response.data.message
          //reload the website list
          const result = await this.handleGetProjectWebsites(this.params)

          if (result.status === 200) {
            setTimeout(() => {
              this.show = false
              this.loading = false
              this.website = {}
            }, 2500)
          }
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },

    removeError() {
      this.error = ''
    },

    allFilled() {
      const { name, domain } = this.website
      //I am removing all the spaces from the subdomain here

      return name !== '' && domain !== ''
    },
  },
}
</script>
