<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <div class="alert alert-success" role="alert" v-if="success">
        {{ success }}
      </div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <h5 class="text-center">Hire Fybrr Experts Today!</h5>
      <p class="description text-center mt-2">
        You're one step away from forming your development team. Your
        credit/debit card will only be charged the subscription amount and you
        can cancel your subscription anytime.
      </p>

      <h6 class="text-center my-2">CREDIT OR DEBIT CARD</h6>
      <div class="form-group">
        <!-- <label for="card_name" style="text-align: center" class="pb-1"
          ></label
        > -->
        <input
          type="text"
          class="form-control"
          id="card_name"
          placeholder="Full Name"
          maxlength="40"
          v-model="userDetails.name"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="card_name"
          placeholder="Email"
          v-model="userDetails.email"
        />
      </div>

      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <StripeElement
          ref="card"
          class="form-control __cretform"
          :elements="elements"
          :options="cardOptions"
        />
      </StripeElements>

      <div class="d-flex align-items-center justify-content-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click.prevent="makePayment"
        >
          <span class=""
            >Proceed to Pay £{{ amountToBePaid }} Per Month</span
          ></Button
        >
        <!-- <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div> -->
      </div>
      <br />
      <div style="width: 100%" class="text-center">
        <img
          src="../../../assets/img/hireExpert/stripe.png"
          style="width: 250px; text-align: center"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'
import Feedback from '@/components/ui/Feedback'

import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ref, onBeforeMount } from 'vue'

export default {
  name: 'hireExpertModal',
  components: { Button, StripeElements, StripeElement, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    amountToBePaid: {
      type: Number,
    },
    params: {
      type: Object,
    },
  },
  setup() {
    const stripeKey = ref(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) // test key

    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    })
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    })
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      value: {
        postalCode: '10001',
      },
    })
    const stripeLoaded = ref(false)
    const card = ref()
    const elms = ref()

    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value)
      stripePromise.then(() => {
        stripeLoaded.value = true
      })
    })

    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
    }
  },

  data() {
    return {
      show: false,
      loading: false,
      error: '',
      success: '',
      userDetails: {
        name: '',
        email: '',
      },
      paramsObject: {},
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
        this.error = ''
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
  methods: {
    ...mapActions(['handleHireExpert']),

    async makePayment() {
      this.loading = true
      //Get stripe element
      const cardElement = this.card.stripeElement

      // Access instance methods, e.g. createToken()
      const token = await this.elms.instance.createToken(cardElement)

      this.paramsObject = this.params

      this.paramsObject.fullname = this.userDetails.name
      this.paramsObject.email = this.userDetails.email
      this.paramsObject.country = 'united kingdom'
      this.paramsObject.stripe_token = token?.token?.id
      // console.log('Params=>', this.paramsObject)
      // console.log('Token=>', token)

      // console.log(token?.error?.message)

      //Show error if card has error
      if (token?.error?.message !== undefined) {
        this.error = token?.error?.message
      } else {
        await this.handleHireExpert(this.paramsObject)
          .then(response => {
            // console.log('success', response)
            this.success = response.data.message
            this.loading = false
          })
          .catch(error => {
            // console.log('error', error.response)
            this.error = error.response.data.message
            this.loading = false
          })
      }

      this.loading = false
    },
    removeError() {
      this.error = ''
    },
    allFilled() {
      const { name, email } = this.userDetails
      return name !== '' && email !== ''
    },
  },
}
</script>
