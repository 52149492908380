import http from '../../config/axios'

export const commandUrls = {
  CREATE_URL: 'commands/create',
  GET_COMMANDS_URL: 'commands/fetch?deployment_id=',
  UPDATE_COMMAND_URL: 'commands/update',
}

/** addCommand */
const addCommands = async params => {
  return await http.post(`${commandUrls.CREATE_URL}`, params)
}

/** fetchAllCommands */
const fetchAllCommands = async params => {
  return await http.get(
    `${commandUrls.GET_COMMANDS_URL}${params.deployment_id}&project_id=${params.project_id}`
  )
}

/** updateCommand */
const updateCommands = async params => {
  return await http.post(
    `${commandUrls.UPDATE_COMMAND_URL}/${params.slug}`,
    params.commandData
  )
}

export { addCommands, fetchAllCommands, updateCommands }
