<template>
  <td class="font-weight-light">
    {{ member.name }}
  </td>
  <td>{{ member.email }}</td>
  <td>
    <span :class="`badge badge--${getButtonClass(member.active)} px-3`">
      <!-- {{ this.getButtonClass(member.active) }} -->
      {{ member.status.toUpperCase() }}
    </span>
  </td>
  <td>{{ member.role[0].toUpperCase() }}{{ member.role.slice(1) }}</td>
  <td>{{ moment(member.created_at).format('LLL') }}</td>
  <td>
    <div class="btn-group dropup p-0" style="offset: auto; margin-left: 5px">
      <button
        type="button"
        class="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        More
      </button>

      <ul class="dropdown-menu">
        <button
          href="#"
          @click="deleteInvitation(member.id)"
          class="menu__items"
        >
          remove
        </button>
        <button href="#" class="menu__items" @click="reInviteUser(member.id)">
          re-invite
        </button>
      </ul>
    </div>
  </td>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
// import Button from '@/components/ui/Button'
import moment from 'moment'
import { mapActions } from 'vuex'
import OverlayLoading from '@/components/ui/Loaders/overlay'
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    userSlug: {
      type: String,
    },
  },
  components: { OverlayLoading },
  emits: ['toggleResult'],
  data() {
    return {
      removeModalIsVisible: false,
      moment: moment,
      businessSlug: this.userSlug,
      loaderIsVisible: false,
    }
  },
  methods: {
    ...mapActions(['handleGetInvitations', 'handleGetAllTeamMembers']),
    toggleRemoveModal(value) {
      this.removeModalIsVisible = value
    },
    getButtonClass(status) {
      switch (status) {
        case 1:
          return 'inactive'
        case 0:
          return 'active'
        default:
          return 'inactive'
      }
    },
    async deleteInvitation(id) {
      this.$store
        .dispatch('handleDeleteInvitation', id)
        .then(async response => {
          this.$emit('toggleResult', {
            errorType: 'success',
            message: response.data.message,
          })

          await this.handleGetInvitations(this.userSlug)
          await this.handleGetAllTeamMembers(this.userSlug)
        })
        .catch(error => {
          //show loader for a while
          this.loaderIsVisible = true

          setTimeout(() => {
            this.loaderIsVisible = false
          }, 1500)

          this.$emit('toggleResult', {
            errorType: 'error',
            message: error,
          })
        })
    },
    async reInviteUser(id) {
      const params = {
        id,
        target_url: `${location.origin}/invitation`,
      }

      this.$store
        .dispatch('handleReInviteMember', params)
        .then(response => {
          //show loader for a while
          this.loaderIsVisible = true

          setTimeout(() => {
            this.loaderIsVisible = false
          }, 1500)

          this.$emit('toggleResult', {
            errorType: 'success',
            message: `${response.data.message} ${response.data.data.name}`,
          })
        })
        .catch(error => {
          //show loader for a while
          this.loaderIsVisible = true

          setTimeout(() => {
            this.loaderIsVisible = false
          }, 1500)

          this.$emit('toggleResult', {
            errorType: 'error',
            message: error,
          })
        })
    },
  },
}
</script>

<style scoped>
.menu__items {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.menu__items:hover {
  color: #447ffc;
}
</style>
