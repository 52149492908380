import * as types from './types'
import {
  getProjectCategories,
  getAllUsersProjects,
  getOneProject,
  addProject,
  deleteProjectById,
  getProjectMembers,
  deleteProjectMember,
  inviteCollaborator,
  transferOwnership,
  getInvitations,
  updateProject,
} from '@/services/project'
import { formatError } from '@/store/helpers/error-helper'

export default {
  async handleAddProject({ commit }, projectData) {
    return new Promise((resolve, reject) => {
      addProject(projectData)
        .then(response => {
          commit(types.ADD_PROJECT, response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async handleDeleteProjectById({ commit }, deleteData) {
    return new Promise((resolve, reject) => {
      deleteProjectById(deleteData)
        .then(response => {
          commit(types.ADD_PROJECT, response.data.data)
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          reject(error)
        })
    })
  },

  handleGetProjectCategories({ commit }) {
    commit(types.PROJECTS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getProjectCategories()
        .then(response => {
          commit(types.PROJECT_CATEGORY, response.data.data)
          commit(types.PROJECTS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          reject(error)
        })
    })
  },

  async handleGetUsersProjects({ commit }, businessSlug) {
    commit(types.PROJECTS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllUsersProjects(businessSlug)
        .then(response => {
          commit(types.PROJECTS_ARRAY, response.data.data.projects.data)
          commit(types.PROJECTS, response.data.data)
          commit(types.PROJECTS_SUMMARY, response.data.data.summary)
          commit(types.PROJECTS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROJECTS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetOneProject({ commit }, projectSlug) {
    commit(types.PROJECT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getOneProject(projectSlug)
        .then(response => {
          commit(types.PROJECT, response.data.data)
          commit(types.PROJECT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROJECT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetProjectMembers({ commit }, projectID) {
    commit(types.PROJECT_MEMBERS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getProjectMembers(projectID)
        .then(response => {
          commit(types.PROJECT_MEMBERS, response.data.data)
          commit(types.PROJECT_MEMBERS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.PROJECT_MEMBERS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteProjectMember({ commit }, memberID) {
    commit(types.DELETE_PROJECT_MEMBER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteProjectMember(memberID)
        .then(response => {
          commit(types.DELETE_PROJECT_MEMBER, response.data.data)
          commit(types.DELETE_PROJECT_MEMBER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_PROJECT_MEMBER_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleInviteCollaborator({ commit }, params) {
    commit(types.INVITE_COLLABORATOR_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      inviteCollaborator(params)
        .then(response => {
          commit(types.INVITE_COLLABORATOR, response.data.data)
          commit(types.INVITE_COLLABORATOR_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.INVITE_COLLABORATOR_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleTransferOwnership({ commit }, params) {
    commit(types.TRANSFER_OWNERSHIP_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      transferOwnership(params)
        .then(response => {
          commit(types.TRANSFER_OWNERSHIP, response.data.data)
          commit(types.TRANSFER_OWNERSHIP_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.TRANSFER_OWNERSHIP_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetInvitations({ commit }, userSlug) {
    commit(types.INVITATIONS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getInvitations(userSlug)
        .then(response => {
          commit(types.INVITATIONS, response.data.data)
          commit(types.INVITATIONS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.INVITATIONS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleUpdateProject({ commit }, params) {
    commit(types.UPDATE_PROJECT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      updateProject(params)
        .then(response => {
          commit(types.UPDATE_PROJECT, response.data.data)
          commit(types.UPDATE_PROJECT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.UPDATE_PROJECT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
