export const BILLINGS = 'BILLINGS'
export const BILLINGS_STATUS = 'BILLINGS_STATUS'
export const BILLINGS_BY_TYPE = 'BILLINGS_BY_TYPE'
export const BILLINGS_BY_TYPE_STATUS = 'BILLINGS_BY_TYPE_STATUS'
export const INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT'
export const INITIALIZE_PAYMENT_STATUS = 'INITIALIZE_PAYMENT_STATUS'
export const RENEW_PILOT = 'RENEW_PILOT'
export const RENEW_PILOT_STATUS = 'RENEW_PILOT_STATUS'
export const VERIFY_PAYMENT = 'VERIFY_PAYMENT'
export const VERIFY_PAYMENT_STATUS = 'VERIFY_PAYMENT_STATUS'
export const ACTIVATE_SUBSCRIPTION = 'ACTIVATE_SUBSCRIPTION'
export const ACTIVATE_SUBSCRIPTION_STATUS = 'ACTIVATE_SUBSCRIPTION_STATUS'
export const EXPERTS_PRICING = 'EXPERTS_PRICING'
export const EXPERTS_PRICING_STATUS = 'EXPERTS_PRICING_STATUS'
export const HIRE_EXPERT = 'HIRE_EXPERT'
export const HIRE_EXPERT_STATUS = 'HIRE_EXPERT_STATUS'
