import axios from 'axios'

/** getAllUsersStores */
const getAllUsersStores = async slug => {
  return await axios({
    method: 'GET',
    url: `https://fybrr.shop/work/store/one?uuid=${slug}`,
  })
}

/** deleteUserStore */
const deleteUserStore = async storeId => {
  return await axios({
    method: 'GET',
    url: `https://fybrr.shop/work/store/delete/${storeId}`,
  })
}

const createStore = async params => {
  const { name, email, password, domain, uuid } = params

  const result = await axios({
    method: 'GET',
    url: 'https://fybrr.shop/work/load/test/gettoken',
  })

  if (result.status == 200) {
    const result2 = await axios.get('https://fybrr.shop/work/store/register', {
      params: {
        name: name,
        email: email,
        password: password,
        domain: domain,
        uuid: uuid,
      },
    })

    return result2
  }
}

export { getAllUsersStores, deleteUserStore, createStore }
