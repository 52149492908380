import * as types from '@/store/modules/repository/types'

export default {
  [types.PROJECT_REPOS_STATUS](state, value) {
    state.projectReposStatus = value
  },
  [types.PROJECT_REPOS](state, value) {
    state.projectRepos = value
  },
  [types.DELETE_REPO_STATUS](state, value) {
    state.deleteRepoStatus = value
  },
  [types.DELETE_REPO](state, value) {
    state.deleteRepo = value
  },
  [types.ALL_COMMITS](state, value) {
    state.allCommits = value
  },
  [types.ALL_COMMITS_STATUS](state, value) {
    state.allCommitsStatus = value
  },
  [types.USER_INTEGRATIONS_STATUS](state, value) {
    state.userIntegrationsStatus = value
  },
  [types.USER_INTEGRATIONS](state, value) {
    state.userIntegrations = value
  },
  [types.ADD_REPOSITORY](state, value) {
    state.addRepository = value
  },
  [types.ADD_REPOSITORY_STATUS](state, value) {
    state.addRepositoryStatus = value
  },
  [types.FETCH_REPO_INTEGRATIONS](state, value) {
    state.fetchRepoIntegrations = value
  },
  [types.FETCH_REPO_INTEGRATIONS_STATUS](state, value) {
    state.fetchRepoIntegrationsStatus = value
  },
  [types.IMPORT_REPO_STATUS](state, value) {
    state.importRepoStatus = value
  },
  [types.IMPORT_REPO](state, value) {
    state.importRepo = value
  },
}
