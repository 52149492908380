<template>
  <div class="auth">
    <div class="form form--authentication" v-if="!forgotPasswordSent">
      <img src="@/assets/img/logo.svg" alt="Fybrr logo" class="form__logo" />
      <h5>Forgot Password</h5>
      <p class="mb-5">No worries, we'll send you reset instructions</p>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="form-group">
        <label for="email">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email address"
          v-model="email"
        />
      </div>
      <Button
        :loading="loading"
        :inactive="!validate"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="resetButton"
        btnClass="btn-block mb-3"
        @click="handleForgotPassword"
      >
        <!-- :inactive="!validate" -->
        <span class="">Reset Password</span></Button
      >
      <div class="text-center font-weight-bold">
        <router-link to="/login">Back to Login</router-link>
      </div>
    </div>
    <div v-else class="text-center form--forgot_password">
      <div class="icon-with-border">
        <svg
          class="icon icon-mailbox"
          width="50px"
          height="50px"
          viewBox="0 0 24 24"
        >
          <use xlink:href="/sprite.svg#icon-mailbox"></use>
        </svg>
      </div>
      <h3>Check your Email</h3>
      <p class="my-4">
        We sent a password reset link to <br />
        <b>{{ email }}</b>
      </p>
      <p>
        Didn't receive the email?
        <a class="font-weight-bold" href="#" @click="forgotPasswordSent = false"
          >Click to resend</a
        >
      </p>
      <div class="text-center font-weight-bold">
        <router-link to="/login"
          ><svg class="icon" width="15px" height="15px" viewBox="0 0 20 20">
            <use xlink:href="/sprite.svg#icon-arrow-left"></use>
          </svg>
          <a class="ml-1"></a>Back to log in</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'

export default {
  name: 'Forgot Password',
  components: { Feedback, Button },
  data() {
    return {
      error: '',
      loading: false,
      email: '',
      forgotPasswordSent: false,
    }
  },
  methods: {
    handleForgotPassword() {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleForgotPassword', {
          email: this.email,
        })
        .then(() => {
          this.loading = false
          this.forgotPasswordSent = true
        })
        .catch(error => {
          this.loading = false
          let { data } = error.response
          this.error = Object.prototype.hasOwnProperty.call(data, 'message')
            ? data.message
            : data.error
        })
    },
    removeError() {
      this.error = ''
    },
  },
  computed: {
    validate() {
      const email = this.email
      if (email.length) {
        if (
          email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          return true
        }
      }
      return false
    },
  },
}
</script>
