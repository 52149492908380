import { Authorization } from '@/utils/authorization'

// Add bearer token to request header
const addTokenInterceptor = config => {
  const token = Authorization.get.accessToken('token')
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }
  return config
}

// reject interceptor
const rejectErrorInterceptor = error => Promise.reject(error)

/**
 * @function refreshTokenInterceptor
 * @param {*} error -> axios error
 * @returns {Promise}
 * This intercepts 401 responses and basically gets a new token,
 * then uses that new token for all requests that came during, the process
 * of getting a new token.
 */
export { addTokenInterceptor, rejectErrorInterceptor }
