<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <h6>Name your website</h6>
      <p class="description">You can change this later</p>
      <div class="form-group">
        <label for="website_name">Site name</label>
        <input
          type="text"
          class="form-control"
          id="website_name"
          placeholder="Name of site"
          v-model="website.name"
        />
      </div>
      <div class="form-group">
        <label for="website_url">Site URL</label>
        <input
          type="text"
          class="form-control"
          id="website_url"
          placeholder="username"
          v-model="website.url"
        />
      </div>
      <p class="text-muted">username.fybrr.site</p>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click="''"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create Site</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
export default {
  name: 'CreateWebsiteModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      website: {
        name: '',
        url: '',
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
}
</script>
