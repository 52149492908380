<template>
  <div class="auth">
    <div class="form form--authentication" v-if="!resetPasswordSuccess">
      <img src="@/assets/img/logo.svg" alt="Fybrr logo" class="form__logo" />
      <h5>Set new password</h5>
      <p class="mb-5">
        Your new password must be different to previously used password
      </p>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="form-group">
        <label for="new_password">New Password</label>
        <input
          type="password"
          class="form-control"
          id="new_password"
          placeholder="enter new password"
          v-model="resetData.password"
        />
      </div>
      <div class="form-group">
        <label for="confirm_password">Confirm Password</label>
        <input
          type="password"
          class="form-control"
          id="confirm_password"
          placeholder="confirm password"
          v-model="resetData.confirm_password"
        />
      </div>
      <Button
        :loading="loading"
        :inactive="!allFilled"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="resetButton"
        btnClass="btn-block mb-3"
        @click="handleResetPassword"
      >
        <!-- :inactive="!validate" -->
        <span class="">Reset Password</span></Button
      >
      <div class="text-center font-weight-bold">
        <router-link to="/login">Back to Login</router-link>
      </div>
    </div>
    <div v-else class="text-center form--reset_password">
      <div class="icon-with-border">
        <svg
          class="icon icon-mailbox"
          width="50px"
          height="50px"
          viewBox="0 0 24 24"
        >
          <use xlink:href="/sprite.svg#icon-check"></use>
        </svg>
      </div>
      <h3>Password reset</h3>
      <p class="my-4">
        Your password has been successfully reset. <br />
        Click below to login
      </p>
      <div class="text-center font-weight-bold">
        <router-link to="/login"
          ><svg class="icon" width="15px" height="15px" viewBox="0 0 20 20">
            <use xlink:href="/sprite.svg#icon-arrow-left"></use>
          </svg>
          <a class="ml-1"></a>Back to log in</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'

export default {
  name: 'Reset Password',
  components: { Feedback, Button },
  data() {
    return {
      error: '',
      loading: false,
      resetData: {
        password: '',
        confirm_password: '',
        token: this.$route.params.token,
      },
      resetPasswordSuccess: false,
    }
  },
  methods: {
    handleResetPassword() {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleResetPassword', this.resetData)
        .then(() => {
          this.loading = false
          this.resetPasswordSuccess = true
        })
        .catch(error => {
          this.loading = false
          let { data } = error.response
          this.error = Object.prototype.hasOwnProperty.call(data, 'message')
            ? data.message
            : data.error
        })
    },
    removeError() {
      this.error = ''
    },
  },
  computed: {
    allFilled() {
      const { token, password, confirm_password } = this.resetData
      return (
        password !== '' &&
        confirm_password !== '' &&
        token !== '' &&
        confirm_password === password
      )
    },
  },
}
</script>
