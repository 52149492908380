import * as types from './types'
import {
  getAllUsersStores,
  deleteUserStore,
  createStore,
} from '@/services/store'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetUsersStores({ commit }, projectSlug) {
    commit(types.STORES_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllUsersStores(projectSlug)
        .then(response => {
          commit(types.STORES, response.data.data)
          commit(types.STORES_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.STORES_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
  handleDeleteUserStore({ commit }, storeId) {
    commit(types.DELETE_STORE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteUserStore(storeId)
        .then(response => {
          commit(types.DELETE_STORE, response.data.data)
          commit(types.DELETE_STORE_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_STORE_STATUS, 'FAILED')
          reject(error)
        })
    })
  },

  async handleCreateUserStore({ commit }, params) {
    commit(types.CREATE_STORE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      createStore(params)
        .then(response => {
          commit(types.CREATE_STORE, response.data.data)
          commit(types.CREATE_STORE_STATUS, response.data.message)
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.CREATE_STORE_STATUS, 'FAILED')
          reject(error)
        })
    })
  },
}
