import workflowMutations from './mutations'
import workflowActions from './actions'
export default {
  state: {
    tasks: [],
    tasksStatus: 'PENDING',
    task: [],
    taskStatus: 'PENDING',
    updateTaskStatus: 'PENDING',
    updateTask: {},
    assignMember: {},
    assignMemberStatus: 'PENDING',
    deleteMemberStatus: 'PENDING',
    deleteMember: {},
    deleteTask: {},
    deleteTaskStatus: 'PENDING',
  },
  actions: { ...workflowActions },
  mutations: { ...workflowMutations },
  getters: {
    getTasks(state) {
      return state.tasks
    },
    getTasksStatus(state) {
      return state.tasksStatus
    },
    getTask(state) {
      return state.task
    },
    getTaskStatus(state) {
      return state.taskStatus
    },
    getUpdateTaskStatus(state) {
      return state.updateTaskStatus
    },
    getUpdateTask(state) {
      return state.updateTask
    },
    getAssignMemberStatus(state) {
      return state.assignMemberStatus
    },
    getDeleteMemberStatus(state) {
      return state.deleteMemberStatus
    },
    getDeleteMember(state) {
      return state.deleteMember
    },
    getDeleteTaskStatus(state) {
      return state.deleteTaskStatus
    },
    getDeleteTask(state) {
      return state.deleteTask
    },
  },
}
