<template>
  <th class="font-weight-light">{{ reposToIntegrate.name }}</th>
  <td>{{ this.$route.params.platform }}</td>
  <td>{{ moment(reposToIntegrate.created_at).format('LLL') }}</td>
  <td>
    <Button
      :loading="loading"
      :inactive="false"
      :variant="'outline-grey'"
      :size="'md'"
      type="submit"
      name="submit"
      btnClass="px-5"
      @click="importRepo(reposToIntegrate, this.$route.params.platform)"
    >
      <span class="font-weight-normal">Import</span>
    </Button>
  </td>
</template>
<script>
import moment from 'moment'
import Button from '@/components/ui/Button'

export default {
  name: 'SingleRepositoryRow',
  components: { Button },
  props: {
    reposToIntegrate: {
      type: Object,
    },
    projectID: {
      type: Number,
    },
    userSlug: {
      type: String,
    },
  },
  data() {
    return {
      moment: moment,
      loading: false,
    }
  },
  emits: ['toggleResult'],
  methods: {
    toggleDeleteModal(value) {
      this.deleteModalIsVisible = value
    },
    importRepo(platformObj, platformName) {
      this.loading = true
      let bodyData = {}

      const { name, description, id, ssh_url_to_repo, http_url_to_repo } =
        platformObj

      bodyData = {
        name,
        platform: this.$route.params.platform,
        project_id: this.projectID,
        description,
        platform_repo_id: id,
        ssh_url: ssh_url_to_repo,
        http_url: http_url_to_repo,
      }

      //If the platform is gitlab
      if (platformName === 'gitlab') {
        const { ssh_url_to_repo, http_url_to_repo } = platformObj

        bodyData = {
          ...bodyData,
          ssh_url: ssh_url_to_repo,
          http_url: http_url_to_repo,
        }
      } else if (platformName === 'github') {
        const { ssh_url_to_repo, html_url } = platformObj

        bodyData = {
          ...bodyData,
          ssh_url: ssh_url_to_repo,
          http_url: html_url,
        }
      }

      //Setup the object params
      let params = { slug: this.userSlug, paramsObj: bodyData }
      //Send the data to the dispatch function
      this.$store
        .dispatch('handleImportRepo', params)
        .then(() => {
          setTimeout(this.redirect, 2000)
        })
        .catch(error => {
          this.loading = false
          this.$emit('toggleResult', {
            errorType: 'error',
            message: error.message,
          })
        })
    },
    redirect() {
      this.loading = false

      //redirect back to the repo page
      this.$router.push({
        name: 'Repository',
        params: { uid: this.$route.params.uid },
      })
    },
  },
  mounted() {},
}
</script>

<style scoped>
.menu__items {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.menu__items:hover {
  color: #447ffc;
}
</style>
