import { createStore } from 'vuex'
import auth from './modules/auth'
import workspace from './modules/workspace'
import workflow from './modules/workflow'
import project from './modules/project'
import team from './modules/team'
import falcon from './modules/falcon'
import website from './modules/website'
import shop from './modules/store'
import member from './modules/member'
import repository from './modules/repository'
import deployment from './modules/deployment'
import integration from './modules/integration'
import command from './modules/command'
import transaction from './modules/transaction'
import website_builder from './modules/website-builder'

const store = createStore({
  state() {
    return {}
  },
  getters: {},
  mutations: {},
  actions: {
    fetchAllAuthStates({ dispatch }, businessSlug) {
      dispatch('handleGetUsersProjects', businessSlug)
      dispatch('handleGetAllTeamMembers', businessSlug)
      // dispatch('handleGetUsersStores')
      dispatch('handleGetUsersWebsites', businessSlug)
      dispatch('handleGetAllTeamMembers', businessSlug)
      dispatch('handleGetInvitations', businessSlug)
    },
  },
  modules: {
    auth,
    workspace,
    workflow,
    project,
    team,
    falcon,
    website,
    shop,
    member,
    repository,
    deployment,
    command,
    integration,
    transaction,
    website_builder,
  },
})

export default store
