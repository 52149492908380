<template>
  <main class="page-dashboard mt-4">
    <div class="page-dashboard-cards page-dashboard-analytics">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-9">
          <div class="card card-blue">
            <div class="card-body">
              <h6>Manage Project</h6>
              <p class="text-xs font-weight-normal my-2">
                Continue working on your project
              </p>
              <div class="d-flex align-items-center flex-wrap">
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'light-transparent'"
                  :size="'sm'"
                  type="submit"
                  name="createProject"
                  btnClass="mt-3 mr-2"
                  @click="$router.push(`/dashboard/project/${uid}/builder`)"
                >
                  <span class="">VIEW</span></Button
                >
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'light-cyan'"
                  :size="'sm'"
                  type="submit"
                  name="createProject"
                  btnClass="mt-3"
                  @click="''"
                >
                  <span class="">UPGRADE</span></Button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-9">
          <AnalyticsCard
            color="white"
            :total="stores"
            :active="stores"
            :link="`/dashboard/project/${uid}/store`"
            buttonColor="primary"
          >
            <div class="d-flex justify-content-between">
              <p class="card-subtitle font-weight-bold">Online stores</p>
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-d-bag"></use>
              </svg>
            </div>
          </AnalyticsCard>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-9">
          <AnalyticsCard
            color="white"
            :total="websites"
            :active="websites"
            :link="`/dashboard/project/${uid}/builder`"
            buttonColor="primary"
          >
            <div class="d-flex justify-content-between">
              <p class="card-subtitle font-weight-bold">Websites</p>
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-d-activity"></use>
              </svg>
            </div>
          </AnalyticsCard>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-9">
          <AnalyticsCard
            color="white"
            :total="falcons"
            :active="falcons"
            :link="`/dashboard/project/${uid}/hosting`"
            buttonColor="primary"
          >
            <div class="d-flex justify-content-between">
              <p class="card-subtitle font-weight-bold">Hosting</p>
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-d-discovery"></use>
              </svg>
            </div>
          </AnalyticsCard>
        </div>
      </div>
    </div>
    <div class="page-dashboard-cards">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="card card-white fancy-box-full">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-subtitle font-weight-bold">Collaborators</p>
                <div class="buttons">
                  <a href="#" @click.prevent="addNewMember(true)"> + ADD </a>
                  &nbsp;&nbsp;&nbsp;
                  <router-link :to="`/dashboard/project/${uid}/collaborators`">
                    SEE ALL
                  </router-link>
                </div>
              </div>
              <div class="mt-4">
                <h3>{{ project?.members.length ?? '' }}</h3>
              </div>
              <div v-if="project?.members.length > 0">
                <div
                  class="page-dashboard-single__card mt-3"
                  v-for="(member, index) in project?.members.slice(0, 5)"
                  :key="index"
                >
                  <p class="font-weight-normal">
                    {{ member?.profile?.first }} {{ member?.profile?.last }}
                    <span v-if="member.owner == true"> | Owner</span>
                  </p>
                  <p>Joined {{ moment(member?.created_at).format('LLL') }}</p>
                  <a
                    v-if="showAdminButton"
                    href="#"
                    class="text-danger"
                    @click.prevent="
                      toggleModal(
                        true,
                        member?.id,
                        member?.profile?.first + ' ' + member?.profile?.last
                      )
                    "
                    >Remove Member</a
                  >
                </div>
              </div>
              <div v-else>No collaborators on this project</div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-6">
          <ServiceCard
            :size="'full'"
            :image="'/assets/img/dashboard/singleProject.png'"
          >
            <div class="fancy-box-footer fancy-box-footer2 text-left">
              <h5>Hire a Developer from Fybrr</h5>
              <p class="w-50">
                Fybrr offers a subscription based model for you to have your
                projects built, developed, maintained and supported all on one
                platform.
              </p>
              <div class="d-flex">
                <a href="tel:+2348134223442">
                  <Button
                    :loading="false"
                    :inactive="false"
                    :variant="'light-transparent'"
                    :size="'md'"
                    type="submit"
                    name="createProject"
                    btnClass="mt-3"
                    @click="''"
                  >
                    <svg width="15px" height="15px" viewBox="0 0 15 15">
                      <use xlink:href="/sprite.svg#icon-d-call2"></use>
                    </svg>
                    <span class="ml-3">Call Us</span></Button
                  >
                </a>
                <Button
                  :loading="false"
                  :inactive="false"
                  :variant="'outline'"
                  :size="'md'"
                  type="submit"
                  name="createProject"
                  btnClass="ml-3 mt-3 text-white"
                  @click="''"
                >
                  <span>Learn how it works</span>
                  <svg width="24px" height="24px" viewBox="0 0 24 24">
                    <use xlink:href="/sprite.svg#icon-angle-right"></use>
                  </svg>
                </Button>
              </div>
            </div>
          </ServiceCard>
        </div>
      </div>
    </div>
    <Modal
      :isVisible="modalIsVisible"
      @toggleModal="closeModal"
      :memberId="memberId"
      :memberName="memberName"
    ></Modal>
    <MemberModal
      :showModal="showModal"
      @toggleModal="closeAddModal"
      :teamMembers="teamMembers"
      :projectId="projectId"
      :projectUid="uid"
    ></MemberModal>
    <OverlayLoading v-if="loaderIsVisible" />
  </main>
</template>
<script>
import AnalyticsCard from '@/components/dashboard/overview/AnalyticsCard'
import ServiceCard from '@/components/dashboard/overview/ServiceCard'
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Modal from '@/components/dashboard/project/DeleteMemberModal'
import MemberModal from '@/components/dashboard/project/AddMemberModal'

export default {
  name: 'SingleProject',
  components: {
    AnalyticsCard,
    Button,
    ServiceCard,
    Modal,
    MemberModal,
    OverlayLoading,
  },
  data() {
    return {
      falcons: 0,
      websites: 0,
      stores: 0,
      loaderIsVisible: false,
      uid: this.$route.params.uid,
      project: null,
      projectId: 0,
      moment: moment,
      modalIsVisible: false,
      memberId: 0,
      memberName: '',
      showModal: false,
      teamMembers: [],
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  methods: {
    ...mapActions([
      'handleGetUsersFalcons',
      'handleGetProjectWebsites',
      'handleGetUsersStores',
      'handleGetOneProject',
      'handleGetAllTeamMembers',
      'handleGetUsersWorkspaces',
    ]),
    paginate(page) {
      this.currentPage = page
    },
    toggleModal(value, id, name) {
      this.modalIsVisible = value
      this.memberId = id
      this.memberName = name
    },
    closeModal(value) {
      this.modalIsVisible = value
    },
    closeAddModal(value) {
      this.showModal = value
    },
    addNewMember(value) {
      this.showModal = value
    },
  },
  computed: {
    ...mapGetters({
      getUserInfoStatus: 'getUserInfoStatus',
      getFalcons: 'getFalcons',
      getWebsites: 'getProjectWebsites',
      getStores: 'getStores',
      projects: 'projects',
      getProject: 'getProject',
      presentBusiness: 'getBusinessInfo',
      getTeamMembers: 'getTeamMembers',
      getSelectedWorkspace: 'getSelectedWorkspace',
      getProjectStatus: 'getProjectStatus',
      getBusinessInfo: 'getBusinessInfo',
    }),
  },
  async created() {
    const userInfo = await this.handleGetUsersWorkspaces()

    if (userInfo.status === 200) {
      let slug = this.getSelectedWorkspace.slug

      //Call other dispatch functions
      await this.handleGetOneProject(this.$route.params.uid)
      await this.handleGetUsersFalcons(this.$route.params.uid)
      await this.handleGetProjectWebsites(this.$route.params.uid)
      await this.handleGetUsersStores(this.$route.params.uid)
      await this.handleGetAllTeamMembers(slug)
    }
  },
  watch: {
    getProject: {
      handler(value) {
        this.project = value
        this.projectId = this.project.id
      },
    },
    getFalcons: {
      handler(value) {
        let allFalcons = value.data
        let tempFalcons = []

        for (let i = 0; i < allFalcons.length; i++) {
          if (allFalcons[i].pilot_id !== null) {
            tempFalcons.push(allFalcons[i])
          }
        }

        this.falcons = tempFalcons.length
      },
    },
    getProjectWebsites: {
      handler(value) {
        this.websites = value.length
      },
    },
    getStores: {
      handler(value) {
        this.stores = value.length
      },
    },
    getTeamMembers: {
      handler(value) {
        this.teamMembers = value
        this.loaderIsVisible = false
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
}
</script>
