import * as types from './types'
import {
  addCommands,
  fetchAllCommands,
  updateCommands,
} from '@/services/command'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetAllCommands({ commit }, commandData) {
    commit(types.COMMANDS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      fetchAllCommands(commandData)
        .then(response => {
          commit(types.COMMANDS, response.data.data)
          commit(types.COMMANDS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.COMMANDS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleAddCommands({ commit }, params) {
    commit(types.ADD_COMMANDS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addCommands(params)
        .then(response => {
          commit(types.ADD_COMMANDS, response.data)
          commit(types.ADD_COMMANDS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ADD_COMMANDS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleUpdateCommands({ commit }, params) {
    commit(types.UPDATE_COMMANDS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      updateCommands(params)
        .then(response => {
          commit(types.UPDATE_COMMANDS, response.data.data)
          commit(types.UPDATE_COMMANDS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.UPDATE_COMMANDS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
