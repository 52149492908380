<template>
  <th class="font-weight-light">{{ singleDeployment.server.name }}</th>
  <td>{{ singleDeployment.branch }}</td>
  <td>{{ singleDeployment.deploy_path }}</td>
  <td>{{ moment(singleDeployment.created_at).format('LLL') }}</td>
  <td>
    <div class="btn-group dropup p-0" style="offset: auto; margin-left: 5px">
      <button
        type="button"
        class="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        More
      </button>

      <ul class="dropdown-menu">
        <a
          href="#"
          v-if="businessID === userID"
          @click="toggleDeleteModal(true)"
          class="menu__items"
          >Delete</a
        >
        <router-link
          :to="`/dashboard/project/${this.$route.params.uid}/${singleDeployment.id}/commands/${projectId}?tab=hosting`"
          class="menu__items"
        >
          Build Commands</router-link
        >
        <router-link
          :to="`/dashboard/project/${this.$route.params.uid}/variables/${singleDeployment.slug}/${singleDeployment.id}?tab=hosting`"
          class="menu__items"
        >
          Environment Variables</router-link
        >
      </ul>
    </div>
  </td>

  <!-- delete deployment modal -->
  <DeleteDeploymentModal
    :isVisible="deleteModalIsVisible"
    @toggleDeleteModal="toggleDeleteModal"
    :deploymentName="singleDeployment.server.name"
    :slug="singleDeployment.slug"
  ></DeleteDeploymentModal>
</template>
<script>
import DeleteDeploymentModal from '@/components/dashboard/project/DeleteDeploymentModal'
import moment from 'moment'

export default {
  name: 'SingleHosting-DeploymentRow',
  components: { DeleteDeploymentModal },
  props: {
    singleDeployment: {
      type: Object,
    },
    projectRepos: {
      type: Array,
    },
    falconsLogs: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
    businessID: {
      type: Number,
    },
    userID: {
      type: Number,
    },
  },
  data() {
    return {
      moment: moment,
      deleteModalIsVisible: false,
    }
  },
  methods: {
    toggleDeleteModal(value) {
      this.deleteModalIsVisible = value
    },
  },
  mounted() {},
}
</script>

<style scoped>
.menu__items {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.menu__items:hover {
  color: #447ffc;
}
</style>
