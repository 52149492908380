<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >

      <div class="alert alert-success py-2" role="alert" v-if="success">
        {{ success }}
      </div>

      <h6>Add a new SSH user</h6>
      <!-- <p class="description">You can change this later</p> -->
      <div class="form-group">
        <label for="project_name">Name</label>
        <input
          type="text"
          class="form-control"
          id="project_name"
          autocomplete="false"
          placeholder="Give this user / key a name"
          maxlength="50"
          v-model="ssh.name"
        />
      </div>

      <div class="form-group">
        <label for="ssh_key">SSH Key</label>
        <textarea
          name="ssh_key"
          id="ssh_key"
          class="form-control"
          cols="30"
          rows="10"
          placeholder="Paste public SSH Key here"
          v-model="ssh.access"
        ></textarea>
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="addSSH"
          btnClass="px-5"
          @click.prevent="addSSH"
        >
          <span class="">Create SSH User</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'CreateSSHKey',
  components: { Button, Feedback },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    projectID: {
      type: Number,
      required: true,
    },
    falconSlug: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: '',
      error: '',
      ssh: {
        name: '',
        access: '',
        falcon_id: this.$route.params.falconid,
      },
    }
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleAddUser', value)
      },
    },
  },
  methods: {
    ...mapActions(['handleAddAccess', 'handleGetAccessEntries']),

    async addSSH() {
      this.loading = true
      this.error = ''

      const params = { ...this.ssh, project_id: this.projectID }

      await this.handleAddAccess(params)
        .then(async response => {
          this.success = response.data.message

          //reload the list of ssh keys
          await this.handleGetAccessEntries(this.falconSlug)

          //remove the modal
          setTimeout(() => {
            this.show = false
            this.loading = false
          }, 1500)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },

    removeError() {
      this.error = ''
    },
    allFilled() {
      const { name, access } = this.ssh
      return name !== '' && access !== ''
    },
  },
}
</script>
