<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="website" />
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="card project-create-card rounded-default card-dashed">
            <h6>Create new website</h6>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="createWebsite"
              btnClass="mt-3"
              @click="$router.push({ name: 'CreateWebsite' })"
            >
              <span class="">create new website</span></Button
            >
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(website, index) in paginateData"
          :key="index"
        >
          <SingleWebsiteCard
            :websiteDetails="website"
            :showAdminButton="showAdminButton"
          ></SingleWebsiteCard>
        </div>
      </div>
      <nav class="page-dashboard__pagination">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import SingleWebsiteCard from '@/components/dashboard/project/SingleWebsiteCard'
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import { mapActions, mapGetters } from 'vuex'
import { Paginator } from 'array-paginator'

export default {
  name: 'DashboardWebsiteBuilder',
  components: { ProjectNav, SingleWebsiteCard, Button },
  data() {
    return {
      websites: [],
      projectUID: this.$route.params,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
      currentPage: 1,
    }
  },
  methods: {
    ...mapActions(['handleGetUsersWorkspaces']),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    toggleModal(value, id, name) {
      this.modalIsVisible = value
      this.memberId = id
      this.memberName = name
    },
    closeModal(value) {
      this.modalIsVisible = value
    },
    closeAddModal(value) {
      this.showModal = value
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
  },
  computed: {
    ...mapGetters([
      'getProjectWebsitesStatus',
      'getProjectWebsites',
      'getProjectStatus',
      'getProject',
      'getUserInfoStatus',
      'getBusinessInfo',
    ]),
  },

  watch: {
    getProjectWebsites: {
      handler(value) {
        //Paginate the data manually
        this.paginator = new Paginator(value, 8, 1)

        //Set the first page as the data
        this.paginateData = this.paginator.first()
        this.totalPages = this.paginator.totalPages
      },
    },
    getProjectWebsitesStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
}
</script>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
