<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav :page="tabShowing" />

      <div class="hosting-table">
        <div class="pb-3">
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="submit"
            btnClass="px-5 mt-4"
            @click="goBack"
          >
            <span class="font-weight-normal">Go Back</span>
          </Button>
        </div>
        <div class="py-2">
          <div class="alert alert-success" v-if="success" role="alert">
            {{ success }}
          </div>
          <div class="alert alert-danger" v-if="error" role="alert">
            {{ error }}
          </div>
        </div>
        <div class="row">
          <div v-if="commands !== null">
            <h3>
              <strong>{{ commands?.server.name }} Server</strong> -
              <small
                >build commands for deployment via SSH on
                {{ commands?.server.ip }}
              </small>
            </h3>

            <p class="my-3">
              Last updated:
              {{ moment(commands?.updated_at).format('LLL') }}
            </p>

            <div class="table-responsive">
              <textarea
                class="commands"
                cols="120"
                rows="20"
                style="resize: none"
                v-model="commands.commands"
                placeholder="Write a new set of build commands to run on your server after successful deployment... e.g composer install"
              ></textarea>

              <div>
                <Button
                  :loading="loading"
                  :inactive="false"
                  :variant="'primary'"
                  :size="'md'"
                  type="submit"
                  class="p-3"
                  @click="saveCommands"
                >
                  <span class=""> Save Commands</span></Button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Button from '@/components/ui/Button'

export default {
  name: 'ViewCommand',
  components: {
    ProjectNav,
    Button,
  },
  data() {
    return {
      commands: null,
      moment: moment,
      loading: false,
      tabShowing: '',
      success: '',
      error: '',
    }
  },
  computed: {
    ...mapGetters([
      'getCommands',
      'getCommandsStatus',
      'getBusinessInfo',
      'getUserInfoStatus',
    ]),
  },
  methods: {
    ...mapActions([
      'handleGetAllCommands',
      'handleAddCommands',
      'handleUpdateCommands',
    ]),

    goBack() {
      history.back()
    },

    async createNewCommands() {
      //Set the new command data
      let commandData = {
        project_id: this.$route.params.project_id,
        deployment_id: this.$route.params.deployment_id,
        commands: 'pwd',
        user_id: this.getBusinessInfo.user_id,
      }

      await this.handleAddCommands(commandData)
    },

    async fetchCommands() {
      let commandData = {
        deployment_id: this.$route.params.deployment_id,
        project_id: this.$route.params.project_id,
      }

      await this.handleGetAllCommands(commandData)
        .then(response => {
          this.commands = response.data.data
        })
        .catch(error => {
          this.error = error.response.data.message
        })
    },

    async saveCommands() {
      this.loading = true
      this.success = ''
      this.error = ''

      const commandData = {
        project_id: this.$route.params.project_id,
        deployment_id: this.$route.params.deployment_id,
        commands: this.commands.commands,
        user_id: this.getBusinessInfo.user_id,
      }
      //Array to pass to the service
      const slug = this.commands.slug

      const updateData = {
        slug,
        commandData,
      }

      await this.handleUpdateCommands(updateData)
        .then(response => {
          this.success = response.data.message
          this.showUpdateResult('success')
        })
        .catch(error => {
          console.log(error)
          this.error = error.response.data.message
          this.showUpdateResult('error')
        })
    },

    showUpdateResult(value) {
      if (value === 'success') {
        setTimeout(() => {
          this.loading = false
          this.success = ''
        }, 2500)
      } else {
        setTimeout(() => {
          this.loading = false
          this.error = ''
        }, 2500)
      }
    },
  },
  watch: {
    getCommandsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          if (this.getCommands?.commands.length > 0) {
            this.commands = this.getCommands
          } else {
            this.createNewCommands()
          }
        }
      },
    },
  },
  async created() {
    this.fetchCommands()

    //show certain elements if the query is hosting
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('tab')

    if (myParam === 'hosting') {
      this.tabShowing = 'hosting'
    } else {
      this.tabShowing = 'deployment'
    }
  },
}
</script>

<style scoped>
.commands {
  background: #000;
  color: #fff;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
