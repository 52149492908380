<template>
  <b-modal
    class="page-modal"
    size="lg"
    v-model="isShow"
    hide-footer
    hide-header
  >
    <div>
      <h6 class="mb-3">Edit Page MetaData</h6>
      <div>
        <div class="form-group mt-4">
          <label for="title">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            v-model="pageData.title"
            placeholder="title"
            autocomplete="off"
          />
        </div>
        <div class="form-group mt-4">
          <label for="description">Description</label>
          <textarea
            type="text"
            class="form-control"
            v-model="pageData.description"
            id="description"
            name="description"
            autocomplete="off"
          ></textarea>
        </div>
        <div class="form-group mt-4">
          <label for="keywords">Keywords</label>
          <input
            type="text"
            class="form-control"
            id="keywords"
            name="keywords"
            v-model="pageData.keywords"
            placeholder="keywords"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <div
          class="ml-3 font-weight-normal cancel-btn cursor-pointer"
          @click="$emit('closeModal')"
        >
          Cancel
        </div>
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="editPage()"
        >
          <!-- :inactive="!validate" -->
          <span class="">Apply</span></Button
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'

export default {
  name: 'CodeModal',
  components: { Button },
  props: {
    id: String,
    pageName: String,
    show: Boolean,
  },
  emits: ['closeModal', 'editPageAction'],
  data() {
    return {
      isShow: false,
      pageData: {
        title: '',
        description: '',
        keywords: '',
      },
    }
  },
  methods: {
    editPage() {
      this.$emit('closeModal')
      this.$emit('editPageAction', {
        pageData: this.pageData,
        pageName: this.pageName,
      })
    },
  },
  mounted() {
    this.pageData.title = this.pageName
  },
  watch: {
    pageName: {
      handler(value) {
        this.pageData.title = value
      },
    },
    isShow: {
      handler(value) {
        if (!value) {
          this.$emit('closeModal')
        }
      },
    },
    show: {
      handler(value) {
        this.isShow = value
      },
    },
  },
}
</script>
<style lang="scss">
.page-modal .modal-content {
  color: #fff;
  background-color: #000000;
}
</style>
