<template>
  <div class="col-lg-4 col-md-12 mb-4 card-container">
    <div class="card h-100">
      <div class="card-body p-0">
        <div class="card-content">
          <p class="card-title fs_lh-16_20">{{ pricingDetails.name }}</p>
          <h3 class="my-2 fs_lh-36_46">{{ pricingDetails.price }}</h3>
          <span class="text-muted fs_lh-14_18">Monthly</span>
          <p class="card-text text-black fs_lh-14_18">
            {{ pricingDetails.description }}
          </p>
          <p class="mt-5 mb-3 fs_lh-14_18">
            {{ pricingDetails.name }} <span class="text-muted">includes</span>
          </p>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item d-flex align-items-center fs_lh-14_23"
              v-for="(detail, index) in pricingDetails.details"
              :key="index"
            >
              <svg class="icon" width="16px" height="16px">
                <use xlink:href="/sprite.svg#icon-circle-check"></use></svg
              ><span class="ml-2">{{ detail }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PricingPageCard',
  props: {
    pricingDetails: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.card-container {
  .card {
    border: 1px solid rgba(99, 122, 135, 0.24);
    .card-text {
      color: #000000;
    }
    .card-title {
      color: rgba(68, 127, 252, 1);
    }
    .card-content .list-group li {
      list-style-type: none;
      .icon {
        color: rgba(68, 127, 252, 1);
      }
    }
  }
}
</style>
