<template>
  <DashboardLayout>
    <div class="page__body">
      <div class="page__head page__back">
        <div class="mr-2">
          <router-link :to="''">
            <a @click="$router.back()">
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-angle-left"></use>
              </svg>
            </a>
          </router-link>
        </div>
        <div>
          <h6>{{ project?.name }}</h6>
          <p>{{ project?.description }}</p>
        </div>
      </div>
      <slot />
    </div>
  </DashboardLayout>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import DashboardLayout from '@/components/layouts/dashboard.vue'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { DashboardLayout, OverlayLoading },
  data() {
    return {
      uid: this.$route.params.uid,
      project: null,
      loaderIsVisible: true,
    }
  },
  methods: {
    ...mapActions(['handleGetOneProject']),
  },
  computed: {
    ...mapGetters({
      getProject: 'getProject',
    }),
  },
  mounted() {
    if (this.getUserInfoStatus === 'COMPLETED') {
      this.handleGetOneProject(this.$route.params.uid)
      this.loaderIsVisible = false
    }
  },
  watch: {
    getProject: {
      handler(value) {
        this.project = value
        this.loaderIsVisible = false
      },
    },
  },
  // unmounted() {
  //   this.project = null
  // },
}
</script>
