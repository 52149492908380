export const PROJECTS = 'PROJECTS'
export const PROJECTS_STATUS = 'PROJECTS_STATUS'
export const PROJECT = 'PROJECT'
export const PROJECT_STATUS = 'PROJECT_STATUS'
export const PROJECT_CATEGORY = 'PROJECT_CATEGORY'
export const ADD_PROJECT = 'ADD_PROJECT'
export const PROJECTS_SUMMARY = 'PROJECTS_SUMMARY'
export const PROJECTS_ARRAY = 'PROJECTS_ARRAY'
export const DELETE_STATUS = 'DELETE_STATUS'
export const DELETE = 'DELETE'
export const PROJECT_MEMBERS = 'PROJECT_MEMBERS'
export const PROJECT_MEMBERS_STATUS = 'PROJECT_MEMBERS_STATUS'
export const DELETE_PROJECT_MEMBER = 'DELETE_PROJECT_MEMBER'
export const DELETE_PROJECT_MEMBER_STATUS = 'DELETE_PROJECT_MEMBER_STATUS'
export const INVITE_COLLABORATOR_STATUS = 'INVITE_COLLABORATOR_STATUS'
export const INVITE_COLLABORATOR = 'INVITE_COLLABORATOR'
export const TRANSFER_OWNERSHIP_STATUS = 'TRANSFER_OWNERSHIP_STATUS'
export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP'
export const INVITATIONS_STATUS = 'INVITATIONS_STATUS'
export const INVITATIONS = 'INVITATIONS'
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
