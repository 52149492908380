import http from '../../config/axios'

export const falconUrls = {
  GET_ALL_URL: 'falcons',
  GET_FALCON_LOGS_URL: 'projects',
  GET_PILOT_TEMPLATES: 'resources/pilot-templates',
  GET_PILOT_DETAILS: 'resources/pilots',
  PROVISION_PILOT: 'falcons/pilot',
  ADD_ACCESS: 'falcons/access/add',
  ACCESS_URL: 'falcons/access',
}

/** getAllUsersFalcons */
const getAllUsersFalcons = async slug => {
  return await http.get(`${falconUrls.GET_ALL_URL}/${slug}/all`)
}

/** getFalconLogs */
const getFalconsLogs = async projectUId => {
  return await http.get(
    `${falconUrls.GET_FALCON_LOGS_URL}/${projectUId}/activities`
  )
}

const pilotTemplates = async () => {
  return await http.get(`${falconUrls.GET_PILOT_TEMPLATES}`)
}

const pilotDetails = async () => {
  return await http.get(`${falconUrls.GET_PILOT_DETAILS}`)
}

const provisionPilot = async pilotID => {
  return await http.get(`${falconUrls.PROVISION_PILOT}/${pilotID}/provision`)
}

const rebootPilot = async pilotID => {
  return await http.get(`${falconUrls.PROVISION_PILOT}/${pilotID}/reboot`)
}

const turnOnPilot = async pilotID => {
  return await http.get(`${falconUrls.PROVISION_PILOT}/${pilotID}/on`)
}

const turnOffPilot = async pilotID => {
  return await http.get(`${falconUrls.PROVISION_PILOT}/${pilotID}/off`)
}

const deletePilot = async slug => {
  return await http.delete(`${falconUrls.GET_ALL_URL}/${slug}`)
}

const addAccess = async params => {
  return await http.post(`${falconUrls.ADD_ACCESS}`, params)
}

const addFalcon = async params => {
  return await http.post(
    `${falconUrls.GET_ALL_URL}/${params.userSlug}/create`,
    params.bodyData
  )
}

const fetchProjectWithSSH = async projectUID => {
  return await http.get(`${falconUrls.GET_FALCON_LOGS_URL}/${projectUID}`)
}

const deleteAccess = async slug => {
  return await http.delete(`${falconUrls.ACCESS_URL}/${slug}`)
}

const getAccessEntries = async slug => {
  return await http.get(`${falconUrls.ACCESS_URL}/${slug}`)
}

export {
  getAllUsersFalcons,
  getFalconsLogs,
  pilotTemplates,
  pilotDetails,
  provisionPilot,
  rebootPilot,
  deletePilot,
  turnOnPilot,
  turnOffPilot,
  addAccess,
  deleteAccess,
  getAccessEntries,
  addFalcon,
  fetchProjectWithSSH,
}
