<template>
  <th class="font-weight-light">{{ singleServer.name }}</th>
  <td>{{ singleServer.hostname }}</td>
  <td>
    <span v-if="singleServer.ip !== null">{{ singleServer.ip }}</span>
    <span v-else>Not Provisioned</span>
  </td>
  <td class="text-center">
    <span :class="`badge badge--${getButtonClass(singleServer.active)} px-3`">
      {{ this.getButtonClass(singleServer.active) }}
    </span>
  </td>
  <td>{{ moment(singleServer.created_at).format('LLL') }}</td>
  <td>
    <div class="btn-group dropup p-0" style="offset: auto; margin-left: 5px">
      <button
        type="button"
        class="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        More
      </button>

      <ul class="dropdown-menu">
        <router-link
          :to="`/dashboard/project/${this.$route.params.uid}/${singleServer.id}/hosting/${singleServer.slug}`"
          class="menu__items"
          v-if="singleServer.active === 1"
        >
          Manage SSH Users</router-link
        >

        <router-link
          :to="`/dashboard/project/hosting/${this.$route.params.uid}/${singleServer.slug}/${singleServer.id}/${singleServer.name}`"
          class="menu__items"
        >
          Deployments</router-link
        >

        <a
          v-if="userID === businessID"
          href="#"
          @click="deletePilot(singleServer.slug)"
          class="menu__items"
          >Delete</a
        >
      </ul>
    </div>
  </td>
  <!-- pilot actions modal -->
  <PilotActionsModal
    :isVisible="pilotActionIsVisible"
    @togglePilotActions="togglePilotActions"
    :modalObj="modalObj"
  ></PilotActionsModal>
</template>
<script>
import PilotActionsModal from '@/components/dashboard/project/PilotActionsModal'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'ServerConnectionsRow',
  components: { PilotActionsModal },
  props: {
    singleServer: {
      type: Object,
    },
    userID: {
      type: Number,
    },
    businessID: {
      type: Number,
    },
  },
  data() {
    return {
      moment: moment,
      pilotActionIsVisible: false,
      modalObj: {},
    }
  },
  methods: {
    ...mapActions([
      'handleRenewPilot',
      'handleDeleteFalcon',
      'handleProvisionFalcon',
      'handleRebootFalcon',
      'handlePowerOnFalcon',
      'handlePowerOffFalcon',
    ]),
    togglePilotActions(value) {
      this.pilotActionIsVisible = value
    },
    getButtonClass(status) {
      switch (status) {
        case 1:
          return 'active'
        case 0:
          return 'inactive'
        default:
          return 'active'
      }
    },

    renewPilot(id) {
      this.modalObj = {
        title: 'Renew Pilot',
        label: 'Are you sure you want to renew this pilot?',
        buttonText: 'Renew',
        type: 'renew pilot',
        action: this.handleRenewPilot,
        params: { pilot_id: id },
      }

      //toggle the modal
      this.togglePilotActions(true)
    },

    deletePilot(slug) {
      this.modalObj = {
        title: 'Delete Pilot',
        label: 'Are you sure you want to delete this pilot?',
        buttonText: 'Delete',
        type: 'delete pilot',
        action: this.handleDeleteFalcon,
        params: slug,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },

    provisionPilot(id) {
      this.modalObj = {
        title: 'Provision Pilot',
        label: 'Are you sure you want to provision this pilot?',
        buttonText: 'Provision Pilot',
        action: this.handleProvisionFalcon,
        type: 'provision pilot',
        params: id,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },

    rebootPilot(id) {
      this.modalObj = {
        title: 'Reboot Pilot',
        label: 'Are you sure you want to reboot this pilot?',
        buttonText: 'Reboot Pilot',
        action: this.handleRebootFalcon,
        params: id,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },

    powerOn(id) {
      this.modalObj = {
        title: 'Power On Pilot',
        label: 'Are you sure you want to power on this pilot?',
        buttonText: 'Power On Pilot',
        action: this.handlePowerOnFalcon,
        type: 'power on',
        params: id,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },

    powerOff(id) {
      this.modalObj = {
        title: 'Power Off Pilot',
        label: 'Are you sure you want to power off this pilot?',
        buttonText: 'Power Off Pilot',
        type: 'power off',
        action: this.handlePowerOffFalcon,
        params: id,
      }

      //toggle the modal
      this.togglePilotActions(true)
    },
  },
  updated() {
    // console.log(`userID - ${this.userID}, businessID - ${this.businessID}`)
  },
}
</script>

<style scoped>
.menu__items {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.menu__items:hover {
  color: #447ffc;
}
</style>
