import memberMutations from './mutations'
import memberActions from './actions'

export default {
  state: {
    member: [],
    memberStatus: 'PENDING',
    submitMember: [],
    submitMemberStatus: 'PENDING',
  },
  actions: { ...memberActions },
  mutations: { ...memberMutations },
  getters: {
    getMember(state) {
      return state.member
    },
    getMemberStatus(state) {
      return state.memberStatus
    },
    submitMember(state) {
      return state.submitMember
    },
    submitMemberStatus(state) {
      return state.submitMemberStatus
    },
  },
}
