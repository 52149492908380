<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      Operation cannot be performed. Please try again later.
    </div>
    <div class="alert alert-success" v-if="success">
      Project has been successfully deleted.
    </div>
    <div>
      <h6>Delete {{ projectDetails.name }}</h6>
      <label>
        Are you sure you want to delete this project? This action cannot be
        undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteProject"
      >
        <!-- :inactive="!validate" -->
        <span class="">Delete project</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'
export default {
  name: 'DeleteProjectModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    businessSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: false,
      error: '',
    }
  },
  methods: {
    ...mapActions(['handleDeleteProjectById', 'handleGetUsersProjects']),
    deleteProject() {
      this.loading = true
      this.error = ''
      let deleteData = {
        projectUid: this.projectDetails.u_id,
        businessSlug: this.businessSlug,
      }

      // console.log(deleteData)

      this.$store
        .dispatch('handleDeleteProjectById', deleteData)
        .then(() => {
          this.success = true

          setTimeout(this.redirect, 3000)
        })
        .catch(error => {
          this.loading = false
          this.error = error
        })
    },
    redirect() {
      this.success = false
      this.error = ''
      this.show = false
      this.loading = false
      //update list of websites
      this.handleGetUsersProjects(this.businessSlug)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.error = ''
      },
    },
  },
}
</script>
