<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Delete {{ storeName }}</h6>
      <label>
        Are you sure you want to delete this store? This action cannot be
        undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteStore(storeId)"
      >
        <span class="">Delete store</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'DeleteStoreModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
    storeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      params: this.$route.params.uid,
      loading: false,
      success: false,
      error: false,
    }
  },
  methods: {
    ...mapActions(['handleDeleteWebsite', 'handleGetUsersStores']),
    deleteStore(storeId) {
      this.loading = true
      this.error = false
      this.$store
        .dispatch('handleDeleteUserStore', storeId)
        .then(response => {
          this.success = response.data.message

          setTimeout(() => this.redirect(), 2500)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },
    async redirect() {
      this.success = false
      this.error = ''
      this.show = false
      this.loading = false
      //update list of websites
      await this.handleGetUsersStores(this.params)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.error = false
        this.success = false
      },
    },
  },
}
</script>
