<template>
  <!-- The alert messages -->
  <div>
    <div class="alert alert-success" role="alert" v-if="success">
      {{ message }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="error">
      {{ message }}
    </div>
  </div>
  <div class="account_details">
    <h6>Update Profile</h6>
    <p>Edit your profile information</p>
    <!-- <form action="#" class="py-4"> -->
    <div class="py-4">
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="first_name">First name</label>
          <input
            type="text"
            id="first_name"
            class="form-control"
            v-model="userDetails.first"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="last_name">Last name</label>
          <input
            type="text"
            id="last_name"
            class="form-control"
            v-model="userDetails.last"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="first_name">Phone Number</label>
          <input
            type="text"
            id="phone_number"
            class="form-control"
            v-model="userDetails.phone"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="country">Country</label>
          <input
            type="text"
            id="country"
            class="form-control"
            v-model="userDetails.country"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="state">State</label>
          <input
            type="text"
            id="state"
            class="form-control"
            v-model="userDetails.state"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="city">city</label>
          <input
            type="text"
            id="city"
            class="form-control"
            v-model="userDetails.city"
          />
        </div>
      </div>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5"
          @click="updateProfile"
        >
          <!-- :inactive="!validate" -->
          <span class="">Update changes</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn">Cancel</div>
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'UpdateAccountDetails',
  props: {
    userDetailsProp: {
      type: Object,
      required: true,
    },
  },
  components: { Button },
  data() {
    return {
      success: false,
      error: false,
      message: '',
      loading: false,
      userDetails: {},
    }
  },
  methods: {
    ...mapActions(['handleUpdateUserProfile']),
    async updateProfile() {
      this.loading = true
      //Fetch the Params
      const { first, last, phone, country, state, city } = this.userDetails

      const params = { first, last, phone, country, state, city }

      //Update the profile
      this.$store
        .dispatch('handleUpdateUserProfile', params)
        .then(response => {
          this.toggleShowResult({
            errorType: 'success',
            message: response.data.message,
          })
        })
        .catch(error => {
          this.toggleShowResult({
            errorType: 'error',
            message: error.data.message,
          })
        })
    },
    toggleShowResult({ errorType, message }) {
      if (errorType === 'success') {
        this.success = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.success = false
          this.message = ''
        }, 3500)
      } else {
        this.error = true
        this.message = message
        this.loading = false
        setTimeout(() => {
          this.error = false
          this.message = ''
        }, 3500)
      }
    },
  },
  //Please just leave the two lifecycles - updated and mounted
  //They populate the data when navigating from other tabs
  updated() {
    this.userDetails = this.userDetailsProp
  },
  mounted() {
    this.userDetails = this.userDetailsProp
  },
}
</script>
<style lang="scss">
.account_details {
  label {
    font-weight: normal;
  }
}
</style>
