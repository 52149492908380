<template>
  <div class="auth">
    <router-link to="/">
      <img
        src="@/assets/img/logo-white.svg"
        alt="Fybrr logo"
        class="form__logo"
      />
    </router-link>
    <div class="form form--authentication">
      <h1 class="text-capitalize font-weight-bold fs_lh-64_64">
        Create account
      </h1>
      <p class="mb-5 fs_lh-20_25 mt-3">We're excited to have you back!</p>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="first_name"
              placeholder="First name"
              v-model="user.first"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="last_name"
              placeholder="Last name"
              v-model="user.last"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email address"
          v-model="user.email"
        />
      </div>
      <div class="form-group">
        <input
          type="tel"
          class="form-control"
          id="phone"
          placeholder="Phone number"
          v-model="user.phone"
        />
      </div>

      <div class="form-group">
        <select class="form-control" id="role" v-model="user.account_type">
          <option value="">Select your role</option>
          <option value="Student">Student</option>
          <option value="Professional">Professional</option>
          <option value="Freelance">Freelance</option>
          <option value="Business">Business</option>
          <option value="Ngo">NGO</option>
          <option value="Government">Government</option>
        </select>
      </div>

      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="business_name"
          placeholder="Business name (optional)"
          v-model="user.business_name"
        />
      </div>

      <div class="form-group">
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="Enter password"
          v-model="user.password"
        />
      </div>
      <div class="form--tc">
        <div>
          <input
            class="field__checkbox"
            type="checkbox"
            id="termsAndCondition"
            name="termsAndCondition"
            v-model="terms_and_conditions"
            autocomplete="off"
            required
          />
          <label for="termsAndCondition"><span></span></label>
        </div>
        <label class="field__label" for="termsAndCondition"
          >I agree to Fybrr's terms of service and consent to Fybrr's privacy
          policy.</label
        >
      </div>
      <Button
        :loading="loading"
        :inactive="!allFilled"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="btn-block h-100 mt-2 mb-3 py-1"
        @click="handleRegister(user)"
      >
        <!-- :inactive="!validate" -->
        <span class="py-3 fs_lh-16_20 font-weight-normal"
          >Create account</span
        ></Button
      >
      <div class="form__link">
        Already have a Fybrr account?
        <router-link to="/login">Login</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
export default {
  name: 'Register',
  components: { Feedback, Button },
  data() {
    return {
      error: '',
      loading: false,
      user: {
        first: '',
        last: '',
        phone: '',
        account_type: '',
        business_name: '',
        email: '',
        password: '',
      },
      terms_and_conditions: false,
    }
  },
  methods: {
    handleRegister(user) {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleRegister', {
          registerData: user,
        })
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          let { data } = error.response
          this.error = Object.prototype.hasOwnProperty.call(data, 'message')
            ? data.message
            : data.error
        })
    },
    removeError() {
      this.error = ''
    },
  },
  computed: {
    allFilled() {
      const { email, password, first, last, phone, account_type } = this.user
      return (
        email !== '' &&
        password !== '' &&
        first !== '' &&
        last !== '' &&
        phone !== '' &&
        account_type !== '' &&
        this.terms_and_conditions !== false
      )
    },
  },
  mounted() {
    const email = localStorage.getItem('email');
    this.user.email = email;
  }
}
</script>
