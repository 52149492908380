import * as types from './types'
import {
  getProjectRepos,
  deleteRepoByID,
  fetchAllCommits,
  fetchUserIntegrations,
  addRepository,
  fetchReposToIntegrate,
  importRepo,
} from '@/services/repository'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetProjectRepos({ commit }, ProjectData) {
    commit(types.PROJECT_REPOS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getProjectRepos(ProjectData)
        .then(response => {
          commit(types.PROJECT_REPOS, response.data)
          commit(types.PROJECT_REPOS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.PROJECT_REPOS_STATUS, 'PENDING')
        })
    })
  },
  handleDeleteRepoById({ commit }, ProjectData) {
    commit(types.DELETE_REPO_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteRepoByID(ProjectData)
        .then(response => {
          commit(types.DELETE_REPO, response.data)
          commit(types.DELETE_REPO_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.DELETE_REPO_STATUS, 'PENDING')
        })
    })
  },
  handleGetAllCommits({ commit }, projectUId) {
    commit(types.ALL_COMMITS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      fetchAllCommits(projectUId)
        .then(response => {
          commit(types.ALL_COMMITS, response.data)
          commit(types.ALL_COMMITS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.ALL_COMMITS_STATUS, 'PENDING')
        })
    })
  },
  handleGetUserIntegrations({ commit }, userSlug) {
    commit(types.USER_INTEGRATIONS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      fetchUserIntegrations(userSlug)
        .then(response => {
          commit(types.USER_INTEGRATIONS, response.data)
          commit(types.USER_INTEGRATIONS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.USER_INTEGRATIONS_STATUS, 'PENDING')
        })
    })
  },
  handleAddRepository({ commit }, userData) {
    commit(types.ADD_REPOSITORY_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      addRepository(userData)
        .then(response => {
          commit(types.ADD_REPOSITORY, response.data)
          commit(types.ADD_REPOSITORY_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.ADD_REPOSITORY_STATUS, 'PENDING')
        })
    })
  },

  handleFetchReposToIntegrate({ commit }, params) {
    commit(types.FETCH_REPO_INTEGRATIONS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      fetchReposToIntegrate(params)
        .then(response => {
          commit(types.FETCH_REPO_INTEGRATIONS, response.data.data)
          commit(types.FETCH_REPO_INTEGRATIONS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.FETCH_REPO_INTEGRATIONS_STATUS, 'PENDING')
        })
    })
  },

  handleImportRepo({ commit }, params) {
    commit(types.IMPORT_REPO_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      importRepo(params)
        .then(response => {
          commit(types.IMPORT_REPO, response.data.data)
          commit(types.IMPORT_REPO_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          reject(error)
          formatError(error)
          commit(types.IMPORT_REPO_STATUS, 'PENDING')
        })
    })
  },
}
