<template>
  <div class="pages about-page">
    <Navbar></Navbar>
    <main class="jumbotron-container">
      <div class="row pages-jumbotron">
        <div class="col-lg-11">
          <p class="pre fs_lh-14_18">ABOUT US</p>
          <h1 class="title my-4 fs_lh-80_94">
            Easy builds & deployments for modern teams
          </h1>
          <p class="post fs_lh-24_31 w-75">
            Talk less and do more. All you have to focus on is to build and push
            your code commits. Fybrr takes care of the rest - easily and
            efficiently.
          </p>
        </div>
      </div>
    </main>
  </div>
  <section class="about-page__empathy">
    <div class="container-fluid px-5">
      <div class="row">
        <div class="col expert-container">
          <div class="expert-container__img">
            <img src="@/assets/img/about/expert/expert-1.svg" alt="" />
          </div>
          <div class="expert-container__img">
            <img src="@/assets/img/about/expert/expert-2.svg" alt="" />
          </div>
          <div class="expert-container__img">
            <img src="@/assets/img/about/expert/expert-3.svg" alt="" />
          </div>
        </div>
        <div class="col-lg-7 py-5 text-center">
          <h2 class="mb-5 fs_lh-56_56">Fybrr is built with empathy</h2>
          <p class="my-3 fs_lh-16_20">
            Fybrr is a full-stack cloud infrastructure for developers and
            startups in Africa to launch software products quicker and safer.
            With Fybrr, you can efficiently deploy, host, scale and secure
            applications without worrying about infrastructure.
          </p>
          <p class="my-3 fs_lh-16_20">
            It is easy to set up. In a few minutes, you can integrate our
            solution with your existing infrastructure. After you complete
            registration, you can invite team members and collaborate with them
            on different projects.
          </p>
          <p class="my-3 fs_lh-16_20">
            Our platform is highly adaptable as we make it easy for users of
            other platforms to access and control their infrastructure from
            Fybrr.
          </p>
        </div>
        <div class="col expert-container">
          <div class="expert-container__img">
            <img src="@/assets/img/about/expert/expert-4.svg" alt="" />
          </div>
          <div class="expert-container__img align-self-end">
            <img src="@/assets/img/about/expert/expert-5.svg" alt="" />
          </div>
          <div class="expert-container__img">
            <img src="@/assets/img/about/expert/expert-6.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about-page__fybrr">
    <div class="center">
      <div class="px-5">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-page__fybrr--what_is_fybrr">
              <p class="text-xs fs_lh-14_18">WHAT IS FYBRR</p>
              <h2 class="mt-4 fs_lh-42_42">Fybrr</h2>
              <p class="py-4 fs_lh-18_23">[ Pronounced phy-baa ] Noun</p>
              <ul class="item_list">
                <li>B2B’s favorite personal assistant.</li>
                <li>
                  The smiling cartoon woman that powers your in-app prospecting
                  solution and lives on your Linkedin, B2B websites, Gmail and
                  more.
                </li>
                <li>
                  Who you thank when you easily find B2B prospects and their
                  accurate contact details.
                </li>
                <li>
                  The nickname of Assaf’s daughter, who inspired our name.
                </li>
                <li>
                  A fun-loving company of data enthusiasts who work hard, play
                  harder and get our kicks from delighting our customers.
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <img
              src="@/assets/img/about/fybrr_logo.svg"
              class="fybrr_logo"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-page__team">
    <h2 class="fs_lh-56_56">Leadership team</h2>
    <div class="row mt-5">
      <div
        class="col-lg-4 my-5"
        v-for="(member, index) in teamMembers"
        :key="index"
      >
        <div class="team_card">
          <div class="team_card--img">
            <img :src="member.image" alt="" />
          </div>
          <h5 class="title fs_lh-24_24">{{ member.name }}</h5>
          <p class="position fs_lh-16_20">{{ member.position }}</p>
          <q class="quote fs_lh-18_23">{{ member.quote }}</q>
        </div>
      </div>
    </div>
  </section>

  <section class="container about-page__values">
    <p class="pre fs_lh-14_18">OUR CORE VALUES</p>
  </section>
  <section class="container about-page__values pt-0">
    <div class="position-relative">
      <TransitionGroup name="list" tag="div" class="d-flex title-slides">
        <h2
          v-for="(slider, index) in sliders"
          :key="index"
          class="my-4 title fs_lh-80_94"
          :class="current === index ? 'active' : ''"
          :id="`img${index}`"
        >
          {{ slider.name }}
        </h2>
      </TransitionGroup>
    </div>
    <h5 class="col-lg-6 p-0 font-weight-light fs_lh-24_31">
      {{ sliders[current].description }}
    </h5>
    <div class="core-values">
      <div class="row">
        <div
          class="col-lg-2"
          :class="index === activeValue ? 'active' : ''"
          v-for="(value, index) in values"
          :key="index"
        >
          <div class="core-values__container">
            <span class="fs_lh-16_21">{{ `0${index + 1}` }}</span>
            <h4 class="mt-3 fs_lh-24_31">{{ value }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-6"
        v-for="(value, index) in missionVision"
        :key="index"
      >
        <ServiceCard :size="'full'" :image="value.image">
          <div class="fancy-box-footer text-left">
            <h3 class="fs_lh-32_32">{{ value.name }}</h3>
            <p class="mt-2 w-90 fs_lh-16_21">
              {{ value.description }}
            </p>
          </div>
        </ServiceCard>
      </div>
    </div>
  </section>

  <section class="hire-expert--contact my-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <h2 class="fs_lh-64_64">Want to hear from us?</h2>
          <p class="my-3 fs_lh-24_31">
            Schedule a call for us to talk through your needs and we can help
            guide you through picking the right model for you
          </p>
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="getStarted"
            btnClass="mr-3 mt-3"
            @click="''"
          >
            <span class="px-3">Call us</span>
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from '@/components/pages/navbar'
import Button from '@/components/ui/Button'
import ServiceCard from '@/components/dashboard/overview/ServiceCard'
import { defineComponent, reactive, ref } from 'vue'
export default defineComponent({
  name: 'AboutPage',
  components: { Navbar, Button, ServiceCard },
  setup() {
    const activeValue = ref(0)

    const current = ref(0)

    const sliders = ref([
      {
        name: 'Customer First',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.',
      },
      {
        name: 'Move fast, Learn faster',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.2',
      },
      {
        name: 'Helping',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.3',
      },
      {
        name: 'Section Four',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.4',
      },
      {
        name: 'Section Five',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.5',
      },
      {
        name: 'Section Six',
        description:
          'We are focused on providing the best user experience possible, all the rest will follow.6',
      },
    ])

    setInterval(() => {
      let firstElement = sliders.value.shift()
      sliders.value.push(firstElement)
    }, 5000)

    const teamMembers = reactive([
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
      {
        name: 'Dele Bakare',
        image: '/assets/img/about/team/member1.jpg',
        position: 'Position',
        quote:
          'I live my life on the taosim teachings and principles. Be Happy',
      },
    ])
    const missionVision = reactive([
      {
        name: 'Our Vision',
        description:
          'Simplify teamwork, harness it and accelerate success. Our vision is to become the world’s leading enabler of growth and productivity for companies of all sizes.',
        image: '/assets/img/about/values/value1.jpg',
      },
      {
        name: 'Our Mission',
        description:
          'We are passionate about teams of different sizes, how they build and how they harness efforts for their success. We are constantly building tools that make team work faster and more fun.',
        image: '/assets/img/about/values/value2.jpg',
      },
    ])
    const values = reactive([
      'Customers First',
      'Name of second value',
      'Name of second value',
      'Name of second value',
      'Name of second value',
      'Name of second value',
    ])

    return { teamMembers, values, activeValue, missionVision, sliders, current }
  },
})
</script>
<style lang="scss" scoped>
.w-90 {
  width: 90%;
}
.title-slides {
  overflow-x: hidden;
  .title {
    color: #637a873d;
    white-space: nowrap;
    margin-right: 50px;
    &.active {
      color: #000000;
    }
  }
}
</style>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}
</style>
