<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>Delete {{ websiteName }}</h6>
      <label>
        Are you sure you want to delete this website? This action cannot be
        undone.
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'danger'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="px-5"
        @click="deleteWebsite(websiteId)"
      >
        <span class="">Delete website</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'DeleteWebsiteModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    websiteName: {
      type: String,
      required: true,
    },
    websiteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      params: this.$route.params.uid,
      loading: false,
      success: false,
      error: false,
    }
  },
  methods: {
    ...mapActions(['handleDeleteWebsite', 'handleGetProjectWebsites']),
    async deleteWebsite(websiteId) {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleDeleteWebsite', websiteId)
        .then(async response => {
          this.success = response.data.message
          //reload the website list
          const result = await this.handleGetProjectWebsites(this.params)

          if (result.status === 200) {
            setTimeout(() => {
              this.show = false
              this.loading = false
              this.website = {}
            }, 2500)
          }
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
        this.error = false
        this.success = false
      },
    },
  },
}
</script>
