<template>
  <div class="project-nav">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isActive('website') ? 'active' : ''"
          aria-current="page"
          @click="changePage('website')"
          >Website Builder
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isActive('ecommerce') ? 'active' : ''"
          @click="changePage('ecommerce')"
          >Ecommerce store</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isActive('hosting') ? 'active' : ''"
          @click="changePage('hosting')"
          >Hosting</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="isActive('fybrr-expert') ? 'active' : ''"
          @click="changePage('fybrr-expert')"
          >Fybrr Experts</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PricingPageNav',
  props: {
    page: String,
  },
  setup(props, { emit }) {
    const changePage = page => {
      emit('changePage', page)
    }
    const isActive = page => {
      return page === props.page
    }
    return { isActive, changePage }
  },
})
</script>
<style scoped lang="scss">
.project-nav {
  width: 100%;
}
.nav {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  &-pills {
    .nav-item {
      .nav-link {
        white-space: nowrap;
        color: #565f62;
        padding: 8px 24px;
        border-radius: 0;
        transition: all 0.5s;
        font-size: 20px;
        line-height: 25px;
        &.active,
        &:hover {
          background: none;
          border-bottom: 3px solid rgba(68, 127, 252, 1);
          color: rgba(68, 127, 252, 1);
        }
      }
    }
  }
}
</style>
