import { logoutOnAll } from '@/utils/logout'

export const formatError = error => {
  if (error.response) {
    const { status } = error.response
    if (status === 401) {
      logoutOnAll()
    }
  }
  return error
}
