import * as types from './types'

export default {
  [types.BUSINESS_INFO](state, payload) {
    state.presentBusinessInfo = payload
  },
  [types.USER_INFO](state, payload) {
    const {
      first,
      last,
      email,
      account_type,
      phone,
      is_verify,
      is_active,
      avatar,
    } = payload
    state.userInfo = {
      first,
      last,
      email,
      account_type,
      phone,
      is_verify,
      is_active,
      avatar,
    }
  },
  [types.USER_STATUS](state, value) {
    state.userInfoStatus = value
  },
  [types.USER_SLUG](state, value) {
    state.userSlug = value
  },
  [types.BUSINESS_ID](state, value) {
    state.businessId = value
  },
  [types.UPDATE_PROFILE_STATUS](state, value) {
    state.updateProfileStatus = value
  },
  [types.UPDATE_PROFILE](state, value) {
    state.updateProfile = value
  },
  [types.UPLOAD_AVATAR_STATUS](state, value) {
    state.uploadAvatarStatus = value
  },
  [types.UPLOAD_AVATAR](state, value) {
    state.uploadAvatar = value
  },
}
