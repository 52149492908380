import * as types from './types'

export default {
  [types.TASK](state, payload) {
    state.task = payload
  },
  [types.TASK_STATUS](state, value) {
    state.taskStatus = value
  },
  [types.TASKS](state, payload) {
    state.tasks = payload
  },
  [types.TASKS_STATUS](state, value) {
    state.tasksStatus = value
  },
  [types.UPDATE_TASK_STATUS](state, value) {
    state.updateTaskStatus = value
  },
  [types.UPDATE_TASK](state, value) {
    state.updateTask = value
  },
  [types.ASSIGN_MEMBER_STATUS](state, value) {
    state.assignMemberStatus = value
  },
  [types.ASSIGN_MEMBER](state, value) {
    state.assignMember = value
  },
  [types.DELETE_MEMBER_STATUS](state, value) {
    state.deleteMemberStatus = value
  },
  [types.DELETE_MEMBER](state, value) {
    state.deleteMember = value
  },
  [types.DELETE_TASK_STATUS](state, value) {
    state.deleteTaskStatus = value
  },
  [types.DELETE_TASK](state, value) {
    state.deleteTask = value
  },
}
