<template>
  <div class="row page-hire">
    <div class="col-lg-4">
      <ServiceCard :size="'cover'" :image="'/assets/img/hireExpert/bg1.jpg'">
        <div class="fancy-box-header text-left">
          <h6>Hire a Developer from Fybrr</h6>
          <p class="my-2 text-xs">
            Fybrr offers a subscription based model for you to have your
            projects built, developed, maintained and supported all on one
            platform.
          </p>
          <div class="d-flex align-items-center mt-3">
            <a href="tel:+2348134223442">
              <Button
                :loading="false"
                :inactive="false"
                :variant="'grey'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass=""
                @click="''"
              >
                <svg
                  class="icon"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-call"></use>
                </svg>
                <span class="font-weight-normal">Call Us</span>
              </Button>
            </a>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'outline-black'"
              :size="'md'"
              type="submit"
              name="submit"
              btnClass="ml-3"
              @click="''"
            >
              <span class="font-weight-normal">Learn how it works</span>
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-angle-right"></use>
              </svg>
            </Button>
          </div>
        </div>
      </ServiceCard>
    </div>
    <div class="col-md-8">
      <ApplicationSuccess @goBack="step = 'form'" v-if="step === 'success'" />
      <HireExpertForm
        :expertsPricing="getExpertsPricing"
        :userInfo="userInfo"
        v-if="step === 'form'"
      />
    </div>
  </div>
</template>
<script>
import ServiceCard from '@/components/dashboard/overview/ServiceCard'
import HireExpertForm from '@/components/dashboard/hireExpert/applyForExpert'
import ApplicationSuccess from '@/components/dashboard/hireExpert/applicationSuccess'
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { ServiceCard, Button, HireExpertForm, ApplicationSuccess },
  data() {
    return {
      step: 'form',
      userInfo: {},
    }
  },
  computed: {
    ...mapGetters(['getExpertsPricing', 'getUserInfo']),
  },
  methods: {
    ...mapActions(['handleGetExpertsPricing', 'handleGetLoggedInUser']),
    submitForm() {
      this.step = 'success'
    },
  },
  async mounted() {
    await this.handleGetExpertsPricing({
      country: 'united kingdom',
      currency: 'gbp',
    })

    await this.handleGetLoggedInUser()

    this.userInfo = this.getUserInfo
  },
}
</script>
<style lang="scss">
.page-hire {
  height: calc(100vh - 140px);
  .fancy-box-case-study .fancy-box-header {
    color: #000000;
  }
  .fancy-box-case-study figure:after {
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 30%);
  }
  &__form,
  &__success {
    padding: 50px;
  }
  .pros {
    margin-bottom: 30px;
    font-weight: 400;
    ul {
      margin-left: 30px;
      margin-top: 10px;
      li {
        list-style-type: disc;
      }
    }
  }
}
</style>
