export const Authorization = {
  get: {
    accessToken(type) {
      const name = type + '='
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
  },
  set: {
    accessToken(type, token) {
      // Store In cookie too
      const d = new Date()
      d.setTime(d.getTime() + 99999999 * 24 * 60 * 60 * 1000)
      const expires = 'expires=' + d.toUTCString()
      document.cookie = type + '=' + token + ';' + expires + ';path=/'

      // Then Store in LocalStorage
      return localStorage.setItem(type, token)
    },
    URLIntended(url) {
      return localStorage.setItem('FROM', url)
    },
  },
  clear: {
    accessToken() {
      document.cookie = 'user=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie =
        'auth_from=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = 'token=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      return localStorage.removeItem('token')
    },
    URLIntended() {
      return localStorage.removeItem('FROM')
    },
  },
  check: {
    isAuthenticated() {
      return !!Authorization.get.accessToken('token')
    },
  },
}
