<template>
  <div class="project-nav">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('website') ? 'active' : ''"
          aria-current="page"
          to="''"
          >Website Builder
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('ecommerce') ? 'active' : ''"
          to="''"
          >eCommerce</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('repository') ? 'active' : ''"
          to="/dashboard/project/80/repository"
          >Repositories</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('hosting') ? 'active' : ''"
          to="''"
          >Hosting</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('deployment') ? 'active' : ''"
          to="''"
          >Deployments</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'BillingNav',
  props: {
    page: String,
  },
  methods: {
    isActive(page) {
      return page === this.page
    },
  },
}
</script>
<style scoped lang="scss">
.project-nav {
  width: 100%;
}
.nav {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  &-pills {
    border-bottom: 1px solid #eeeeee;
    .nav-item {
      .nav-link {
        white-space: nowrap;
        color: #565f62;
        font-weight: 400;
        padding: 8px 24px;
        border-radius: 0;
        transition: all 0.5s;
        &.active,
        &:hover {
          background: none;
          border-bottom: 3px solid rgba(68, 127, 252, 1);
          color: rgba(68, 127, 252, 1);
        }
      }
    }
  }
}
</style>
