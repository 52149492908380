import http from '../../config/axios'

export const deploymentUrls = {
  GET_ALL_URL: 'projects',
  GET_BASE_URL: 'deployments',
  GET_VARIABLES_URL: 'deployments/variables',
}

/** getAllDeploymentsBelongingToAServer */
const getAllDeploymentsBelongingToAServer = async serverSlug => {
  return await http.get(`${deploymentUrls.GET_BASE_URL}/${serverSlug}/all`)
}

/** getAllDeployments */
const getAllDeployments = async projectUId => {
  return await http.get(
    `${deploymentUrls.GET_ALL_URL}/${projectUId}/deployments`
  )
}

/** addDeployment */
const addDeployment = async params => {
  return await http.post(`${deploymentUrls.GET_BASE_URL}/create`, params)
}

/** deleteDeployment */
const deleteDeployment = async slug => {
  return await http.delete(`${deploymentUrls.GET_BASE_URL}/${slug}`)
}

/** getDeploymentConfig */
const getDeploymentConfig = async deploymentSlug => {
  return await http.get(`${deploymentUrls.GET_VARIABLES_URL}/${deploymentSlug}`)
}

/** updateDeploymentSlug */
const updateDeploymentConfigs = async params => {
  return await http.post(
    `${deploymentUrls.GET_VARIABLES_URL}/${params.slug}`,
    params.configData
  )
}

/** addDeploymentConfigs */
const addDeploymentConfigs = async params => {
  return await http.post(`${deploymentUrls.GET_VARIABLES_URL}`, params)
}

export {
  getAllDeploymentsBelongingToAServer,
  getAllDeployments,
  addDeployment,
  deleteDeployment,
  getDeploymentConfig,
  updateDeploymentConfigs,
  addDeploymentConfigs,
}
