<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="hosting" />
      <div class="pb-3">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="submit"
          btnClass="px-5 mt-4"
          @click="goBack"
        >
          <span class="font-weight-normal">Go Back</span>
        </Button>
      </div>

      <!--choose plan starts here-->
      <div class="container">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="padding10" style="text-align: center">
            Create Pilots - get your own fully provisioned cloud servers.
          </h4>
          <div class="title__padding" id="pilot-plans">
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="submit"
              btnClass="mt-4"
              @click.prevent="rechoosePlan()"
            >
              <span class="font-weight-normal">Go back to Pilot Plans</span>
            </Button>
          </div>

          <div class="row box-wrapper" id="plans">
            <div
              class="col-4 col-lg-3 plan-box"
              v-for="(pilot, index) in pilotDetails"
              :key="index"
            >
              <h4>${{ pilot.dollar_price }}/month</h4>
              <strong>i.e ₦{{ pilot.naira_price }}/mo </strong>
              <hr />
              <span v-for="(details, idx) in pilot.details" :key="idx"
                >{{ details }}<br /></span
              ><br />
              <Button
                :loading="false"
                :inactive="false"
                :variant="'outline-grey'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5 mt-4"
                @click.prevent="choosePlan(pilot.id)"
              >
                <span class="font-weight-normal">Select Plan</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--choose plan ends here-->

      <!--choose template starts here-->
      <div class="container">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row box-wrapper" id="templates">
            <div
              class="col-4 col-lg-3 plan-box"
              v-for="(template, index) in pilotTemplates"
              :key="index"
            >
              <strong
                ><h4 style="text-transform: uppercase">
                  {{ template.name }}
                </h4></strong
              >
              <hr />
              <span v-for="(item, idx) in template.details" :key="idx"
                >{{ item }} <br /></span
              ><br />
              <Button
                :loading="false"
                :inactive="false"
                :variant="'outline-grey'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5 mt-4"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                @click.prevent="chooseTemplate(template.id)"
              >
                <span class="font-weight-normal">Install template</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--choose template ends here-->

      <!--discount modal starts from here-->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <!-- The alert messages -->
            <div style="margin-bottom: -18px" class="px-2 pt-3">
              <div class="alert alert-success" role="alert" v-if="success">
                {{ message }}
              </div>
              <div class="alert alert-danger" role="alert" v-if="error">
                {{ message }}
              </div>
            </div>
            <!--alert message ends here-->
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Have a promo code?
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="discount code"
                  placeholder="Enter your discount code (optional)"
                  v-model="discount"
                />
              </div>
            </div>

            <div class="modal-footer">
              <div
                class="ml-3 font-weight-normal cancel-btn"
                data-dismiss="modal"
              >
                Cancel
              </div>
              <Button
                :loading="loading"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="submit"
                btnClass="px-5"
                @click.prevent="makePayment"
              >
                <!-- :inactive="!validate" -->
                <span class="">Proceed to Payment</span></Button
              >
            </div>
          </div>
        </div>
      </div>
    </main>
    <OverlayLoading v-if="loaderIsVisible" />
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Button from '@/components/ui/Button'

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AddPilot',
  components: {
    ProjectNav,

    Button,
    OverlayLoading,
  },
  data() {
    return {
      loaderIsVisible: true,
      pilotDetails: [],
      pilotTemplates: [],
      customMessage: '',
      success: false,
      message: '',
      error: false,
      loading: false,
      discount: '',
      businessID: null,
      projectID: null,
    }
  },
  computed: {
    ...mapGetters([
      'getPilotTemplatesStatus',
      'getPilotDetailsStatus',
      'getPilotDetails',
      'getPilotTemplates',
      'getProject',
      'getSelectedWorkspace',
    ]),
  },
  methods: {
    ...mapActions([
      'handleGetPilotDetails',
      'handleGetPilotTemplates',
      'handleInitializePayment',
    ]),
    async choosePlan(plan) {
      document.getElementById('plans').style.display = 'none'
      document.getElementById('templates').style.display = 'flex'
      document.getElementById('pilot-plans').style.display = 'block'

      //clear the plan_id before setting another one
      await localStorage.removeItem('plan_id')

      //set a new plan_id
      await localStorage.setItem('plan_id', plan)
    },
    goBack() {
      history.back()
    },
    rechoosePlan() {
      document.getElementById('plans').style.display = 'flex'
      document.getElementById('templates').style.display = 'none'
      document.getElementById('pilot-plans').style.display = 'none'
    },
    async chooseTemplate(templateId) {
      //clear the plan_id before setting another one
      await localStorage.removeItem('template_id')

      //set a new plan_id
      await localStorage.setItem('template_id', templateId)
    },

    async makePayment() {
      //loading button
      this.loading = true
      this.error = false
      this.success = false

      //save the project id in the localStorage
      await localStorage.setItem('pilot_project_uid', this.$route.params.uid)

      const plan_id_result = await localStorage.getItem('plan_id')
      const template_id_result = await localStorage.getItem('template_id')
      const discount = this.discount

      let params = {
        plan_id: parseInt(plan_id_result),
        template_id: parseInt(template_id_result),
        business_id: parseInt(this.businessID),
        type: 'pilot',
        action: 'create.pilot',
        region: 'LON1',
        project_id: this.projectID,
      }

      //If discount code is available
      if (discount !== '') {
        params = { ...params, discount_code: this.discount }
      }

      //If no errors, initialize payments
      if (
        plan_id_result !== undefined ||
        (plan_id_result !== '' && template_id_result !== undefined) ||
        template_id_result !== ''
      ) {
        this.$store
          .dispatch('handleInitializePayment', params)
          .then(response => {
            //if payment initialization is successful
            localStorage.removeItem('reference_no')
            localStorage.removeItem('type')

            // Set payment reference number in localstorage
            localStorage.setItem('reference_no', response.data.data.reference)
            localStorage.setItem('type', 'pilot')

            location.href = response.data.data.payment_config.link
            this.loading = false
          })
          .catch(error => {
            //Show the error from the backend
            this.error = true
            this.message = error.response.data.message
            this.loading = false
          })
      } else {
        //Show error to Please select a plan and template to continue'
        this.error = true
        this.message =
          'There is a problem. Please select a plan and template to continue'
        this.loading = false
      }
    },
  },
  watch: {
    getPilotTemplatesStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.pilotTemplates = this.getPilotTemplates
        }
      },
    },
    getPilotDetailsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.pilotDetails = this.getPilotDetails
          this.loaderIsVisible = false
        }
      },
    },
  },
  async mounted() {
    await this.handleGetPilotDetails()
    await this.handleGetPilotTemplates()
    //get the business id
    this.businessID = await this.getSelectedWorkspace?.subscription.business_id
    this.projectID = await this.getProject?.id
  },
}
</script>

<style>
.box-wrapper {
  display: flex;
  justify-content: center !important;
  margin-top: 15px;
}
.plan-box {
  width: 30% !important;
  min-height: 150px;
  background: #eceef9;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-right: 5px;
}
.padding10 {
  padding: 0 7px;
}
.radio-buttons {
  display: flex;
}

.radio-buttons div label {
  margin-left: 4px;
}

.radio-buttons div:first-of-type {
  margin-right: 10px;
}

.__probtnac:focus {
  background: darkblue;
}

#templates {
  display: none;
}
#plans {
  display: flex;
}

#pilot-plans {
  display: none;
}

.btn-primary {
  background: #0d6efd !important;
}

@media only screen and (min-width: 800px) {
  .title__padding {
    margin-left: 110px;
  }
}
</style>
