<template>
  <footer class="footer">
    <div class="container footer-container px-0">
      <div class="row footer-row">
        <div class="footer-left col-lg-3 col-md-5 col-sm-12">
          <div class="footer-widget" data-aos="fade-up" data-aos-duration="800">
            <a href="/" class="footer-logo"
              ><img src="@/assets/img/logo-white.svg" alt="logo"
            /></a>
            <p class="description fs_lh-14_18">
              Fybrr is the ultimate solution for businesses and individuals looking to create powerful applications, websites, and automations without writing a single line of code.
            </p>
          </div>
        </div>
        <div class="footer-right offset-lg-2 col-lg-7 col-md-7 col-sm-12">
          <div class="row">
            <div
              class="col-6 col-sm-6 col-lg-3"
              v-for="(column, index) in footerColumns"
              :key="index"
            >
              <div class="footer-widget">
                <h5 class="fs_lh-18_23">{{ index }}</h5>
                <ul class="footer-widget-link">
                  <li
                    data-aos="fade-up"
                    data-aos-duration="800"
                    v-for="(link, i) in column"
                    :key="i"
                  >
                    <div v-if="link.type === 'link'" class="fs_lh-14_18">
                      <router-link v-if="link.isDynamic" :to="link.to">{{
                        link.title
                      }}</router-link>
                      <a v-else :href="link.to">{{ link.title }}</a>
                    </div>
                    <div v-else>
                      <a>{{ link.location }}</a>
                      <p class="mt-3">{{ link.address }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="site-menu__bottom-bar">
          <ul class="footer-menu">
            <li class="footer-item fs_lh-14_18">
              © 2023 Fybrr.com. All rights reserved.
            </li>
            <li class="footer-item fs_lh-14_18">Terms of Service</li>
            <li class="footer-item fs_lh-14_18">Privacy Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'Footer',
  setup() {
    const footerColumns = reactive({
      Product: [
        { title: 'Register', type: 'link', to: '/register', isDynamic: true },
        { title: 'Login', type: 'link', to: '/login', isDynamic: true },
        // { title: 'Support', type: 'link', to: '/', isDynamic: true },
      ],
      Company: [
        { title: 'Contact Us', type: 'link', to: '/contact', isDynamic: true },
        { title: 'Community', type: 'link', to: 'https://community.fybrr.com', isDynamic: false },
        { title: 'Reseller Programme', type: 'link', to: '/', isDynamic: true },
      ],
      Socials: [
        { title: 'Instagram', type: 'link', to: 'https://instagram.com/fybrrhq', isDynamic: false },
        { title: 'Twitter', type: 'link', to: 'https://twitter.com/fybrrhq', isDynamic: false },
        { title: 'Facebook', type: 'link', to: 'https://facebook.com/fybrrhq', isDynamic: false },
      ],
      Contact: [
        {
          location: 'Nigeria',
          address: 'Yaba, Lagos, Nigeria',
          type: 'address',
        },
        // {
        //   location: 'United Kingdom',
        //   address:
        //     'Kemp House, 160 City Road, London, EC1V 2NX, United Kingdom.',
        //   type: 'address',
        // },
      ],
    })

    return { footerColumns }
  },
})
</script>
