<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="transfer" />
      <div class="row py-5 justify-content-center">
        <div class="col-md-4">
          <div class="transfer">
            <div>
              <div>
                <div class="alert alert-success" role="alert" v-if="success">
                  {{ success }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="error">
                  {{ error }}
                </div>
              </div>
              <h6>Transfer project</h6>
              <p class="text-xs">
                Initiate a project transfer to another individual/organization
                on Fybrr.
              </p>
              <div class="alert alert-danger d-flex py-4 mt-3">
                <div>
                  <svg
                    class="icon"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  >
                    <use xlink:href="/sprite.svg#icon-info"></use>
                  </svg>
                </div>
                <div class="ml-2">
                  <p class="font-weight-normal text-xs">Please note</p>
                  <p class="text-xs mt-2">
                    Once you transfer ownership you will lose your
                    Administration privileges to this project.
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label for="member"
                  >Select someone from your collaborators to transfer the
                  project to:</label
                >
                <br />
                <select
                  name="member"
                  id="member"
                  class="form-control"
                  v-model="memberID"
                >
                  <option value="" disabled>Select an Option</option>
                  <option
                    :value="member.profile.id"
                    v-for="(member, index) in teamMembers"
                    :key="index"
                  >
                    {{ member.profile.first }} {{ member.profile.last }}
                  </option>
                </select>
              </div>
              <Button
                :loading="loading"
                :inactive="!showAdminButton"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="editIntegration"
                btnClass="mt-1"
                @click="transferOwnership"
              >
                <span class="">Transfer Ownership</span></Button
              >
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import Button from '@/components/ui/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'TransferOwnership',
  components: { ProjectNav, Button },
  data() {
    return {
      teamMembers: [],
      currentUserID: 0,
      memberID: '',
      loading: false,
      success: '',
      error: '',
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  computed: {
    ...mapGetters([
      'getProjectMembersStatus',
      'getProjectMembers',
      'getProjectStatus',
      'getProject',
      'getUserInfoStatus',
      'getBusinessInfo',
    ]),
  },
  watch: {
    getProjectMembersStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.teamMembers = this.getProjectMembers
          this.currentUserID = this.getBusinessInfo.user_id
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
  methods: {
    async transferOwnership() {
      this.loading = true
      this.error = ''
      this.success = ''

      const params = {
        member_id: this.memberID,
        user_id: this.currentUserID,
      }

      this.$store
        .dispatch('handleTransferOwnership', params)
        .then(response => {
          this.success = response.data.message
          this.redirect()
        })
        .catch(error => {
          this.error = error.response.data.message
          this.loading = false
        })
    },
    redirect() {
      this.success = false
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
.transfer {
  width: 100%;
  .alert {
    color: #000000;
    p {
      line-height: 1.2;
    }
  }
}
</style>
