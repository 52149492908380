<template>
  <div class="navbar header navbar-expand-lg">
    <div class="header__toggle">
      <svg class="icon" width="30px" height="30px" viewBox="0 0 24 24">
        <use xlink:href="/sprite.svg#icon-toggle" />
      </svg>
    </div>
    <div class="navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto d-none d-sm-flex">
        <li class="nav-item pl-3">
          <!-- Custom edit -->

          <div class="header__search">
            <select
              name="projects"
              id="projects"
              class="form-control"
              v-model="projectSelected"
              @change="gotoProjectPage($event)"
            >
              <option v-if="projects.length > 0" :value="''" disabled selected>
                Select a Project
              </option>
              <option v-else :value="''" disabled selected>
                There is no project in this workspace
              </option>
              <option
                v-for="project in projects"
                v-bind:key="project.id"
                v-bind:value="project.u_id"
              >
                {{ project.name }}
              </option>
            </select>
          </div>

          <!-- custom edit ends -->

          <!-- <form class="header__search">
            <input
              class="header__input"
              type="text"
              placeholder="Search for projects, website and online stores"
            /><button class="header__btn-search">
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
                <use xlink:href="/sprite.svg#icon-d-search"></use>
              </svg>
            </button>
          </form> -->
        </li>
      </ul>
      <div class="my-2 my-lg-0 d-flex pr-4">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'outline-primary'"
          :size="'md'"
          type="submit"
          name="createProject"
          btnClass="mr-4 d-none d-sm-block"
          @click="$router.push('/dashboard/hire-expert')"
        >
          <!-- :inactive="!validate" -->
          <span class="">Hire a Fybrr Expert</span></Button
        >
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="createProject"
          btnClass="mr-4 d-none d-sm-block"
          @click="$router.push({ name: 'CreateProjects' })"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create new project</span></Button
        >
        <div class="header__profile">
          <div class="header__profile-pic mr-2">
            <svg class="icon" width="16px" height="20px">
              <use xlink:href="/sprite.svg#icon-d-profile2"></use>
            </svg>
          </div>
          <div class="header__profile-details">
            <p class="header__profile-details-name">Hi {{ user.first }}</p>
            <p class="header__profile-details-time">{{ getTimeOfDay }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import Button from '@/components/ui/Button'
import { mapGetters } from 'vuex'
export default {
  components: { Button },
  data() {
    return {
      projectSelected: '',
    }
  },
  mounted() {
    let page = $('.page'),
      sidebar = $('.sidebar'),
      headerToggle = $('.header__toggle')

    headerToggle.on('click', function () {
      sidebar.addClass('active')
      page.addClass('toggle')
      $('body').addClass('no-scroll')
      $('html').addClass('no-scroll')
    })
  },
  computed: {
    ...mapGetters({ user: 'getUserInfo', projects: 'getProjectsArray' }),
    getTimeOfDay() {
      let today = new Date()
      let currentHour = today.getHours()

      if (currentHour < 12) {
        return 'Good Morning'
      } else if (currentHour < 18) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    },
  },
  methods: {
    gotoProjectPage(event) {
      //Router.push is not reloading page sometimes
      // this.$router.push({
      //   name: 'SingleProject',
      //   params: { uid: event.target.value },
      // })

      window.location.href = `/dashboard/project/${event.target.value}`
    },
  },
}
</script>
<style scoped>
.navbar {
  width: 100%;
  min-width: 100%;
}
</style>
