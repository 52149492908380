<template>
  <div class="card rounded-default">
    <div class="card-body">
      <span
        class="badge mb-2"
        :class="`badge--${projectDetails.status.toLowerCase()}`"
        >{{ projectDetails.status }}</span
      >
      <h6 class="w-75 min-h-60">{{ projectDetails.name }}</h6>
      <div class="d-flex mt-2">
        <span
          class="badge badge--grey"
          v-for="(badge, index) in projectDetails.badges"
          :key="index"
          >{{ badge }}</span
        >
      </div>
      <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Created at</p>
          <h6>{{ moment(projectDetails.created_at).format('LLL') }}</h6>
        </div>
        <div class="project-card-info__details">
          <p>Last edit at</p>
          <h6>
            {{ moment(projectDetails.updated_at).format('LLL') }}
          </h6>
        </div>
      </div>
      <div class="project-card-button mt-3 d-flex align-items-center">
        <Button
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          @click="toggleEditModal(true)"
        >
          <span class="">EDIT PROJECT</span></Button
        >
        <Button
          :loading="false"
          :inactive="false"
          :variant="'outline-primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mt-1"
          @click="goToProjectPage(projectDetails.u_id)"
        >
          <span class="">VIEW PROJECT</span></Button
        >
        <svg
          v-if="showAdminButton"
          class="icon cursor-pointer"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          @click="toggleModal(true)"
        >
          <use xlink:href="/sprite.svg#icon-d-trash" />
        </svg>
      </div>
    </div>
  </div>
  <Modal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :projectDetails="projectDetails"
    :businessSlug="businessSlug"
  ></Modal>
  <EditModal
    :isVisible="editModalIsVisible"
    @toggleEditModal="toggleEditModal"
    :projectDetails="projectDetails"
    :categories="categories"
    :businessSlug="businessSlug"
  ></EditModal>
</template>
<script>
import Button from '@/components/ui/Button'
import Modal from '@/components/dashboard/project/DeleteProjectModal'
import EditModal from '@/components/dashboard/project/UpdateProjectModal'
import moment from 'moment'

export default {
  name: 'SingleProjectCard',
  components: { Button, Modal, EditModal },
  props: {
    projectDetails: {
      type: Object,
    },
    categories: {
      type: Array,
    },
    businessSlug: {
      type: String,
    },
    showAdminButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      moment: moment,
      modalIsVisible: false,
      editModalIsVisible: false,
    }
  },
  computed: {},
  methods: {
    toggleModal(value) {
      this.modalIsVisible = value
    },
    toggleEditModal(value) {
      this.editModalIsVisible = value
    },
    goToProjectPage(uid) {
      window.location.href = `/dashboard/project/${uid}`
    },
  },
  async mounted() {},
}
</script>
<style lang="scss">
.min-h-60 {
  min-height: 60px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
