<template>
  <div class="auth">
    <a href="/">
      <img
        src="@/assets/img/logo-white.svg"
        alt="Fybrr logo"
        class="form__logo"
      />
    </a>
    <form class="form form--authentication" @submit.prevent="handleLogin(user)">
      <h1 class="text-capitalize font-weight-bold fs_lh-64_64">
        Login to my account
      </h1>
      <p class="mb-5 fs_lh-20_25 mt-3">We're excited to have you back!</p>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email"
          v-model="user.email"
          autocomplete="off"
        />
      </div>
      <div class="form-group mt-4">
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="Password"
          v-model="user.password"
          autocomplete="off"
        />
      </div>
      <Button
        :loading="loading"
        :inactive="!allFilled"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="loginButton"
        btnClass="btn-block h-100 mt-2 mb-3 py-2"
        @click="handleLogin(user)"
      >
        <!-- :inactive="!validate" -->
        <span class="fs_lh-16_20 font-weight-normal">Login</span></Button
      >
      <div class="d-flex justify-content-between">
        <div class="form__link">
          <router-link to="/register">Forgot password</router-link>
        </div>
        <div class="form__link">
          Don't have a Fybrr account?
          <router-link to="/register">Create account</router-link>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
// import { response } from 'express'

export default {
  name: 'Login',
  components: { Feedback, Button },
  data() {
    return {
      error: '',
      loading: false,
      user: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true
      this.error = ''
      this.$store
        .dispatch('handleLogin', {
          loginData: user,
        })
        .then(async () => {
          this.loading = false

          let previousLocation = await localStorage.getItem('FROM')

          if (previousLocation) {
            this.$router.push({ path: previousLocation })

            //clear the FROM variable
            localStorage.removeItem('FROM')
          } else {
            this.$router.push({ name: 'DashboardOverview' })
          }
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
    removeError() {
      this.error = ''
    },
  },
  computed: {
    validate() {
      const { email, password } = this.user
      if (email.length && password.length) {
        if (
          email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          return true
        }
      }
      return false
    },
    allFilled() {
      const { email, password } = this.user
      return email !== '' && password !== '' && this.validate
    },
  },
}
</script>
