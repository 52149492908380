<template>
  <div>
    <div class="transfer">
      <div>
        <h5>Delete account</h5>
        <p class="text-xs">Are you sure you want to remove account?</p>
        <div class="alert alert-danger d-flex py-4 mt-3">
          <div>
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-info"></use>
            </svg>
          </div>
          <div class="ml-2">
            <p class="font-weight-normal text-xs">Please note</p>
            <p class="text-xs mt-2">
              Once you delete your account, all your data will be erased, you
              you cannot recover again. Reset password instead
            </p>
          </div>
        </div>
        <div class="form-group mt-4">
          <label for="email" class="font-weight-normal">Auth</label>
          <input
            type="email"
            id="email"
            v-model="auth_token"
            class="form-control"
            placeholder="authorisation token"
          />
        </div>
        <Button
          :loading="false"
          :inactive="true"
          :variant="'danger'"
          :size="'md'"
          type="submit"
          name="editIntegration"
          btnClass="mt-1"
          @click="''"
        >
          <span class="">Delete account</span></Button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'

export default {
  name: 'DeleteAccount',
  components: { Button },
  data() {
    return {
      auth_token: '',
    }
  },
}
</script>
