import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_BUILDER,
  proxyHeaders: false,
  credentials: false,
  timeout: 90000,
})

export const builderUrls = {
  LOGIN_URL: 'auth/login',
}

const login = async params => {
  return await http.post(`${builderUrls.LOGIN_URL}`, params)
}

export { login }
