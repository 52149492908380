<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="ecommerce" />
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="card project-create-card rounded-default card-dashed">
            <h6>Create new online store</h6>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="createOnlineStore"
              btnClass="mt-3"
              @click="$router.push({ name: 'CreateStore' })"
            >
              <span class="">create new store</span></Button
            >
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(store, index) in paginateData"
          :key="index"
        >
          <SingleStoreCard
            :storeDetails="store"
            :showAdminButton="showAdminButton"
          ></SingleStoreCard>
        </div>
      </div>
      <OverlayLoading v-if="loaderIsVisible" />
      <nav class="page-dashboard__pagination">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import SingleStoreCard from '@/components/dashboard/project/SingleStoreCard'
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapGetters } from 'vuex'
import { Paginator } from 'array-paginator'

export default {
  name: 'Online Store',
  components: { ProjectNav, SingleStoreCard, Button, OverlayLoading },
  data() {
    return {
      stores: [],
      loaderIsVisible: true,
      projectUID: this.$route.params,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  methods: {
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
  },
  computed: {
    ...mapGetters([
      'getStoresStatus',
      'getStores',
      'getProjectStatus',
      'getProject',
      'getUserInfoStatus',
      'getBusinessInfo',
    ]),
  },
  watch: {
    getStores: {
      handler(value) {
        //Paginate the data manually
        this.paginator = new Paginator(value, 8, 1)

        //Set the first page as the data
        this.paginateData = this.paginator.first()
        this.totalPages = this.paginator.totalPages
      },
    },
    getStoresStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
}
</script>
<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
