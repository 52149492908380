<template>
  <main class="contact">
    <Navbar></Navbar>
    <div class="container contact-container px-0">
      <div class="row">
        <div class="col-lg-6">
          <form class="contact-form p-5">
            <h3 class="fs_lh-42_42">
              Get in touch - or pop in and see us. Let's chat
            </h3>
            <div class="form-group mt-5">
              <input type="text" class="form-control" placeholder="Full name" />
            </div>
            <div class="form-group mt-2">
              <input type="email" class="form-control" placeholder="Email" />
            </div>
            <div class="form-group mt-2">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                class="form-control"
                placeholder="Type message"
              ></textarea>
            </div>
            <Button
              :variant="'primary'"
              :loading="loading"
              :size="'md'"
              type="submit"
              btnClass="contactButton"
              name="contactButton"
              @click="''"
            >
              <span class="px-5 py-3">Send message</span></Button
            >
          </form>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { defineComponent } from 'vue'
import Navbar from '@/components/pages/navbar'
import Button from '@/components/ui/Button'
import { ref } from 'vue'
export default defineComponent({
  name: 'Contact',
  components: { Navbar, Button },
  setup() {
    const loading = ref(false)

    return { loading }
  },
})
</script>
<style scoped lang="scss">
.contact-form {
  border-radius: 10px;
  background-color: #ffffff;
  input,
  textarea {
    background-color: #eeeeee;
    color: #2e384d;
    border: none;
    font-weight: 500;
  }
  .contactButton {
    height: fit-content;
  }
  .form-control {
    padding: 10px 20px;
    border-radius: 8px;
  }
}
</style>
