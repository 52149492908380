import http from '../../config/axios'

export const transactionUrls = {
  ALL_TRANSACTIONS: 'transactions',
  INITIALIZE_PAYMENT: 'transactions/initialize',
  RENEW_PILOT: 'transactions/renew-pilot',
  VERIFY_PAYMENT: 'payment-hooks',
  ACTIVATE_SUBSCRIPTION: 'subscription/activate',
  EXPERTS_PRICING: 'resources/pricing/experts',
  HIRE_EXPERTS: 'experts/hire',
}

/**Get experts pricing */
const getExpertsPricing = async params => {
  return await http.get(
    `${transactionUrls.EXPERTS_PRICING}?country=${params.country}&currency=${params.currency}`
  )
}

/** getAllTransactions */
const getAllTransactionsByType = async params => {
  return await http.get(
    `${transactionUrls.ALL_TRANSACTIONS}/${params.slug}?type=${params.bodyData.type}`,
    params.bodyData
  )
}

/** getAllTransactions */
const getAllTransactions = async params => {
  return await http.get(
    `${transactionUrls.ALL_TRANSACTIONS}/${params.slug}`,
    params.bodyData
  )
}

const initializePayment = async params => {
  return await http.post(transactionUrls.INITIALIZE_PAYMENT, params)
}

const renewPilot = async pilotID => {
  return await http.post(transactionUrls.RENEW_PILOT, pilotID)
}

const verifyPayment = async params => {
  return await http.get(
    `${transactionUrls.VERIFY_PAYMENT}?reference=${params.reference}&transaction_id=${params.transaction_id}`
  )
}

const activateSubscription = async params => {
  return await http.post(`${transactionUrls.ACTIVATE_SUBSCRIPTION}`, params)
}

const hireExpert = async params => {
  return await http.post(`${transactionUrls.HIRE_EXPERTS}`, params)
}

export {
  getAllTransactions,
  initializePayment,
  renewPilot,
  verifyPayment,
  getAllTransactionsByType,
  activateSubscription,
  getExpertsPricing,
  hireExpert,
}
