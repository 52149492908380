<template>
  <main class="website-builder-app">
    <WebsiteBuilderSideBar
      :sectionsVisibility="isSectionVisible"
      @toggleSection="toggleVisibility"
    />
    <section class="builder-body">
      <WebsiteBuilderNavbar
        @toggleSection="toggleVisibility"
        @changeFrameDimension="frameDimension"
      />
      <section class="section">
        <select class="page-input" name="" id="">
          <option value="">Homepage</option>
          <option value="">About</option>
          <option value="">Pricing</option>
          <option value="">Login</option>
        </select>
        <div class="section-body">
          <div
            class="wrap viewing-desktop"
            :style="
              !frameDevice.isDesktop && {
                width: `${frameDevice.width}px`,
                height: `${frameDevice.height}px`,
              }
            "
          >
            <iframe
              src="/builder/index.html"
              class="section_screen"
              id="website-builder_iframe"
            ></iframe>
          </div>
        </div>
      </section>
    </section>
    <CodeModal id="codeSection"></CodeModal>
  </main>
</template>

<script>
import { defineComponent, ref } from 'vue'
import WebsiteBuilderSideBar from '@/components/website-builder/Sidebar'
import WebsiteBuilderNavbar from '@/components/website-builder/Navbar'
import CodeModal from '@/components/website-builder/modal'
export default defineComponent({
  name: 'WebsiteBuilder',
  components: {
    WebsiteBuilderSideBar,
    WebsiteBuilderNavbar,
    CodeModal,
  },
  setup() {
    const isSectionVisible = ref(false)
    const modalIsVisible = ref(false)
    const toggleModal = value => {
      modalIsVisible.value = value
    }
    const toggleVisibility = () => {
      isSectionVisible.value = !isSectionVisible.value
    }
    const frameDevice = ref({ isDesktop: true, width: 0, height: 0 })
    const frameDimension = device => {
      if (device === 'phone') {
        frameDevice.value = { isDesktop: false, width: 375, height: 667 }
      } else if (device === 'tablet') {
        frameDevice.value = { isDesktop: false, width: 1024, height: 768 }
      } else {
        frameDevice.value = { isDesktop: true, width: 0, height: 0 }
      }
      console.log(frameDevice.value)
    }

    return {
      isSectionVisible,
      toggleVisibility,
      frameDimension,
      frameDevice,
      modalIsVisible,
      toggleModal,
    }
  },
})
</script>

<style>
.navbar > .container {
  display: block !important;
}
</style>
