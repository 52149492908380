<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="alert alert-success" v-if="success">
      {{ success }}
    </div>
    <div>
      <h6>{{ modalObj.title }}</h6>
      <label>
        {{ modalObj.label }}
      </label>
    </div>
    <div class="d-flex align-items-center mt-4">
      <Button
        :loading="loading"
        :inactive="false"
        :variant="'primary'"
        :size="'md'"
        type="submit"
        name="pilotAction"
        btnClass="px-5"
        @click="makeAction(modalObj.params)"
      >
        <!-- :inactive="!validate" -->
        <span class="">{{ modalObj.buttonText }}</span></Button
      >
      <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
        Cancel
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions } from 'vuex'

export default {
  name: 'PilotActionsModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    modalObj: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      success: '',
      error: '',
    }
  },
  methods: {
    ...mapActions(['handleGetUsersFalcons']),
    async makeAction(params) {
      this.loading = true
      this.error = ''
      this.success = ''

      const actionFunction = this.modalObj.action

      await actionFunction(params)
        .then(async response => {
          if (
            response.data.ip !== null &&
            this.modalObj.type === 'renew pilot'
          ) {
            this.success = 'Pilot was successfully provisioned!'
          } else if (this.modalObj.type === 'delete pilot') {
            this.success = 'Pilot was successfully deleted!'
          } else {
            this.success = response.data.message
          }

          if (this.modalObj.type !== 'delete task') {
            //reload the list
            await this.handleGetUsersFalcons(this.$route.params.uid)
          } else {
            const reloadAction = this.modalObj.reloadAction
            const reloadActionParam = this.modalObj.reloadActionParam

            await reloadAction(reloadActionParam)
          }

          if (
            this.modalObj.type === 'power on' ||
            this.modalObj.type === 'power off' ||
            this.modalObj.type === 'provision pilot' ||
            this.modalObj.type === 'deleteKey' ||
            this.modalObj.type === 'delete pilot' ||
            this.modalObj.type === 'delete task'
          ) {
            this.show = false
            this.loading = false
          } else {
            this.loading = false
          }
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('togglePilotActions', value)

        this.error = ''
        this.success = ''
      },
    },
  },
}
</script>
