import http from '../../config/axios'

export const authUrls = {
  LOGIN_URL: 'api/login',
  REGISTER_URL: 'api/user/create',
  VERIFY_URL: 'api/user/verify',
  GET_USER_URL: 'users/current-user',
  LOGOUT_URL: 'logout',
  UPDATE_URL: 'users/update',
  FORGOT_PASSWORD_URL: 'api/user/forgot',
  RESET_PASSWORD_URL: 'api/user/forgotpassword',
  UPLOAD_AVATAR: 'users/upload-avatar',
}

/** Log user in */
const login = async params => {
  return await http.post(authUrls.LOGIN_URL, params)
}

const forgot_password = async params => {
  return await http.post(authUrls.FORGOT_PASSWORD_URL, params)
}

const reset_password = async params => {
  return await http.post(authUrls.RESET_PASSWORD_URL, params)
}

/** register user in */
const register = async params => {
  return await http.post(authUrls.REGISTER_URL, params)
}

/** register user in */
const verify = async token => {
  return await http.get(`${authUrls.VERIFY_URL}/${token}`)
}

/** get logged in user */
const get_logged_in_user = async () => {
  return await http.get(authUrls.GET_USER_URL)
}

/** update user */
const update_user_profile = async params => {
  return await http.post(authUrls.UPDATE_URL, params)
}

/** upload user avatar */
const upload_user_avatar = async avatar => {
  return await http.post(authUrls.UPLOAD_AVATAR, avatar)
}

export {
  login,
  register,
  forgot_password,
  reset_password,
  verify,
  get_logged_in_user,
  update_user_profile,
  upload_user_avatar,
}
