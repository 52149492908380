<template>
  <div class="page-hire__form">
    <h6>Let us know how we can help</h6>
    <p class="text-xs">Select from our flexible pricing</p>
    <div class="mt-4">
      <h3 class="text-primary">£{{ amountToBePaid }} / month</h3>
      <p class="text-xs">
        Select how many developers you want to work with and number of days per
        week will the developers work for
      </p>
    </div>

    <div class="row mt-4">
      <div class="col-md-6 form-group">
        <label for="noOfDevelopers" class="font-weight-normal"
          >How many developers do you want</label
        >
        <select
          name="noOfDevelopers"
          id="noOfDevelopers"
          class="form-control"
          v-model="params.quantity"
          @change="calculateDeveloperFee($event)"
        >
          <option :value="i" v-for="i in 24" :key="i">{{ i }}</option>
        </select>
      </div>
      <div class="col-md-6 form-group">
        <label for="noOfDays" class="font-weight-normal"
          >How many days per week</label
        >
        <select
          name="noOfDays"
          id="noOfDays"
          class="form-control"
          @change="changePricing($event, expertsPricing)"
        >
          <option
            v-for="singlePricing in expertsPricing"
            v-bind:value="JSON.stringify(singlePricing)"
            v-bind:key="singlePricing.id"
            v-bind:id="expertsPricing.indexOf(singlePricing)"
          >
            {{ expertsPricing.indexOf(singlePricing) }} days
          </option>
        </select>
      </div>
    </div>
    <div class="pros">
      What you will get
      <ul>
        <li>{{ params.quantity }} developer</li>
        <li>Git repositories</li>
        <li>{{ dayPerWeek }} day per week</li>
        <li>Site Templates</li>
        <li>Dedicated Support</li>
        <li>Project Maintenance</li>
      </ul>
    </div>
    <Button
      :loading="false"
      :inactive="false"
      :variant="'primary'"
      :size="'md'"
      type="submit"
      name="submit"
      btnClass=""
      @click="toggleModal(true)"
    >
      <span class="font-weight-normal">Hire an Expert</span>
    </Button>
  </div>
  <HireExpertModal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :amountToBePaid="amountToBePaid"
    :params="params"
  ></HireExpertModal>
</template>
<script>
import Button from '@/components/ui/Button'
import HireExpertModal from '@/components/dashboard/hireExpert/hireExpertModal'
import { mapActions } from 'vuex'
export default {
  name: 'HireExpertForm',
  components: { Button, HireExpertModal },
  data() {
    return {
      pricePerMonth: 800,
      dayPerWeek: 1,
      modalIsVisible: false,
      params: {
        quantity: 1,
        stripe_token: 'prod_LM9eiSWJS87gHk',
        plan_id: 'price_1KfRLIHxuG3TUMgkN6xVfWtw',
      },
      amountToBePaid: 800,
    }
  },
  props: {
    expertsPricing: {
      type: Array,
    },
    userInfo: {
      type: Object,
    },
  },
  methods: {
    ...mapActions['handleHireExpert'],
    //Go to the other form
    handleApplyForExpert() {
      this.$emit('applyForExpert')
    },
    changePricing(event, objectArray) {
      let currentPricing = JSON.parse(event.target.value)

      //change num of days text
      objectArray.forEach((pricing, index) => {
        if (pricing.id === currentPricing.id) this.dayPerWeek = index
      })

      //Change the price per month
      this.pricePerMonth = parseInt(currentPricing.amount)

      //re-calculate pricing
      this.calculateDeveloperFee(this.params.quantity)

      //change the required params - plan_id, quantity etc
      this.params.stripe_token = currentPricing.plan_token
      this.params.plan_id = currentPricing.plan_id
    },
    calculateDeveloperFee(event) {
      let numOfDeveloper = 1

      if (event.target !== undefined) {
        numOfDeveloper = event.target.value
      } else {
        numOfDeveloper = event
      }

      //multiple the developer fee with the current billing amount
      this.amountToBePaid = parseInt(numOfDeveloper) * this.pricePerMonth
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },
    //Hire an expert
    // hireAnExpert() {},
  },
}
</script>
