<template>
  <div class="website-card card">
    <div class="card-body">
      <div class="cb-img" v-if="template.image">
        <figure :style="{ backgroundImage: `url(${template.image})` }"></figure>
      </div>
      <h4
        v-else
        class="d-flex align-items-center h-100 justify-content-center text-uppercase text-muted"
      >
        {{ template.name.split(' ')[0] }}
      </h4>
    </div>
    <div class="card-footer">
      <p class="name">{{ template.name }}</p>
      <router-link :to="template.link" class="price font-weight-normal"
        >Get started</router-link
      >
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home Template Card',
  components: {},
  props: {
    template: {
      type: Object,
    },
  },
})
</script>
<style scoped lang="scss">
.website-card {
  border: 1px solid rgba(99, 122, 135, 0.24);
  .card-footer {
    background: none;
  }
}
</style>
