import * as types from '@/store/modules/team/types'
import {
  getAllTeamMembers,
  deleteTeamMember,
  deleteInvitation,
  reInviteMember,
} from '@/services/team'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetAllTeamMembers({ commit }, slug) {
    commit(types.TEAM_MEMBERS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllTeamMembers(slug)
        .then(response => {
          commit(types.TEAM_MEMBERS, response.data.data)
          commit(types.TEAM_MEMBERS_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.TEAM_MEMBERS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteTeamMember({ commit }, memberID) {
    commit(types.DELETE_MEMBER_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteTeamMember(memberID)
        .then(response => {
          commit(types.DELETE_MEMBER, response.data.data)
          commit(types.DELETE_MEMBER_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_MEMBER_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleDeleteInvitation({ commit }, invitationID) {
    commit(types.DELETE_INVITATION_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      deleteInvitation(invitationID)
        .then(response => {
          commit(types.DELETE_INVITATION, response.data.data)
          commit(types.DELETE_INVITATION_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.DELETE_INVITATION_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleReInviteMember({ commit }, params) {
    commit(types.RE_INVITE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      reInviteMember(params)
        .then(response => {
          commit(types.RE_INVITE, response.data.data)
          commit(types.RE_INVITE_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.RE_INVITE_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
