<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="collaborators" />
      <div class="hosting-table" v-if="loaded">
        <div class="my-3">
          <Button
            :loading="false"
            :inactive="false"
            :variant="'primary'"
            :size="'md'"
            type="submit"
            name="addPilot"
            @click="toggleModal(true)"
          >
            <span class="">Add new collaborator</span></Button
          >
          <Button
            :loading="false"
            :inactive="false"
            :variant="'gray'"
            :size="'md'"
            type="submit"
            name="pending requests"
            btnClass="ml-2"
            @click="this.$router.push({ name: 'DashboardTeams' })"
          >
            <span class="">Invite Collaborator</span></Button
          >
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">DATE CREATED</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(collaborator, index) in collaborators" :key="index">
              <td class="font-weight-light">
                {{ collaborator?.profile.first }}
                {{ collaborator?.profile.last }}
                <span
                  v-if="collaborator?.owner === true"
                  class="badge badge--blue-bg font-weight-normal"
                  >Owner</span
                >
              </td>
              <td>{{ collaborator?.profile.email }}</td>
              <td>{{ moment(collaborator?.created_at).format('LLL') }}</td>
              <td>
                <Button
                  v-if="showAdminButton"
                  :loading="false"
                  :inactive="false"
                  :variant="'outline-grey'"
                  :size="'md'"
                  type="submit"
                  name="submit"
                  btnClass="px-5"
                  @click="toggleDeleteModal(true, collaborator.id)"
                >
                  <span class="font-weight-normal">Remove</span>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav class="page-dashboard__pagination mr-4 mb-3">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
    </main>
  </div>
  <Modal
    :showModal="modalIsVisible"
    @toggleModal="toggleModal"
    :projectId="projectID"
    :teamMembers="teamMembers"
    :projectUid="this.$route.params.uid"
  ></Modal>
  <DeleteModal
    :isVisible="deleteModalIsVisible"
    @toggleDeleteModal="toggleDeleteModal"
    :collaboratorID="deleteMemberID"
  >
  </DeleteModal>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import Modal from '@/components/dashboard/project/AddMemberModal'
import DeleteModal from '@/components/dashboard/project/DeleteCollaboratorModal'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import Button from '@/components/ui/Button'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { Paginator } from 'array-paginator'

export default {
  name: 'Collaborators',
  components: { ProjectNav, Button, Modal, DeleteModal, OverlayLoading },
  data() {
    return {
      modalIsVisible: false,
      deleteModalIsVisible: false,
      collaborators: [],
      moment: moment,
      deleteMemberID: 0,
      projectID: 0,
      loaded: false,
      loaderIsVisible: true,
      teamMembers: [],
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  methods: {
    ...mapActions(['handleGetAllTeamMembers']),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },
    toggleDeleteModal(value, collaboratorID) {
      this.deleteMemberID = collaboratorID
      this.deleteModalIsVisible = value
    },
  },
  computed: {
    ...mapGetters([
      'getProjectMembersStatus',
      'getProjectMembers',
      'getProjectStatus',
      'getProject',
      'getTeamMembers',
      'getTeamMembersStatus',
      'getBusinessInfo',
      'getUserInfoStatus',
    ]),
  },
  watch: {
    getProjectMembersStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.collaborators = this.getProjectMembers

          //Paginate the data manually
          this.paginator = new Paginator(this.collaborators, 15, 1)

          //Set the first page as the data
          this.paginateData = this.paginator.first()
          if (this.paginateData === undefined) {
            this.paginateData = []
          }
          this.totalPages = this.paginator.totalPages
          this.loaded = true
          this.loaderIsVisible = false
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectID = this.getProject.id
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getTeamMembersStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.teamMembers = this.getTeamMembers
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
}
</script>
<style lang="scss">
.hosting-table {
  &__options {
    .badge {
      border-radius: 12px;
      padding: 8px 14px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
    }
  }
  thead {
    th {
      border-top: none;
      font-weight: 300;
    }
  }
}
</style>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
