<template>
  <div class="page__body">
    <div class="page__head">
      <h6>Project</h6>
      <p>Manage or create new projects</p>
    </div>
    <main class="page-dashboard mt-5 px-4 py-2 border rounded-default">
      <h6 class="mt-3 mb-4">All projects</h6>
      <div class="row mt-3 mb-3">
        <div class="col-md-4">
          <div class="card project-create-card rounded-default card-dashed">
            <h6>Create new project</h6>
            <Button
              :loading="false"
              :inactive="false"
              :variant="'primary'"
              :size="'md'"
              type="submit"
              name="editProject"
              btnClass="mt-3"
              @click="$router.push({ name: 'CreateProjects' })"
            >
              <span class="">create new project</span></Button
            >
          </div>
        </div>

        <div
          class="col-md-4"
          v-for="(project, index) in paginateData"
          :key="index"
        >
          <SingleProjectCard
            :projectDetails="project"
            :categories="categories"
            :businessSlug="userSlug"
            :showAdminButton="showAdminButton"
          ></SingleProjectCard>
        </div>
      </div>
      <nav class="page-dashboard__pagination mr-3 mt-4">
        <ul class="pagination">
          <li
            class="page-item"
            v-for="index in this.totalPages"
            :key="index"
            @click="changePaginatedData(index)"
            :class="`${activePage(index)}`"
          >
            <a class="page-link" href="#">{{ index }}</a>
          </li>
        </ul>
      </nav>
      <OverlayLoading v-if="loaderIsVisible" />
    </main>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import SingleProjectCard from '@/components/dashboard/project/SingleProjectCard'
import { mapActions, mapGetters } from 'vuex'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { Paginator } from 'array-paginator'

export default {
  name: 'Projects',
  components: { SingleProjectCard, Button, OverlayLoading },
  data() {
    return {
      projects: [],
      loaderIsVisible: true,
      paginator: Paginator,
      paginateData: [],
      totalPages: 0,
      currentPage: 1,
      categories: [],
      userSlug: '',
      projectBusinessID: null,
      userID: null,
      showAdminButton: false,
    }
  },
  methods: {
    ...mapActions([
      'fetchAllAuthStates',
      'handleGetUsersProjects',
      'handleGetLoggedInUser',
      'handleGetUsersWorkspaces',
      'handleGetProjectCategories',
    ]),
    changePaginatedData(page) {
      this.paginateData = this.paginator.page(page)
    },
    activePage(page) {
      if (page === this.paginator.currentPage) {
        return `bg-primary inner_text`
      }
    },
  },
  computed: {
    ...mapGetters([
      'getProjectsArray',
      'getProjectsStatus',
      'getSelectedWorkspace',
      'getProjects',
      'getUserInfoStatus',
      'getProjectStatus',
    ]),
  },
  watch: {
    //When data is updated
    getProjectsStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projects = this.getProjectsArray
          //Paginate the data manually
          this.paginator = new Paginator(this.projects, 11, 1)
          //Set the first page as the data
          this.paginateData = this.paginator.first()
          this.totalPages = this.paginator.totalPages
          this.loaderIsVisible = false
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectBusinessID = this.getProject.business_id

          if (this.projectBusinessID === this.userID) {
            this.showAdminButton = true
          }
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.userID = this.getBusinessInfo.user_id
        }
      },
    },
  },
  async mounted() {
    //Set the user slug - check with localStorage or Vuex Getter
    const result = await this.handleGetUsersWorkspaces()
    if (result.status === 200) {
      this.userSlug = this.getSelectedWorkspace.slug
      const categoryResult = await this.handleGetProjectCategories()

      if (categoryResult.status === 200) {
        this.categories = categoryResult.data.data
      }

      if (this.getProjectsStatus === 'COMPLETED') {
        this.projects = this.getProjectsArray
        //Paginate the data manually
        this.paginator = new Paginator(this.projects, 11, 1)

        //Set the first page as the data
        this.paginateData = this.paginator.first()
        this.totalPages = this.paginator.totalPages
      } else {
        if (!this.userSlug) {
          this.userSlug = await localStorage.getItem('selected_workspace_slug')
          await this.fetchAllAuthStates(this.userSlug)
        }
      }
    }
  },
}
</script>

<style scoped>
.inner_text > a {
  color: white !important;
  background: #007bff;
}
</style>
