<template>
  <main class="landing">
    <router-view></router-view>
    <Footer></Footer>
  </main>
</template>
<script>
import Footer from '@/components/pages/footer'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LandingLayout',
  components: { Footer },
})
</script>
