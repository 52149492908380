<template>
  <div class="page__body">
    <div class="page__head">
      <h6>Project</h6>
      <p>Manage or create new projects</p>
    </div>
    <main
      class="page-dashboard page-dashboard__create mt-5 px-4 py-4 border rounded-default"
    >
      <h6>Choose how you want to create your project</h6>
      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="card rounded-default py-3">
            <div class="card-body">
              <img src="@/assets/img/logo.svg" alt="logo" class="logo" />
              <div class="my-4">
                <h6>Work with a Fybrr Developer expert</h6>
                <p class="mt-2">
                  Answer a few simple questions and automatically get a website
                  designed just for you in minutes
                </p>
              </div>
              <Button
                :loading="false"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="editProject"
                btn-class="mt-4"
                @click="$router.push('/dashboard/hire-expert')"
              >
                <span class="">Get started</span></Button
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card rounded-default py-3">
            <div class="card-body">
              <div class="icon-with-border">
                <svg
                  class="icon icon-mailbox"
                  width="44px"
                  height="44px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-d-activity"></use>
                </svg>
              </div>
              <div class="my-4">
                <h6>Create Your Project on your own</h6>
                <p class="mt-2">
                  Start with a template and make it your own, with easy drag and
                  drop & 1000s of design features.
                </p>
              </div>
              <Button
                :loading="false"
                :inactive="false"
                :variant="'primary'"
                :size="'md'"
                type="submit"
                name="editProject"
                btn-class="mt-4"
                @click="toggleModal(true)"
              >
                <span class="">Get started</span></Button
              >
            </div>
          </div>
        </div>
      </div>
      <OverlayLoading v-if="loaderIsVisible" />

      <!-- This modal won't show if the business id is not being sent to the modal -->
      <Modal
        :isVisible="modalIsVisible"
        @toggleModal="toggleModal"
        :categories="this.categories"
        :businessId="this.business_id"
      ></Modal>
    </main>
  </div>
</template>
<script>
import Button from '@/components/ui/Button'
import Modal from '@/components/dashboard/project/CreatProjectModal'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateProject',
  components: { Button, Modal, OverlayLoading },
  data() {
    return {
      modalIsVisible: false,
      loaderIsVisible: false,
      business_id: '',
      categories: [],
    }
  },
  watch: {
    getProjectCategories() {
      if (this.getProjectsStatus === 'COMPLETED') {
        this.categories = this.getProjectCategories
      }
    },
  },
  computed: {
    ...mapGetters(['getProjectCategories', 'getSelectedWorkspace']),
  },
  methods: {
    toggleModal(value) {
      this.modalIsVisible = value
    },
    ...mapActions([
      'fetchAllAuthStates',
      'handleGetUsersWorkspaces',
      'handleGetProjectCategories',
    ]),
  },
  async mounted() {
    //load the needed data
    const userInfo = await this.handleGetUsersWorkspaces()

    if (userInfo.status === 200) {
      let userSlug = this.getSelectedWorkspace.slug
      this.business_id = this.getSelectedWorkspace.id.toString()

      //dispatch functions
      this.fetchAllAuthStates(userSlug)

      const result = await this.handleGetProjectCategories()

      this.categories = result.data.data
    }
  },
}
</script>
