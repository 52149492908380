<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        class="mb-1"
        ><p class="text-xs text-center w-100 px-2 my-auto">
          {{ error }}
        </p></feedback
      >
      <div class="alert alert-success" role="alert" v-if="success">
        {{ success }}
      </div>
      <h6>Create Task</h6>
      <p class="description">You can change this later</p>
      <div class="form-group">
        <label for="title">Title of task</label>
        <input
          type="text"
          class="form-control"
          id="title"
          maxlength="30"
          placeholder="Enter title of task"
          v-model="task.title"
        />
      </div>
      <div class="form-group">
        <label for="priority">Priority</label>
        <select
          name="priority"
          id="priority"
          class="form-control"
          v-model="task.priority"
        >
          <option :value="undefined" selected hidden disabled>
            Select priority level
          </option>
          <option value="low">Low</option>
          <option value="normal">Normal</option>
          <option value="critical">Critical</option>
          <option value="important">Important</option>
        </select>
      </div>
      <div class="form-group">
        <label for="status">Status</label>
        <select
          name="status"
          id="status"
          class="form-control"
          v-model="task.status"
        >
          <option :value="undefined" selected hidden disabled>
            Select workflow status
          </option>
          <option value="todo">Todo</option>
          <option value="in_progress">In Progress</option>
          <option value="review">In Review</option>
          <option value="done">Done</option>
        </select>
      </div>
      <div class="form-group">
        <label>Start Date:</label>
        <input
          type="date"
          class="form-control"
          placeholder="Start Date"
          v-model="task.start_date"
          required
        />
      </div>
      <div class="form-group">
        <label>End Date:</label>
        <input
          type="date"
          class="form-control"
          placeholder="End Date"
          v-model="task.end_date"
          required
        />
      </div>
      <div class="form-group">
        <label for="assignee">Assignee</label>
        <select
          name="assignee"
          id="assignee"
          class="form-control"
          v-model="unfilteredAssignees"
          multiple
        >
          <option :value="''" disabled selected>
            Hold down the control (ctrl) button to select multiple options
          </option>
          <option
            v-for="member in teamMembers"
            v-bind:key="member.profile?.id"
            v-bind:value="member.profile?.id"
          >
            {{ member.profile?.first }} {{ member?.profile.last }} ({{
              member.profile?.email
            }})
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="description">Task description</label>
        <Editor
          :initial-value="task.description"
          api-key="uypuxdvqkqw9e4ua1bo1olwfig921cslj0z12oc12n1esk32"
          v-model="task.description"
          :init="{
            plugins: 'lists link image table code help wordcount',
          }"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="createTask"
          btnClass="px-5"
          @click="addTask"
        >
          <!-- :inactive="!validate" -->
          <span class="">Create task</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import Feedback from '@/components/ui/Feedback'
import Editor from '@tinymce/tinymce-vue'
import { mapActions } from 'vuex'

export default {
  name: 'createTaskModal',
  components: { Button, Feedback, Editor: Editor },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    teamMembers: {
      type: Array,
    },
    projectID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      error: '',
      success: '',
      unfilteredAssignees: [],
      task: {
        title: '',
        priority: '',
        description: '',
      },
    }
  },
  methods: {
    ...mapActions(['handleGetTasks', 'handleAddTask']),
    removeError() {
      this.error = ''
    },
    async addTask() {
      this.loading = true
      this.error = ''
      this.success = ''

      //Replace the assignees with enum
      const assignees = this.unfilteredAssignees.join(',')
      const project_id = this.projectID

      const newTask = { ...this.task, assignees, project_id }

      //dispatch function to create task
      await this.handleAddTask(newTask)
        .then(async response => {
          this.success = response.data.message

          await this.handleGetTasks(this.projectID)

          this.redirect()
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },

    redirect() {
      setTimeout(() => {
        //disable loading button
        this.loading = false
        this.success = ''
        this.error = ''
        //clear the fields
        this.task = {}
        this.task.description = ''
        //disable the modal
        this.show = false

        this.unfilteredAssignees = []
      }, 1000)
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
}
</script>
