import * as types from '@/store/modules/transaction/types'
import {
  getAllTransactions,
  initializePayment,
  renewPilot,
  verifyPayment,
  getAllTransactionsByType,
  activateSubscription,
  getExpertsPricing,
  hireExpert,
} from '@/services/transaction'
import { formatError } from '@/store/helpers/error-helper'

export default {
  handleGetAllBillings({ commit }, params) {
    commit(types.BILLINGS_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllTransactions(params)
        .then(response => {
          commit(types.BILLINGS, response.data)
          commit(types.BILLINGS_STATUS, 'COMPLETED')
          resolve(response.data)
        })
        .catch(error => {
          formatError(error)
          commit(types.BILLINGS_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetAllBillingsByType({ commit }, params) {
    commit(types.BILLINGS_BY_TYPE_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getAllTransactionsByType(params)
        .then(response => {
          commit(types.BILLINGS_BY_TYPE, response.data)
          commit(types.BILLINGS_BY_TYPE_STATUS, 'COMPLETED')
          resolve(response.data)
        })
        .catch(error => {
          formatError(error)
          commit(types.BILLINGS_BY_TYPE_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleInitializePayment({ commit }, params) {
    commit(types.INITIALIZE_PAYMENT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      initializePayment(params)
        .then(response => {
          commit(types.INITIALIZE_PAYMENT, response.data)
          commit(types.INITIALIZE_PAYMENT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.INITIALIZE_PAYMENT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleRenewPilot({ commit }, params) {
    commit(types.RENEW_PILOT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      renewPilot(params)
        .then(response => {
          commit(types.RENEW_PILOT, response.data)
          commit(types.RENEW_PILOT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.RENEW_PILOT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleVerifyPayment({ commit }, params) {
    commit(types.VERIFY_PAYMENT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      verifyPayment(params)
        .then(response => {
          commit(types.VERIFY_PAYMENT, response.data)
          commit(types.VERIFY_PAYMENT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.VERIFY_PAYMENT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleActivateSubscription({ commit }, params) {
    commit(types.ACTIVATE_SUBSCRIPTION_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      activateSubscription(params)
        .then(response => {
          commit(types.ACTIVATE_SUBSCRIPTION, response.data)
          commit(types.ACTIVATE_SUBSCRIPTION_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.ACTIVATE_SUBSCRIPTION_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleGetExpertsPricing({ commit }, params) {
    commit(types.EXPERTS_PRICING_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      getExpertsPricing(params)
        .then(response => {
          commit(types.EXPERTS_PRICING, response.data.data)
          commit(types.EXPERTS_PRICING_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.EXPERTS_PRICING_STATUS, 'PENDING')
          reject(error)
        })
    })
  },

  handleHireExpert({ commit }, params) {
    commit(types.HIRE_EXPERT_STATUS, 'LOADING')
    return new Promise((resolve, reject) => {
      hireExpert(params)
        .then(response => {
          commit(types.HIRE_EXPERT, response.data)
          commit(types.HIRE_EXPERT_STATUS, 'COMPLETED')
          resolve(response)
        })
        .catch(error => {
          formatError(error)
          commit(types.HIRE_EXPERT_STATUS, 'PENDING')
          reject(error)
        })
    })
  },
}
