import { Authorization } from '@/utils/authorization'

export default (to, from, next) => {
  if (!Authorization.check.isAuthenticated()) {
    next()
  } else {
    next({
      path: '/dashboard',
    })
  }
}
