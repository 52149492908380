<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <!-- The alert messages -->
      <div class="py-2">
        <div class="alert alert-success" role="alert" v-if="success">
          {{ success }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ error }}
        </div>
      </div>
      <h6>Add Team member</h6>
      <p class="description">Add team member</p>
      <div class="form-group">
        <label for="member_email">Email</label>
        <input
          type="email"
          class="form-control"
          id="member_email"
          placeholder="Email Address"
          v-model="member.email"
        />
      </div>
      <div class="form-group">
        <label for="member_name">Name</label>
        <input
          type="text"
          class="form-control"
          id="member_name"
          placeholder="Name"
          v-model="member.name"
        />
      </div>
      <div class="form-group">
        <label for="member_role">Role</label>
        <select
          name="member_role"
          id="member_role"
          class="form-control"
          v-model="member.role"
        >
          <option value="" disabled>Select a Role</option>
          <option value="maintainer">Maintainer</option>
          <option value="developer">Developer</option>
          <option value="guest">Guest</option>
        </select>
      </div>
      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="addTeamMember"
          btnClass="px-5"
          @click.prevent="addCollaborator"
        >
          <!-- :inactive="!validate" -->
          <span class="">Add team member</span></Button
        >
        <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Button from '@/components/ui/Button'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AddTeamMemberModal',
  components: { Button },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    businessID: {
      type: Number,
      required: true,
    },
    userSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      member: {
        email: '',
        name: '',
        role: '',
      },
      success: false,
      error: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getUserInfoStatus']),
  },
  methods: {
    ...mapActions(['handleGetAllTeamMembers', 'handleGetInvitations']),
    async addCollaborator() {
      const params = {
        ...this.member,
        business_id: this.businessID,
        target_url: `${location.origin}/invitation`,
      }

      this.loading = true
      this.error = ''

      //This will avoid inviting a team member to a null workspace
      if (this.businessID !== 0) {
        this.$store
          .dispatch('handleInviteCollaborator', params)
          .then(async response => {
            this.success = response.data.message

            //Reload the Invitations list
            await this.handleGetInvitations(this.userSlug)
            await this.handleGetAllTeamMembers(this.userSlug)

            setTimeout(this.redirect, 2000)
          })
          .catch(error => {
            this.loading = false
            this.error = error.response.data.message
          })
      }
    },
    redirect() {
      this.success = false
      this.error = ''
      this.show = false
      this.loading = false
      //update list of websites
    },
    allFilled() {
      return (
        this.member.email !== '' &&
        this.member.name !== '' &&
        this.member.role !== ''
      )
    },
  },
  watch: {
    isVisible: {
      handler(value) {
        this.show = value
      },
    },
    show: {
      handler(value) {
        this.$emit('toggleModal', value)
      },
    },
  },
}
</script>
