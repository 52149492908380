<template>
  <component :is="layoutComponent" v-if="!isInitialRoute">
    <router-view />
  </component>
</template>

<script>
import LandingLayout from './components/layouts/landing'
import AuthLayout from './components/layouts/auth'
import EmptyLayout from './components/layouts/empty'
import ProjectLayout from './components/layouts/dashboard/project'
import DashboardLayout from './components/layouts/dashboard'
export default {
  name: 'App',
  computed: {
    isInitialRoute() {
      return this.$route.name === null
    },
    layoutComponent() {
      let currentLayout = ''
      const routeLayout = this.$route.meta.layout?.toLowerCase()
      switch (routeLayout) {
        case 'landing':
          currentLayout = LandingLayout
          break
        case 'auth':
          currentLayout = AuthLayout
          break
        case 'dashboard':
          currentLayout = DashboardLayout
          break
        case 'project':
          currentLayout = ProjectLayout
          break
        default:
          currentLayout = EmptyLayout
      }
      return currentLayout
    },
  },
}
</script>
<style lang="scss">
@import 'assets/sass/main.scss';
</style>
