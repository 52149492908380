<template>
  <b-modal class="app-modal" centered v-model="show" hide-footer hide-header>
    <div>
      <feedback
        :showIcon="true"
        v-on:close-feedback="removeError()"
        v-if="error"
        type="error"
        ><p class="text-xs text-center w-100 px-2">
          {{ error }}
        </p></feedback
      >
      <h6>Create a Repository</h6>

      <div class="form-group">
        <label for="repo_name">Repository Name</label>
        <input
          type="text"
          class="form-control"
          id="repository_name"
          placeholder="Name for this repository"
          v-model="repository.name"
        />
      </div>
      <div class="form-group">
        <label for="select_repository">Select Code Repo</label>
        <select
          name="select_repository"
          id="select_repository"
          class="form-control"
          v-model="repository.platform"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option
            v-for="integration in userIntegrations"
            v-bind:key="integration.platform"
            v-bind:value="integration.platform"
          >
            {{
              `${integration.platform[0].toUpperCase()}${integration.platform.substr(
                1
              )}`
            }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="select_repository">Private or Public Repository?</label>
        <select
          name="private_repository"
          id="private_repository"
          class="form-control"
          v-model="repository.privateRepo"
        >
          <option :value="''" disabled selected>Select an option</option>
          <option :value="1">Private</option>
          <option :value="0">Public</option>
        </select>
      </div>

      <div class="form-group">
        <label for="project_description">Description</label>
        <textarea
          name="project_description"
          id="project_description"
          class="form-control"
          cols="30"
          rows="10"
          placeholder="Briefly what this project is about"
          v-model="repository.description"
        ></textarea>
      </div>

      <div class="d-flex align-items-center mt-4">
        <Button
          :loading="loading"
          :inactive="!allFilled()"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="loginButton"
          btnClass="px-5"
          @click="addRepoToProject(repository, userSlug)"
        >
          <span class="">Create Repository</span></Button
        >
        <!-- <div class="ml-3 font-weight-normal cancel-btn" @click="show = false">
          Cancel
        </div> -->
      </div>
    </div>
  </b-modal>
</template>
<script>
import Feedback from '@/components/ui/Feedback'
import Button from '@/components/ui/Button'
// import { mapActions } from 'vuex'

export default {
  name: 'CreateRepoModal',
  components: { Button, Feedback },
  props: {
    userIntegrations: {
      type: Array,
      required: true,
    },
    projectID: {
      type: Number,
      required: true,
    },
    currentUserSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      loading: false,
      error: '',
      userSlug: this.currentUserSlug,
      repository: {
        name: '',
        platform: '',
        privateRepo: '',
        description: '',
        project_id: this.projectID,
      },
    }
  },
  watch: {
    show: {
      handler(value) {
        this.$emit('showCreateModal', value)
      },
    },
  },
  methods: {
    // ...mapActions(['handleAddRepository']),

    addRepoToProject(repo, slug) {
      this.loading = true
      this.error = ''
      const { name, description, privateRepo, project_id, platform } = repo

      const repoData = {
        name,
        description,
        private: privateRepo,
        project_id,
        platform,
      }

      const userData = {
        slug: slug,
        bodyData: repoData,
      }

      this.$store
        .dispatch('handleAddRepository', userData)
        .then(() => {
          setTimeout(this.redirect, 2500)
        })
        .catch(error => {
          this.loading = false
          this.error = error.response.data.message
        })
    },

    redirect() {
      this.loading = false
      this.show = false

      //clear the input field
      this.repository = {}

      //Redirect back to the store page
      this.$router.push({
        name: 'Repository',
        params: { uid: this.$route.params.uid },
      })
    },

    removeError() {
      this.error = ''
    },
    allFilled() {
      const { name, description, platform, privateRepo } = this.repository
      return (
        name !== '' &&
        description !== '' &&
        platform !== '' &&
        privateRepo !== ''
      )
    },
  },
  updated() {
    this.$emit('showCreateModal', false)
  },
}
</script>
