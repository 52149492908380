import * as types from '@/store/modules/website/types'

export default {
  [types.WEBSITES_STATUS](state, value) {
    state.websitesStatus = value
  },
  [types.WEBSITES](state, payload) {
    state.websites = payload
  },
  [types.DELETE_WEBSITE_STATUS](state, value) {
    state.deleteWebsiteStatus = value
  },
  [types.DELETE_WEBSITE](state, payload) {
    state.deleteWebsite = payload
  },
  [types.PROJECT_WEBSITES](state, payload) {
    state.projectWebsites = payload
  },
  [types.PROJECT_WEBSITES_STATUS](state, payload) {
    state.projectWebsitesStatus = payload
  },
  [types.WEBSITE_UPDATE_STATUS](state, payload) {
    state.websiteUpdateStatus = payload
  },
  [types.WEBSITE_UPDATE](state, payload) {
    state.websiteUpdate = payload
  },
}
