<template>
  <td class="font-weight-light">
    {{ member.profile.first }} {{ member.profile.last }}
    <span
      v-if="member.role === 'admin'"
      class="badge badge--blue-bg font-weight-normal"
      >Owner</span
    >
  </td>
  <td>{{ member.profile.email }}</td>
  <td>
    <span
      :class="`badge badge--${getButtonClass(member.profile.is_active)} px-3`"
    >
      {{ this.getButtonClass(member.profile.is_active) }}
    </span>
  </td>
  <td>{{ moment(member.created_at).format('LLL') }}</td>
  <td>
    <RemoveModal
      :isVisible="removeModalIsVisible"
      :memberDetails="member"
      @toggleModal="toggleRemoveModal"
      :businessSlug="businessSlug"
    ></RemoveModal>
    <Button
      v-if="member.role !== 'owner'"
      :loading="false"
      :inactive="false"
      :variant="'outline-grey'"
      :size="'md'"
      type="submit"
      name="submit"
      btnClass="px-5"
      @click="toggleRemoveModal(true)"
    >
      <span class="font-weight-normal">Remove</span>
    </Button>
  </td>
</template>
<script>
import Button from '@/components/ui/Button'
import RemoveModal from '@/components/dashboard/team/RemoveMemberModal'
import moment from 'moment'
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    userSlug: {
      type: String,
    },
  },
  components: { RemoveModal, Button },
  data() {
    return {
      removeModalIsVisible: false,
      moment: moment,
      businessSlug: this.userSlug,
    }
  },
  methods: {
    toggleRemoveModal(value) {
      this.removeModalIsVisible = value
    },
    getButtonClass(status) {
      switch (status) {
        case 1:
          return 'active'
        case 2:
          return 'inactive'
        default:
          return 'active'
      }
    },
  },
}
</script>
