<template>
  <div class="page__body">
    <main class="page-dashboard mt-5 px-4 py-3 border rounded-default">
      <ProjectNav page="workflow" />
      <div class="row mt-5 workflow" v-if="showTasks">
        <div class="col-3">
          <div class="px-3 py-2 workflow-column">
            <h6 class="d-flex justify-content-between align-items-center">
              Todo
              <a
                class="text-primary text-xxs font-weight-normal"
                @click="toggleModal(true)"
                >+ ADD TASK</a
              >
            </h6>
            <draggable
              class="list-group"
              :list="todo"
              group="tasks"
              @change="onAddTodo"
            >
              <div
                class="workflow-item"
                v-for="(element, index) in todo"
                :key="index"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="badge"
                    :class="`badge--${badgeClassWithPriority(
                      element?.priority
                    )}`"
                    >{{ element?.priority }}</span
                  >
                  <!--The other menu-->
                  <span>
                    <span class="font-weight-normal task-id">{{
                      element?.id
                    }}</span>
                    <li
                      class="dropdown"
                      style="display: inline-block; margin-left: 5px"
                    >
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                        ><i class="fa fa-ellipsis-h menu" aria-hidden="true"></i
                      ></a>
                      <ul class="dropdown-menu __dropdown-menu" role="menu">
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleViewModal(element, true)"
                            >View Task</a
                          >
                        </li>
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleUpdateModal(element, true)"
                            >Update Task</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleAssignModal(element, true)"
                            >Assign Member</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleRemoveModal(element, true)"
                            >Remove Assignee</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="deleteTask(element.id)"
                            >Delete Task</a
                          >
                        </li>
                      </ul>
                    </li>
                  </span>
                  <!--end-->
                </div>
                <div>
                  <p class="heading mt-3">{{ element?.title }}</p>
                  <p>
                    {{ convertDescToText(element?.description.slice(0, 70)) }}
                  </p>
                  <span v-if="element?.description.length > 70">...</span>
                </div>
              </div>
              <div v-if="todo.length <= 0" class="workflow-add-task">
                <div>
                  <h6>Add task</h6>
                  <p class="text-xs">No task assigned to anyone</p>
                  <Button
                    :loading="false"
                    :inactive="false"
                    :variant="'primary'"
                    :size="'md'"
                    type="submit"
                    name="addTask"
                    btnClass="mt-2"
                    @click="toggleModal(true)"
                  >
                    <span class="">Add task</span></Button
                  >
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="col-3">
          <div class="px-3 py-2 workflow-column">
            <h6 class="d-flex justify-content-between align-items-center">
              In Progress
              <a
                class="text-primary text-xxs font-weight-normal"
                @click="toggleModal(true)"
                >+ ADD TASK</a
              >
            </h6>
            <draggable
              class="list-group"
              :list="inProgress"
              @change="onAddInDev"
              group="tasks"
            >
              <div
                class="workflow-item"
                v-for="(element, index) in inProgress"
                :key="index"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="badge"
                    :class="`badge--${badgeClassWithPriority(
                      element?.priority
                    )}`"
                    >{{ element?.priority }}</span
                  >
                  <!--The other menu-->
                  <span>
                    <span class="font-weight-normal task-id">{{
                      element?.id
                    }}</span>
                    <li
                      class="dropdown"
                      style="display: inline-block; margin-left: 5px"
                    >
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                        ><i class="fa fa-ellipsis-h menu" aria-hidden="true"></i
                      ></a>
                      <ul class="dropdown-menu __dropdown-menu" role="menu">
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleViewModal(element, true)"
                            >View Task</a
                          >
                        </li>
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleUpdateModal(element, true)"
                            >Update Task</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleAssignModal(element, true)"
                            >Assign Member</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleRemoveModal(element, true)"
                            >Remove Assignee</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="deleteTask(element.id)"
                            >Delete Task</a
                          >
                        </li>
                      </ul>
                    </li>
                  </span>
                  <!--end-->
                </div>
                <div>
                  <p class="heading mt-3">{{ element?.title }}</p>
                  <p>
                    {{ convertDescToText(element?.description.slice(0, 70)) }}
                  </p>
                  <span v-if="element?.description?.length > 70">...</span>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="col-3">
          <div class="px-3 py-2 workflow-column">
            <h6 class="d-flex justify-content-between align-items-center">
              In Review
              <a
                class="text-primary text-xxs font-weight-normal"
                @click="toggleModal(true)"
                >+ ADD TASK</a
              >
            </h6>
            <draggable
              class="list-group"
              :list="qaReview"
              @change="onAddInReview"
              group="tasks"
            >
              <div
                class="workflow-item"
                v-for="(element, index) in qaReview"
                :key="index"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="badge"
                    :class="`badge--${badgeClassWithPriority(
                      element?.priority
                    )}`"
                    >{{ element?.priority }}</span
                  >
                  <!--The other menu-->
                  <span>
                    <span class="font-weight-normal task-id">{{
                      element?.id
                    }}</span>
                    <li
                      class="dropdown"
                      style="display: inline-block; margin-left: 5px"
                    >
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                        ><i class="fa fa-ellipsis-h menu" aria-hidden="true"></i
                      ></a>
                      <ul class="dropdown-menu __dropdown-menu" role="menu">
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleViewModal(element, true)"
                            >View Task</a
                          >
                        </li>
                        <li>
                          <a
                            href=""
                            class="__a"
                            @click.prevent="toggleUpdateModal(element, true)"
                            >Update Task</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleAssignModal(element, true)"
                            >Assign Member</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="toggleRemoveModal(element, true)"
                            >Remove Assignee</a
                          >
                        </li>
                        <li>
                          <a
                            class="__a"
                            href=""
                            @click.prevent="deleteTask(element?.id)"
                            >Delete Task</a
                          >
                        </li>
                      </ul>
                    </li>
                  </span>
                  <!--end-->
                </div>
                <div>
                  <p class="heading mt-3">{{ element?.title }}</p>
                  <p>
                    {{ convertDescToText(element?.description.slice(0, 70)) }}
                  </p>
                  <span v-if="element?.description.length > 70">...</span>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="col-3">
          <div class="px-3 py-2 workflow-column">
            <h6>Done</h6>
            <draggable
              class="list-group"
              :list="inProduction"
              @change="onAddInDone"
              group="tasks"
            >
              <div
                class="workflow-item"
                v-for="(element, index) in inProduction"
                :key="index"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="badge"
                    :class="`badge--${badgeClassWithPriority(
                      element?.priority
                    )}`"
                    >{{ element?.priority }}</span
                  >
                  <span class="font-weight-normal task-id">{{
                    element?.id
                  }}</span>
                </div>
                <div>
                  <p class="heading mt-3">{{ element?.title }}</p>
                  <p>
                    {{ convertDescToText(element?.description.slice(0, 70)) }}
                  </p>
                  <span v-if="element?.description.length > 70">...</span>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </main>
  </div>
  <Modal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
    :teamMembers="teamMembers"
    :projectID="projectID"
  ></Modal>
  <UpdateTaskModal
    :isVisible="updateTaskModalIsVisible"
    @toggleUpdateModal="toggleUpdateModal"
    :teamMembers="teamMembers"
    :projectID="projectID"
    :currentTask="selectedTask"
  ></UpdateTaskModal>
  <ViewTaskModal
    :isVisible="viewTaskModalIsVisible"
    @toggleViewModal="toggleViewModal"
    :teamMembers="teamMembers"
    :projectID="projectID"
    :currentTask="selectedTask"
  ></ViewTaskModal>
  <AssignMemberModal
    :isVisible="modalAssignMember"
    @toggleAssignModal="toggleAssignModal"
    :teamMembers="teamMembers"
    :currentTask="selectedTask"
    :projectID="projectID"
  ></AssignMemberModal>
  <RemoveMemberModal
    :isVisible="removeModalIsVisible"
    @toggleRemoveModal="toggleRemoveModal"
    :teamMembers="teamMembers"
    :currentTask="selectedTask"
    :projectID="projectID"
  ></RemoveMemberModal>
  <!-- This is a reusable modal -->
  <PilotActionsModal
    :isVisible="deleteModalIsVisible"
    @togglePilotActions="toggleDeleteTask"
    :modalObj="modalObj"
  ></PilotActionsModal>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import Button from '@/components/ui/Button'
import { VueDraggableNext } from 'vue-draggable-next'
import ProjectNav from '@/components/dashboard/project/ProjectNav'
import Modal from '@/components/dashboard/project/createTaskModal.vue'
import UpdateTaskModal from '@/components/dashboard/project/updateTaskModal.vue'
import ViewTaskModal from '@/components/dashboard/project/ViewTaskModal.vue'
import RemoveMemberModal from '@/components/dashboard/project/RemoveMemberModal.vue'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import AssignMemberModal from '@/components/dashboard/project/AssignMemberModal.vue'
import PilotActionsModal from '@/components/dashboard/project/PilotActionsModal'
import htmlToFormattedText from 'html-to-formatted-text'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Workflow',
  components: {
    ProjectNav: ProjectNav,
    UpdateTaskModal,
    AssignMemberModal,
    OverlayLoading,
    PilotActionsModal,
    Modal: Modal,
    draggable: VueDraggableNext,
    Button: Button,
    RemoveMemberModal,
    ViewTaskModal,
  },
  data() {
    return {
      task: {
        title: '',
        description: '',
        priority: '',
      },
      todo: [],
      allTasks: [],
      inProgress: [],
      qaReview: [],
      inProduction: [],
      modalIsVisible: false,
      loaderIsVisible: true,
      deleteModalIsVisible: false,
      removeModalIsVisible: false,
      updateTaskModalIsVisible: false,
      viewTaskModalIsVisible: false,
      modalAssignMember: false,
      teamMembers: [],
      selectedTask: {},
      userSlug: null,
      businessID: null,
      projectID: 0,
      showTasks: false,
      modalObj: {},
    }
  },
  methods: {
    ...mapActions([
      'handleGetAllTeamMembers',
      'handleUpdateTask',
      'handleDeleteTask',
      'handleGetTasks',
      'handleGetUsersWorkspaces',
    ]),
    toggleRemoveModal(taskObj, value) {
      //Send the selected task
      this.selectedTask = taskObj

      this.removeModalIsVisible = value
    },

    toggleDeleteTask(value) {
      this.deleteModalIsVisible = value
    },

    deleteTask(slug) {
      this.modalObj = {
        title: 'Delete Task',
        label: 'Are you sure you want to delete this task?',
        buttonText: 'Delete',
        type: 'delete task',
        action: this.handleDeleteTask,
        params: slug,
        reloadAction: this.handleGetTasks,
        reloadActionParam: this.projectID,
      }

      //toggle the modal
      this.toggleDeleteTask(true)
    },

    convertDescToText(description) {
      return htmlToFormattedText(description)
    },

    toggleModal(value) {
      this.modalIsVisible = value
    },
    toggleAssignModal(taskObj, value) {
      //Send the selected task
      this.selectedTask = taskObj

      //toggle the update button
      this.modalAssignMember = value
    },
    toggleUpdateModal(taskObj, value) {
      this.selectedTask = taskObj
      this.updateTaskModalIsVisible = value
    },
    toggleViewModal(taskObj, value) {
      this.selectedTask = taskObj
      this.viewTaskModalIsVisible = value
    },
    badgeClassWithPriority(priority) {
      switch (priority) {
        case 'important':
          return 'blue-bg'
        case 'normal':
          return 'active'
        case 'low':
          return 'grey'
        case 'critical':
          return 'inactive'
        default:
          return 'blue-bg'
      }
    },
    sortTasks() {
      this.inProgress = this.allTasks?.filter(el => {
        return el.status === 'in_progress'
      })
      this.inProduction = this.allTasks?.filter(el => {
        return el.status === 'done'
      })
      this.qaReview = this.allTasks?.filter(el => {
        return el.status === 'review'
      })
      this.todo = this.allTasks?.filter(el => {
        return el.status === 'todo'
      })
    },
    onAddTodo(event) {
      //Get the newly added task
      const addedTask = event.added

      if (event.added !== undefined) {
        //change the status of the task
        const selectedTask = addedTask.element
        this.updateTaskStatus(selectedTask, 'todo')
      }
    },
    onAddInDev(event) {
      //Get the newly added task
      const addedTask = event.added

      if (event.added !== undefined) {
        //change the status of the task
        const selectedTask = addedTask.element
        this.updateTaskStatus(selectedTask, 'in_progress')
      }
    },
    updateTaskStatus(task, status) {
      //destructure the task and change the task status
      const {
        id,
        title,
        description,
        priority,
        start_date,
        end_date,
        project_id,
      } = task

      const newTaskInfo = {
        task_id: id,
        title,
        description,
        start_date,
        priority,
        status: status,
        end_date,
        project_id,
      }

      //update the task
      this.handleUpdateTask(newTaskInfo)
    },
    onAddInReview(event) {
      //Get the newly added task
      const addedTask = event.added

      if (event.added !== undefined) {
        //change the status of the task
        const selectedTask = addedTask.element
        this.updateTaskStatus(selectedTask, 'review')
      }
    },
    onAddInDone(event) {
      //Get the newly added task
      const addedTask = event.added

      if (event.added !== undefined) {
        //change the status of the task
        const selectedTask = addedTask.element
        this.updateTaskStatus(selectedTask, 'done')
      }
    },
  },
  computed: {
    ...mapGetters([
      'getTasks',
      'getTasksStatus',
      'getProjectMembers',
      'getUserInfoStatus',
      'getUserInfo',
      'getProjectMembersStatus',
      'getProjectStatus',
      'getProject',
      'getSelectedWorkspace',
    ]),
  },
  watch: {
    getTasksStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.allTasks = this.getTasks
          this.sortTasks()
          this.showTasks = true
          this.loaderIsVisible = false
        }
      },
    },
    getProjectMembersStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          //Current user email
          const email = this.getUserInfo.email

          //Remove the current user's email from the list
          this.teamMembers = this.getProjectMembers.filter(userEmail => {
            if (email !== userEmail.profile.email) return true
          })
        }
      },
    },
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          //Get the business slug from localStorage
          this.handleGetAllTeamMembers(this.userSlug)
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.projectID = this.getProject.id
        }
      },
    },
  },
  async mounted() {
    await this.handleGetUsersWorkspaces()

    this.userSlug = this.getSelectedWorkspace?.slug
    this.businessID = this.getSelectedWorkspace?.subscription?.business_id

    if (!this.userSlug) {
      this.userSlug = await localStorage.getItem('selected_workspace_slug')
    }
  },
}
</script>

<style scoped>
.dropdown {
  list-style-type: none;
}
.__a {
  padding: 8px;
}
</style>

<style lang="scss">
/* light styling for the kanban columns */
.workflow {
  min-height: 700px;
  &-column {
    height: 100%;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    .list-group {
      margin-top: 30px;
      height: 100%;
      .workflow-item {
        display: block;
        padding: 15px;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        margin-bottom: 10px;
        background: #ffffff;
        .task-id {
          line-height: 1;
          font-size: 14px;
          color: #637a87;
        }
      }
    }
  }
  &-add-task {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.heading {
  font-size: 16px;
  font-weight: 700;
}
</style>
