<template>
  <div class="project-nav">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('website') ? 'active' : ''"
          aria-current="page"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/builder`,
          }"
          >Website Builder
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('ecommerce') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/store`,
          }"
          >eCommerce</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('repository') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/repository`,
          }"
          >Repositories</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('hosting') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/hosting`,
          }"
          >Hosting</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('deployment') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/deployments`,
          }"
          >Deployments</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('workflow') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/workflow`,
          }"
          >Taskboard</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('collaborators') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/collaborators`,
          }"
          >Collaborators</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('integrations') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/integrations`,
          }"
          >Integrations</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :class="isActive('transfer') ? 'active' : ''"
          v-bind:to="{
            path: `/dashboard/project/${projectUID}/transfer`,
          }"
          >Transfers</router-link
        >
      </li>
    </ul>
  </div>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import OverlayLoading from '@/components/ui/Loaders/overlay'

export default {
  name: 'ProjectNav',
  components: { OverlayLoading },
  props: {
    page: String,
  },
  data() {
    return {
      loaderIsVisible: true,
      projectUID: this.$route.params.uid,
      getBusinessSlug: '',
    }
  },
  methods: {
    ...mapActions([
      'fetchAllAuthStates',
      'handleGetProjectWebsites',
      'handleGetUsersStores',
      'handleGetOneProject',
      'handleGetUsersFalcons',
      'handleGetProjectRepos',
      'handleGetAllCommits',
      'handleGetUserIntegrations',
      'handleGetFalconLogs',
      'handleGetLoggedInUser',
      'handleGetAllDeployments',
      'handleGetTasks',
      'handleGetProjectMembers',
      'handleGetAllTeamMembers',
      'handleGetUsersWorkspaces',
    ]),
    isActive(page) {
      return page === this.page
    },
  },
  watch: {
    getUserInfoStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.loaderIsVisible = false
        }
      },
    },
    getProjectStatus: {
      handler(value) {
        if (value === 'COMPLETED') {
          this.handleGetTasks(this.getProject.id)
          this.handleGetProjectMembers(this.getProject.id)
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      'getUserInfoStatus',
      'getProjectStatus',
      'getProject',
      'getSelectedWorkspace',
    ]),
  },
  async created() {
    //When a tab is clicked, run all the needed tab datas
    //There won't be need to call them on each tab anymore
    this.handleGetLoggedInUser()
    this.handleGetFalconLogs(this.$route.params.uid)
    this.handleGetUsersStores(this.$route.params.uid)
    this.handleGetUsersFalcons(this.$route.params.uid)
    this.handleGetProjectWebsites(this.$route.params.uid)
    this.handleGetProjectRepos(this.$route.params.uid)
    this.handleGetAllCommits(this.$route.params.uid)
    this.handleGetAllDeployments(this.$route.params.uid)

    //Get the user slug
    const userInfo = await this.handleGetUsersWorkspaces()

    if (userInfo.status === 200) {
      this.getBusinessSlug = this.getSelectedWorkspace?.slug
      await this.handleGetUserIntegrations(this.getBusinessSlug)
      await this.handleGetAllTeamMembers(this.getBusinessSlug)
    }
  },
  async mounted() {
    //This will re-confirm the project being looked at atm
    await this.handleGetOneProject(this.$route.params.uid)
  },
}
</script>
<style scoped lang="scss">
.project-nav {
  width: 100%;
}
.nav {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  &-pills {
    border-bottom: 1px solid #eeeeee;
    .nav-item {
      .nav-link {
        white-space: nowrap;
        color: #565f62;
        font-weight: 400;
        padding: 8px 24px;
        border-radius: 0;
        transition: all 0.5s;
        &.active,
        &:hover {
          background: none;
          border-bottom: 3px solid rgba(68, 127, 252, 1);
          color: rgba(68, 127, 252, 1);
        }
      }
    }
  }
}
</style>
