<template>
  <aside :class="`outer-sub-left ${visibility.is_visible && 'show'}`">
    <nav class="d-flex align-items-start flex-column py-4 px-3">
      <div
        class="side_body"
        v-for="(sub_section, index) in sub_sections"
        :key="index"
      >
        <div v-if="visibility.name === sub_section.name">
          <div
            class="side_item"
            v-for="(item, index) in sub_sections[index].sub_sections"
            :key="index"
            :id="`${sub_section.name}/${item}`"
            @dragstart="onDragStart($event)"
            draggable="true"
          >
            <img
              :src="`/sections/${sub_section.name}/${item}/${item}.jpg`"
              alt=""
            />
            <div class="side-overlay">
              <div class="text-center">
                <svg
                  class="icon"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                >
                  <use xlink:href="/sprite.svg#icon-plus" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </aside>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubSectionBar',
  props: {
    sub_sections: {
      type: Array,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const onDragStart = event => {
      event.dataTransfer.setData('text/plain', event.target.id)
    }
    return { onDragStart }
  },
})
</script>
