<template>
  <div class="sidebar">
    <div class="sidebar__head">
      <a class="sidebar__logo">
        <img
          class="sidebar__pic sidebar__pic_light"
          src="@/assets/img/logo.svg"
          alt=""
        />
      </a>
      <button class="sidebar__close">
        <svg class="icon" width="30px" height="30px" viewBox="0 0 24 24">
          <use xlink:href="/sprite.svg#icon-close"></use>
        </svg>
      </button>
    </div>
    <div class="sidebar__body">
      <div class="dropdown">
        <div
          class="sidebar__workspace dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div v-if="this.workspaces !== []" class="sidebar__workspace-details">
            <p class="sidebar__workspace-title">WORKSPACE</p>
            <p class="sidebar__workspace-name">
              {{ currentWorkspace?.name }}
            </p>
          </div>
          <svg
            class="sidebar__workspace-icon"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <use xlink:href="/sprite.svg#icon-angle-down"></use>
          </svg>
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <router-link
            :to="{ name: 'DashboardOverview' }"
            class="dropdown-item"
            v-for="(workspace, index) in workspaces"
            :key="index"
          >
            <div v-if="workspace !== null || workspace !== undefined">
              <div
                class="sidebar__workspace-details"
                @click="switchWorkspace(workspace)"
              >
                <!-- <p class="sidebar__workspace-name">Current Workspace</p> -->
                <p class="sidebar__workspace-title">
                  {{ workspace?.name }}
                </p>
              </div>
            </div>
          </router-link>
          <div class="dropdown-item dropdown-btn">
            <Button
              :loading="false"
              :inactive="false"
              :variant="'outline'"
              :size="'md'"
              type="submit"
              name="loginButton"
              btnClass="btn-block mb-3"
              @click="toggleModal(true)"
            >
              <!-- :inactive="!validate" -->
              <span class="">Create new workspace</span></Button
            >
          </div>
        </div>
      </div>
      <nav class="sidebar__nav">
        <router-link
          to="/dashboard"
          :class="isActive('DashboardOverview') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-chart"></use>
            </svg>
          </div>
          <div class="sidebar__text">Dashboard</div>
        </router-link>
        <router-link
          to="/dashboard/projects"
          :class="isActive('DashboardProjects') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-activity"></use>
            </svg>
          </div>
          <div class="sidebar__text">Projects</div>
        </router-link>
        <router-link
          to="/dashboard/teams"
          :class="isActive('DashboardTeams') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-chart"></use>
            </svg>
          </div>
          <div class="sidebar__text">Team</div>
        </router-link>
        <router-link
          to="/dashboard/integrations"
          :class="isActive('DashboardIntegration') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-password"></use>
            </svg>
          </div>
          <div class="sidebar__text">Integrations</div>
        </router-link>
        <router-link
          to="/dashboard/profile"
          :class="isActive('DashboardProfile') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-profile"></use>
            </svg>
          </div>
          <div class="sidebar__text">Profile</div>
        </router-link>
        <router-link
          to="/dashboard/billing"
          :class="isActive('DashboardBilling') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-wallet"></use>
            </svg>
          </div>
          <div class="sidebar__text">Billing</div>
        </router-link>
        <router-link
          to="/dashboard/hire-expert"
          :class="isActive('DashboardHireExpert') ? 'active' : ''"
          class="sidebar__item"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-discovery"></use>
            </svg>
          </div>
          <div class="sidebar__text">Hire a Fybrr Expert</div>
        </router-link>
        <router-link
          to="/logout"
          :class="isActive('Logout') ? 'active' : ''"
          class="sidebar__item sidebar__last"
        >
          <div class="sidebar__icon">
            <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24">
              <use xlink:href="/sprite.svg#icon-d-logout"></use>
            </svg>
          </div>
          <div class="sidebar__text">Logout</div>
        </router-link>
      </nav>
    </div>
    <div class="sidebar__bottom">
      <div class="sidebar__profile">
        <div class="sidebar__user" href="#">
          <div class="sidebar__desc">
            <div class="sidebar__man">HAVE SOMETHING TO SELL?</div>
            <div class="sidebar__status caption">Create online store</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateWorkspaceModal
    :isVisible="modalIsVisible"
    @toggleModal="toggleModal"
  ></CreateWorkspaceModal>
  <OverlayLoading v-if="loaderIsVisible" />
</template>
<script>
import Button from '@/components/ui/Button'
import $ from 'jquery'
import CreateWorkspaceModal from '@/components/dashboard/CreateWorkspaceModal'
import OverlayLoading from '@/components/ui/Loaders/overlay'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { Button, CreateWorkspaceModal, OverlayLoading },
  data() {
    return {
      modalIsVisible: false,
      business: {},
      workspaces: null,
      currentWorkspace: {},
      currentSlug: '',
      loaderIsVisible: true,
    }
  },
  methods: {
    ...mapActions([
      'fetchAllAuthStates',
      'handleGetUsersWorkspaces',
      'handleSwitchWorkspace',
    ]),
    isActive(routeName) {
      return this.$route.name === routeName
    },
    async switchWorkspace(workspace) {
      this.loaderIsVisible = true
      //switch the workspace
      await this.fetchAllAuthStates(workspace?.slug)

      //change the current workspace
      this.currentWorkspace = workspace

      //Check if the user should go back to dashboard
      //If I don't do this, when the page is reloaded,
      //It will go to the dashboard
      let oldUserSlug = await localStorage.getItem('selected_workspace_slug')

      if (oldUserSlug !== workspace?.slug) {
        //set the new workspace name in the localStorage
        await localStorage.setItem('selected_workspace_slug', workspace?.slug)

        //update the vuex data
        await this.handleSwitchWorkspace(this.workspaces)

        //go to the dashboard
        this.$router.push({ name: 'DashboardOverview' })
      }

      //disable loader
      this.loaderIsVisible = false
    },
    toggleModal(value) {
      this.modalIsVisible = value
    },
  },
  async mounted() {
    let toggle = $('.sidebar__toggle'),
      page = $('.page'),
      sidebar = $('.sidebar'),
      headerToggle = $('.header__toggle'),
      logo = $('.header__logo'),
      close = $('.sidebar__close'),
      sidebar__user = $('.sidebar__user'),
      sidebar__detail = $('.sidebar__details')
    toggle.on('click', function () {
      sidebar.toggleClass('active')
      page.toggleClass('wide')
    })

    headerToggle.on('click', function () {
      sidebar.addClass('active')
      page.addClass('toggle')
      logo.addClass('hidden')
      $('body').addClass('no-scroll')
      $('html').addClass('no-scroll')
    })

    close.on('click', function () {
      sidebar.removeClass('active')
      page.removeClass('toggle')
      logo.removeClass('hidden')
      $('body').removeClass('no-scroll')
      $('html').removeClass('no-scroll')
    })
    sidebar__user.on('click', function () {
      sidebar__user.toggleClass('active')
      sidebar__detail.toggleClass('show')
    })

    //Setup the user workspace logics
    await this.handleGetUsersWorkspaces().then(async result => {
      this.workspaces = result.data.data

      //fetch current slug from the localStorage
      this.currentSlug = await localStorage.getItem('selected_workspace_slug')

      if (this.currentSlug === 'undefined' || !this.currentSlug) {
        //if null, use the default workspace
        await localStorage.removeItem('selected_workspace_slug')
        this.currentSlug = this.workspaces[this.workspaces.length - 1].slug
      }

      //set the current slug
      this.currentWorkspace = this.workspaces.filter(workspace => {
        if (workspace !== null || workspace !== undefined) {
          return workspace?.slug === this.currentSlug
        }
      })

      //switch the workspace
      this.switchWorkspace(this.currentWorkspace[0])
    })
  },
  computed: {
    ...mapGetters(['getBusinessInfo', 'getWorkspaces', 'getWorkspacesStatus']),
  },
}
</script>
