import http from '../../config/axios'

export const integrationUrls = {
  GET_CONFIG: 'repositories',
  INTEGRATE_URL: 'repositories/integrate',
}

/** getIntegrationConfig */
const getIntegrationConfig = async platform => {
  return await http.get(`${integrationUrls.GET_CONFIG}/${platform}/config`)
}

/** integratePlatform */
const integratePlatform = async params => {
  return await http.post(integrationUrls.INTEGRATE_URL, params)
}

/** revokePlatform */
const revokePlatform = async params => {
  return await http.delete(
    `${integrationUrls.GET_CONFIG}/${params.slug}/${params.platform}`,
    params
  )
}

export { getIntegrationConfig, integratePlatform, revokePlatform }
