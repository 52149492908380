<template>
  <div class="card rounded-default p-4">
    <div class="card-body">
      <div class="image-size mx-auto d-block">
        <img
          :src="`/assets/img/integration/${integrationDetails?.platform}.png`"
          :alt="integrationDetails.name"
        />
      </div>
      <h6 class="text-center">{{ integrationDetails?.platform }}</h6>
      <div class="d-flex mt-4">
        <span
          class="badge badge--blue"
          v-for="(badge, index) in integrationDetails.badges"
          :key="index"
          >{{ badge }}</span
        >
      </div>
      <!-- <div class="project-card-info">
        <div class="project-card-info__details">
          <p>Added at</p>
          <h6>{{ moment(integrationDetails.created_at).format('LLL') }}</h6>
        </div>
      </div> -->
      <!-- <div class="project-card-button mt-3 d-flex align-items-center">
        <Button
          v-if="integrationDetails.isActive"
          :loading="false"
          :inactive="false"
          :variant="'primary'"
          :size="'md'"
          type="submit"
          name="editProject"
          btnClass="mr-3 mt-1"
          @click="''"
        >
          <span class="">Add Integration</span></Button
        >
        <Button
          v-else
          :loading="false"
          :inactive="false"
          :variant="'outline-danger'"
          :size="'md'"
          type="submit"
          name="editIntegration"
          btnClass="mt-1"
          @click="''"
        >
          <span class="">Remove Integration</span></Button
        >
      </div> -->
    </div>
  </div>
</template>
<script>
// import Button from '@/components/ui/Button'
import moment from 'moment'

export default {
  name: 'SingleIntegrationCard',
  components: {},
  props: {
    integrationDetails: {
      type: Object,
    },
  },
  data() {
    return {
      moment: moment,
    }
  },
}
</script>
<style lang="scss">
.min-h-60 {
  min-height: 60px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
