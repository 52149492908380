<template>
  <div
    :id="type === 'success' || type === 'info' ? 'success-bar' : 'error-bar'"
    :class="
      type === 'success' || type === 'info'
        ? ' bg-success-60 text-green-700 border-success-70'
        : ' bg-danger-50 text-danger text-failed-20 border-failed-20'
    "
    class="w-100 px-3 text-center rounded mt-3 mb-5 py-3 border d-inline-flex justify-content-between"
  >
    <div v-if="showIcon" class="d-inline-flex w-80">
      <svg
        v-if="type === 'success' || type === 'info'"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1C7.41775 1 5.87103 1.46919 4.55544 2.34824C3.23985 3.22729 2.21447 4.47672 1.60897 5.93853C1.00347 7.40034 0.84504 9.00887 1.15372 10.5607C1.4624 12.1126 2.22433 13.538 3.34315 14.6569C4.46197 15.7757 5.88743 16.5376 7.43928 16.8463C8.99113 17.155 10.5997 16.9965 12.0615 16.391C13.5233 15.7855 14.7727 14.7602 15.6518 13.4446C16.5308 12.129 17 10.5823 17 9C17 6.87827 16.1571 4.84344 14.6569 3.34315C13.1566 1.84285 11.1217 1 9 1ZM14.225 6.315L7.655 12.88L3.775 9C3.6424 8.86739 3.5679 8.68754 3.5679 8.5C3.5679 8.31246 3.6424 8.13261 3.775 8C3.90761 7.86739 4.08747 7.79289 4.275 7.79289C4.46254 7.79289 4.6424 7.86739 4.775 8L7.665 10.89L13.235 5.325C13.3007 5.25934 13.3786 5.20725 13.4644 5.17172C13.5502 5.13618 13.6421 5.11789 13.735 5.11789C13.8279 5.11789 13.9198 5.13618 14.0056 5.17172C14.0914 5.20725 14.1693 5.25934 14.235 5.325C14.3007 5.39066 14.3527 5.46861 14.3883 5.5544C14.4238 5.64019 14.4421 5.73214 14.4421 5.825C14.4421 5.91786 14.4238 6.00981 14.3883 6.0956C14.3527 6.18139 14.3007 6.25934 14.235 6.325L14.225 6.315Z"
          fill="#018B38"
        />
      </svg>
      <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 4V16C3 17.103 3.897 18 5 18H8.5L12 22L15.5 18H19C20.103 18 21 17.103 21 16V4C21 2.897 20.103 2 19 2H5C3.897 2 3 2.897 3 4ZM11 5H13V11H11V5ZM11 13H13V15H11V13Z"
          fill="#D97070"
        />
      </svg>
      <slot></slot>
    </div>
    <button type="button" @click="$emit('close-feedback')" v-if="cancelButton">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="white" />
        <path
          d="M10.5481 10.0003L13.3831 7.16526C13.4468 7.09087 13.4801 6.99517 13.4763 6.8973C13.4725 6.79943 13.4319 6.70658 13.3627 6.63732C13.2934 6.56806 13.2006 6.52749 13.1027 6.52371C13.0048 6.51993 12.9091 6.55322 12.8347 6.61693L9.99972 9.45193L7.16472 6.61304C7.09032 6.54933 6.99463 6.51604 6.89675 6.51982C6.79888 6.5236 6.70604 6.56418 6.63678 6.63343C6.56752 6.70269 6.52694 6.79554 6.52316 6.89341C6.51938 6.99128 6.55267 7.08698 6.61639 7.16138L9.45139 10.0003L6.6125 12.8353C6.57179 12.8701 6.53872 12.913 6.51538 12.9613C6.49204 13.0095 6.47892 13.0621 6.47685 13.1156C6.47478 13.1692 6.48381 13.2226 6.50336 13.2725C6.52291 13.3224 6.55257 13.3677 6.59047 13.4056C6.62836 13.4435 6.67369 13.4732 6.72359 13.4927C6.7735 13.5123 6.8269 13.5213 6.88046 13.5192C6.93402 13.5172 6.98657 13.5041 7.03482 13.4807C7.08307 13.4574 7.12597 13.4243 7.16083 13.3836L9.99972 10.5486L12.8347 13.3836C12.9091 13.4473 13.0048 13.4806 13.1027 13.4768C13.2006 13.473 13.2934 13.4325 13.3627 13.3632C13.4319 13.2939 13.4725 13.2011 13.4763 13.1032C13.4801 13.0054 13.4468 12.9097 13.3831 12.8353L10.5481 10.0003Z"
          :fill="type === 'success' || type === 'info' ? '#018B38' : ' #D75C5C'"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Feedback',
  props: {
    type: {
      type: String,
      default: 'success',
      validator: value => ['success', 'info', 'error'].indexOf(value) !== -1,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showContent: true,
    }
  },
}
</script>
<style scoped>
.bg-success-60 {
  background-color: #e6ffeb;
}
.border-success-70 {
  border-color: #066f29;
}
.bg-danger-50 {
  background-color: #ffe8e8;
}
.border-failed-20 {
  border-color: #cc2e2e;
}
.w-80 {
  width: 80%;
}
</style>
