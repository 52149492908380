import http from '../../config/axios'
// import axios from 'axios'

export const repositoryUrls = {
  GET_ALL_URL: 'projects',
  GET_INTEGRATIONS_URL: 'repositories',
  CREATE_REPOSITORY_URL: 'projects/repositories',
  FETCH_REPOS: 'repositories',
}

/** getAllRepositories */
const getProjectRepos = async projectUID => {
  return await http.get(
    `${repositoryUrls.GET_ALL_URL}/${projectUID}/repositories`
  )
}

/** deleteRepoByID */
const deleteRepoByID = async params => {
  return await http.delete(
    `${repositoryUrls.GET_ALL_URL}/${params.projectUID}/repositories/${params.id}`
  )
}

/** fetchAllCommits */
const fetchAllCommits = async projectUId => {
  return await http.get(
    `${repositoryUrls.GET_ALL_URL}/${projectUId}/repo-activities`
  )
}

const fetchUserIntegrations = async userSlug => {
  return await http.get(
    `${repositoryUrls.GET_INTEGRATIONS_URL}/${userSlug}/integrations`
  )
}

const fetchReposToIntegrate = async params => {
  const { platform, slug } = params
  return await http.get(
    `${repositoryUrls.FETCH_REPOS}/${slug}?platform=${platform}`
  )
}

const addRepository = async userData => {
  return await http.post(
    `${repositoryUrls.CREATE_REPOSITORY_URL}/${userData.slug}/create`,
    userData.bodyData
  )
}

const importRepo = async params => {
  return await http.post(
    `${repositoryUrls.CREATE_REPOSITORY_URL}/${params.slug}/import`,
    params.paramsObj
  )
}

export {
  getProjectRepos,
  deleteRepoByID,
  fetchAllCommits,
  fetchUserIntegrations,
  addRepository,
  fetchReposToIntegrate,
  importRepo,
}
